import { Box, Flex, ModalCloseButton, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import {
  MdOutlineChevronRight,
  MdOutlineFolder,
  MdOutlineStore,
} from "react-icons/md";
import { useSelector } from "react-redux";

import { Breadcrumb, BreadcrumbItem } from "@/design/components/breadcrumbs";
import { Button } from "@/design/components/button";
import { Icon } from "@/design/components/icon";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@/design/components/modal";
import { useSyncObjectMutation } from "@/features/user-manager";
import { useAppDispatch } from "@/reduxHooks.ts";
import { closeModal } from "@/slices/modal-slice.ts";
import { RootState } from "@/store";

const BreadCrumbRow = ({
  project,
  workspace,
}: {
  project: string;
  workspace: string;
}) => {
  return (
    <Breadcrumb
      separator={<MdOutlineChevronRight />}
      spacing="0.125rem"
      color="gray.700"
    >
      <BreadcrumbItem icon={<Icon as={MdOutlineStore} size="md" />}>
        <Text className="font-medium text-md">{workspace}</Text>
      </BreadcrumbItem>
      <BreadcrumbItem icon={<Icon as={MdOutlineFolder} size="md" />}>
        <Text className="font-medium text-md">{project}</Text>
      </BreadcrumbItem>
    </Breadcrumb>
  );
};

const MoveAnalysisModal: React.FC = () => {
  const { modalProps } = useSelector(
    (state) => (state as RootState).rootReducer.modals
  );
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [syncObjectApi] = useSyncObjectMutation();

  const onClose = () => {
    dispatch(closeModal());
    modalProps.onClose?.();
  };

  const onPrimaryAction = async () => {
    try {
      setIsLoading(true);
      await modalProps?.primaryAction();
      await syncObjectApi({
        sourceObjectId: modalProps.sourceObjectId,
        sourceObjectType: modalProps.sourceObjectType,
        targetObjectId: modalProps.targetObjectId,
        targetObjectType: modalProps.targetObjectType,
      });
      dispatch(closeModal());
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      closeOnOverlayClick={!isLoading}
      isCentered
      size="lg"
    >
      <ModalOverlay />
      <ModalContent className="!py-4" maxWidth="700px">
        <ModalHeader>{modalProps.title}</ModalHeader>
        <ModalCloseButton className="!mr-0" isDisabled={isLoading} />
        <ModalBody className="!px-6">
          <Flex className="flex-col gap-y-4">
            <Flex className="items-center flex-wrap">
              <Text as="span">You are about to move </Text>
              <Text className="font-bold mx-1" as="span">
                {modalProps.name}
              </Text>
              <Text as="span">to </Text>
              <Box className="ml-1 inline-flex">
                <BreadCrumbRow
                  workspace={modalProps.workspaceName || ""}
                  project={modalProps.projectName || ""}
                />
              </Box>
            </Flex>

            {modalProps.subDescription && (
              <Text mt={2}>{modalProps.subDescription}</Text>
            )}
          </Flex>
        </ModalBody>
        <ModalFooter className="!px-6 mt-6 !flex !justify-between">
          <Button
            isDisabled={isLoading}
            onClick={onClose}
            variant="ghost"
            colorScheme="secondary"
          >
            Cancel
          </Button>
          <Button
            variant={modalProps.primaryButtonVariant ?? "solid"}
            colorScheme={modalProps.primaryButtonColorScheme ?? "secondary"}
            onClick={onPrimaryAction}
            isLoading={isLoading}
          >
            {modalProps.primaryButtonText ?? "Move"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default MoveAnalysisModal;
