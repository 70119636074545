import { Portal } from "@chakra-ui/react";
import React from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { MdMoreVert, MdOutlineEdit, MdOutlineGroup } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

import { ProtectedComponent } from "@/components/protected-component/protected-component";
import { useShowToast } from "@/components/toast";
import { MODAL_MESSAGES } from "@/constants/alerts-messages.ts";
import { TOAST_MESSAGES } from "@/constants/toast-constants.ts";
import { Icon } from "@/design/components/icon";
import { IconButton } from "@/design/components/icon-button";
import { Menu, MenuButton, MenuItem, MenuList } from "@/design/components/menu";
import { useDeleteWsMutation } from "@/features/ws-manager";
import { WorkspaceSchema } from "@/features/ws-manager/types";
import { useAppDispatch } from "@/reduxHooks.ts";
import { ModalTypes, openModal } from "@/slices/modal-slice.ts";
import { CATEGORY, PERMISSIONS } from "@/utils/enums.ts";

export const WorkspaceDropdown = (props: WorkspaceSchema) => {
  const [deleteWs] = useDeleteWsMutation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const toast = useShowToast();
  const onDelete = () => {
    dispatch(
      openModal({
        modalType: ModalTypes.CONFIRMATION,
        modalProps: {
          ...MODAL_MESSAGES.deleteWorkspace,
          primaryAction: () => {
            deleteWs(props.id);
            toast(TOAST_MESSAGES.workspaceDeleted(props.name));
          },
        },
      })
    );
  };

  const onEdit = () => {
    dispatch(
      openModal({
        modalType: ModalTypes.WORKSPACE_CREATION,
        modalProps: { data: props },
      })
    );
  };

  const onViewInfo = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    dispatch(
      openModal({
        modalType: ModalTypes.INFO,
        modalProps: { type: CATEGORY.Workspace, data: props, navigate },
      })
    );
  };
  const openManageAccessModal = () => {
    dispatch(
      openModal({
        modalType: ModalTypes.MANAGE_ACCESS,
        modalProps: {
          object: props,
          type: CATEGORY.Workspace,
          navigate: navigate,
        },
      })
    );
  };

  return (
    <Menu placement="bottom-end">
      <MenuButton
        as={IconButton}
        icon={<MdMoreVert />}
        variant="ghost"
        onContextMenu={(e) => e.stopPropagation()}
        colorScheme="dark"
        onClick={(e) => {
          e.stopPropagation();
        }}
      />
      <Portal>
        <MenuList onContextMenu={(e) => e.stopPropagation()}>
          <MenuItem
            className="text-gray-900"
            icon={<Icon as={AiOutlineInfoCircle} />}
            onClick={onViewInfo}
          >
            View Workspace Info
          </MenuItem>
          <ProtectedComponent
            requiredPermission={PERMISSIONS.WRITE}
            id={props.id}
            type={CATEGORY.Workspace}
          >
            <MenuItem
              className="text-gray-900"
              icon={<Icon as={MdOutlineEdit} />}
              onClick={onEdit}
            >
              Edit Workspace Details
            </MenuItem>
          </ProtectedComponent>
          <ProtectedComponent
            requiredPermission={PERMISSIONS.WRITE}
            id={props.id}
            type={CATEGORY.Workspace}
          >
            <MenuItem
              onClick={openManageAccessModal}
              className="text-gray-900"
              icon={<Icon as={MdOutlineGroup} />}
            >
              Manage Access
            </MenuItem>
          </ProtectedComponent>
          <ProtectedComponent
            requiredPermission={PERMISSIONS.DELETE}
            id={props.id}
            type={CATEGORY.Workspace}
          >
            <MenuItem
              color="red.600"
              onClick={onDelete}
              icon={<Icon as={RiDeleteBin6Line} />}
            >
              Delete Workspace
            </MenuItem>
          </ProtectedComponent>
        </MenuList>
      </Portal>
    </Menu>
  );
};
