import { Box, Spinner } from "@chakra-ui/react";
import { useCallback, useEffect, useRef, useState, useMemo } from "react";
import { MdErrorOutline } from "react-icons/md";
import { useParams } from "react-router-dom";
import { Edge, Node, ReactFlowProvider } from "reactflow";

import { ToastType, useShowToast } from "@/components/toast";
import { CUSTOM_QUERY, resetState } from "@/features/data-transformation";
import { useCustomQuery } from "@/hooks/useCustomQuery.ts";
import usePermissions from "@/hooks/usePermissions";
import { useAppDispatch, useAppSelector } from "@/reduxHooks.ts";
import { currentUserMetadata } from "@/slices/auth-slice";
import { ModalTypes, openModal } from "@/slices/modal-slice";
import { CATEGORY, PERMISSIONS, WF_ACCESS_MODE } from "@/utils/enums";

import {
  hideAllPanels,
  hidePanel,
  selectPanel,
  showPanel,
  showLLMConfigPanel,
  useGetNodesFromUsageInstanceIdMutation,
  useGetWFActiveUserQuery,
} from "..";
import {
  useGetWorkflowQuery,
  useRequestEditAccessMutation,
} from "../api/workflow-api";
import FlowEditor from "../components/flow-editor";
import { setLastSavedTime } from "../redux";
import {
  currentWfActiveUser,
  currentWorkflowId,
  getIsInactiveModalOpen,
  setCurrentWfActiveUser,
  setEditingAllowed,
  setIsInactiveModalOpen,
  setReferenceRunId,
  setWorkflowAccessMode,
  setWorkFlowId,
  setWorkflowRunId,
  setWorkflowRunStatus,
  setWorkflowState,
  setWorkflowVersionTagId,
  workflowRunningStatus,
} from "../redux/workflow-slice";
import { NodeType, EventType } from "../types";
import {
  NODE_STATUS,
  TIMEOUT_MODAL_TIME,
  WORKFLOW_PANELS,
} from "../utils/constants";
import { createReactflowObjectFromWorkflow } from "../utils/transform-response";

export const Editor = () => {
  const workflowId = useAppSelector(currentWorkflowId);
  const dispatch = useAppDispatch();
  const params = useParams();
  const workflowLoadedRef = useRef(false);
  const { checkPermission } = usePermissions();
  const currentUserId = useAppSelector(currentUserMetadata)?.userId;

  const { currentParam, removeCurrentParam } = useCustomQuery(CUSTOM_QUERY);

  const transformPanel = useAppSelector(
    selectPanel(WORKFLOW_PANELS.DataTransformationPanel)
  );
  const wfRunningStatus = useAppSelector(workflowRunningStatus);
  const llmPanel = useAppSelector(showLLMConfigPanel);
  const isInactiveModalOpen = useAppSelector(getIsInactiveModalOpen);

  const hasEditAccess = checkPermission({
    requiredPermission: PERMISSIONS.WRITE,
    type: CATEGORY.Analysis,
    id: params["analysisId"]!,
  });

  const [initialNodes, setInitialNodes] = useState<Node[]>([]);

  const [initialEdges, setInitialEdges] = useState<Edge[]>([]);
  const currentActiveUser = useAppSelector(currentWfActiveUser);
  const {
    isLoading: isFetchingWorkflow,
    data,
    isError,
    error: workflowError,
  } = useGetWorkflowQuery(
    {
      workflowId: workflowId ?? params["editorId"]!,
    },
    { refetchOnMountOrArgChange: true }
  );

  const workflow = data?.response?.data?.workflows[0];

  const isWFRunning =
    wfRunningStatus === NODE_STATUS.RUNNING ||
    wfRunningStatus === NODE_STATUS.CANCELLING;

  const [getNodeList, { isLoading: loadingNodes }] =
    useGetNodesFromUsageInstanceIdMutation();

  const [requestEditAccess] = useRequestEditAccessMutation();

  const { data: activeUserData, isError: errorInActiveUser } =
    useGetWFActiveUserQuery(
      {
        analysisId: params["analysisId"]!,
      },
      {
        skip: !params["analysisId"] || isWFRunning,
        pollingInterval: 10000,
        refetchOnMountOrArgChange: true,
      }
    );

  const logsPanel = useAppSelector(selectPanel(WORKFLOW_PANELS.LogsPanel));
  const toast = useShowToast();

  const hasBlockingEvents = useMemo(() => {
    return (
      activeUserData?.data?.results?.events?.some(
        (event) => event.eventType === EventType.ACTIONABLE_BLOCKER
      ) ?? false
    );
  }, [activeUserData?.data?.results?.events]);

  // Track previous panel visibility to detect close attempts
  const prevPanelVisibleRef = useRef(logsPanel.isVisible);

  // Effect to keep panel open when there are blocking events
  useEffect(() => {
    // Only enforce panel visibility for active users
    if (!activeUserData?.data?.results?.activeUser?.isCurrentUser) return;

    // Detect if this was a close attempt (panel was visible and is now trying to close)
    const isCloseAttempt = prevPanelVisibleRef.current && !logsPanel.isVisible;
    prevPanelVisibleRef.current = logsPanel.isVisible;

    if (hasBlockingEvents && !logsPanel.isVisible) {
      // console.log('🔒 Forcing logs panel to stay open - blocking events present');

      // If this was a close attempt, show notification to user
      if (isCloseAttempt) {
        toast({
          title: "Cannot Close Panel",
          description:
            "Please resolve all blocking issues before closing the logs panel.",
          status: ToastType.Warning,
          duration: 5000,
          isClosable: true,
        });
      }

      dispatch(showPanel({ panel: WORKFLOW_PANELS.LogsPanel }));
    }
  }, [
    hasBlockingEvents,
    logsPanel.isVisible,
    dispatch,
    toast,
    activeUserData?.data?.results?.activeUser?.isCurrentUser,
  ]);

  useEffect(() => {
    if (activeUserData) {
      const results = activeUserData?.data?.results;
      if (errorInActiveUser) {
        dispatch(setEditingAllowed(false));
        dispatch(setCurrentWfActiveUser(null));
        dispatch(setWorkflowAccessMode(WF_ACCESS_MODE.LOCKOUT));
        return;
      }

      if (
        currentActiveUser?.isCurrentUser &&
        !results?.activeUser?.isCurrentUser
      ) {
        dispatch(hideAllPanels());
      }

      if (results?.activeUser.isCurrentUser && !workflowLoadedRef.current) {
        // make this api call on first load to reset timer in BE
        requestEditAccess({
          analysisId: params["analysisId"]!,
        }).catch((err) => {
          console.log(err);
        });
      }

      if (results?.activeUser) {
        const seconds = results.activeUser.timeSinceLastActivity?.seconds ?? 0;
        const timeout = results.timeout || TIMEOUT_MODAL_TIME;

        /*
        Conditions to show timeout warning:
        0. less than 60 seconds are left
        1. User is current user
        2. inactive modal is not already open 
        3. Workflow is loaded
        4. Workflow is not running
        */
        if (
          seconds > timeout - 70 &&
          results?.activeUser.isCurrentUser &&
          !isInactiveModalOpen &&
          workflowLoadedRef.current &&
          workflow?.workflowStatus !== NODE_STATUS.RUNNING
        ) {
          dispatch(setIsInactiveModalOpen(true));
          dispatch(
            openModal({
              modalType: ModalTypes.TIMEOUT_WARNING,
              modalProps: {
                analysisId: params["analysisId"]!,
                initialSeconds: seconds,
                timeOut: results.timeout - 10 || TIMEOUT_MODAL_TIME,
              },
            })
          );
        }

        dispatch(setCurrentWfActiveUser(results?.activeUser));
        dispatch(setWorkflowState(results?.workflowState));
        dispatch(setWorkflowAccessMode(results?.workflowAccessMode));

        const isCurrentUserEditing =
          results?.activeUser?.user?.id === currentUserId;

        const shouldLockWF =
          !hasEditAccess ||
          results.workflowAccessMode === WF_ACCESS_MODE.LOCKOUT ||
          wfRunningStatus === NODE_STATUS.RUNNING ||
          wfRunningStatus === NODE_STATUS.CANCELLING;

        if (shouldLockWF) {
          dispatch(setEditingAllowed(false));
          return;
        } else if (isCurrentUserEditing) {
          dispatch(setEditingAllowed(true));
          return;
        } else {
          dispatch(setEditingAllowed(false));
        }
      }
    }
  }, [
    activeUserData,
    currentUserId,
    errorInActiveUser,
    isInactiveModalOpen,
    wfRunningStatus,
    hasEditAccess,
  ]);

  // This removed edaid  if user has refreshed the page
  useEffect(() => {
    if (transformPanel.isVisible) return;
    if (currentParam) {
      removeCurrentParam();
      dispatch(resetState());
    }
  }, [transformPanel.isVisible, currentParam]);

  useEffect(() => {
    return () => {
      dispatch(hidePanel(WORKFLOW_PANELS.DataTransformationPanel));
    };
  }, []);

  useEffect(() => {
    dispatch(setWorkFlowId(params["editorId"]!));
  }, [params, dispatch]);

  const getNodeDetailsFromInstanceIds = useCallback(
    async (nodeUsageInstanceId: string[]) => {
      let nodeList: NodeType[] = [];
      const nodeResponse = await getNodeList({
        analysisId: params["analysisId"] as string,
        nodeUsageIdList: nodeUsageInstanceId,
      }).unwrap();
      nodeList = nodeResponse.response.data!.nodes;
      return nodeList;
    },
    [getNodeList, params.editorId]
  );

  const initialWorkflowSetup = useCallback(async () => {
    // console.log('🔄 Initial Workflow Setup - Started');
    
    // if workflow is not loaded or has errors, return
    if (!workflow?.workflowNodes) return;

    // Load workflow only once
    if (workflowLoadedRef.current) return;
    workflowLoadedRef.current = true;

    if (!isError && !data) return;

    // console.log('🔄 Initial Workflow Setup - Loading workflow data');

    // extract nodeUsageInstanceId from workflowNodes
    const nodesInWorkflow = workflow.workflowNodes
      .flatMap((node) => node.nodeUsageInstanceId)
      .filter((node) => node !== null);

    // if nodes are present, fetch node details using nodeUsageInstanceId
    if (nodesInWorkflow?.length > 0) {
      const nodeDetails = await getNodeDetailsFromInstanceIds(
        nodesInWorkflow as string[]
      );

      const { configuredNodes, configuredEdges } =
        createReactflowObjectFromWorkflow(nodeDetails, workflow);
      setInitialEdges(configuredEdges);
      setInitialNodes(configuredNodes);
    } else {
      setInitialEdges([]);
      setInitialNodes([]);
    }

    console.log("🔄 Setting workflow state", {
      workflowRunId: workflow.workflowRunId,
      workflowVersionTagId: workflow.workflowVersionTagId,
      referenceRunId: workflow.referenceRunId,
      workflowStatus: workflow.workflowStatus,
      lastSavedOn: workflow.lastSavedOn,
    });
    dispatch(setWorkflowRunId(workflow.workflowRunId as string));
    dispatch(setWorkflowVersionTagId(workflow.workflowVersionTagId));
    dispatch(setReferenceRunId(workflow.referenceRunId as string));
    dispatch(setWorkflowRunStatus(workflow.workflowStatus));
    dispatch(setLastSavedTime(workflow.lastSavedOn!));

    // console.log('🔄 Initial Workflow Setup - Completed');
  }, [workflow, dispatch, isError, getNodeDetailsFromInstanceIds]);

  // Track if initial setup is complete
  const [isInitialSetupComplete, setIsInitialSetupComplete] = useState(false);

  useEffect(() => {
    if (llmPanel.isVisible) return;
    initialWorkflowSetup()
      .then(() => {
        // console.log('🔄 Setting initial setup complete');
        setIsInitialSetupComplete(true);
      })
      .catch((err) => {
        console.log(err);
        setIsInitialSetupComplete(true); // Set to true even on error to allow panel to show
      });
  }, [data]);

  // Replace the existing useEffect for showing logs panel with this enhanced version
  useEffect(() => {
    // console.log('🔍 Logs Panel Effect - Started', {
    //   hasActiveUserData: !!activeUserData,
    //   results: activeUserData?.data?.results,
    //   isInitialSetupComplete
    // });

    if (!isInitialSetupComplete) {
      // console.log('🔍 Logs Panel Effect - Waiting for initial setup');
      return;
    }

    if (activeUserData?.data?.results) {
      const results = activeUserData.data.results;
      const events = results.events;
      const isCurrentUserActive = results.activeUser?.isCurrentUser;
      
      // console.log('🔍 Logs Panel Effect - Checking conditions:', {
      //   hasEvents: !!events,
      //   eventCount: events?.length,
      //   isCurrentUserActive,
      //   activeUser: results.activeUser
      // });
      
      // Only show the panel if:
      // 1. We have events
      // 2. Current user is the active user
      // 3. There are blocker events
      if (events && isCurrentUserActive) {
        const hasBlockingEvents = events.some(
          (event) => event.eventType === EventType.ACTIONABLE_BLOCKER
        );
        
        // console.log('🔍 Logs Panel Effect - Blocker check:', {
        //   hasBlockingEvents,
        //   events: events.map(e => ({
        //     type: e.eventType,
        //     name: e.eventName
        //   }))
        // });
        
        if (hasBlockingEvents) {
          // console.log('🔍 Logs Panel Effect - Opening panel');
          // Show the logs panel and ensure it's in front of other panels
          dispatch(hideAllPanels());
          dispatch(showPanel({ panel: WORKFLOW_PANELS.LogsPanel }));
        }
      }
    }
  }, [activeUserData?.data?.results, dispatch, isInitialSetupComplete]);

  if (isError) {
    return (
      <div className="z-[1] flex flex-col items-center justify-center w-full h-full overflow-hidden">
        <Box className="p-3 flex items-center gap-4 text-red-500 bg-red-50 rounded">
          <MdErrorOutline size={24} />
          {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore */}
          <Box>Oops! {workflowError?.data.message}</Box>
        </Box>
      </div>
    );
  }

  const isLoadingWorkflow =
    isFetchingWorkflow && !transformPanel.isVisible && !llmPanel.isVisible;
  const isLoadingNode =
    loadingNodes && !transformPanel.isVisible && !llmPanel.isVisible;

  const isLoading =
    isLoadingWorkflow || !workflowId || isLoadingNode || !initialEdges;

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center w-full h-full overflow-hidden">
        <Box className="p-3 flex items-center gap-4 border bg-gray-50 rounded">
          <Spinner color="gray" />
          <Box>
            {loadingNodes ? `Setting up editor...` : `Fetching Workflow...`}
          </Box>
        </Box>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center w-full h-full overflow-hidden z-[1]">
      <ReactFlowProvider>
        {/* Main content area that's already positioned after the sidebar */}
        <div className="relative w-full h-full main-content-wrapper">
          <FlowEditor initialEdges={initialEdges} initialNodes={initialNodes} />
          {hasBlockingEvents && logsPanel.isVisible && (
            <div
              className="absolute inset-0 bg-blackAlpha-500 cursor-not-allowed"
              style={{ backdropFilter: "blur(2px)" }}
              onClick={(e) => e.stopPropagation()}
              aria-hidden="true"
            />
          )}
        </div>
      </ReactFlowProvider>
    </div>
  );
};
