import { useEffect } from "react";
import { useReactFlow } from "reactflow";

import { useShowToast } from "@/components/toast";
import { updateParamsInWfNodes } from "@/features/llm-prompt/components/llm-footer/helper";
import { useAppDispatch, useAppSelector } from "@/reduxHooks";

import { useLazyGetWorkflowQuery } from "../api/workflow-api";
import { currentWorkflowId, setEditingAllowed } from "../redux/workflow-slice";
import { triggerRunButton } from "../redux/workflow-studio-slice";

import { useRunWorkflow } from "./useRunWorkflow";

export const useWorkflowRunTrigger = (
  hasError?: boolean,
  openErrorModal?: VoidFunction
) => {
  const dispatch = useAppDispatch();
  const { setNodes } = useReactFlow();
  const toast = useShowToast();

  const triggerRun = useAppSelector(triggerRunButton);
  const workflowId = useAppSelector(currentWorkflowId);
  const { runWorkflow } = useRunWorkflow();
  const [getWorkflowDetails] = useLazyGetWorkflowQuery();

  const runWf = async () => {
    dispatch(setEditingAllowed(false));

    try {
      // const workflowResp = await getWorkflowDetails({
      //   workflowId: workflowId!,
      // }).unwrap();
      // const newNodes = workflowResp?.response.data?.workflows[0].workflowNodes;
      if (hasError) {
        dispatch(setEditingAllowed(true));
        openErrorModal?.();
      } else {
        /**
         * Passign newNodes directly because run won't take the latest parameter changes
         * in the nodes. This happens because setNodes is async and the runWorkflow is called
         * after setNodes but it still ha
         *
         */
        void runWorkflow(workflowId!);
      }

      // void updateParamsInWfNodes(newNodes ?? [], setNodes);
    } catch (error) {
      dispatch(setEditingAllowed(true));
      toast({
        title: "Failed to fetch workflow details",
        status: "error",
      });
    }
  };

  useEffect(() => {
    if (!triggerRun) return;
    void runWf();
  }, [triggerRun]);

  return null;
};
