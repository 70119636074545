import {
  COLUMN_IDENTIFIER,
  COMMA_SEPARATOR,
  CONDITIONS_MAP,
  FILTER_TYPE,
  IFilterStatsTaskData,
  ITransformationStep,
  IViewFiltersRequest,
  IViewTransformationParameter,
  LabelValuePair,
  NO_INPUT,
  STATUS_API_TYPE,
  STEP_STATUS,
} from "@/features/data-transformation";

export function convertArrayToLabelValue(array: string[]): LabelValuePair[] {
  return array.map((item) => ({
    label: item,
    value: item,
  }));
}

export function arrayToString(arr: string[] | null | undefined): string {
  if (!arr) {
    return "";
  }
  return arr.join(COMMA_SEPARATOR);
}
export function stringToArray(
  str: string | null | undefined,
  columnNames: string[]
): string[] {
  if (!str) {
    return [];
  }

  // Escape special characters for regex
  const escapedColumnNames = columnNames.map((name) =>
    name.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
  );

  // Create a regex pattern to match column names or commas
  const pattern = new RegExp(`(${escapedColumnNames.join("|")}|,)`, "g");

  // Split the string using the pattern
  const tokens = str.split(pattern).filter(Boolean);

  // Combine adjacent tokens that are not separators
  const result: string[] = [];
  let currentToken = "";

  for (const token of tokens) {
    if (token === ",") {
      if (currentToken) {
        result.push(currentToken);
        currentToken = "";
      }
    } else {
      currentToken += token;
    }
  }

  if (currentToken) {
    result.push(currentToken);
  }

  return result;
}
/**
 * This function processes the filters to convert FilterType  both into two separate filters
 * for the server
 * It also converts filter conditionType to the server format
 */
export const seperateFilters = (filters: IViewFiltersRequest[]) => {
  const seperatedFilters = filters
    .map((filter) => {
      if (filter.filterType === FILTER_TYPE.BOTH) {
        return [
          {
            column: filter.column,
            value: filter.value,
            conditionType: filter.conditionType,
            id: filter.id,
            filterType: FILTER_TYPE.CONDITION,
          } as IViewFiltersRequest,
          {
            column: filter.column,
            values: convertBooleanToString(filter.values),
            id: filter.id,
            filterType: FILTER_TYPE.VALUE,
          } as IViewFiltersRequest,
        ];
      }
      return [filter];
    })
    .flat();

  const convertConditionToValue = seperatedFilters.map((filter) => {
    if (filter.conditionType) {
      return {
        ...filter,
        conditionType: CONDITIONS_MAP.find(
          (item) => item.value === filter.conditionType
        )?.value,
      } as IViewFiltersRequest;
    }
    return filter;
  });

  return convertConditionToValue.map(convertFilterToServerType);
};

const convertFilterToServerType = (filter: IViewFiltersRequest) => {
  if (filter.filterType === FILTER_TYPE.VALUE) {
    return {
      ...filter,
      values: convertBooleanToString(filter.values),
    };
  }
  return filter;
};

const convertBooleanToString = (values: any[] | undefined): any => {
  if (!values) return values;

  return values.map((value) =>
    typeof value === "boolean" ? value.toString() : value
  );
};

export const combineFilters = (array: IViewFiltersRequest[]) => {
  return array.reduce((acc: IViewFiltersRequest[], curr) => {
    const existingIndex = acc.findIndex(
      (item: IViewFiltersRequest) => item.column === curr.column
    );

    if (existingIndex !== -1) {
      // Column already exists, merge information
      acc[existingIndex].filterType = FILTER_TYPE.BOTH;
      if (curr.conditionType) {
        acc[existingIndex].conditionType = curr.conditionType;
      }
      if (curr.values) {
        acc[existingIndex].values = curr.values;
      }
    } else {
      // Column doesn't exist, add new entry
      acc.push({ ...curr });
    }

    return acc;
  }, []);
};

export const getAllFilterOptions = (data: IFilterStatsTaskData | null) => {
  return data?.uniqueValues.map((_col) => _col.value);
};

export const isInvalidFilter = (filter: IViewFiltersRequest) => {
  if (filter.column === "" || filter.column === undefined) {
    return true;
  }

  switch (filter.filterType) {
    case FILTER_TYPE.CONDITION: {
      const skipValueCheck =
        filter.conditionType && NO_INPUT.includes(filter.conditionType);
      const valueCheck = skipValueCheck ? false : !filter.value;
      return !filter.conditionType || valueCheck;
    }
    case FILTER_TYPE.VALUE:
      return filter.values?.length === 0;
    case FILTER_TYPE.BOTH: {
      const skipValueCheck =
        filter.conditionType && NO_INPUT.includes(filter.conditionType);
      const valueCheck = skipValueCheck ? false : !filter.value;
      return (
        !filter.conditionType ||
        valueCheck ||
        !filter.values ||
        filter.values?.length === 0
      );
    }
  }
};

export const getFilterText = (
  filter: IViewFiltersRequest,
  allFilters: string[]
) => {
  switch (filter.filterType) {
    case FILTER_TYPE.VALUE:
      if (filter.values?.length == allFilters.length) {
        return "all";
      } else if (filter.values?.length === 1) {
        return `${filter.values[0]}`;
      } else {
        return `${filter.values?.length} values`;
      }
    case FILTER_TYPE.CONDITION: {
      const condition = CONDITIONS_MAP.find(
        (item) => item.value === filter.conditionType
      );
      if (condition?.icon) {
        return `${filter.value}`;
      } else {
        return "formula";
      }
    }
    default:
      return "(multiple filters)";
  }
};

export const getFilterIcon = (filter: IViewFiltersRequest) => {
  switch (filter.filterType) {
    case FILTER_TYPE.VALUE:
      return "=";
    case FILTER_TYPE.CONDITION: {
      const condition = CONDITIONS_MAP.find(
        (item) => item.value === filter.conditionType
      );
      return condition?.icon ?? "=";
    }
    default:
      return "=";
  }
};

export const getUpdatedSteps = (
  oldSteps: Partial<ITransformationStep>[],
  newSteps: Partial<ITransformationStep>[]
): ITransformationStep[] => {
  const updatedSteps = oldSteps.map((_step) => {
    const step =
      newSteps.find(
        (_dataStep) =>
          _dataStep.transformationStepId == _step.transformationStepId
      ) ?? {};
    return { ..._step, ...step } as ITransformationStep;
  });

  return updatedSteps;
};

export const getStepWithWarnings = (
  steps: ITransformationStep[]
): ITransformationStep | null => {
  return (
    steps.find(
      (_step) => _step.status == STEP_STATUS.Warning && _step.activeStatus
    ) ?? null
  );
};

export const parametersFromStep = (
  _operation: ITransformationStep,
  allParameters: Record<string, IViewTransformationParameter>,
  {
    mergeParameters = [],
  }: { mergeParameters?: Partial<IViewTransformationParameter>[] }
) => {
  return (_operation.parameters as string[]).map((paramId) => {
    const _newParam = Object.values(allParameters).find(
      (param) => param.id === paramId
    )!;

    if (mergeParameters.length > 0) {
      const _oldParam = mergeParameters.find(
        (param) => param.id === paramId || param.parameterId === paramId
      )!;

      const updatedParam = { ..._newParam, ..._oldParam };
      return updatedParam;
    }

    return _newParam;
  });
};

export const columnFromParameter = (
  params: Partial<IViewTransformationParameter>[]
) => {
  const _column = params.find(
    (_param) => _param.identifier === COLUMN_IDENTIFIER
  );
  return _column?.value;
};

export const filterStatusTypeForMessage = (status: STATUS_API_TYPE) => {
  const shouldShowMessage = [
    STATUS_API_TYPE.TRANSFORMATION,
    STATUS_API_TYPE.SAMPLING,
  ].includes(status);
  return shouldShowMessage;
};

export const isBool = (value: any) => typeof value == "boolean";
export const normalizeValue = (value: any): string | number => {
  if (isBool(value)) {
    return String(value);
  } else if (typeof value === "string") {
    return value;
  } else if (typeof value === "number") {
    return value;
  } else if (typeof value === "object" && value !== null) {
    return JSON.stringify(value).toLowerCase();
  } else {
    return value;
  }
};
