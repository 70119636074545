import { Box, Button } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";

import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@/design/components/modal";
import { useAppDispatch, useAppSelector } from "@/reduxHooks";
import { closeModal, ModalTypes, openModal } from "@/slices/modal-slice";

import {
  useEndEDASessionMutation,
  useRefreshEDASessionMutation,
} from "../../api";
import { setEdaMetaData } from "../../redux/data-transformation-slice";
import { ACCESS_MODE } from "../../utils";

export const EDASessionTimeoutWarningModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const { analysisId, initialSeconds, timeOut, edaId } = useAppSelector(
    (state) => state.rootReducer.modals
  ).modalProps;
  const [endEditAccess, { isLoading }] = useEndEDASessionMutation();
  const [requestAccess, { isLoading: isRequestingAccess }] =
    useRefreshEDASessionMutation();

  const [countdown, setCountdown] = useState(timeOut - initialSeconds);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prev) => {
        if (prev <= 1) {
          navigateToTimedOutModal();
          clearInterval(timer);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    // Check if countdown is already negative or zero on component mount
    if (countdown <= 0) {
      navigateToTimedOutModal();
    }

    return () => clearInterval(timer);
  }, []);

  const endEditing = useCallback(async () => {
    try {
      await endEditAccess({ analysisId, edaId }).unwrap();
      dispatch(closeModal());
    } catch (error) {
      console.error("Failed to end editing:", error);
    }
  }, [analysisId, edaId, endEditAccess, dispatch]);

  const navigateToTimedOutModal = () => {
    endEditing()
      .then(() => {
        setEdaMetaData({
          accessMode: ACCESS_MODE.READ,
          userHasWriteAccess: false,
          activeUser: null,
        });
        dispatch(
          openModal({
            modalType: ModalTypes.EDA_SESSION_ENDED,
            modalProps: { analysisId, edaId },
          })
        );
      })
      .catch((error) => {
        console.error("Failed to end editing:", error);
      });
  };

  const onContinueEditing = async () => {
    try {
      await requestAccess({ analysisId, edaId }).unwrap();
      dispatch(closeModal());
    } catch (error) {
      console.error("Failed to request access:", error);
    }
  };

  return (
    <Modal
      isOpen={true}
      closeOnOverlayClick={false}
      isCentered
      size="md"
      onClose={() => {}}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader className="flex gap-3 items-start justify-between border-b !ml-0 !pb-3 !pl-5">
          <Box className="w-fit text-lg text-gray-800 font-medium">
            Are you still there?
          </Box>
        </ModalHeader>
        <ModalBody className="!px-4 py-3">
          <div className="space-y-4">
            <p>
              You have been inactive for a while. Please confirm if you would
              like to continue editing.
            </p>
            <div className="flex justify-end space-x-2">
              <Button
                colorScheme="dark"
                isDisabled={isLoading || isRequestingAccess}
                isLoading={isLoading}
                onClick={endEditing}
                variant="outline"
              >
                End Editing ({Math.max(0, countdown)})
              </Button>
              <Button
                colorScheme="dark"
                isDisabled={isLoading || isRequestingAccess}
                isLoading={isRequestingAccess}
                onClick={onContinueEditing}
              >
                Continue Editing
              </Button>
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
