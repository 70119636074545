import { FormErrorMessage, Input, Textarea } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { isEmpty, PartialObject, pickBy } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { MdStore } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import {
  ControlledSelect,
  CreateWorkspaceButton,
  customIconComponent,
  LoadingMessage,
  NoOptions,
} from "@/components/controlled-select";
import { useShowToast } from "@/components/toast";
import { TOAST_MESSAGES } from "@/constants/toast-constants.ts";
import { Avatar } from "@/design/components/avatar";
import { Button } from "@/design/components/button";
import { FormControl, FormLabel } from "@/design/components/form";
import { Icon } from "@/design/components/icon";
import { useGetAccessListQuery } from "@/features/user-manager";
import {
  ProjectCreateSchema,
  ProjectSchema,
} from "@/features/ws-manager/types";
import usePermissions from "@/hooks/usePermissions";
import { useAppSelector } from "@/reduxHooks";
import { currentUserMetadata } from "@/slices/auth-slice";
import { closeModal, ModalTypes, openModal } from "@/slices/modal-slice.ts";
import { Optional } from "@/types";
import { CATEGORY, PERMISSIONS } from "@/utils/enums";
import { keysToSnake } from "@/utils/snakeCaseConverter";


import {
  useCreateProjectMutation,
  useGetWsItemsByIdListMutation,
  useUpdateProjectMutation,
} from "../../api";
import { setPreviewAnalysis } from "../../redux";

type ProjectFormProps = {
  data: {
    data: any;
    parentWsId: string;
  };
};

const wsOptionsSchema = z.object(
  {
    label: z.string(),
    value: z.string(),
  },
  {
    required_error: "Workspace is required",
    invalid_type_error: "Workspace is required",
  }
);

type WsOptions = z.infer<typeof wsOptionsSchema>;

const objectToOptions = (obj: any) => {
  return {
    label: obj.label,
    value: obj.value,
  };
};

const Project = z.object({
  workspaceId: wsOptionsSchema.required(),
  name: z
    .string({ required_error: "Project name is required" })
    .min(1, { message: "Project name is required" })
    .regex(/^[a-zA-Z0-9 _ -]+$/, {
      message:
        "Name must only contain letters, numbers, spaces, underscores, and hyphens",
    })
    .max(50, { message: "Name must be at most 50 characters long" })
    .refine((data) => data.trim() !== "", {
      message: "Project name cannot be empty",
    }),
  description: z
    .string()
    .regex(/^[a-zA-Z0-9 _ -]*$/, {
      message:
        "Description must only contain English letters, numbers, spaces, underscores, and hyphens",
    })
    .max(500, { message: "Description must be at most 500 characters long" })
    .optional(),
  discreet: z.boolean(),
});

type ProjectFormSchema = z.infer<typeof Project>;

// Add this type definition
type SelectionChange = {
  from: string | null;
  to: string | null;
  name: string;
};

const ProjectForm = ({ data: projectData }: ProjectFormProps) => {
  const ref = useRef(null);
  const toast = useShowToast(undefined, undefined, true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { modalProps } = useAppSelector((state) => state.rootReducer.modals);
  const userData = useAppSelector(currentUserMetadata);

  // const isAdmin = userData?.role === USER_ROLES.ADMIN;

  const [createProjectApi, { isLoading }] = useCreateProjectMutation();
  const [updateProjectApi, { isLoading: isUpdatingProject }] =
    useUpdateProjectMutation();

  // const { data: wsData, isLoading: isLoadingWs } = useGetWsListQuery();

  const { id, name, description, wsId, discreet } = (projectData?.data ??
    {}) as Partial<ProjectSchema> & { wsId: string };

  const isEditing = useMemo(
    () => projectData?.data?.name !== undefined,
    [projectData?.data]
  );

  const { checkPermission } = usePermissions();

  const hasProjectCreatePermission = checkPermission({
    requiredPermission: PERMISSIONS.DELETE,
    type: CATEGORY.Project,
    id: id,
  });

  let permission = "create";
  if (isEditing)
    permission = "edit";
  if (hasProjectCreatePermission) 
    permission = "delete";

  const { data: ItemsWithAccess, isLoading: isAccessListLoading } =
    useGetAccessListQuery(
      {
        scope: "project",
        permission: permission,
      },
      {
        refetchOnMountOrArgChange: true,
      }
    );

  const [getWsListItems, { data: WsListItems, isLoading: isWsListLoading }] =
    useGetWsItemsByIdListMutation();

  const isUpdating = isAccessListLoading || isWsListLoading;

  const workspaces = WsListItems?.response.data?.workspaces ?? [];

  const wsOptions = useMemo(() => {
    const filteredWorkspaces = workspaces;
    return filteredWorkspaces.map((ws) => {
      const icon = ws.icon ? (
        <Avatar size="xs" bgColor={ws.icon} name={ws.name} />
      ) : (
        <Avatar size="xs" bgColor={"brand.300"} name={ws.name} />
        // <Icon as={AiFillFolder} size="md" color="gray.900" />
      );
      return {
        label: ws.name,
        value: ws.id,
        icon: ws.emoji ?? icon,
      };
    }) as WsOptions[];
  }, [workspaces]);

  // TODO: Make custom hook for this and analysis form
  const [defaultValues, defaultWs]: [
    Optional<ProjectFormSchema, "workspaceId">,
    WsOptions | undefined,
  ] = useMemo(() => {
    const values = {
      name: name ?? "",
      description: description ?? "",
      discreet: discreet ?? false,
    };

    const searchWsId = projectData?.parentWsId ?? wsId;

    const wsOption = wsOptions.find((ws) => ws.value == searchWsId);
    Object.assign(values, {
      workspaceId: wsOption ? objectToOptions(wsOption) : null,
    });
    if (!isEmpty(wsOptions) && !wsOption && isEditing) {
      const toastId = "workspace-access-revoked";
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          title: "Workspace access revoked or Workspace deleted.",
          status: "error",
        });
      }
      dispatch(closeModal());
      return [values, wsOption];
    }
    return [values, wsOption];
  }, [projectData?.data, wsOptions, isEditing]);

  const {
    control,
    handleSubmit,
    register,
    formState: { errors, isDirty, dirtyFields },
    getValues,
    setValue,
  } = useForm<ProjectFormSchema>({
    resolver: zodResolver(Project),
    defaultValues: defaultValues,
  });

  const [changes, setChanges] = useState<{
    workspace: SelectionChange | null;
  }>({
    workspace: null,
  });

  const initialWorkspaceRef = useRef<string | null>(null);

  useEffect(() => {
    if (isAccessListLoading || !ItemsWithAccess) return;
    getWsListItems({
      entityType: "workspace",
      idList: ItemsWithAccess?.response.data?.workspace ?? [],
    }).catch((e) => console.error(e));
  }, [isAccessListLoading, ItemsWithAccess, getWsListItems]);

  useEffect(() => {
    if (defaultWs) {
      setValue("workspaceId", defaultWs);
    }
  }, [defaultWs]);

  useEffect(() => {
    initialWorkspaceRef.current = defaultWs?.value ?? null;
  }, [defaultWs]);

  const handleWorkspaceChange = (newValue: WsOptions | null) => {
    if (!newValue) return;

    const currentWsValue = newValue.value;

    const isReturningToInitial = initialWorkspaceRef.current === currentWsValue;

    setValue("workspaceId", newValue);

    if (isReturningToInitial) {
      setChanges((prev) => ({
        ...prev,
        workspace: null,
      }));
    } else if (initialWorkspaceRef.current !== currentWsValue) {
      setChanges((prev) => ({
        ...prev,
        workspace: {
          from: initialWorkspaceRef.current,
          to: currentWsValue,
          name: newValue.label,
        },
      }));
    }
  };

  const handleFormSubmit: SubmitHandler<ProjectFormSchema> = async (values) => {
    if (isEditing) {
      if (changes.workspace) {
        const sanitizedValues: Partial<ProjectSchema> = Object.keys(
          dirtyFields
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
        ).reduce((o, key) => ({ ...o, [key]: getValues(key) }), {});

        if (changes.workspace) {
          sanitizedValues.workspaceId = changes.workspace?.to ?? undefined;
        }
        const snakeCaseValues = keysToSnake(sanitizedValues);

        dispatch(
          openModal({
            modalType: ModalTypes.MOVE_PROJECT, // Reuse the same modal type
            modalProps: {
              title: "Move Project?",
              name: name,
              workspaceName:
                changes.workspace?.name || getValues("workspaceId").label,
              subDescription: "Are you sure you want to move this project?",
              primaryButtonText: "Move Project?",
              primaryButtonColorScheme: "secondary",
              primaryButtonVariant: "solid",
              sourceObjectId: id,
              sourceObjectType: "project",
              targetObjectId: changes.workspace?.to,
              targetObjectType: "workspace",
              navigate,
              changes,
              primaryAction: async () => {
                await handleEditFormSubmit(
                  snakeCaseValues as Partial<ProjectSchema>
                );
              },
              isNested: true,
            },
          })
        );
      } else {
        await handleEditFormSubmit();
        dispatch(closeModal());
      }
    } else {
      handleCreateFormSubmit(values);
    }
  };

  const handleEditFormSubmit = async (
    overrideValues?: Partial<ProjectSchema>
  ) => {
    if (isDirty || overrideValues) {
      try {
        const sanitizedValues: Partial<ProjectSchema> =
          overrideValues ||
          Object.keys(
            dirtyFields
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
          ).reduce((o, key) => ({ ...o, [key]: getValues(key) }), {});

        await updateProjectApi({
          ...sanitizedValues,
          wsId: overrideValues?.workspaceId || wsId,
          id: id!,
        }).unwrap();

        if (changes.workspace) {
          navigate(`/home/${changes.workspace.to}/project/${id}`);
        }
        toast({ ...TOAST_MESSAGES.projectDetailsUpdated });
      } catch (e) {
        console.error("Error updating project:", e);
        return;
      }
    }

    // dispatch(closeModal());
  };

  const handleCreateFormSubmit: SubmitHandler<ProjectFormSchema> = async (
    _values: ProjectFormSchema
  ) => {
    try {
      const sanitizedValues: PartialObject<ProjectCreateSchema> = pickBy(
        _values,
        (item) => {
          if (typeof item == "string") return item.length > 0;
          if (typeof item == "object") return !isEmpty(item);
          return true;
        }
      );

      await createProject(sanitizedValues as ProjectFormSchema);
    } catch (e) {
      console.error("issue", e);
    }
  };

  const createProject = async (values: ProjectFormSchema) => {
    const createData = {
      ...values,
      workspaceId: values.workspaceId.value,
    };

    await createProjectApi(createData)
      .unwrap()
      .then((res) => {
        toast(TOAST_MESSAGES.projectCreated(values.name));
        const wkId = res?.response?.data?.projects[0]?.workspaceId;
        const projId = res?.response?.data?.projects[0]?.id;

        // if navigated from a previous modal like create project -> create workspace,
        // then reopen the create project modal
        if (modalProps?.reopenModal) {
          dispatch(
            openModal({
              modalType: modalProps?.reopenModal,
              modalProps: {
                workspace: { id: wkId },
                project: { id: projId },
                navigate: modalProps.navigate,
              },
            })
          );
          return;
        }

        if (modalProps.navigate && wkId) {
          dispatch(setPreviewAnalysis(null));
          modalProps.navigate(`/home/${wkId}/project/${projId}`);
        }
        dispatch(closeModal());
      });
  };

  return (
    <form
      /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
      onSubmit={handleSubmit(handleFormSubmit)}
      className="flex items-center justify-between flex-col mt-4 mb-2 gap-4 w-full rounded px-6"
      noValidate={true}
      ref={ref}
    >
      <ControlledSelect<ProjectFormSchema, WsOptions, true>
        name="workspaceId"
        control={control}
        label="Workspace"
        placeholder="Choose a Workspace"
        options={wsOptions}
        components={{
          ...customIconComponent,
          MenuList: (props) => (
            <CreateWorkspaceButton {...props} parent={CATEGORY.Project} />
          ),
          NoOptionsMessage: NoOptions,
          LoadingMessage: LoadingMessage,
        }}
        isLoading={isUpdating}
        placeholderIcon={<Icon as={MdStore} size="md" />}
        isDisabled={isUpdating || !hasProjectCreatePermission}
        selectedOptionStyle="check"
        useBasicStyles
        onChange={handleWorkspaceChange}
      />

      <FormControl
        id="Project-title"
        isRequired={true}
        isInvalid={!!errors.name}
      >
        <FormLabel>Project Title</FormLabel>
        <Input {...register("name")} />
        <FormErrorMessage>
          {errors.name && errors.name.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl id="description" isInvalid={!!errors.description}>
        <FormLabel>Description</FormLabel>
        <Textarea {...register("description")} />
        <FormErrorMessage>
          {errors.description && errors.description.message}
        </FormErrorMessage>
      </FormControl>
      {/*
      <FormControl id="isDiscreet" isDisabled={true}>
        <Checkbox {...register("discreet")}>Mark as discreet</Checkbox>
      </FormControl>
      */}
      <Button
        variant="solid"
        colorScheme="secondary"
        className="pb-0 mt-8 self-end"
        type="submit"
        isLoading={isEditing ? isUpdatingProject : isLoading}
      >
        {isEditing ? "Save Details" : "Create Project"}
      </Button>
    </form>
  );
};

export default ProjectForm;
