import { Box, Button, Flex, Skeleton } from "@chakra-ui/react";
import { Select, chakraComponents } from "chakra-react-select";
import { useMemo } from "react";
import { MdInfo, MdTransform } from "react-icons/md";
import { MdsArrowOutwardRound } from "react-icons-with-materialsymbols/mds";
import { Link, useNavigate, useParams } from "react-router-dom";

import { DataTableIcon } from "@/components/icons/data-table";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@/design/components/form";
import { useGetDataSetsQuery } from "@/features/data-manager/api";
import { NodeParameter } from "@/features/workflow-studio/types";
import { useAppDispatch } from "@/reduxHooks";
import { ModalTypes, openModal } from "@/slices/modal-slice";
import { EDA_STATE } from "@/utils/enums";

import { useConfigPanel } from "../config-panel-context";

export const SourceNodeConfig = ({
  parameters,
  setParameters,
}: {
  parameters: NodeParameter[];
  setParameters: (parameters: NodeParameter[]) => void;
}) => {
  const params = useParams();
  const datasetIdParam = parameters.find((p) => p.name === "dataset_id");
  const hasError = datasetIdParam?.errors?.[0]?.includes("empty");
  const { datasets, isLoadingDatasets } = useGetDataSetsQuery(
    {
      analysisId: params.analysisId as string,
    },
    {
      selectFromResult: ({ data, isLoading, isError }) => ({
        datasets: (data?.response?.data?.datasets ?? [])
          .filter(({ ioRecord }) => ioRecord?.ioType !== "output")
          .map(({ ioRecord: _, ...rest }) => rest),
        isLoadingDatasets: isLoading,
        isErrorDatasets: isError,
      }),
    }
  );
  const selectedDataset = datasets.find((d) => d.id === datasetIdParam?.value);
  const datasetList = useMemo(() => {
    return datasets.map((dataset) => ({
      label: dataset.displayName,
      value: dataset,
    }));
  }, [datasets]);

  const selectedValue = useMemo(() => {
    if (selectedDataset) {
      return {
        label: selectedDataset.displayName ?? "",
        value: selectedDataset,
      };
    }
    return null;
  }, [datasetIdParam, datasets]);

  const handleChange = (e: any) => {
    setParameters(
      parameters.map((param) => {
        if (param.name === "dataset_id") {
          return { ...param, errors: [], value: e?.value?.id };
        } else if (param.name === "dataset_info") {
          return { ...param, errors: [], value: JSON.stringify(e?.value) };
        }
        return param;
      })
    );
  };

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const addDataset = () => {
    navigate(`/analysis/${params.analysisId}/data-manager`);
    dispatch(
      openModal({
        modalType: ModalTypes.ADD_NEW_DATASET,
        modalProps: {
          analysisId: params.analysisId,
        },
      })
    );
  };

  const { isEditingAllowed } = useConfigPanel();

  if (isLoadingDatasets) {
    return (
      <Flex className="flex-col gap-1">
        <Box className="flex-1 text-sm text-gray-600">Loading Datasets</Box>
        <Skeleton className="h-10 w-full rounded" />
      </Flex>
    );
  }

  if (datasets.length === 0) {
    return (
      <Flex className="flex-col gap-1 items-center justify-center border p-2 rounded">
        <Box className="flex-1 text-sm text-gray-700">No datasets added</Box>
        <Button onClick={addDataset} size="sm" variant="link">
          Add dataset
        </Button>
      </Flex>
    );
  }

  if (datasets) {
    return (
      <Flex className="flex-col gap-2">
        <FormControl isInvalid={hasError} isRequired key={parameters[0].name}>
          <FormLabel>Select Dataset</FormLabel>
          <Select
            value={selectedValue}
            selectedOptionStyle="check"
            openMenuOnClick={isEditingAllowed}
            isDisabled={!isEditingAllowed}
            className="rounded-md"
            chakraStyles={{
              control: (provided) => ({
                ...provided,
                borderColor: isEditingAllowed ? undefined : "transparent",
                opacity: isEditingAllowed ? undefined : "100",
                backgroundColor: isEditingAllowed
                  ? undefined
                  : "var(--chakra-colors-gray-300)",
              }),
            }}
            components={{
              Placeholder: ({ children, ...props }) => {
                return (
                  <chakraComponents.Placeholder {...props}>
                    <Box className="text-gray-500 font-medium">
                      Select Dataset
                    </Box>
                  </chakraComponents.Placeholder>
                );
              },
              Option: ({ ...props }) => (
                <chakraComponents.Option
                  {...props}
                  className="flex-row-reverse !p-0"
                >
                  <Flex className="items-center gap-3 grow p-2 overflow-hidden">
                    <Box className="bg-green-50 text-green-500 p-1.5 rounded">
                      <DataTableIcon fontSize={24} />
                    </Box>
                    <Flex className="flex-col gap-1 max-w-full overflow-hidden" title={props.data.label}>
                      <Box className="text-sm font-medium truncate">{props.data.label}</Box>
                      <Flex className="gap-2 items-center">
                        <Box className="text-green-500 text-sm font-medium">
                          {props.data.value.rowCount} rows
                        </Box>
                        {props.data.value.edaState !== EDA_STATE.COMPLETE && (
                          <Box
                            className="h-7 w-7 bg-blue-50 text-blue-600 grid place-items-center rounded-md text-sm font-medium"
                            title="Transformations applied"
                          >
                            <MdTransform size={18} className="stroke-[0.1]" />
                          </Box>
                        )}
                      </Flex>
                    </Flex>
                  </Flex>
                </chakraComponents.Option>
              ),
            }}
            options={datasetList}
            onChange={(e) => {
              handleChange(e);
            }}
          />
          <FormErrorMessage>Dataset is required</FormErrorMessage>
        </FormControl>
        {selectedDataset?.edaState === EDA_STATE.COMPLETE && (
          <Box className="border flex flex-col gap-2 p-4 rounded">
            <Flex className="gap-2 font-medium items-center">
              <MdInfo size={24} />
              <Box>Dataset has Transforms</Box>
            </Flex>
            <Box className="text-sm">
              The selected dataset has some transformation steps applied to it.
            </Box>
            <Link
              className="flex self-end hover:underline items-center gap-1 text-sm font-medium"
              target="_blank"
              to={`/analysis/${params.analysisId}/data-manager/${selectedDataset.id}/transform`}
            >
              View transforms
              <MdsArrowOutwardRound className="stroke-[22]" />
            </Link>
          </Box>
        )}
      </Flex>
    );
  }
};
