import { Button, Flex, FormControl, FormLabel, Icon } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {
  MdsDownloadRound,
  MdsErrorRound,
} from "react-icons-with-materialsymbols/mds";

import { ControlledSelectNoForm } from "@/components/controlled-select";
import { useAppSelector } from "@/reduxHooks";
import { DATASET_FORMAT } from "@/utils/enums";

import { getDatasetIconColor } from "../../components/ongoing-uploads/get-icon";
import useDatasetActions from "../../hooks/useDatasetActions";
import { DatasetsSchema } from "../../types";

import { DownloadTitle } from "./download-title";

interface DownloadOption {
  label: string;
  value: DATASET_FORMAT;
}

export const DownloadDatasetBody = () => {
  const availableOptions: DownloadOption[] = [
    { label: "CSV  (comma-separated values)", value: DATASET_FORMAT.CSV },
    { label: "XLSX  (Microsoft Excel)", value: DATASET_FORMAT.XLSX },
  ];
  const { modalProps } = useAppSelector((s) => s.rootReducer.modals);
  const { downloadDataset, isAddingDownload } = useDatasetActions();

  const dataset = modalProps?.dataset as DatasetsSchema;
  const iconColor = getDatasetIconColor(dataset?.ioRecord?.ioType || "input");

  const [downloadFormat, setdownloadFormat] = useState<DownloadOption>(
    availableOptions[0]
  );

  const [showDataLimitWarning, setShowDataLimitWarning] = useState(false);

  const onDownload = async () => {
    try {
      await downloadDataset({
        dataset,
        format: downloadFormat.value,
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (
      downloadFormat.value === DATASET_FORMAT.XLSX &&
      dataset.rowCount > 400000
    ) {
      setShowDataLimitWarning(true);
    } else {
      setShowDataLimitWarning(false);
    }
  }, [downloadFormat, dataset.rowCount]);

  return (
    <Flex className="flex-col gap-3">
      <DownloadTitle iconColor={iconColor} title={dataset.displayName} />
      <FormControl>
        <FormLabel className="!text-sm">Select format</FormLabel>
        <ControlledSelectNoForm
          isSearchable={false}
          value={downloadFormat}
          selectedOptionStyle="check"
          options={availableOptions}
          onChange={(option) => setdownloadFormat(option as DownloadOption)}
        />
      </FormControl>
      {showDataLimitWarning && (
        <Flex className="text-red-600 items-center text-sm gap-2">
          <Icon className="!text-red-600" as={MdsErrorRound} strokeWidth={22} />
          Only 400k records will be downloaded
        </Flex>
      )}
      <Button
        className="w-fit mt-8"
        alignSelf={"flex-end"}
        colorScheme="dark"
        isDisabled={isAddingDownload}
        isLoading={isAddingDownload}
        leftIcon={<Icon as={MdsDownloadRound} strokeWidth={22} />}
        onClick={onDownload}
      >
        Download
      </Button>
    </Flex>
  );
};
