import { createApi, retry } from "@reduxjs/toolkit/query/react";

import { getStudioAnalysis } from "@/features/data-manager/redux/data-manager-slice";
import { RootState } from "@/store";
import { baseQuery } from "@/utils/baseQuery.ts";
import { getMSBaseUrl } from "@/utils/baseUrls.ts";
import { MICRO_SERVICES } from "@/utils/enums.ts";

const staggeredBaseQuery = retry(
  async (args, api, extraOptions) => {
    const state = api.getState() as RootState;
    const analysis = getStudioAnalysis(state);
    
    const baseQueryFn = baseQuery({
      baseUrl: getMSBaseUrl(MICRO_SERVICES.WORKFLOW_MANAGER),
      defaultHeaders: {
        "X-CLIENT-ID": import.meta.env.VITE_CLIENT_ID,
        "X-WORKSPACE-ID": analysis?.workspaceId ?? "",
      },
    });
    
    return baseQueryFn(args, api, extraOptions);
  },
  {
    maxRetries: 0,
  }
);

export const workflowEditorApi = createApi({
  reducerPath: "workflowEditorApi",
  baseQuery: staggeredBaseQuery,
  tagTypes: ["Nodes", "Flows", "Workflows", "NodeData"],
  endpoints: () => ({}),
});
