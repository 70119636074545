export enum FORM_DATA_TYPE {
  DROPDOWN = "DROPDOWN",
  TEXT = "TEXT",
  RANGE = "RANGE",
  DATE = "DATE",
  MULTI_SELECT = "MULTI_SELECT",
  MULTI_CONFIG = "MULTI_CONFIG",
}

export enum DATA_TYPE {
  STRING = "string",
  DATE = "date",
  INT = "integer",
  FLOAT = "float",
  BOOLEAN = "boolean",
  AUTO_DETECT = "auto_detect",
  ARRAY = "array",
  TIMESTAMP = "timestamp",
}

export enum FILTER_ROW_OPERATION_TYPE {
  AddRow = "added",
  RemoveRows = "removed",
  EditedRows = "modified",
  UNCHANGED = "unchanged",
}

export enum FILTER_TYPE {
  VALUE = "value",
  CONDITION = "condition",
  BOTH = "both",
}

export enum SORT_ORDER {
  ASC = "asc",
  DESC = "desc",
}

export enum STEP_STATUS {
  Active = "active",
  Removed = "removed",
  Failed = "failed",
  Success = "success",
  Warning = "warning",
}

export enum ORIGIN {
  Workflow = "workflow",
  DataManager = "dataset",
}

export enum EDA_STATUS {
  Loading = "loading",
  CanEdit = "can edit",
  ReadOnly = "read only",
  Failed = "failed",
}

export enum TABLE_DATA_STATUS {
  IN_PROGRESS = "in_progress",
  COMPLETED = "completed",
  FAILED = "failed",
}

export enum ACCESS_MODE {
  READ = "read_only",
  READ_WRITE = "read_write",
}

export enum ACTIVE_REQUEST_TYPE {
  SAMPLING = "sampling",
  EXECUTE = "execute",
  EXPLORE_DATA = "explore_data",
  FILTER_STATS = "filter_stats",
  TRANSFORMATION = "transformation",
  TABLE_DATA = "table_data",
}

export enum FETCH_TYPE {
  TABLE = "Table",
  STEPS = "Steps",
  EXPLORE = "Explore",
  SUGGESTIONS = "Suggestions",
  SAVE_EXECUTE = "SaveExecute",
}

export enum STATISTICS {
  MEAN = "mean",
  STDDEV = "stddev",
  MIN = "min",
  MAX = "max",
  MODE = "mode",
  MEDIAN = "median",
  NON_NULL_COUNT = "nonNullCount",
  TOTAL_COUNT = "totalCount",
  DISTINCT_COUNT = "distinctCount",
  MIN_LENGTH = "minLength",
  MAX_LENGTH = "maxLength",
}

export enum STATUS_API_TYPE {
  EXECUTE = "execute",
  TRANSFORMATION = "transformation",
  FILTER_STATS = "filter_stats",
  SAMPLING = "sampling",
  SUGGESTIONS = "suggestions",
  EXPLORE_DATA = "explore_data",
  TABLE_DATA = "table_data",
}

export enum EXPLORE_STATS_TYPE {
  SAMPLE = "on Sample",
  FULL = "on Full Dataset",
}
