import { Box, Skeleton, Text } from "@chakra-ui/react";
import { Select, CreatableSelect } from "chakra-react-select";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { useShowToast } from "@/components/toast";
import {
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
  FormLabel,
} from "@/design/components/form";
import { useGetNodeInputSchemaMutation } from "@/features/workflow-studio/api/node-manager-api";
import {
  currentReferenceRunId,
  currentWorkflowId,
  currentWorkflowRunId,
  currentWorkflowVersionTagId,
  isAutoSaving,
} from "@/features/workflow-studio/redux";
import { NodeParameter } from "@/features/workflow-studio/types";
import { useAppSelector } from "@/reduxHooks";

import { useConfigPanel } from "../config-panel-context";

export const MultiSelectConfig = ({
  param,
  updateParameter,
}: {
  param: NodeParameter;
  updateParameter: (paramId: string, value: any) => void;
}) => {
  const [options, setOptions] = useState<
    Array<{ label: string; value: string }>
  >([]);
  const [isLoadingColumns, setIsLoadingColumns] = useState(false);
  const [getNodeInputSchema] = useGetNodeInputSchemaMutation();
  const toast = useShowToast(undefined, undefined, true);
  const { analysisId, editorId } = useParams();
  const isSaving = useAppSelector(isAutoSaving);

  const { currentNodeData, setParameterError, isEditingAllowed } =
    useConfigPanel();
  const workflowId = useAppSelector(currentWorkflowId);
  const referenceRunId = useAppSelector(currentReferenceRunId);
  const workflowVersionTagId = useAppSelector(currentWorkflowVersionTagId);

  const fetchStringColumns =
    currentNodeData?.name.includes("DTS") ||
    currentNodeData?.name.includes("Actionables") ||
    currentNodeData?.name.includes("Detect Themes and Sentiments");

  const isMultiSelect =
    param.additionalInfo?.dropdownProperties?.["isMultiSelect"] === true;
  const maxAllowedSelections =
    param.additionalInfo?.dropdownProperties?.["maxAllowedSelections"] || null;

  const shouldFetchColumns =
    param.additionalInfo?.dropdownProperties?.["showColumnsInDropdown"] ===
      true &&
    currentNodeData?.workflowNodeId &&
    analysisId &&
    editorId &&
    !isSaving;

  const currentErrorRef = useRef<string | null>(null);

  useEffect(() => {
    // Convert valuesList to options format if not fetching columns
    if (!shouldFetchColumns && param.valuesList?.length) {
      setOptions(
        param.valuesList.map((value: string) => ({
          label: value,
          value: value,
        }))
      );
    }
  }, [param.valuesList, shouldFetchColumns]);

  useEffect(() => {
    const fetchColumnChoices = async () => {
      if (!shouldFetchColumns) return;

      setIsLoadingColumns(true);
      try {
        const response = await getNodeInputSchema({
          workflowNodeId: currentNodeData?.workflowNodeId ?? "",
          workflowVersionTagId: workflowVersionTagId ?? "",
          analysisId,
          workflowId: workflowId ?? "",
          filterByType: fetchStringColumns ? "string" : "all",
        }).unwrap();

        if (response.response?.data?.columnChoices?.length) {
          const newOptions = response.response.data.columnChoices.map(
            (column: string) => ({
              label: column,
              value: column,
            })
          );
          setOptions(newOptions);
        } else {
          setOptions([]);
        }
      } catch (error) {
        setOptions([]);
      } finally {
        setIsLoadingColumns(false);
      }
    };

    void fetchColumnChoices();
  }, [
    shouldFetchColumns,
    currentNodeData?.workflowNodeId,
    workflowVersionTagId,
    analysisId,
    editorId,
    getNodeInputSchema,
    toast,
    fetchStringColumns,
  ]);

  // Validation effect for multiselect values
  useEffect(() => {
    if (!isMultiSelect || isLoadingColumns) {
      // For single select, check if default value exists in options
      if (!isLoadingColumns && options.length > 0) {
        const defaultValue = param.value || param.defaultValue;
        if (defaultValue) {
          const valueExists = options.some(
            (option) => option.value === defaultValue
          );
          if (!valueExists) {
            const error = "Provided column not found in data";
            if (currentErrorRef.current !== error) {
              setParameterError(param.parameterId, error);
              currentErrorRef.current = error;
            }
            return;
          }
        }
      }
      // Clear any existing errors while loading or if validation passes
      if (currentErrorRef.current !== null) {
        setParameterError(param.parameterId, null);
        currentErrorRef.current = null;
      }
      return;
    }

    const defaultValue = param.value || param.defaultValue;

    // Skip if no value
    if (!defaultValue) {
      if (currentErrorRef.current !== null) {
        setParameterError(param.parameterId, null);
        currentErrorRef.current = null;
      }
      return;
    }

    try {
      const values = JSON.parse(defaultValue as string);
      if (!Array.isArray(values)) {
        const error = "Invalid format: expected array";
        if (currentErrorRef.current !== error) {
          setParameterError(param.parameterId, error);
          currentErrorRef.current = error;
        }
        return;
      }

      // Skip column name validation if options is empty
      if (values.length > 0 && options.length > 0) {
        const hasInvalidValue = values.some(
          (value: string) => !options.find((opt) => opt.value === value)
        );
        const error = hasInvalidValue
          ? "Provided column not found in data"
          : null;
        if (currentErrorRef.current !== error) {
          setParameterError(param.parameterId, error);
          currentErrorRef.current = error;
        }
      } else if (currentErrorRef.current !== null) {
        setParameterError(param.parameterId, null);
        currentErrorRef.current = null;
      }
    } catch (error) {
      const errorMessage = "Invalid format";
      if (currentErrorRef.current !== errorMessage) {
        setParameterError(param.parameterId, errorMessage);
        currentErrorRef.current = errorMessage;
      }
    }
  }, [
    options,
    param.value,
    param.defaultValue,
    isMultiSelect,
    param.parameterId,
    setParameterError,
    isLoadingColumns,
  ]);

  const handleChange = (selectedOption: any) => {
    if (isMultiSelect) {
      const valueArray = selectedOption
        ? selectedOption.map((option: any) => option.value)
        : [];
      updateParameter(param.parameterId, JSON.stringify(valueArray));
    } else {
      updateParameter(param.parameterId, selectedOption?.value || "");
    }
  };

  const defaultValue = param.value || param.defaultValue || "";

  // Handle displaying selected values for both single and multi-select
  const selectedOption = useMemo(() => {
    if (isMultiSelect && defaultValue) {
      try {
        const parsed = JSON.parse(defaultValue as string);
        if (Array.isArray(parsed)) {
          return parsed.map((value: string) => ({
            label: value,
            value: value,
          }));
        }
        return [];
      } catch (error) {
        return [];
      }
    }

    // For single select
    const defaultOption = options.find(
      (option) => option.value === defaultValue
    );
    return (
      defaultOption ||
      (defaultValue ? { label: defaultValue, value: defaultValue } : null)
    );
  }, [isMultiSelect, defaultValue, options]);

  const SelectComponent = useMemo(() => {
    // Use CreatableSelect only for multiselect or when options are empty
    if (isMultiSelect || options.length === 0) return CreatableSelect;
    return Select;
  }, [isMultiSelect, options.length]);

  if (isSaving) return <Skeleton className="w-full h-20" />;

  const disableSelect =
    !param.isModifiable || !isEditingAllowed || isLoadingColumns || isSaving;

  return (
    <FormControl
      key={param.name}
      isRequired={param.isMandatory}
      isDisabled={!param.isModifiable || !isEditingAllowed}
      isInvalid={param.errors && param.errors.length > 0}
    >
      <FormLabel>{param.name}</FormLabel>
      <SelectComponent
        options={options}
        name={param.name}
        errorBorderColor="red.500"
        value={selectedOption}
        selectedOptionStyle="check"
        onChange={handleChange}
        maxMenuHeight={200}
        isMulti={isMultiSelect}
        placeholder={
          isLoadingColumns || isSaving
            ? "Fetching options..."
            : options.length === 0
            ? "Select or enter option"
            : "Select option"
        }
        isDisabled={disableSelect}
        isLoading={isLoadingColumns || isSaving}
        isClearable
        classNamePrefix="c-r-s"
        formatCreateLabel={(inputValue: string) => `Add "${inputValue}"`}
        {...(isMultiSelect && maxAllowedSelections
          ? {
              closeMenuOnSelect: false,
              hideSelectedOptions: false,
              controlShouldRenderValue: true,
              isOptionDisabled: () =>
                (selectedOption as any[])?.length >= maxAllowedSelections,
            }
          : {})}
      />
      {!isLoadingColumns && options.length === 0 && (
        <Text mt={2} color="gray.500" fontSize="sm">
          No columns found. Please add columns manually.
        </Text>
      )}

      <FormErrorMessage>
        <FormErrorIcon />
        {param.errors?.map((err) => <div key={err}>{err}</div>)}
      </FormErrorMessage>
    </FormControl>
  );
};
