import { useRef } from "react";
import { useParams } from "react-router-dom";
import {
  Edge,
  getRectOfNodes,
  useReactFlow,
  Node,
  getIncomers,
} from "reactflow";
import { v4 as uuidv4 } from "uuid";

import { ToastType, useShowToast } from "@/components/toast";
import {
  showPanel,
  useGetNodesListQuery,
  useLazyGetWorkflowQuery,
  useSaveWorkflowMutation,
} from "@/features/workflow-studio";
import {
  currentReferenceRunId,
  currentWorkflowRunId,
  getEditingAllowed,
  workflowRunningStatus,
} from "@/features/workflow-studio/redux";
import { NodeType, WorkflowNodeSchema } from "@/features/workflow-studio/types";
import {
  ConvertFlowtoWorkflowPayload,
  NODE_STATUS,
  NODE_TYPES,
  RELATION_TYPE,
  UNIQUE_NODES,
  updateNodeIdAfterRun,
  WORKFLOW_PANELS,
} from "@/features/workflow-studio/utils";
import { useAppDispatch, useAppSelector } from "@/reduxHooks.ts";

type NodeIdsProps = {
  nodeUiId?: string;
  nodeWorkflowId?: string;
};

type ShowTransformPanelProps = {
  transformNodeUiId?: string;
  transformNodeId?: string;
};
export const useShowTransformPanel = () => {
  const params = useParams();

  const toast = useShowToast(undefined, undefined, true);
  const workflowRunId = useAppSelector(currentWorkflowRunId);
  const referenceRunId = useAppSelector(currentReferenceRunId);
  const currentWFStatus = useAppSelector(workflowRunningStatus);
  const isEditingAllowed = useAppSelector(getEditingAllowed);

  const dispatch = useAppDispatch();

  const {
    toObject,
    project,
    getViewport,
    getNode,
    setNodes,
    setEdges,
    getNodes,
    addEdges,
    getEdges,
  } = useReactFlow();

  const [saveWorkflow, { isLoading }] = useSaveWorkflowMutation();
  const [getWorkflow, { isLoading: isGetWorkflowLoading }] =
    useLazyGetWorkflowQuery();

  const nodeIds = useRef<NodeIdsProps>({});

  const { data: nodesList } = useGetNodesListQuery({
    analysisId: params.analysisId as string,
  });

  const createTransformNode = (
    nodeData: NodeType,
    currentNode: Node<NodeType>
  ) => {
    const nodes = getNodes();
    const wrapperBounds = getRectOfNodes(nodes);
    const currentNodeBounds = getRectOfNodes([currentNode]);
    const zoom = getViewport().zoom;
    const gap = 50 * zoom;

    const position = project({
      x: currentNodeBounds.x + currentNodeBounds.width * zoom + gap,
      y: currentNodeBounds.y - currentNodeBounds.height * zoom,
    });

    const newTransformNode = {
      id: uuidv4(),
      data: { ...nodeData, status: NODE_STATUS.DEFAULT },
      type: "custom-node",
      position,
    };
    setNodes((nds) => nds.concat(newTransformNode));

    return newTransformNode;
  };

  const createEdges = (
    _newNode: Node<NodeType>,
    _currentNode: Node<NodeType>
  ) => {
    const outgoingEdges = getEdges().filter(
      (edge) => edge.source === _currentNode.id
    );

    const hasNoConnectedNode = outgoingEdges.length == 0;
    if (hasNoConnectedNode) {
      createEdgeAtEnd(_newNode, _currentNode);
      return;
    }

    createEdgeMiddle(_newNode, _currentNode);
  };
  const createEdgeAtEnd = (
    _newNode: Node<NodeType>,
    _currentNode: Node<NodeType>
  ) => {
    const edge: Edge = {
      id: uuidv4(),
      source: _currentNode.id,
      target: _newNode.id,
      data: {
        relationType: RELATION_TYPE.ON_SUCCESS,
        sourceUiNodeId: _currentNode.id,
        targetUiNodeId: _newNode.id,
        sourceNodeIoId: _currentNode.data.outputs[0].ioDetailId,
        targetNodeIoId: _newNode.data.inputs[0].ioDetailId,
      },
    };
    addEdges(edge);
  };

  const createEdgeMiddle = (
    _newNode: Node<NodeType>,
    _currentNode: Node<NodeType>
  ) => {
    const outgoingEdges = getEdges().filter(
      (edge) => edge.source === _currentNode.id
    );
    const outgoingEdge = outgoingEdges[0];

    const nodeToTransformEdge: Edge = {
      ...outgoingEdge,
      id: uuidv4(),
      source: _currentNode.id,
      target: _newNode.id,
      data: {
        relationType: RELATION_TYPE.ON_SUCCESS,
        sourceUiNodeId: _currentNode.id,
        targetUiNodeId: _newNode.id,
        sourceNodeIoId: outgoingEdge.data.sourceNodeIoId,
        targetNodeIoId: _newNode.data.inputs[0].ioDetailId,
      },
    };

    const transformToNextEdge: Edge = {
      ...outgoingEdge,
      source: _newNode.id,
      target: outgoingEdge.target,
      data: {
        relationType: RELATION_TYPE.ON_SUCCESS,
        sourceUiNodeId: _newNode.id,
        targetUiNodeId: outgoingEdge.target,
        sourceNodeIoId: _newNode.data.outputs[0].ioDetailId,
        targetNodeIoId: outgoingEdge.data.targetNodeIoId,
      },
    };

    setEdges((currentEdges) =>
      currentEdges
        .map(function (_edge) {
          if (_edge.id == transformToNextEdge.id) return transformToNextEdge;
          return _edge;
        })
        .concat(nodeToTransformEdge)
    );
  };

  const addTransformNode = (_currentNode: Node) => {
    const _nodes = nodesList?.DATA_PREP ?? [];
    const transformNode = _nodes.find(
      (node) => node.displayName === UNIQUE_NODES.TRANSFORM
    );

    if (!transformNode) return;
    const newTransformNode = createTransformNode(transformNode, _currentNode);
    createEdges(newTransformNode, _currentNode);
    return newTransformNode;
  };

  const parameterIdMap = (parameters: any[]) => {
    const paramNewMap = parameters.map((param) => ({
      parameterId: param?.id ?? param?.parameterId,
      name: param?.name,
    }));

    return paramNewMap;
  };

  const getTransformNode = (nodes: WorkflowNodeSchema[]) => {
    return nodes.find(
      (node) =>
        node.uiNodeId === nodeIds.current.nodeUiId ||
        node.workflowNodeId === nodeIds.current.nodeWorkflowId
    );
  };

  const getTransformNodeFromCurrentNodes = () => {
    const nodes = getNodes();
    return nodes.find(
      (node) =>
        node.id === nodeIds.current.nodeUiId ||
        node.data.workflowNodeId === nodeIds.current.nodeWorkflowId
    );
  };

  const callWorkflowApi = async ({ save }: { save: boolean }) => {
    const obj = toObject();

    try {
      let res;
      if (save && isEditingAllowed) {
        res = await saveWorkflow({
          analysisId: params.analysisId!,
          workflow: ConvertFlowtoWorkflowPayload(obj),
          workflowId: params.editorId!,
        }).unwrap();
      } else {
        res = await getWorkflow({
          workflowId: params.editorId!,
        }).unwrap();
      }

      const newNodes = res.response.data!.workflows[0].workflowNodes;
      updateNodeIdAfterRun(newNodes, setNodes);
      const node = getTransformNode(newNodes)!;

      return {
        workflowNodeId: node.workflowNodeId,
        parameters: parameterIdMap(node.nodeParameters),
      };
    } catch (error) {
      if (error instanceof Error && (error as any).status === 403) {
        const node = getTransformNodeFromCurrentNodes();
        return {
          workflowNodeId: node?.data.workflowNodeId,
          parameters: parameterIdMap((node?.data as NodeType)?.parameters),
        };
      }
    }
  };
  const showTransformPanel = async ({
    transformNodeUiId,
    transformNodeId,
  }: ShowTransformPanelProps) => {
    nodeIds.current = {
      nodeUiId: transformNodeUiId,
      nodeWorkflowId: transformNodeId,
    };
    const node = getNode(transformNodeUiId as string)!;

    const incomingNodes = getIncomers(node, getNodes(), getEdges());
    if (!incomingNodes || incomingNodes.length === 0) {
      toast({
        title: "Please connect an input node to use transform",
        status: ToastType.Warning,
      });
      return;
    }

    const incoingNodeData = incomingNodes[0].data as WorkflowNodeSchema;
    const isSourceNodeDataNode =
      incoingNodeData.nodeType === NODE_TYPES.SOURCE_NODE;

    const restrictTransform =
      !isSourceNodeDataNode && !incoingNodeData.isOutputDataAvailable;

    if (restrictTransform) {
      toast({
        title: "Connected node does not have output data",
        status: ToastType.Warning,
      });
      return;
    }

    const saveResponse = await callWorkflowApi({ save: !!isEditingAllowed });
    const workflowNodeId = saveResponse?.workflowNodeId;
    const parameters = saveResponse?.parameters;

    const hasWfRanAtleastOnce = currentWFStatus !== NODE_STATUS.DEFAULT;
    const wfRunId = hasWfRanAtleastOnce ? workflowRunId : referenceRunId;

    dispatch(
      showPanel({
        panel: WORKFLOW_PANELS.DataTransformationPanel,
        nodeId: transformNodeUiId,
        workflowNodeId,
        parameters,
        workflowRunId: wfRunId,
        // TODO: May not be needed later
        nodeIoDetailsId: "",
        workflowId: params.editorId!,
        analysisId: params.analysisId!,
      })
    );
  };

  return {
    showTransformPanel,
    addTransformNode,
    isLoading: isLoading || isGetWorkflowLoading,
  };
};
