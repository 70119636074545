import { Portal } from "@chakra-ui/react";
import React from "react";
import { IoShareSocialOutline } from "react-icons/io5";
import { MdArrowOutward, MdMoreVert, MdOutlineEdit } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { TbHeartMinus, TbHeartPlus } from "react-icons/tb";

import { ProtectedComponent } from "@/components/protected-component/protected-component";
import { Icon } from "@/design/components/icon";
import { IconButton } from "@/design/components/icon-button";
import { Menu, MenuButton, MenuItem, MenuList } from "@/design/components/menu";
import { AnalysesSchema } from "@/features/ws-manager/types";
import { CATEGORY, PERMISSIONS } from "@/utils/enums.ts";

import useAnalysis from "../../hooks/useAnalysis";

export const AnalysisTableDropdown = (props: AnalysesSchema) => {
  const { onDelete, onEdit, openManageAccessModal, openDM, onFavorite } =
    useAnalysis();

  const handleDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onDelete(e, { data: props });
  };

  const handleEdit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onEdit(e, { data: props });
  };

  const handleFavorite = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    onFavorite(e, { data: props });
  };

  const handleManageAccess = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    openManageAccessModal(e, { data: props, type: CATEGORY.Analysis });
  };

  const handleDM = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    openDM(e, { data: props });
  };

  return (
    <Menu isLazy>
      <MenuButton
        onContextMenu={(e) => e.stopPropagation()}
        as={IconButton}
        icon={<MdMoreVert />}
        variant="ghost"
        colorScheme="dark"
        onClick={(e) => {
          e.stopPropagation();
        }}
      />
      <Portal>
        <MenuList
          onContextMenu={(e) => e.stopPropagation()}
          motionProps={{
            initial: { opacity: 0, y: -20 },
            animate: { opacity: 1, y: 0 },
            exit: { opacity: 0, y: -20 },
          }}
        >
          <MenuItem onClick={handleDM} icon={<Icon as={MdArrowOutward} />}>
            Edit In Studio
          </MenuItem>
          {/* <MenuItem
            //eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={handleFavorite}
            icon={<Icon as={props.favorite ? TbHeartMinus : TbHeartPlus} />}
          >
            {props.favorite ? "Remove from Favorites" : "Add to Favorites"}
          </MenuItem> */}
          <ProtectedComponent
            requiredPermission={PERMISSIONS.WRITE}
            id={props.id}
            type={CATEGORY.Analysis}
          >
            <MenuItem
              icon={<Icon as={IoShareSocialOutline} />}
              onClick={handleManageAccess}
            >
              Manage Sharing
            </MenuItem>
          </ProtectedComponent>
          <ProtectedComponent
            requiredPermission={PERMISSIONS.WRITE}
            id={props.id}
            type={CATEGORY.Analysis}
          >
            <MenuItem onClick={handleEdit} icon={<Icon as={MdOutlineEdit} />}>
              Edit Analysis Details
            </MenuItem>
          </ProtectedComponent>
          <ProtectedComponent
            requiredPermission={PERMISSIONS.DELETE}
            id={props.id}
            type={CATEGORY.Analysis}
          >
            <MenuItem
              color="red.600"
              onClick={handleDelete}
              icon={<Icon as={RiDeleteBin6Line} />}
            >
              Delete Analysis
            </MenuItem>
          </ProtectedComponent>
        </MenuList>
      </Portal>
    </Menu>
  );
};
