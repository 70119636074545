import { Box, Flex } from "@chakra-ui/react";
import clsx from "clsx";
import { useState } from "react";
import {
  MdAccountBox,
  MdFavorite,
  MdFavoriteBorder,
  MdHome,
  MdLightbulb,
  MdOutlineAccountBox,
  MdOutlineHome,
  MdOutlineLightbulb,
  MdOutlineStore,
  MdStore,
} from "react-icons/md";

import { Icon } from "@/design/components/icon";
import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@/design/components/tabs";
import { useAppDispatch, useAppSelector } from "@/reduxHooks.ts";

import { currentFlowItem, setCurrentFlowStoreItem } from "../..";
import { FLOW_TYPES } from "../../utils/constants";

import { FlowStoreDatatable } from "./flow-store-datatable";
import FlowDetails from "./flow-store-detail";

const tabIndexMap = {
  [FLOW_TYPES.ALL_FLOWS]: 0,
  [FLOW_TYPES.STANDARD_FLOWS]: 1,
  [FLOW_TYPES.THIS_ORG]: 2,
  [FLOW_TYPES.THIS_WORKSPACE]: 3,
  [FLOW_TYPES.MY_FLOWS]: 4,
  [FLOW_TYPES.FAVORITES]: 5,
};

export const FlowStoreTabs = () => {
  const currentFlow = useAppSelector(currentFlowItem);
  const currentTabType =
    useAppSelector(currentFlowItem)?.flowType ?? FLOW_TYPES.ALL_FLOWS;
  const dispatch = useAppDispatch();
  const isFlowListLoading = false;
  const [tabIndex, setTabIndex] = useState(tabIndexMap[currentTabType]);

  const onTabChange = (index: number) => {
    setTabIndex(index);
    dispatch(setCurrentFlowStoreItem(null));
  };

  return (
    <Tabs
      variant="line"
      size="sm"
      className="!flex flex-col h-full w-full"
      onChange={onTabChange}
      defaultIndex={tabIndex}
      isLazy={true}
    >
      <TabList className="font-medium ">
        <Tab key={1} className="items-center gap-1.5 !text-base">
          <Icon as={tabIndex == 0 ? MdHome : MdOutlineHome} />
          All Flows
        </Tab>
        <Tab key={2} className="items-center gap-1.5 !text-base">
          <Icon as={tabIndex == 1 ? MdLightbulb : MdOutlineLightbulb} />
          Standard Flows
        </Tab>
        <Tab key={3} className="items-center gap-1.5 !text-base">
          <Icon as={tabIndex == 2 ? MdStore : MdOutlineStore} />
          This Organization
        </Tab>
        <Tab key={4} className="items-center gap-1.5 !text-base">
          <Icon as={tabIndex == 3 ? MdStore : MdOutlineStore} />
          This Workspace
        </Tab>
        <Tab key={5} className="items-center gap-1.5 !text-base">
          <Icon as={tabIndex == 4 ? MdAccountBox : MdOutlineAccountBox} />
          Uploaded By Me
        </Tab>
        <Tab key={6} className="items-center gap-1.5 !text-base">
          <Icon as={tabIndex == 5 ? MdFavorite : MdFavoriteBorder} />
          Favorites
        </Tab>
      </TabList>
      {isFlowListLoading ? (
        <div>Loading...</div>
      ) : (
        <Flex className="h-full w-full overflow-hidden max-w-full">
          <TabPanels
            marginTop="2px"
            className="shrink h-full w-full p-3 bg-gray-100 border-t overflow-x-auto"
          >
            <TabPanel key={1} padding={0} className="h-full flex w-full">
              <FlowStoreDatatable
                flowType={FLOW_TYPES.ALL_FLOWS}
                onOpen={() => {}}
                label="All Flows"
              />
            </TabPanel>
            <TabPanel key={2} padding={0} className="h-full flex w-full">
              <FlowStoreDatatable
                flowType={FLOW_TYPES.STANDARD_FLOWS}
                onOpen={() => {}}
                label="Standard Flows"
              />
            </TabPanel>
            <TabPanel key={3} padding={0} className="h-full flex w-full">
              <FlowStoreDatatable
                flowType={FLOW_TYPES.THIS_ORG}
                onOpen={() => {}}
                label="This Org"
              />
            </TabPanel>
            <TabPanel key={4} padding={0} className="h-full flex w-full">
              <FlowStoreDatatable
                flowType={FLOW_TYPES.THIS_WORKSPACE}
                onOpen={() => {}}
                label="This Workspace"
              />
            </TabPanel>
            <TabPanel key={5} padding={0} className="h-full flex w-full">
              <FlowStoreDatatable
                flowType={FLOW_TYPES.MY_FLOWS}
                onOpen={() => {}}
                label="Uploaded By Me"
              />
            </TabPanel>
            <TabPanel key={6} padding={0} className="h-full flex w-full">
              <FlowStoreDatatable
                flowType={FLOW_TYPES.FAVORITES}
                onOpen={() => {}}
                label="Favorites"
              />
            </TabPanel>
          </TabPanels>
          <Box
            className={clsx(
              "h-full transition-all border-t grow flex-1 shrink-0",
              currentFlow === null ? "w-0 max-w-0" : "w-[30vw]"
            )}
            mt={0.5}
          >
            {currentFlow !== null && <FlowDetails />}
          </Box>
        </Flex>
      )}
    </Tabs>
  );
};
