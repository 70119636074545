import { isEmpty } from "lodash";

import { ViewTransformationParameter } from "@/features/data-transformation";

interface RegexValidation {
  pattern: string;
  message: string;
}

export const getInvalidValues = (
  values: Partial<ViewTransformationParameter>[],
  currentColumnNames: string[]
) => {
  const invalidValues = values
    .map((value) => {
      const err = isValid(value, currentColumnNames);
      if (err) {
        return { ...value, error: err };
      }
      return value;
    })
    .filter((value: any) => value.error);

  return isEmpty(invalidValues) ? null : invalidValues;
};

const checkRegexValidation = (
  value: string,
  regexValidation: RegexValidation
): string | null => {
  const regex = new RegExp(regexValidation.pattern);
  return regex.test(value) ? null : regexValidation.message;
};

const checkColumnNameValidation = (
  value: string,
  currentColumnNames: string[]
): string | null => {
  const nameExists = currentColumnNames.find(
    (name) => name.toLowerCase() === value.toLowerCase()
  );
  return nameExists
    ? `The column name ${value} is similar to an existing column named ${nameExists}`
    : null;
};

const isValid = (
  obj: Partial<ViewTransformationParameter>,
  currentColumnNames: string[]
): string | null => {
  const { value = "", isMandatory = false, additionalInfo } = obj;

  if (isMandatory) {
    if (additionalInfo?.regex) {
      const regexValidationResult = checkRegexValidation(
        value as string,
        additionalInfo.regex
      );
      if (regexValidationResult) return regexValidationResult;
    } else if (isEmpty(value)) {
      return "This field input is required";
    }
  }

  if (!isEmpty(value)) {
    if (additionalInfo?.regex) {
      const regexValidationResult = checkRegexValidation(
        value as string,
        additionalInfo.regex
      );
      if (regexValidationResult) return regexValidationResult;
    }

    if (additionalInfo?.columnNameValidation) {
      const columnValidationResult = checkColumnNameValidation(
        value as string,
        currentColumnNames
      );
      if (columnValidationResult) return columnValidationResult;
    }
  }

  return null;
};
