import { Button, Flex, Skeleton } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { MdArrowRight } from "react-icons/md";
import { useParams } from "react-router-dom";

import Loader from "@/components/loaders/loader";
import { useCreateTaxonomyMutation } from "@/features/workflow-studio/api/taxonomy-api";
import { useTaxonomyActions } from "@/features/workflow-studio/hooks/useTaxonomyActions";
import { useWorkflowActions } from "@/features/workflow-studio/hooks/useWorkflowActions";
import { NodeType } from "@/features/workflow-studio/types";
import { TaxonomyDatasetDetails } from "@/features/workflow-studio/types/taxonomy-types";

import { ReactFlowInstanceContext } from "../../../flow-editor";
import { useConfigPanel } from "../../config-panel-context";

import { ImportTaxonomyConfig } from "./import-taxonomy";
import RunModeOptions from "./run-mode-options";

export const PARAMETER_NAMES = {
  TAXONOMY_FILE_PATH: "Taxonomy File Path Info",
  RUN_MODE_CONFIG: "Run Mode Config",
} as const;

export const RUN_MODES = {
  FIND_NEW_THEMES: "find_new_themes",
  UPLOAD_TAXONOMY: "upload_taxonomy",
} as const;

const ThemeMapping = () => {
  const [taxonomyId, setTaxonomyId] = useState<string>();
  const {
    updateNodeParams,
    autoSave,
    currentNodeId,
    setParameters,
    parameters,
    setLoadingConfig,
    loadingConfig,
    error,
    setError,
    allowPanelClose,
    isEditingAllowed,
  } = useConfigPanel();

  const { analysisId, editorId } = useParams();
  const { nodes } = useContext(ReactFlowInstanceContext)!;
  const currentNodeData = nodes.find((node) => node.id === currentNodeId)
    ?.data as NodeType;
  const [selectedDataset, setSelectedDataset] =
    useState<TaxonomyDatasetDetails>();

  const runModeParam = parameters.find((p) => p.name === "Run Mode");
  const [createTaxonomy] = useCreateTaxonomyMutation();
  const { saveWFandUpdateNodes } = useWorkflowActions();

  const { handleSave, handleSaveAndExecute, uploading, isProcessing } =
    useTaxonomyActions({
      currentNodeId,
      parameters,
      updateNodeParams,
      autoSave,
      taxonomyId,
      nodeUsageInstanceId: currentNodeData?.nodeUsageInstanceId ?? "",
      selectedDataset,
      setParameters,
      allowPanelClose,
    });

  useEffect(() => {
    const initializeTaxonomy = async () => {
      if (taxonomyId) return;
      setLoadingConfig(true);
      try {
        if (!currentNodeData?.workflowNodeId) {
          await saveWFandUpdateNodes();
          return;
        }

        const response = await createTaxonomy({
          workflowId: editorId!,
          analysisId: analysisId!,
          workflowNodeId: currentNodeData.workflowNodeId,
        }).unwrap();

        const taxonomyMaster = response?.response?.data?.taxonomyMaster;
        if (taxonomyMaster?.[0]?.id) {
          setTaxonomyId(taxonomyMaster[0].id);
        } else {
          setError("No taxonomy master data received");
        }
      } catch (e) {
        setError("Failed to load Config");
      } finally {
        setLoadingConfig(false);
      }
    };

    initializeTaxonomy();

    return () => {
      setTaxonomyId(undefined);
    };
  }, [currentNodeData?.workflowNodeId, currentNodeId]);

  if (loadingConfig) {
    return <Skeleton className="h-20 w-full" />;
  }

  if (error) return null;

  if (!taxonomyId) {
    return (
      <Flex className="h-full" direction="column" gap={4}>
        Taxonomy ID is not set
      </Flex>
    );
  }

  return (
    <Flex className="h-full" direction="column" gap={4}>
      <Flex className="relative flex-col gap-4">
        {uploading && (
          <Flex className="absolute h-full w-full grid place-items-center z-10 bg-opacity-75 bg-white p-4">
            <Loader title="Uploading taxonomy..." />
          </Flex>
        )}
        <RunModeOptions />
        {runModeParam?.value === "upload_taxonomy" && (
          <ImportTaxonomyConfig
            selectedDataset={selectedDataset}
            setSelectedDataset={setSelectedDataset}
            parameters={parameters}
            setParameters={setParameters}
          />
        )}
      </Flex>
      {isEditingAllowed && (
        <Flex className="z-20 bg-white absolute bottom-0 left-0 right-0 justify-end items-center gap-2 border-t p-4">
          <Button
            colorScheme="dark"
            isDisabled={uploading || !isEditingAllowed || isProcessing}
          isLoading={isProcessing}
            onClick={handleSave}
            variant="outline"
          >
            Save
          </Button>
           {/* Hide execute button for now */}
          {/* <Button
          colorScheme="dark"
          isDisabled={uploading}
          onClick={handleSaveAndExecute}
          rightIcon={<MdArrowRight size={24} />}
        >
          Save & Execute
        </Button> */}
        </Flex>
      )}
    </Flex>
  );
};

export default ThemeMapping;
