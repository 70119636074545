export const WsManagerEndpoints = {
  analyses: (analysisId: string) => `/api/v1.0/analyses/${analysisId}`,
  createAnalyses: (projectId: string) =>
    `/api/v1.0/projects/${projectId}/analyses/`,
  getAnalysisListForProject: (projectId: string, wsId: string) =>
    `/api/v1.0/workspaces/${wsId}/projects/${projectId}`,
  getAnalysisListForWs: (wsId: string) =>
    `/api/v1.0/workspaces/${wsId}?filter=analysis`,
  getAnalysisListForOrg: (clientId: string) =>
    `/api/v1.0/clients/${clientId}?filter=analysis/`,
  getAnalysisFavourite: (clientId: string) =>
    `/api/v1.0/clients/${clientId}/favorites/`,
  getTags: (wsId: string) => `/api/v1.0/workspaces/${wsId}/suggest_tags/`,
  createProject: (wsId: string) => `/api/v1.0/workspaces/${wsId}/projects/`,
  getProjectList: (wsId: string) => `/api/v1.0/workspaces/${wsId}/projects/`,
  project: (projectId: string) => `/api/v1.0/projects/${projectId}`,
  restoreItem: (clientId: string) =>
    `/api/v1.0/clients/${clientId}/recycle_bin/items/restore/`,
  getRecycleList: (clientId: string) =>
    `/api/v1.0/clients/${clientId}/recycle_bin/items/`,
  createWs: (clientId: string) => `/api/v1.0/clients/${clientId}/workspaces`,
  workspace: (wsId: string) => `/api/v1.0/workspaces/${wsId}`,
  getWsList: (clientId: string) => `/api/v1.0/clients/${clientId}/workspaces`,
  searchInWs: (clientId: string) => `/api/v1.0/clients/${clientId}`,
  getWsItemsByIdList: () => `/api/v1.0/item-list/`,
};
