import { Box } from "@chakra-ui/react";
import { isEmpty } from "lodash";

import {
  NavigationPanel,
  NavigationPanelBody,
  NavigationPanelItem,
} from "@/design/components/navigation-panel";

import { ConnectorSources } from "../../types";

export const AddDatasetNavigation = (props: {
  setSelectedNavValue: any;
  connectorSources: ConnectorSources;
}) => {
  const { setSelectedNavValue, connectorSources } = props;
  if (isEmpty(connectorSources)) return <></>;
  return (
    <NavigationPanel
      type="studio"
      className="!w-60 !basis-60 h-full"
      colorScheme="light"
      defaultIndex={0}
    >
      <NavigationPanelBody>
        {Object.keys(connectorSources).map((item) => (
          <Box
            key={item}
            onClick={(e) => {
              e.stopPropagation();
              setSelectedNavValue(item);
            }}
          >
            <NavigationPanelItem hasIcon={false}>{item}</NavigationPanelItem>
          </Box>
        ))}
        {/* <NavigationPanelItem hasIcon={false}>Database</NavigationPanelItem>
        <NavigationPanelItem hasIcon={false}>
          Online Service
        </NavigationPanelItem>
        <NavigationPanelItem hasIcon={false}>Others</NavigationPanelItem> */}
      </NavigationPanelBody>
    </NavigationPanel>
  );
};
