import { Button } from "@chakra-ui/react";
import { isEmpty } from "lodash";
import { useEffect } from "react";
import { MdArrowRight } from "react-icons/md";
import { MdsErrorRound } from "react-icons-with-materialsymbols/mds";

import { useShowToast } from "@/components/toast";
import { getEditingAllowed } from "@/features/workflow-studio/redux/workflow-slice";
import { useAppDispatch, useAppSelector } from "@/reduxHooks";
import { ModalTypes, openModal } from "@/slices/modal-slice";
import { RUN_STATUS } from "@/utils/enums";

import { useGetRunDetailsQuery, useRunTestMutation } from "../../api";
import {
  currentActiveVersion,
  getActivePlayground,
  getActiveRunStatus,
  isLLMEditable,
  selectResultRowCount,
  setActiveVersion,
  setIsExecuteEnabled,
} from "../../redux";
import { EstimatedCost, RunSnakeCase } from "../../types";
import { useLLMValidation } from "../../utils/llm-validation";
import { convertTextGenVersionToSaveRunPayload } from "../../utils/transform-payload";

const POLLING_INTERVAL = 5000;

const RunTestButton = () => {
  const dispatch = useAppDispatch();
  const isValid = useLLMValidation();
  const toast = useShowToast(undefined, undefined, true);

  const [runTest, { isLoading }] = useRunTestMutation();

  const currentPlayground = useAppSelector(getActivePlayground);
  const activeVersion = useAppSelector(currentActiveVersion);
  const runStatus = useAppSelector(getActiveRunStatus);
  const inputSampleCount = useAppSelector(selectResultRowCount);
  const isLLMEditDisabled = useAppSelector(isLLMEditable);
  const isWFEditAllowed = useAppSelector(getEditingAllowed);

  const isEditAllowed = isWFEditAllowed && !isLLMEditDisabled;
  const isDisabled = !isEditAllowed || isLoading || !currentPlayground;
  const isRunTestLoading = isLoading || runStatus === RUN_STATUS.RUNNING;

  const { runDetailsData } = useGetRunDetailsQuery(
    {
      textGenId: currentPlayground?.textGeneration[0].id ?? "",
      runId: activeVersion?.runs?.runId ?? "",
      isActiveVersionResult: true,
    },
    {
      skip: runStatus !== RUN_STATUS.RUNNING,
      pollingInterval: POLLING_INTERVAL,
      selectFromResult: ({ data: resp }) => ({
        runDetailsData: resp?.response.data?.text_generation[0],
      }),
    }
  );

  function updateVersionWithRunDetails(runData: RunSnakeCase | undefined) {
    if (!activeVersion?.runs || !runData) return activeVersion;
    const {
      run_status: status,
      cost_estimation: cost,
      run_results: runResults,
    } = runData;

    return {
      ...activeVersion,
      estimatedCost: {
        currency: cost?.currency ?? activeVersion.estimatedCost?.currency,
        estimatedCost:
          cost?.estimated_cost ?? activeVersion.estimatedCost?.estimatedCost,
        estimatedToken:
          cost?.estimated_token ?? activeVersion.estimatedCost?.estimatedToken,
        minimumCost:
          cost?.minimum_cost ?? activeVersion.estimatedCost?.minimumCost,
        maximumCost:
          cost?.maximum_cost ?? activeVersion.estimatedCost?.maximumCost,
      } as EstimatedCost,
      runs: {
        ...activeVersion.runs,
        runStatus: status,
        ...(status === RUN_STATUS.COMPLETED && { runResults }),
      },
    };
  }

  useEffect(() => {
    const updatedActiveVersion = updateVersionWithRunDetails(runDetailsData);
    dispatch(setIsExecuteEnabled(!isEmpty(updatedActiveVersion?.runs?.runId)));
    dispatch(setActiveVersion(updatedActiveVersion));
  }, [runDetailsData]);

  const handleClick = () => {
    if (!activeVersion) return;
    if (!isDisabled && isValid) {
      runTest({
        playgroundId: currentPlayground.id,
        textGenId: currentPlayground.textGeneration[0].id,
        body: convertTextGenVersionToSaveRunPayload(
          activeVersion,
          inputSampleCount ?? 0
        ),
      })
        .unwrap()
        .then((res) => {
          const runInfo = res?.response?.data?.textGeneration[0].version.runs;
          if (!activeVersion || !runInfo?.runStatus) return;
          const updatedActiveVersion = {
            ...activeVersion,
            runs: {
              ...activeVersion.runs,
              runStatus: runInfo.runStatus,
              runId: runInfo.runId,
            },
          };
          dispatch(setActiveVersion(updatedActiveVersion));
          if (runInfo.runStatus === RUN_STATUS.RUNNING) {
            toast({
              title: "Test run started",
              status: "info",
            });
          }
        })
        .catch((e) => console.log(e));
    }
  };

  const openLLMErrorsModal = () => {
    dispatch(openModal({ modalType: ModalTypes.LLM_ERRORS, modalProps: {} }));
  };

  return (
    <>
      {isValid ? (
        <Button
          isDisabled={isDisabled}
          isLoading={isRunTestLoading}
          onClick={handleClick}
          rightIcon={<MdArrowRight />}
          size={"sm"}
        >
          Run Test
        </Button>
      ) : (
        <Button
          colorScheme={"dark"}
          isDisabled={isDisabled}
          isLoading={isRunTestLoading}
          onClick={openLLMErrorsModal}
          rightIcon={<MdsErrorRound className="stroke-[24]" />}
          size={"sm"}
          variant={"outline"}
        >
          Run Test
        </Button>
      )}
    </>
  );
};

export default RunTestButton;
