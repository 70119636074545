import { createContext } from "react";

import { TransformationStep } from "@/features/data-transformation";

export const StepFormContext = createContext<{
  step: Partial<TransformationStep> | null;
  editEnabled: boolean;
  register: any;
  setValue: any;
  getValues: any;
  unregister: (name: string | string[]) => void;
  reset: (values?: any, options?: any) => void;
} | null>(null);
