import { Box, Button } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";

import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@/design/components/modal";
import {
  useEndEditAccessMutation,
  useLazyGetWFActiveUserQuery,
  useRequestEditAccessMutation,
} from "@/features/workflow-studio/api";
import { useAppDispatch, useAppSelector } from "@/reduxHooks";
import { closeModal, ModalTypes, openModal } from "@/slices/modal-slice";

import {
  hideAllPanels,
  setCurrentWfActiveUser,
  setEditingAllowed,
  setIsInactiveModalOpen,
} from "../../redux";

export const TimeoutWarningModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const { analysisId, initialSeconds, timeOut } = useAppSelector(
    (state) => state.rootReducer.modals
  ).modalProps;
  const [endEditAccess, { isLoading }] = useEndEditAccessMutation();
  const [requestAccess, { isLoading: isRequestingAccess }] =
    useRequestEditAccessMutation();

  const [getWFActiveUser, { isLoading: isLoadingActiveUser }] =
    useLazyGetWFActiveUserQuery();
  const [countdown, setCountdown] = useState(timeOut - initialSeconds);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prev) => {
        if (prev <= 1) {
          navigateToTimedOutModal();
          clearInterval(timer);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    // Check if countdown is already negative or zero on component mount
    if (countdown <= 0) {
      navigateToTimedOutModal();
    }

    return () => clearInterval(timer);
  }, []);

  const endEditing = useCallback(async () => {
    try {
      await endEditAccess({ analysisId }).unwrap();
      dispatch(closeModal());
      dispatch(hideAllPanels());
      dispatch(setEditingAllowed(false));
      dispatch(
        setCurrentWfActiveUser({
          user: null,
          status: "",
          isCurrentUser: false,
          timeSinceLastActivity: null,
        })
      );
    } catch (error) {
      console.error("Failed to end editing:", error);
    }
  }, [analysisId, endEditAccess, dispatch]);

  const navigateToTimedOutModal = () => {
    try {
      dispatch(hideAllPanels());
      endEditing().catch((error) => {
        console.error("Failed to end editing:", error);
      });
      dispatch(closeModal());
      dispatch(
        openModal({
          modalType: ModalTypes.TIMED_OUT,
          modalProps: { analysisId },
        })
      );
    } catch (error) {
      console.error("Failed to end editing:", error);
    }
  };

  const onContinueEditing = async () => {
    try {
      await requestAccess({ analysisId }).unwrap();
      await getWFActiveUser({ analysisId }).unwrap();
      dispatch(setIsInactiveModalOpen(false));
      dispatch(closeModal());
    } catch (error) {
      console.error("Failed to request access:", error);
    }
  };

  const timeoutInMinutes = Math.floor(timeOut / 60);

  return (
    <Modal
      isOpen={true}
      closeOnOverlayClick={false}
      isCentered
      size="md"
      onClose={() => {}}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader className="flex gap-3 items-start justify-between border-b !ml-0 !pb-3 !pl-5">
          <Box className="w-fit text-lg text-gray-800 font-medium">
            Are you still there?
          </Box>
        </ModalHeader>
        <ModalBody className="!px-4 py-3">
          <div className="space-y-4">
            <p>
              You have been inactive for a while. Please confirm if you would
              like to continue editing.
            </p>
            <div className="flex justify-end space-x-2">
              <Button
                colorScheme="dark"
                isDisabled={
                  isLoading || isRequestingAccess || isLoadingActiveUser
                }
                isLoading={isLoading}
                onClick={endEditing}
                variant="outline"
              >
                End Editing ({Math.max(0, countdown)})
              </Button>
              <Button
                colorScheme="dark"
                isDisabled={
                  isLoading || isRequestingAccess || isLoadingActiveUser
                }
                isLoading={isRequestingAccess || isLoadingActiveUser}
                onClick={onContinueEditing}
              >
                Continue Editing
              </Button>
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
