import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";

import { useAppDispatch } from "@/reduxHooks";
import { closeModal } from "@/slices/modal-slice";

import EditDatasetBody from "./edit-dataset-body";

export const EditDatasetDetail: React.FC = () => {
  const dispatch = useAppDispatch();
  const onClose = () => {
    dispatch(closeModal());
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isCentered
      isOpen={true}
      onClose={onClose}
      size="md"
    >
      <ModalOverlay />
      <ModalContent className="!pb-0 !gap-0">
        <ModalHeader>Edit Dataset</ModalHeader>
        <ModalCloseButton className="!mr-0" />
        <ModalBody className="!flex !flex-col !p-5 gap-5">
          <EditDatasetBody />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
