import { Textarea } from "@chakra-ui/react";
import clsx from "clsx";
import { isEmpty } from "lodash";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  ControlledSelectNoForm,
  CustomOptions,
  CustomValueContainer
} from "@/components/controlled-select";
import {
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
  FormHelperText,
  FormHelperTextIcon,
  FormLabel,
} from "@/design/components/form";
import {
  COLUMN_IDENTIFIER,
  convertArrayToLabelValue,
  ViewTransformationParameter,
  LabelValuePair,
  tableColumns,
  CHILD_PARAMETER,
} from "@/features/data-transformation";

import { StepFormContext } from "../step-form-context.ts";

import { MultiConfigContext } from "./multi-config-context";

interface SelectFormProps {
  param: Partial<ViewTransformationParameter>;
  errors?: string;
  hideLabel?: boolean;
  hideHintText?: boolean;
  id?: string;
  parentShortName?: string;
  hideErrorIcon?: boolean;
  onChange?: (value: string) => void;
  placeholder?: string;
}

export const SelectForm = ({
  param,
  errors,
  onChange,
  hideLabel,
  hideHintText,
  id,
  parentShortName,
  hideErrorIcon,
  placeholder = "Selection",
}: SelectFormProps) => {
  const columns = useSelector(tableColumns);
  const { step, editEnabled, register, setValue } =
    useContext(StepFormContext)!;
  const multiConfig = useContext(MultiConfigContext);

  const defaultValue = (param.value ?? param.defaultValue ?? "") as string;

  const initialSelectedValue = !isEmpty(defaultValue)
    ? defaultValue
    : undefined;

  const [selectedValue, setSelectedValue] = useState<string | undefined>(
    initialSelectedValue
  );

  useEffect(() => {
    setSelectedValue(initialSelectedValue);
  }, [initialSelectedValue]);

  const valueObject: LabelValuePair | undefined = selectedValue
    ? {
        label: selectedValue ?? "",
        value: selectedValue ?? "",
      }
    : undefined;

  const showColumns = param.identifier === COLUMN_IDENTIFIER;

  const oldColumns = step?.metadata?.onSample?.previousStepColumnInfo;
  const dropdownColumns = isEmpty(oldColumns)
    ? columns.map((item) => item.name)
    : oldColumns;

  const list = showColumns ? dropdownColumns : param.valuesList;

  // Filter out columns that are selected in other configs
  const availableColumns =
    showColumns && multiConfig
      ? (list ?? []).filter(
          (col) =>
            !multiConfig.selectedColumns.some(
              (selected) =>
                selected.column === col &&
                selected.configId !== multiConfig.currentConfigId
            )
        )
      : list;

  const controlKey = id
    ? `${CHILD_PARAMETER}%%%${parentShortName}%%%${param.shortName}%%%${id}`
    : param.shortName;

  const handleChange = (value: LabelValuePair) => {
    const val = value.value;
    setSelectedValue(val);
    setValue(controlKey, val, {
      shouldDirty: true,
    });
    if (onChange) {
      onChange(val);
    }
  };

  return (
    <FormControl
      key={controlKey}
      isRequired={param.isMandatory}
      isInvalid={!!errors}
      isDisabled={!editEnabled}
    >
      {!hideLabel && (
        <FormLabel className="!text-sm !mb-1.5 font-medium leading-none">
          {param.name}
        </FormLabel>
      )}
      <Textarea
        data-shortname={controlKey}
        id={controlKey}
        type="string"
        value={selectedValue}
        {...register(controlKey)}
        hidden={true}
        readOnly
      />
      <ControlledSelectNoForm
        options={convertArrayToLabelValue(availableColumns ?? [])}
        value={valueObject}
        placeholder={placeholder}
        data-shortname={param.shortName}
        id={param.shortName}
        size="sm"
        onChange={(newValue) => handleChange(newValue as LabelValuePair)}
        selectedOptionStyle="check"
        components={{
          Option: CustomOptions,
          ValueContainer: CustomValueContainer,
        }}
        isRequired={param.isMandatory}
        useBasicStyles
      />
      {!errors && param?.hintText && !hideHintText && (
        <FormHelperText className="pl-3 text-[13px]">
          <FormHelperTextIcon className="!self-start" />
          {param.hintText}
        </FormHelperText>
      )}
      <FormErrorMessage
        className={clsx("pl-1 text-[13px]", !hideErrorIcon && "pl-3")}
      >
        {!hideErrorIcon && <FormErrorIcon />}
        {errors && errors}
      </FormErrorMessage>
    </FormControl>
  );
};
