import { Box, Button, Flex, Tooltip } from "@chakra-ui/react";
import { isEmpty } from "lodash";
import { useContext, useEffect, useState } from "react";
import { MdEdit, MdSync } from "react-icons/md";
import { useParams } from "react-router-dom";

import { ToastType, useShowToast } from "@/components/toast";
import { useAppDispatch, useAppSelector } from "@/reduxHooks";

import { useRequestEditAccessMutation } from "../../api";
import { useSyncWorkflow } from "../../hooks/useSyncWorkflow";
import {
  currentWfActiveUser,
  getEditingAllowed,
  getWorkflowState,
  hideAllPanels,
  setCurrentWfActiveUser,
  setEditingAllowed,
} from "../../redux";
import { ReactFlowInstanceContext } from "../flow-editor";

const ViewModeOptions = ({ hasEditAccess }: { hasEditAccess: boolean }) => {
  const params = useParams();
  const toast = useShowToast(undefined, undefined, true);
  const { setNodesFn, setEdgesFn, editorInstance } = useContext(
    ReactFlowInstanceContext
  )!;
  const dispatch = useAppDispatch();

  const isEditingAllowed = useAppSelector(getEditingAllowed);
  const workflowState = useAppSelector(getWorkflowState);
  const currentUser = useAppSelector(currentWfActiveUser);

  const [requestEditAccess, { isLoading: isRequesting }] =
    useRequestEditAccessMutation();

  const { refetchWorkflow, isSyncing } = useSyncWorkflow();

  const isLoading = isRequesting || isSyncing;

  const [showSyncButton, setShowSyncButton] = useState(false);
  const [currentStateHash, setCurrentStateHash] = useState(
    workflowState?.stateHash
  );

  useEffect(() => {
    if (isEditingAllowed) {
      setShowSyncButton(false);
    }
    if (
      workflowState?.stateHash &&
      currentStateHash &&
      currentStateHash !== workflowState.stateHash
    ) {
      setShowSyncButton(true);
    }
    setCurrentStateHash(workflowState?.stateHash);
  }, [workflowState?.stateHash, currentStateHash, isEditingAllowed]);

  const handleSyncChanges = async () => {
    const success = await refetchWorkflow(
      setNodesFn,
      setEdgesFn,
      editorInstance
    );
    if (success) {
      setShowSyncButton(false);
    }
  };

  const isCurrentUserEditing = currentUser?.isCurrentUser;

  const onEditClick = async () => {
    try {
      await refetchWorkflow(setNodesFn, setEdgesFn, editorInstance);
      const res = await requestEditAccess({
        analysisId: params.analysisId!,
      }).unwrap();
      toast({
        title: res?.data?.results?.message,
        status: ToastType.Success,
      });
      // optimistically update the current user as active to
      // allow setting setEditingAllowed to true in slice
      dispatch(
        setCurrentWfActiveUser({
          isCurrentUser: true,
          user: null,
          status: null,
          timeSinceLastActivity: null,
        })
      );
      dispatch(hideAllPanels());
      dispatch(setEditingAllowed(true));
    } catch (err) {
      toast({
        title: "Failed to request edit access",
        status: ToastType.Error,
      });
    }
  };

  return (
    <Flex className="ml-2 text-sm" align="center" gap={2}>
      {isEmpty(currentUser?.user) ? (
        <>
          {hasEditAccess ? (
            <Box>Workflow is now free for editing</Box>
          ) : (
            <Box>You have view only access to this workflow</Box>
          )}

          <Button
            colorScheme="dark"
            isDisabled={isCurrentUserEditing || !hasEditAccess}
            isLoading={isLoading}
            onClick={onEditClick}
            rightIcon={<MdEdit size={14} />}
            size="sm"
          >
            Start Editing
          </Button>
        </>
      ) : (
        <>
          <Box className="text-sm">
            <span className="font-semibold mr-2">
              {currentUser?.user?.displayName}
            </span>
            is currently editing...
          </Box>

          {!isCurrentUserEditing && (
            <Tooltip
              label={
                showSyncButton ? "Sync latest changes" : "No changes to sync"
              }
              openDelay={500}
              placement="bottom"
            >
              <Button
                aria-label="Sync Changes"
                colorScheme="green"
                isDisabled={!showSyncButton || isSyncing}
                onClick={handleSyncChanges}
                rightIcon={
                  isSyncing ? (
                    <MdSync className="animate-spin" size={16} />
                  ) : (
                    <MdSync size={16} />
                  )
                }
                size="sm"
              >
                {isSyncing ? "Syncing changes" : "Sync Changes"}
              </Button>
            </Tooltip>
          )}
        </>
      )}
    </Flex>
  );
};

export default ViewModeOptions;
