import { Button, Flex, Tooltip } from "@chakra-ui/react";
import clsx from "clsx";
import { isEmpty } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { MdArrowBack } from "react-icons/md";
import { useParams } from "react-router-dom";
import { useReactFlow } from "reactflow";

import { ToastType, useShowToast } from "@/components/toast";
import {
  toggleLLMConfigPanel,
  useLazyGetWorkflowQuery,
} from "@/features/workflow-studio";
import {
  getEditingAllowed,
  triggerRun as triggerRunAction,
} from "@/features/workflow-studio/redux";
import { useAppDispatch, useAppSelector } from "@/reduxHooks";

import { useSaveAndExecuteMutation, useSaveVersionMutation } from "../../api";
import {
  currentActiveVersion,
  getActivePlayground,
  getCurrentRunResults,
  getInputPrompt,
  isExecuteEnabled,
  isLLMEditable,
  selectResultRowCount,
  showPromptHistory,
} from "../../redux";
import { useLLMValidation } from "../../utils/llm-validation";
import { convertTextGenVersionToSaveRunPayload } from "../../utils/transform-payload";

import { updateParamsInWfNodes } from "./helper";
import LLMCostEstimationCard from "./llm-cost-estimation";

export const LLMConfigFooter = () => {
  const [saveVersion, { isLoading }] = useSaveVersionMutation();
  const [_, { isLoading: isExecuting }] = useSaveAndExecuteMutation();
  const [getWorkflowDetails, { isLoading: fetchingWf, isFetching: loadingWf }] =
    useLazyGetWorkflowQuery();

  const { setNodes } = useReactFlow();
  const toast = useShowToast();
  const params = useParams();
  const isValid = useLLMValidation();
  const dispatch = useAppDispatch();

  const [triggerRun, setTriggerRun] = useState(false);
  const activeVersion = useAppSelector(currentActiveVersion);
  const currentPlayground = useAppSelector(getActivePlayground);
  const currentRunResults = useAppSelector(getCurrentRunResults);
  const inputSampleCount = useAppSelector(selectResultRowCount);
  const isHistoryOpen = useAppSelector(showPromptHistory);
  const inputPrompt = useAppSelector(getInputPrompt);
  const executeEnabled = useAppSelector(isExecuteEnabled);
  const isLLMEditDisabled = useAppSelector(isLLMEditable);

  const isWFEditAllowed = useAppSelector(getEditingAllowed);

  const isDisabled =
    isLLMEditDisabled ||
    isLoading ||
    !currentPlayground ||
    isEmpty(inputPrompt);
  const isExecuteLoading = isLoading || isExecuting || fetchingWf;

  const isExecuteDisabled = useMemo(() => {
    if (!executeEnabled || isLLMEditDisabled) return true;
    if (!isValid) return true;
    if (!currentRunResults || isEmpty(currentRunResults.headers)) return true;
    return isLoading || !activeVersion;
  }, [
    isLoading,
    activeVersion,
    currentRunResults,
    isValid,
    isLLMEditDisabled,
    executeEnabled,
  ]);

  const onCancel = async () => {
    try {
      await getWorkflow();
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(toggleLLMConfigPanel({ show: false }));
    }
  };

  useEffect(() => {
    if (!triggerRun) return;
    if (triggerRun) {
      dispatch(triggerRunAction(true));
      setTimeout(() => dispatch(triggerRunAction(false)), 1000);
      setTriggerRun(false);
      dispatch(toggleLLMConfigPanel({ show: false }));
    }
  }, [triggerRun]);

  const saveLLMNode = async () => {
    if (!activeVersion) return;
    if (!isDisabled) {
      await saveVersion({
        playgroundId: currentPlayground.id,
        textGenId: currentPlayground.textGeneration[0].id,
        body: convertTextGenVersionToSaveRunPayload(
          activeVersion,
          inputSampleCount ?? 0
        ),
      });
    }
  };

  const getWorkflow = async () => {
    const workflowResp = await getWorkflowDetails({
      workflowId: params["editorId"] as string,
    }).unwrap();
    const newNodes = workflowResp?.response.data?.workflows[0].workflowNodes;
    await updateParamsInWfNodes(newNodes ?? [], setNodes);
  };

  const onSave = async () => {
    try {
      await saveLLMNode();
      await getWorkflow();
      toast({
        description: "Saved Successfully",
        status: ToastType.Success,
      });
    } catch (error) {
      toast({
        description: "Failed to Save",
        status: ToastType.Error,
      });
    }
  };

  const onSaveAndExecute = async () => {
    try {
      await saveLLMNode();
      await getWorkflow();
      toast({
        description: "Saved Successfully",
        status: ToastType.Success,
      });
      setTriggerRun(true);
    } catch (error) {
      toast({
        description: "Failed to Save & Execute",
        status: ToastType.Error,
      });
    }
  };

  return (
    <Flex
      className={clsx(
        "w-full border-t p-3",
        isHistoryOpen ? "justify-end" : "justify-between"
      )}
    >
      {!isHistoryOpen && (
        <Button
          colorScheme="dark"
          isDisabled={loadingWf}
          isLoading={loadingWf}
          leftIcon={<MdArrowBack />}
          onClick={onCancel}
          size={"sm"}
          variant={"outline"}
        >
          Back
        </Button>
      )}
      <Flex gap={6}>
        <LLMCostEstimationCard />
        {!isHistoryOpen && isWFEditAllowed && (
          <Flex gap={2}>
            <Button
              colorScheme="dark"
              isDisabled={isDisabled}
              isLoading={isLoading}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={onSave}
              size={"sm"}
              variant={"outline"}
            >
              Save
            </Button>
            <Tooltip
              className="w-36 h-14 flex items-center !bg-gray-800 text-white text-center"
              sx={{
                ".chakra-tooltip__arrow": {
                  backgroundColor: "rgb(64,64,64) !important",
                },
              }}
              hasArrow
              isDisabled={!isExecuteDisabled}
              label={"Run Test required before execution"}
              placement="top-end"
              shouldWrapChildren={true}
            >
              <Button
                colorScheme="dark"
                isDisabled={isExecuteDisabled || isExecuteLoading}
                isLoading={isExecuteLoading}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onClick={onSaveAndExecute}
                size={"sm"}
                variant={"solid"}
              >
                Save & Execute
              </Button>
            </Tooltip>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
