import { getMSBaseUrl } from "@/utils/baseUrls.ts";
import { MICRO_SERVICES } from "@/utils/enums.ts";

export const DataManagerEndpoints = {
  getSources: `/api/v1.0/sources/`,
  uploadLocalFile: (analysisId: string) =>
    `/api/v1.0/analyses/${analysisId}/iorecords/?activity_type=new_local_upload`,
  getOngoingUploads: (analysisId: string) =>
    `/api/v1.0/analyses/${analysisId}/iorecords/`,
  getDatSets: (analysisId: string) =>
    `/api/v1.0/analyses/${analysisId}/datasets/?include_schema=false`,
  editDataset: (analysisId: string, datasetId: string) =>
    `/api/v1.0/analyses/${analysisId}/datasets/${datasetId}`,
  getSingleDataSet: (analysisId: string, datasetId: string) =>
    `/api/v1.0/analyses/${analysisId}/datasets/${datasetId}`,
  deleteDataset: (analysisId: string, datasetId: string) =>
    `/api/v1.0/analyses/${analysisId}/datasets/${datasetId}`,
  getSingleIoRecord: (analysisId: string, ioRecordId: string) =>
    `/api/v1.0/analyses/${analysisId}/iorecords/${ioRecordId}`,
  getPreviewData: (analysisId: string, ioRecordId: string) =>
    `${getMSBaseUrl(
      MICRO_SERVICES.PDS
    )}/api/v1.0/analyses/${analysisId}/iorecords/${ioRecordId}/`,
  acceptSchema: (analysisId: string, ioRecordId: string) =>
    `${getMSBaseUrl(
      MICRO_SERVICES.PDS
    )}/api/v1.0/analyses/${analysisId}/iorecords/${ioRecordId}/?action=accept_schema`,
  deleteIORecord: (analysisId: string, ioRecordId: string) =>
    `/api/v1.0/analyses/${analysisId}/iorecords/${ioRecordId}`,
  runValidation: (analysisId: string, validation_type: string) =>
    `/api/v1.0/analyses/${analysisId}/validate/?activity_type=${validation_type}`,
  sourceForm: (actorDefinitionId: string) =>
    `/api/v1.0/actor_definition_specifications/${actorDefinitionId}?actor_type=source`,

  addDownload: (analysisId: string) =>
    `/api/v1.0/analyses/${analysisId}/downloads/`,
  getSingleDownload: (analysisId: string, downloadId: string) =>
    `/api/v1.0/analyses/${analysisId}/downloads/${downloadId}`,
  getDownloads: (analysisId: string) =>
    `/api/v1.0/analyses/${analysisId}/downloads/`,

  getDestinationActors: (analysisId: string, definitionId: string) =>
    `/api/v1.0/analyses/${analysisId}/actors/?definition_id=${definitionId}`,
  getDestinationFormDetails: (analysisId: string, actorId: string) =>
    `/api/v1.0/analyses/${analysisId}/actors/${actorId}?action=form_details`,
  getDestinationForm: (actorDefinitionId: string) =>
    `/api/v1.0/actor_definition_specifications/${actorDefinitionId}`,
  createDestinationActor: (analysisId: string) =>
    `/api/v1.0/analyses/${analysisId}/actors/?actor_type=destination`,
  getSchemaComparison: (analysisId: string, actorId: string) =>
    `/api/v1.0/analyses/${analysisId}/actors/${actorId}?action=compare_schema`,
  confirmSchema: (analysisId: string) =>
    `/api/v1.0/analyses/${analysisId}/exports/`,
  getOngoingExports: (analysisId: string) =>
    `/api/v1.0/analyses/${analysisId}/exports/`,
  cancelExport: (analysisId: string, exportId: string) =>
    `/api/v1.0/analyses/${analysisId}/exports/${exportId}`,
  deleteDestinationActor: (analysisId: string, actorId: string) =>
    `/api/v1.0/analyses/${analysisId}/actors/${actorId}`,

  listSources: () => `/api/v1.0/sources/`,
  listDestinations: () => `/api/v1.0/destinations/`,
  verifySourceForm: (analysisId: string) =>
    `/api/v1.0/analyses/${analysisId}/iorecords/?activity_type=new_upload`,
  submitConnectionForm: (analysisId: string) =>
    `/api/v1.0/analyses/${analysisId}/actors/?actor_type=source`,
  getfilesList: (ioId: string, analysisId: string) =>
    `/api/v1.0/analyses/${analysisId}/iorecords/${ioId}?action=discover_schema`,
  getDatasetList: (ioId: string, analysisId: string) =>
    `/api/v1.0/analyses/${analysisId}/actors/${ioId}?action=discover_schema`,
  getIoRecordMetaData: (ioId: string, analysisId: string) =>
    `/api/v1.0/analyses/${analysisId}/iorecords/${ioId}?action=metadata_details`,
  processIoRecord: (ioId: string, analysisId: string) =>
    `/api/v1.0/analyses/${analysisId}/iorecords/${ioId}?action=process_data`,
  syncSource: (analysisId: string, ioId: string) =>
    `/api/v1.0/analyses/${analysisId}/iorecords/${ioId}?action=sync`,
  uploadAirbyteFiles: (analysisId: string) =>
    `/api/v1.0/analyses/${analysisId}/iorecords/?activity_type=new_upload`,
  uploadFileChunk: `/api/v1.0/local_file_upload/`,
  getConnectorDocumentation:
    import.meta.env.VITE_STRAPI_BASE_URL + "/connector-descriptions",
  getUploadOffset: (id: string) => `api/v1.0/local_file_upload/${id}/`,

  // OAuth endpoints
  getConsentUrl: `/api/v1.0/authorization/init_oauth`,
  completeOAuth: `/api/v1.0/authorization/complete_oauth`,
};
