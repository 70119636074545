import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { isEmpty } from "lodash";
import React, { useEffect } from "react";

import { ToastType, useShowToast } from "@/components/toast";
import { useAppDispatch, useAppSelector } from "@/reduxHooks";
import { closeModal } from "@/slices/modal-slice";

import { useEditDatasetMutation } from "../../api";
import { DatasetsSchema } from "../../types";

const EditDatasetBody = () => {
  const dispatch = useAppDispatch();
  const { modalProps } = useAppSelector((state) => state.rootReducer.modals);

  const toast = useShowToast(undefined, undefined, true);
  const [datasetName, setDatasetName] = React.useState("");
  const [editDataseApi, { isLoading: isEditing }] = useEditDatasetMutation();
  const fileName = (modalProps.dataset as DatasetsSchema).displayName;

  useEffect(() => {
    setDatasetName(fileName);
  }, [fileName]);

  const [errorMessage, setErrorMessage] = React.useState("");

  const handleRename = async () => {
    try {
      await editDataseApi({
        analysisId: modalProps.dataset.analysisId,
        datasetId: modalProps.dataset.id,
        body: {
          name: datasetName,
        },
      }).unwrap();
      toast({
        title: "Dataset renamed successfully",
        status: ToastType.Success,
      });
      dispatch(closeModal());
    } catch (e: any) {
      console.error(e);
      if (e.data.type === "validation_error") {
        setErrorMessage(e.data.errors[0].detail as string);
        return;
      }
      toast({
        title: "An error occurred while renaming the dataset",
        status: ToastType.Error,
      });
    }
  };

  const onSubmit = async () => {
    if (datasetName.trim().length === 0) {
      setErrorMessage("Dataset Name is required");
      return;
    }
    if (datasetName.length > 100) {
      setErrorMessage("Max length for Dataset Name is 100 characters");
      return;
    }
    await handleRename();
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setDatasetName(inputValue);
    setErrorMessage("");
    if (inputValue.length > 100) {
      setErrorMessage("Max length for Dataset Name is 100 characters");
    }
  };

  return (
    <Flex className="flex-col gap-4">
      <FormControl isInvalid={!isEmpty(errorMessage)}>
        <FormLabel>Dataset Name</FormLabel>
        <Input
          isDisabled={isEditing}
          onChange={handleInputChange}
          placeholder="Enter Dataset Name"
          value={datasetName}
        />
        <FormErrorMessage className="break-all">
          {errorMessage}
        </FormErrorMessage>
      </FormControl>
      <Button
        className="w-fit self-end"
        colorScheme="dark"
        isDisabled={isEditing || !isEmpty(errorMessage)}
        isLoading={isEditing}
        onClick={onSubmit}
      >
        Save Changes
      </Button>
    </Flex>
  );
};

export default EditDatasetBody;
