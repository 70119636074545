import {
  MdsBlockRound,
  MdsCellMergeRound,
  MdsErrorRound,
  MdsSearchRound,
  MdsTranslateRound,
} from "react-icons-with-materialsymbols/mds";

import {
  FilterCondition,
  DATA_TYPE,
  STEP_STATUS,
  STATISTICS,
  EXPLORE_STATS_TYPE,
} from "@/features/data-transformation";

export const CUSTOM_QUERY = "edaId";
export const FULL_DATA_SAMPLING = "use_full_data";
export const RANDOM_DATA_SAMPLING = "random";
export const STRATIFIED_SAMPLING = "stratified";
export const START_PARAMETER_SHORT_NAME = "strat_column";
export const NUMBER_OF_RECORDS_SHORT_NAME = "num_of_records";
export const COLUMN_IDENTIFIER = "COLUMN_NAME";
export const COLUMN_SHORTNAME = "column";
export const NEW_POSITION_SHORTNAME = "new_position";
export const REFERENCE_COLUMN_SHORTNAME = "reference_column";
export const DATA_TYPE_IDENTIFIER = "data_type";
export const CHILD_PARAMETER = "&child";

export const REORDER_COLUMN_SHORTNAME = "reorder_column";
export const RENAME_COLUMN_IDENTIFIER = "rename_column";
export const FIND_REPLACE_IDENTIFIER = "find_and_replace";
export const DUPLICATE_COLUMN_IDENTIFIER = "duplicate_column";
export const REMOVE_COLUMN_IDENTIFIER = "remove_column";
export const CHANGE_DATA_TYPE_IDENTIFIER = "change_data_type";
export const COMMA_SEPARATOR = "[Nf2BJHrnkj2o849fbjrknjfkwehj]";

export const STEP_MESSAGE: Record<
  STEP_STATUS,
  Partial<{
    heading: string;
    icon: any;
    description: string;
  }>
> = {
  [STEP_STATUS.Removed]: {
    heading: "Step Removed",
    icon: MdsBlockRound,
    description:
      "Removed steps are discarded before execution. Recover this step if you wish to apply this transformation step.",
  },
  [STEP_STATUS.Failed]: {
    heading: "Step has Issues",
    icon: MdsErrorRound,
    description:
      "Some configurations in this step are invalid. You will not be able to execute the transformations until all the issues are resolved.",
  },
  [STEP_STATUS.Active]: {},
  [STEP_STATUS.Success]: {},
};

export const NO_CONDITION = "none";

export const CONDITIONS_MAP: FilterCondition[] = [
  { key: "None", value: NO_CONDITION, icon: "=", hasNoInput: true },
  { key: "Is equal to", value: "equal_to", icon: "=" },
  { key: "Is not equal to", value: "not_equal_to", icon: "≠" },
  { key: "Text contains", value: "contains", icon: null },
  { key: "Text does not contain", value: "not_contains", icon: "≠" },
  { key: "Text starts with", value: "starts_with", icon: null },
  { key: "Text ends with", value: "ends_with", icon: null },
  { key: "Text is exactly", value: "text_equals", icon: "=" },
  { key: "Matches the regex", value: "regex", icon: null },
  { key: "Date is", value: "date_is", icon: "=" },
  { key: "Date is after", value: "date_is_after", icon: null },
  { key: "Date is before", value: "date_is_before", icon: null },
  { key: "Greater than", value: "greater_than", icon: ">" },
  {
    key: "Greater than or equal to",
    value: "greater_than_or_equal_to",
    icon: ">=",
  },
  { key: "Less than", value: "less_than", icon: "<" },
  { key: "Less than or equal to", value: "less_than_or_equal_to", icon: "<=" },
  { key: "Is Between", value: "between", icon: null },
  { key: "Is not Between", value: "not_between", icon: null },
  { key: "Is empty", value: "is_empty", icon: null, hasNoInput: true },
  { key: "Is not empty", value: "is_not_empty", icon: null, hasNoInput: true },
];

export const MULTIPLE_INPUT: FilterCondition[] = [
  { key: "Is Between", value: "between", icon: null },
  { key: "Is not Between", value: "not_between", icon: null },
];
export const NO_INPUT = ["is_not_empty", "is_empty", null];
export const CONDITONS_SET_1 = CONDITIONS_MAP.slice(0, 1);
export const CONDITONS_SET_2 = CONDITIONS_MAP.slice(1, 3);
export const CONDITONS_SET_3 = CONDITIONS_MAP.slice(3, 9);
export const CONDITONS_SET_4 = CONDITIONS_MAP.slice(9, 13);
export const CONDITONS_SET_5 = CONDITIONS_MAP.slice(13, -1);
export const DATE_TIMESTAMP_TYPE = [DATA_TYPE.DATE, DATA_TYPE.TIMESTAMP];
export const COLUMN_TYPE_MAP: { [k: string]: string } = {
  integer: "int",
  float: "float",
  string: "string",
  [DATA_TYPE.DATE]: "date_time",
  [DATA_TYPE.BOOLEAN]: "bool",
};

export const STATS_MAP: Record<string, string> = {
  [STATISTICS.MIN]: "Minimum",
  [STATISTICS.MEAN]: "Mean",
  [STATISTICS.MAX]: "Maximum",
  [STATISTICS.MEDIAN]: "Median",
  [STATISTICS.STDDEV]: "Std.deviation",
  [STATISTICS.MODE]: "Mode",
  [STATISTICS.TOTAL_COUNT]: "Total Count",
  [STATISTICS.DISTINCT_COUNT]: "Distinct Count",
  [STATISTICS.NON_NULL_COUNT]: "Non Null Count",
  [STATISTICS.MIN_LENGTH]: "Min Length",
  [STATISTICS.MAX_LENGTH]: "Max Length",
};

export const suggestionIconMap: Record<string, any> = {
  dedup: {
    icon: MdsCellMergeRound,
  },
  find_and_replace: {
    icon: MdsSearchRound,
    rows: "All",
  },
  translate: {
    icon: MdsTranslateRound,
    rows: "All",
  },
  default: {
    icon: MdsCellMergeRound,
    rows: "All",
  },
};

export const EXPLORE_DESCRIPTION = {
  [EXPLORE_STATS_TYPE.SAMPLE]:
    "Loads instantly, but might not be an accurate representation of the entire data",
  [EXPLORE_STATS_TYPE.FULL]:
    "Computations on full data for large datasets can take a while",
};

export const TIME_FRAMES = ["day", "week", "month", "year"] as const;
export type TimeFrame = (typeof TIME_FRAMES)[number];

export const ERROR_CODE = {
  FILTER_DELETED_COLUMN_ERROR: "EC-0036",
  SORT_DELETED_COLUMN_ERROR: "EC-0037",
};

export const MASK_DATATYPE = {
  double: "float",
  long: "integer",
  timestamp: "date",
};
