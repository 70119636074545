import {
  Box,
  chakra,
  Flex,
  Icon,
  IconButton,
  Input,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  SortingState,
  getSortedRowModel,
  createColumnHelper,
} from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";
import clsx from "clsx";
import { format } from "date-fns";
import { isEmpty } from "lodash";
import * as React from "react";
import { useEffect, useMemo } from "react";
import { BsArrowDown, BsArrowDownUp, BsArrowUp } from "react-icons/bs";
import {
  MdsCloseSharp,
  MdsSearchSharp,
  MdsTableRound,
} from "react-icons-with-materialsymbols/mds";
import { useNavigate, useParams } from "react-router-dom";

import { DoneFill } from "@/components/icons/done-filled.tsx";
import { ToastType, useShowToast } from "@/components/toast/show-toast.ts";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@/design/components/data-table";
import { snakeCaseToCapital } from "@/utils/string-utils.ts";

import { useUploadAirbyteFilesMutation } from "../api";
import { SourceListFilesFooter } from "../modals/add-dataset/source-list-files-footer.tsx";
import {
  IAdditionalProperties,
  IDatasetAdditionalProperties,
} from "../types/connector-types.ts";

interface DatasetsSchema {
  name: string;
  schema: string;
  config: any;
  filefield: string;
  [key: string]: any;
}
const columnHelper = createColumnHelper<DatasetsSchema>();

export const stripName = (name: string) => {
  if (!name) return "";
  const neoIndex = name.indexOf("__neo");
  if (neoIndex !== -1) {
    name = name.substring(0, neoIndex);
  }
  return name.split(".")[0];
};

export const getDatasetIcon = (type: string) => {
  switch (type) {
    case "output":
      return "text-brand-500 bg-brand-50";
    case "input":
      return "text-green-500 bg-green-50";
    default:
      return "text-gray-800 bg-gray-50";
  }
};

export function SourceFilesList({
  actorId,
  setActiveStep,
  setformState,
  catalog,
}: {
  actorId: string | undefined;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  setformState: React.Dispatch<React.SetStateAction<any>>;
  catalog: any;
}) {
  const { analysisId } = useParams();
  const navigate = useNavigate();
  const toast = useShowToast(undefined, undefined, true);
  const [importFiles, { isLoading: isImporting }] =
    useUploadAirbyteFilesMutation();

  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [search, setSearch] = React.useState("");
  const [isSearching, setIsSearching] = React.useState(false);
  const tableContainerRef = React.useRef<HTMLDivElement>(null);

  // change this back to array when multiple file selection are supported
  const [selectedFiles, setselectedFiles] = React.useState<any[]>([]);

  const data = useMemo(() => {
    const streams = catalog.streams.map(
      (
        file: { stream: { name: any; jsonSchema: any }; config: any },
        index: number
      ) => {
        const additionalProps: IAdditionalProperties =
          file?.stream?.jsonSchema?.additionalProperties ?? [];
        return {
          id: `${file.stream.name}-${index}`,
          name: file.stream.name,
          schema: "JSON",
          filefield: "All",
          config: file,
          additionalProperties: Object.fromEntries(
            Object.entries(additionalProps).filter(
              ([_, value]) => !value.is_hidden
            )
          ),
        };
      }
    );
    if (!search) return streams;
    const filteredStreams = streams.filter(
      (s: any) => s?.name.toLowerCase().includes(search.toLowerCase())
    );
    return filteredStreams;
  }, [catalog, search]);

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        cell: ({ row: _row, getValue }) => {
          const addedFile = selectedFiles.some(
            (sf) => sf.id == _row.original.id
          );
          const filename = stripName(getValue<string>());
          return (
            <Tooltip
              isDisabled={filename.length < 20}
              label={filename}
              placement="bottom-start"
            >
              <Flex className="w-full justify-between items-center">
                <Flex className="items-center gap-2">
                  <Box
                    className={clsx(
                      "grid shrink-0 bg-gray-50 place-items-center h-9 w-9 rounded"
                    )}
                  >
                    <Icon as={MdsTableRound} fontSize={24} strokeWidth={22} />
                  </Box>
                  <Text className=" whitespace-pre k-all">{filename}</Text>
                </Flex>
                {addedFile && isEmpty(data?.[0]?.additionalProperties) && (
                  <Box className="flex-shrink-0 text-brand-500 h-5 w-5 grid rounded-full place-items-center">
                    <Icon as={DoneFill} fontSize={23} />
                  </Box>
                )}
              </Flex>
            </Tooltip>
          );
        },
        header: () => <span className="select-none">Name</span>,
        size: 50,
        enableSorting: !isSearching,
      }),
      ...Object.keys(
        (data?.[0]?.additionalProperties as IAdditionalProperties) ?? {}
      ).map((prop: string, idx: number) =>
        columnHelper.accessor(`additionalProperties.${prop}`, {
          header: () => (
            <span className="select-none whitespace-pre">
              {snakeCaseToCapital(prop)}
            </span>
          ),
          enableSorting: false,
          cell: ({ row: _row, getValue }) => {
            const value = getValue<IDatasetAdditionalProperties>();
            const addedFile = selectedFiles.some(
              (sf) => sf.id == _row.original.id
            );
            const additionalProps = data?.[0]
              ?.additionalProperties as IAdditionalProperties;
            const lastPropertyKey = Object.keys(additionalProps).at(-1);
            const isLastProperty = prop === lastPropertyKey;
            const displayValue = value.display_value;
            return (
              <Flex
                className="gap-2 justify-between"
                key={value.display_name + idx + displayValue}
              >
                {value.data_type === "DateTime" ? (
                  <Text key={value.display_name + idx + displayValue}>
                    {format(new Date(displayValue), "dd/MM/yyyy")}
                  </Text>
                ) : (
                  <Box className="w-fit whitespace-pre">{displayValue}</Box>
                )}
                {isLastProperty && addedFile && (
                  <Box className="flex-shrink-0 text-brand-500 h-5 w-5 grid rounded-full place-items-center">
                    <Icon as={DoneFill} fontSize={23} />
                  </Box>
                )}
              </Flex>
            );
          },
        })
      ),
    ],
    [isSearching, selectedFiles, data]
  );

  const onSearchClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setSearch("");
    setIsSearching((state) => !state);
  };

  const handleInputChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape" && isSearching) {
        setIsSearching(false);
        setSearch("");
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isSearching]);

  const handleRowClick = (fileStreamConfig: any) => {
    if (isImporting) return;
    setselectedFiles((prev) => {
      const fileId = fileStreamConfig.id;
      const exists = prev.some((file) => file.id === fileId);
      if (!exists) {
        return [
          ...prev,
          {
            id: fileId,
            name: stripName(fileStreamConfig.config.stream.name as string),
            sync_catalog: {
              streams: [fileStreamConfig.config],
            },
          },
        ];
      } else {
        return prev.filter((file) => file.id !== fileId);
      }
    });
  };

  const onUpload = async () => {
    try {
      const body_new = {
        actor_id: actorId!,
        selected_streams: selectedFiles,
      };
      if (!actorId) {
        console.log(`No actor id found`);
        return;
      }
      if (selectedFiles?.length === 0) {
        console.log(`No files selected. Select files to proceed`);
        return;
      }
      await importFiles({
        analysisId: analysisId ?? "",
        body: body_new,
      }).unwrap();
      toast({
        title: "Import started",
        status: ToastType.Success,
      });
      setformState(undefined);
      navigate(`/analysis/${analysisId}/data-manager/uploads`);
    } catch (e) {
      toast({
        title: "Failed to upload files.",
        description: "Something went wrong",
        status: ToastType.Error,
      });
      console.log("Error", e);
    }
  };

  const table = useReactTable({
    columns: columns,
    data: data ?? [],
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
  });

  const { rows } = table.getRowModel();

  const virtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => tableContainerRef.current,
    estimateSize: () => 70,
    overscan: 5,
  });

  const onCancel = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setselectedFiles([]);
  };
  return (
    <Flex className="flex-col h-full mb-14">
      <Box
        className={clsx("max-w-full h-full min-h-[400px]")}
        ref={tableContainerRef}
      >
        <Table
          width="100%"
          variant="simple"
          __css={{
            borderCollapse: "separate",
            borderSpacing: 0,
            position: "relative",
            "&::after": {
              display: "block",
              content: "''",
              // this ensures the table takes up as much size as the total elements by adding padding to the end
              height: `${
                virtualizer.getTotalSize() -
                (virtualizer?.scrollRect?.height ?? 0)
              }px`,
            },
          }}
        >
          <Thead
            position="sticky"
            top={0}
            zIndex={1}
            h={"50px"}
            bg={"white"}
            insetBlockStart={0}
          >
            <Box
              className={clsx(
                "absolute top-1 left-0 flex bg-white",
                isSearching ? "w-[50%] z-10" : "w-min"
              )}
            >
              <IconButton
                color="gray.900"
                aria-label="search"
                colorScheme="blackAlpha"
                icon={
                  isSearching ? (
                    <MdsCloseSharp size={18} title="search" />
                  ) : (
                    <MdsSearchSharp size={18} title="search" />
                  )
                }
                onClick={onSearchClick}
                variant={"ghost"}
              />
              {isSearching && (
                <Input
                  className="text-gray-800 !bg-white font-normal mr-2"
                  autoFocus={true}
                  name="search"
                  onChange={handleInputChange}
                  placeholder="Search by name"
                  value={search}
                />
              )}
            </Box>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header, idx) => {
                  const meta = header.column.columnDef.meta;
                  return (
                    <Th
                      width={`${header.column.getSize()}%`}
                      maxWidth={`${header.column.getSize()}%`}
                      className={clsx(
                        "overflow-hidden table-header-text text-ellipsis border-b-[1px] border-gray-200 !py-1",
                        idx === 0 && "!pl-10"
                      )}
                      key={header.id}
                      h={"50px"}
                      isNumeric={meta?.isNumeric}
                      onClick={header.column.getToggleSortingHandler()}
                      role="button"
                    >
                      <Flex align="center">
                        <chakra.span
                          className={clsx(
                            "inline-flex items-center",
                            isSearching && "w-full"
                          )}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </chakra.span>
                        {header.column.getCanSort() && (
                          <chakra.span pl="4">
                            {header.column.getIsSorted() ? (
                              header.column.getIsSorted() === "desc" ? (
                                <BsArrowDown
                                  aria-label="sorted descending"
                                  title="sorted descending"
                                />
                              ) : (
                                <BsArrowUp
                                  aria-label="sorted ascending"
                                  title="sorted ascending"
                                />
                              )
                            ) : (
                              <BsArrowDownUp aria-label="sort" title="sort" />
                            )}
                          </chakra.span>
                        )}
                      </Flex>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>

          <Tbody className="font-medium overflow-hidden max-w-full">
            {virtualizer.getVirtualItems().map((virtualRow, index) => {
              const row = rows[virtualRow.index];
              const isSelected = selectedFiles.some(
                (file) => file.id === row.original.id
              );
              return (
                <Tr
                  key={row.id}
                  _hover={{
                    cursor: "pointer",
                  }}
                  className={clsx(
                    "overflow-hidden max-w-full hover:bg-gradient-to-r hover:from-white  hover:to-gray-50",
                    isSelected && "bg-gradient-to-r from-white  !to-[#FFF0E8]"
                  )}
                  style={{
                    height: `${virtualRow.size}px`,
                    transform: `translateY(${
                      virtualRow.start - index * virtualRow.size
                    }px)`,
                  }}
                  onClick={() => handleRowClick(row.original)}
                >
                  {row.getVisibleCells().map((cell, cellIndex) => {
                    return (
                      <Td
                        border={0}
                        borderRight={2}
                        key={cell.id}
                        className={clsx(
                          "font-medium !grow-0 !shrink-0",
                          cellIndex == 0 &&
                            "pl-7 hover:before:absolute hover:before:inset-y-0 hover:before:left-0 hover:before:w-1 hover:before:bg-orange-400 relative before:content-['']",
                          cellIndex == 0 &&
                            isSelected &&
                            "pl-7 before:absolute before:inset-y-0 before:left-0 before:w-1 before:bg-orange-400 relative before:content-['']"
                        )}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        {table.getRowModel().rows.length === 0 && (
          <Flex className="justify-center w-full items-center mt-32">
            No Data
          </Flex>
        )}
      </Box>

      <Box
        className={clsx(
          "absolute w-full transition-all left-0",
          selectedFiles.length > 0 ? "bottom-0" : "-bottom-20"
        )}
      >
        {selectedFiles.length > 0 && (
          <SourceListFilesFooter
            onCancel={onCancel}
            onUpload={onUpload}
            isLoading={isImporting}
            selectedFiles={selectedFiles}
          />
        )}
      </Box>
    </Flex>
  );
}
