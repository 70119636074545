import { createContext } from "react";

import {
  SamplingTechnique,
  SamplingTechniquesResponse,
  TableDataColumns,
} from "@/features/data-transformation";

type FilterSubmenuContextType = {
  isOpen: boolean;
  sampling: SamplingTechniquesResponse | null;
  selectedSamplingMethod: SamplingTechnique | null;
  onSamplingChange: (
    method: SamplingTechnique | null,
    samplingNumber: string,
    selectedColumn: Partial<TableDataColumns> | null
  ) => void;
  numberOfRows: string | undefined;
  setNumberOfRows: (value: string) => void;
  selectedColumn: Partial<TableDataColumns> | null;
  setSelectedColumn: (column: Partial<TableDataColumns>) => void;
  error: string;
  setError: (error: string) => void;
};

export const FilterSubmenuContext = createContext<
  FilterSubmenuContextType | undefined
>(undefined);
