import { isEmpty, isEqual } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import {
  CHANGE_DATA_TYPE_IDENTIFIER,
  COLUMN_IDENTIFIER,
  DATA_TYPE,
  DATA_TYPE_IDENTIFIER,
  DUPLICATE_COLUMN_IDENTIFIER,
  FILTER_TYPE,
  FIND_REPLACE_IDENTIFIER,
  Sort,
  ViewFiltersRequest,
  parametersFromStep,
  REMOVE_COLUMN_IDENTIFIER,
  RENAME_COLUMN_IDENTIFIER,
  setCurrentlyAddedStep,
  setCurrentOpenFilter,
  setIsFilterMenuOpen,
  setOpenStepPanel,
  setSorting,
  setTempFilter,
  setViewFilters,
  SORT_ORDER,
  useGetTransformationsQuery,
  viewFilters,
} from "@/features/data-transformation";
import { ModalTypes, openModal } from "@/slices/modal-slice.ts";

type AdditionalParams = {
  [string: string]: any;
};

export const useColumnTransformations = (sort: Sort[]) => {
  const dispatch = useDispatch();
  const filters = useSelector(viewFilters);
  const { data: res } = useGetTransformationsQuery({});
  const data = res?.response.data ?? {
    availableTransformation: [],
    parameters: {},
    transformations: [],
  };
  const allParameters = data?.parameters || {};

  const createAndDispatchStep = (
    column: string,
    shortName: string,
    additionalParams: AdditionalParams = {}
  ) => {
    const _step = data.transformations.find(
      (step) => step.shortName === shortName
    )!;
    const stepParameters = parametersFromStep(_step, allParameters, {});
    const updatedParameters = stepParameters.map((_param) => {
      if (_param.identifier === COLUMN_IDENTIFIER)
        return { ..._param, value: column };

      if (additionalParams[_param.shortName])
        return {
          ..._param,
          value: additionalParams[_param.shortName] as string,
        };
      return _param;
    });
    const step = { ..._step, parameters: updatedParameters };
    const addViewFilter = step?.isFilterAllowed && !isEmpty(filters);

    if (addViewFilter) {
      step.config = { filters: filters };
    }

    dispatch(setOpenStepPanel(true));
    dispatch(setCurrentlyAddedStep(step));
  };

  const createAndDispatchMultiConfigStep = (
    renameColumn: string | undefined,
    shortName: string
  ) => {
    const _step = data.transformations.find(
      (step) => step.shortName === shortName
    )!;
    const stepParameters = parametersFromStep(_step, allParameters, {});

    const step = { ..._step, parameters: [{ ...stepParameters[0], renameColumn }] };

    const addViewFilter = step?.isFilterAllowed && !isEmpty(filters);

    if (addViewFilter) {
      step.config = { filters: filters };
    }

    dispatch(setOpenStepPanel(true));
    dispatch(setCurrentlyAddedStep(step));
  };

  const onRenameClick = (column: string) =>
    createAndDispatchMultiConfigStep(column, RENAME_COLUMN_IDENTIFIER);
  const onDuplicateClick = (column: string) =>
    createAndDispatchStep(column, DUPLICATE_COLUMN_IDENTIFIER);
  const onRemoveClick = (column: string) =>
    createAndDispatchStep(column, REMOVE_COLUMN_IDENTIFIER);
  const findAndReplace = (text: string, column: string) =>
    createAndDispatchStep(column, FIND_REPLACE_IDENTIFIER, { find: text });
  const onChangeDataTypeClick = (column: string, dataType: DATA_TYPE) =>
    createAndDispatchStep(column, CHANGE_DATA_TYPE_IDENTIFIER, {
      [DATA_TYPE_IDENTIFIER]: dataType,
    });
  const openFilter = (col: string) => {
    const hasFilter = filters?.find((filterItem) => filterItem.column == col);
    if (!hasFilter) return;

    dispatch(setCurrentOpenFilter(col));
    setTimeout(() => {
      dispatch(setIsFilterMenuOpen(true));
    }, 200);
  };

  const addFilter = (col: string, filterValue?: string) => {
    const hasFilter = filters?.find((filterItem) => filterItem.column == col);
    if (hasFilter) return;

    let filterData: ViewFiltersRequest;
    if (filterValue) {
      filterData = {
        column: col,
        filterType: FILTER_TYPE.CONDITION,
        conditionType: "equal_to",
        value: filterValue,
      };
    } else {
      filterData = {
        column: col,
        filterType: FILTER_TYPE.VALUE,
      };
    }

    dispatch(setTempFilter(filterData));

    dispatch(setCurrentOpenFilter(col));
    setTimeout(() => {
      dispatch(setIsFilterMenuOpen(true));
    }, 200);
  };

  const onClearFilterClick = (column: string) => {
    const hasFilter = filters?.find(
      (filterItem: any) => filterItem.column == column
    );
    if (hasFilter) {
      const updatedFilters = filters?.filter(
        (filterItem: any) => filterItem.column !== column
      );
      dispatch(setViewFilters(updatedFilters));
    } else {
      dispatch(setTempFilter(null));
    }
  };

  const showFullValueModal = (text: string) => {
    dispatch(
      openModal({ modalType: ModalTypes.FULL_DATA, modalProps: { text } })
    );
  };

  const sortData = (column: string, type: SORT_ORDER) => {
    let updatedSort;
    const hasSort = sort.find((sortItem) =>
      isEqual(sortItem, { column, order: type })
    );
    if (hasSort) {
      updatedSort = sort.filter((sortItem) => sortItem.column != column);
    } else {
      /*
            const hasColumn = sort.find((sortItem) => sortItem.column == column);
            const baseSort = hasColumn
              ? sort.filter((sortItem) => sortItem.column != column)
              : sort;
      */

      updatedSort = [{ column, order: type }];
    }
    dispatch(setSorting(updatedSort));

    return updatedSort;
  };

  return {
    sortData,
    onDuplicateClick,
    onRenameClick,
    onRemoveClick,
    onChangeDataTypeClick,
    addFilter,
    onClearFilterClick,
    showFullValueModal,
    findAndReplace,
    openFilter,
    createAndDispatchStep,
  };
};
