import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { Edge, Node, ReactFlowInstance } from "reactflow";

import { useAppDispatch } from "@/reduxHooks";

import { useGetNodesFromUsageInstanceIdMutation } from "../api/node-manager-api";
import { useLazyGetWorkflowQuery } from "../api/workflow-api";
import { setLastSavedTime } from "../redux";
import {
  setReferenceRunId,
  setWorkflowRunId,
  setWorkflowRunStatus,
} from "../redux/workflow-slice";
import { createReactflowObjectFromWorkflow } from "../utils";

export const useSyncWorkflow = () => {
  const [isSyncing, setIsSyncing] = useState(false);
  const dispatch = useAppDispatch();
  const params = useParams();

  const [getWorkflowDetails] = useLazyGetWorkflowQuery();
  const [getNodeList] = useGetNodesFromUsageInstanceIdMutation({
    fixedCacheKey: "getNodeList",
  });

  const refetchWorkflow = useCallback(
    async (
      setNodes: React.Dispatch<React.SetStateAction<Node[]>>,
      setEdges: React.Dispatch<React.SetStateAction<Edge[]>>,
      reactFlowInstance: ReactFlowInstance | null
    ) => {
      setIsSyncing(true);
      try {
        const workflowResponse = await getWorkflowDetails({
          workflowId: params.editorId!,
        }).unwrap();
        const workflow = workflowResponse.response.data?.workflows[0];

        const nodeUsageInstanceIds = workflow?.workflowNodes
          .flatMap((node) => node.nodeUsageInstanceId)
          .filter((node) => node !== null);

        const nodeDetailsList = await getNodeList({
          analysisId: params["analysisId"] as string,
          nodeUsageIdList: nodeUsageInstanceIds as string[],
        }).unwrap();

        const nodeDetails = nodeDetailsList.response.data!.nodes;

        if (!workflow?.workflowNodes) return false;
        if (!workflow.workflowNodes.length) {
          setNodes([]);
          setEdges([]);
        } else {
          const flowObject = createReactflowObjectFromWorkflow(
            nodeDetails,
            workflow
          );
          setNodes(flowObject.configuredNodes);
          setEdges(flowObject.configuredEdges);
        }

        dispatch(setWorkflowRunId(workflow.workflowRunId as string));
        dispatch(setReferenceRunId(workflow.referenceRunId as string));
        dispatch(setWorkflowRunStatus(workflow.workflowStatus));
        dispatch(setLastSavedTime(workflow.lastSavedOn!));

        setIsSyncing(false);
        return true;
      } catch (error) {
        setIsSyncing(false);
        console.error("Error refreshing workflow:", error);
        return false;
      }
    },
    [dispatch, getWorkflowDetails, params.editorId]
  );

  return { refetchWorkflow, isSyncing };
};
