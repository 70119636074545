import { isEmpty } from "lodash";
import {
  Edge,
  Node,
  ReactFlowJsonObject,
  Rect,
  XYPosition,
  getIncomers,
  getOutgoers,
  getRectOfNodes,
  useReactFlow,
} from "reactflow";
import { v4 as uuidv4 } from "uuid";

import { ApiResponse } from "@/types";
import { getAuthClientIdFromRegion } from "@/utils/baseUrls";
import { cacheUtil, CacheKey } from "@/utils/localstorage";
import { keysToCamel, keysToSnake } from "@/utils/snakeCaseConverter";
import { snakeCaseToCapital, toCaptialSnakeCase } from "@/utils/string-utils";

import {
  FlowCreatePayloadSchema,
  FlowDetailsResponse,
  FlowListResponse,
  FlowRelations,
  FlowSchema,
  NodeGroups,
  NodeListResponse,
  NodeParameter,
  NodeRelations,
  NodeType,
} from "../types";
import {
  NodeAdvancedProperties,
  SaveWorkflowSchema,
  WorkflowNodeColumnMappingSchema,
  WorkflowNodeRelationsSchema,
  WorkflowNodeSchema,
  WorkflowSchema,
} from "../types/workflow-types";

import { NODE_STATUS, RELATION_TYPE } from "./constants";

export function NodeListToNodeGroups(response: ApiResponse<NodeListResponse>) {
  const nodes: NodeType[] = response.response.data!.nodes;
  const sourceNodes: NodeType[] = response.response.data!.sourceNodes ?? [];
  const nodeGroups: NodeGroups = nodes
    .filter((node) => node.nodeCategory !== "Flow")
    .reduce((groups, item) => {
      const category = toCaptialSnakeCase(item.nodeCategory);
      item.nodeCategory = category;
      item.nodeStatus = item.nodeStatus ?? NODE_STATUS.DEFAULT;
      const group: NodeType[] = groups[category] || [];
      group.push(item);
      groups[category] = group;
      return groups;
    }, {} as NodeGroups);

  // TODO : do not hard code SOURCE, ask backend for a solution,
  // shameel asked to change name for Source Nodes

  if (!sourceNodes || sourceNodes.length === 0)
    return { SOURCE: [], ...nodeGroups };

  const sourceNodeGroups: NodeGroups = sourceNodes.reduce((groups, item) => {
    const category = toCaptialSnakeCase(item.nodeCategory);
    item.nodeCategory = category;
    item.inputs = item.inputs ?? [];
    item.nodeStatus = item.nodeStatus ?? NODE_STATUS.DEFAULT;
    const group: NodeType[] = groups[category] || [];
    group.push(item);
    groups[category] = group;
    return groups;
  }, {} as NodeGroups);

  return { ...sourceNodeGroups, ...nodeGroups };
}
export function FlowListToGroup(response: ApiResponse<FlowListResponse>) {
  // const flows: FlowSchema[] = response.response.data!.flows;
  return (
    response.response.data ??
    ({
      flows: [],
      pagination: {
        next: null,
        previous: null,
        count: 0,
      },
    } as FlowListResponse)
  );
}

// Write function to convert reactflow object to above POST DATA Format
export const ConvertRFObjectToFlow = (
  rf: ReactFlowJsonObject,
  flowId: string
) => {
  const flowDummyId = Math.random() * 1000;
  const { nodes, edges } = rf;
  const nodesInFlow = nodes.filter(
    (node) => node.type === "custom-node" && node.parentNode == flowId
  );

  const incomingNodeIds = nodesInFlow.filter(
    (node) =>
      getIncomers(node, nodes, edges).length === 0 &&
      getOutgoers(node, nodes, edges).length > 0
  );

  const outgoingNodeIds = nodesInFlow.filter(
    (node) =>
      getOutgoers(node, nodes, edges).length === 0 &&
      getIncomers(node, nodes, edges).length > 0
  );

  const flow = {
    name: "Flow Name -" + flowDummyId,
    display_name: "Flow Display Name -" + flowDummyId,
    display_text: "flowDetails.flow.displayText",
    description: "flowDetails.flow.description",
    tags: ["tag1", "tag2"],
    workspace_id: "cebfdcad-030f-4858-8c35-82df6957a440",
    client_id: "cebfdcad-030f-4858-8c35-82df6957a445",
    user_id: "cebfdcad-030f-4858-8c35-82df6957a449",
    nodes: nodesInFlow.map((node) => {
      return {
        node_version_id: node.data.nodeVersionId,
        rendering_metadata: {
          node_id: node.id,
          position_x: node.positionAbsolute!.x,
          position_y: node.positionAbsolute!.y,
        },
      };
    }),
    node_relations: edges.map((edge: Edge) => {
      return {
        source_node_version_id: edge.source,
        target_node_version_id: edge.target,
        relation_type: RELATION_TYPE.ON_SUCCESS,
        source_io_detail_id: edge.data.source_io_details_id,
        target_io_detail_id: edge.data.target_io_details_id,
      };
    }),
    // create edge_nodes property which checks the incoming and outgoing edges of each node
    // and adds the edge id to the edge_nodes property in input_nodes and output_nodes respectively
    edge_nodes: {
      input_nodes: incomingNodeIds.map((node) => node.id),
      output_nodes: outgoingNodeIds.map((node) => node.id),
    },
  };
  return flow;
};

export const ConvertFlowtoWorkflowPayload = (
  rf: ReactFlowJsonObject,
  latestNodes?: WorkflowNodeSchema[]
): SaveWorkflowSchema => {
  const { nodes, edges } = rf;

  const nodesInFlow: WorkflowNodeSchema[] = nodes.map((node: Node) => {
    const nodeData: NodeType = node.data;
    const latestNodeData = latestNodes?.find(
      (n) => n.workflowNodeId === nodeData.workflowNodeId
    );

    const numInputs = getIncomers(node, nodes, edges).length;
    const numOutputs = getOutgoers(node, nodes, edges).length;

    let nodeParameters: { id: string; value: string }[] = [];

    if (node.type !== "group-node") {
      const latestNodeParams = latestNodeData?.nodeParameters;
      nodeParameters = nodeData.parameters.map((param: NodeParameter) => {
        const latestParam = latestNodeParams?.find(
          (p) => p.id === param.parameterId
        );

        return {
          name: param.name,
          type: param.parameterType,
          id: param.parameterId,
          value: latestParam?.value ?? param.value ?? param.defaultValue,
        };
      });
    }

    // TODO : add this property to the node data
    const nodeColumnMappings: Array<WorkflowNodeColumnMappingSchema> = [];
    const nodeStatus = nodeData.nodeStatus ?? NODE_STATUS.DEFAULT;
    return {
      uiNodeId: node.id,
      description: isEmpty(nodeData.description)
        ? "Sample Description"
        : nodeData.description,
      nodeVersionId: nodeData.nodeVersionId || null,
      nodeUsageInstanceId: nodeData.nodeUsageInstanceId || null,
      name: nodeData.name,
      displayName: nodeData.displayName ?? nodeData.name,
      nodeDisplayParams: JSON.stringify(node),
      nodeType: nodeData.nodeType,
      numInputs,
      numOutputs,
      nodeParameters: nodeParameters,
      nodeColumnMappings: nodeColumnMappings,
      nodeStatus: nodeStatus,
      parentNodeUiId: node.parentNode,
    };
  });

  const nodeRelations: WorkflowNodeRelationsSchema[] = edges.map(
    (edge: Edge) => {
      const edgeData = edge.data;
      return {
        sourceUiNodeId: edge.source,
        targetUiNodeId: edge.target,
        sourceNodeIoId: edgeData.sourceNodeIoId,
        targetNodeIoId: edgeData.targetNodeIoId,
        relationType: RELATION_TYPE.ON_SUCCESS,
      };
    }
  );

  // Desc : This function is used to get the start and end nodes of each flow node
  // it populates the flow map such that you have parentId as key and startNodes and endNodes as value
  const flowMap: Record<string, any> = {};
  nodes
    .filter((node) => node.parentNode)
    .forEach((node) => {
      const outgoingNodes = getOutgoers(node, nodes, edges);
      const incomingNodes = getIncomers(node, nodes, edges);
      const currentParentId = node.parentNode as string;
      if (!flowMap[currentParentId]) {
        flowMap[currentParentId] = {
          startNodes: [],
          endNodes: [],
        };
      }
      let startNodeId: string | undefined = undefined;
      let endNodeId: string | undefined = undefined;

      // if the node has no incoming nodes or the parent of the incoming node is different
      // then the current node is a start node
      if (isEmpty(incomingNodes)) {
        startNodeId = node.id;
      } else {
        const incomingParentId = incomingNodes[0].parentNode as string;
        const hasNoOrDifferentParent =
          incomingParentId === undefined ||
          currentParentId !== incomingParentId;

        if (hasNoOrDifferentParent) {
          startNodeId = node.id;
        }
      }

      if (startNodeId) {
        flowMap[currentParentId]["startNodes"] = [
          ...(flowMap[currentParentId]["startNodes"] ?? []),
          startNodeId,
        ];
      }
      // if the node has no outgoing nodes or the parent of the outgoing node is different
      // then the current node is an end node
      if (isEmpty(outgoingNodes)) {
        endNodeId = node.id;
      } else {
        const outgoingParentId = outgoingNodes[0].parentNode as string;
        const hasNoOrDifferentParent =
          outgoingParentId === undefined ||
          currentParentId !== outgoingParentId;

        if (hasNoOrDifferentParent) {
          endNodeId = node.id;
        }
      }
      if (endNodeId) {
        flowMap[currentParentId]["endNodes"] = [
          ...(flowMap[currentParentId]["endNodes"] ?? []),
          endNodeId,
        ];
      }
    });

  const nodeAdvancedProperties: NodeAdvancedProperties[] = Object.keys(
    flowMap
  ).map((key) => {
    return {
      nodeId: key,
      nodeType:
        nodes.find((node) => node.id === key)!.data.nodeType ||
        "CUSTOM_FLOW_NODE",
      startNodes: flowMap[key].startNodes,
      endNodes: flowMap[key].endNodes,
    };
  });

  const wfOutputNodes = nodes
    .filter((n) => (n.data as NodeType).isOutput)
    .map((node) => {
      const nodeData: NodeType = node.data;
      return {
        nodeIoDetailId: nodeData.outputs[0]?.ioDetailId,
        outputTitle: nodeData.outputName,
        uiNodeId: node.id,
      };
    });

  return {
    workflowStatus: NODE_STATUS.DEFAULT,
    nodes: nodesInFlow,
    relations: nodeRelations,
    nodeAdvancedProperties: nodeAdvancedProperties,
    outputNodes: wfOutputNodes,
  } as SaveWorkflowSchema;
};

export const createReactflowObjectFromWorkflow = (
  nodeList: NodeType[],
  workflows: WorkflowSchema
) => {
  const { workflowNodes, userNodeRelations } = workflows;
  const childNodes = new Set<string>();
  const configuredNodes: Node[] = workflowNodes.map(
    (wfnode: WorkflowNodeSchema) => {
      // currentNode is used to map the node parameters

      const nodeMetaData = JSON.parse(wfnode.nodeDisplayParams) as Node;
      if (nodeMetaData.parentNode) {
        childNodes.add(nodeMetaData.id);
      }
      if (nodeMetaData.type === "group-node") {
        return {
          ...nodeMetaData,
          id: wfnode.uiNodeId,
          position: nodeMetaData.position,
          positionAbsolute: nodeMetaData.positionAbsolute,
          selected: false,
          type: nodeMetaData.type,
          data: {
            ...nodeMetaData.data,
            name: wfnode.name,
            displayName: wfnode.displayName ?? wfnode.name,
            displayText: wfnode.name,
            description: wfnode.description ?? null,
            workflowNodeId: wfnode.workflowNodeId,
            nodeCategory: "FLOWS",
            nodeType: wfnode.nodeType ?? "CUSTOM_FLOW_NODE",
            nodeStatus: wfnode.nodeStatus as NODE_STATUS,
          },
        };
      } else {
        const currentNode = nodeList.find(
          (nodeType) =>
            nodeType.nodeUsageInstanceId === wfnode.nodeUsageInstanceId
        );
        const nodeData: Partial<NodeType> = {
          ...currentNode,
          name: wfnode.name,
          isOutput: wfnode.isOutput || false,
          outputName: wfnode.outputName,
          isOutputDataAvailable: wfnode.isOutputDataAvailable,
          outputState:
            (wfnode.outputState as NODE_STATUS) || NODE_STATUS.DEFAULT,
          displayName: wfnode.displayName ?? wfnode.name,
          workflowNodeId: wfnode.workflowNodeId,
          nodeCategory: toCaptialSnakeCase(currentNode!.nodeCategory),
          nodeStatus: wfnode.nodeStatus as NODE_STATUS,
          parameters: wfnode.nodeParameters.map((param) => {
            const actualParams = currentNode?.parameters.find(
              (p) => p.parameterId === param.id
            );
            return {
              ...actualParams!,
              parameterId: param.id,
              value: param.value ?? actualParams?.defaultValue,
            };
          }),
        };
        return {
          ...nodeMetaData,
          id: wfnode.uiNodeId,
          position: nodeMetaData.position,
          positionAbsolute: nodeMetaData.positionAbsolute,
          selected: false,
          type: nodeMetaData.type,
          data: nodeData,
          // draggable: nodeMetaData.parentNode ? false : undefined,
        };
      }
    }
  );

  const configuredEdges: Edge[] = userNodeRelations.map(
    (wfedge: WorkflowNodeRelationsSchema) => {
      const deletable =
        !childNodes.has(wfedge.targetUiNodeId) ||
        !childNodes.has(wfedge.sourceUiNodeId);
      return {
        id: `${wfedge.sourceUiNodeId}-${wfedge.targetUiNodeId}`,
        source: wfedge.sourceUiNodeId,
        target: wfedge.targetUiNodeId,
        data: wfedge,
        deletable,
        sourceHandle: "a",
        targetHandle: "b",
        type: "custom-edge",
        zIndex: 1001,
      };
    }
  );
  configuredNodes.sort((a, b) => (a.type === "group-node" ? -1 : 1));
  return {
    configuredNodes,
    configuredEdges,
  };
};

export const createReactflowNodesFromFlow = (flow: FlowSchema) => {
  const { nodes, nodeRelations } = flow;
  const flowUiId = uuidv4();

  const nodesinFlow: Node[] = nodes!.map((node: NodeType) => {
    const nodeData: NodeType = {
      ...node,
      nodeCategory: toCaptialSnakeCase(node.nodeCategory),
      nodeStatus: node.nodeStatus ?? (NODE_STATUS.DEFAULT as NODE_STATUS),
      parameters: node.parameters ?? [],
      isOutput: node.isOutput || false,
      outputName: node.outputName,
      outputState: undefined,
      name: node.name ?? node.displayName,
      displayName: node.displayName ?? node.name,
    };
    return {
      id: uuidv4(),
      position: node.renderingMetadata as XYPosition,
      selected: false,
      width: 160,
      height: 120,
      type: "custom-node",
      parentNode: flowUiId,
      data: nodeData,
      // draggable: false,
      extent: "parent",
    };
  });
  const edgesInFlow: Edge[] = nodeRelations!.map((relation: FlowRelations) => {
    return {
      id: uuidv4(),
      source: nodesinFlow.find(
        (node) =>
          node.data.nodeUsageInstanceId === relation.sourceNodeUsageInstanceId
      )!.id,
      target: nodesinFlow.find(
        (node) =>
          node.data.nodeUsageInstanceId === relation.targetNodeUsageInstanceId
      )!.id,
      data: relation,
      deletable: false,
      type: "custom-edge",
      zIndex: 1001,
    };
  });
  const rectOfNodes = getRectOfNodes(nodesinFlow);

  const parentNode: Node = {
    id: flowUiId,
    position: {
      x: rectOfNodes.x,
      y: rectOfNodes.y,
    },
    data: flow,
    style: {
      width: rectOfNodes.width + 25 * 2,
      height: rectOfNodes.height + 25 * 2,
    },
    type: "group-node",
    selected: false,
    expandParent: false,
  };
  return {
    nodesinFlow,
    edgesInFlow,
    parentNode,
  };
};

export const getCreateFlowPayload = ({
  flow,
  rf,
}: {
  flow: Node;
  rf: ReactFlowJsonObject;
}): FlowCreatePayloadSchema => {
  const { nodes, edges } = rf;
  const childNodes = nodes
    .filter((node) => node.parentNode == flow.id)
    .map((node) => {
      const nodeParameters = node.data.parameters.map(
        (param: NodeParameter) => {
          return {
            type: param.parameterType,
            name: param.name,
            id: param.parameterId,
            value: param.value ?? param.defaultValue ?? "",
          };
        }
      );
      return {
        nodeVersionId: node.data.nodeVersionId as string,
        renderingMetadata: {
          x: node.position.x,
          y: node.position.y,
        },
        uiId: node.id,
        isOutput: node.data.isOutput || false,
        outputName: node.data.outputName,
        displayName: node.data.displayName,
        nodeParameters,
      };
    });
  const flowEdges = edges
    .filter((e) => {
      return (
        childNodes.find((n) => n.uiId === e.source) &&
        childNodes.find((n) => n.uiId === e.target)
      );
    })
    .map((e) => ({
      sourceNodeUiId: childNodes.find((n) => n.uiId === e.source)!.uiId,
      targetNodeUiId: childNodes.find((n) => n.uiId === e.target)!.uiId,
      sourceNodeVersionId: childNodes.find((n) => n.uiId === e.source)!
        .nodeVersionId,
      targetNodeVersionId: childNodes.find((n) => n.uiId === e.target)!
        .nodeVersionId,
      relationType: "ON_SUCCESS",
      sourceIoDetailId: e.data.sourceNodeIoId,
      targetIoDetailId: e.data.targetNodeIoId,
    }));
  return {
    name: flow.data.displayName.trim(),
    displayName: flow.data.displayName.trim(),
    displayText: flow.data.displayName.trim(),
    description: flow.data.description.trim(),
    tags: flow.data.tags,
    nodes: childNodes,
    nodeRelations: flowEdges,
    accessLevel: flow.data.scope,
  };
};
