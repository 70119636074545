import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

import { useAppDispatch, useAppSelector } from "@/reduxHooks";
import { closeModal } from "@/slices/modal-slice";

export const ConfirmNodeEdit = () => {
  const dispatch = useAppDispatch();
  const { nodeData, onConfirmEdit } = useAppSelector(
    (state) => state.rootReducer.modals
  ).modalProps;

  const onClose = () => {
    dispatch(closeModal());
  };

  const handleDelete = () => {
    onConfirmEdit();
    onClose();
  };

  return (
    <Modal isCentered isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent className="!w-[80vw]">
        <ModalHeader className="flex gap-3 items-start justify-between border-b !ml-0 !pb-3 !pl-5">
          <Box className="w-fit text-lg text-gray-800 font-semibold break-all">
            Change Node Configuration?
          </Box>
          <ModalCloseButton className="!relative !top-0 !right-0" />
        </ModalHeader>
        <ModalBody className="!px-4 py-3">
          <Flex className="flex-col gap-4">
            Changing node configuration will reset all downstream nodes. You
            will have to run them again. Are you sure you'd like to proceed?
          </Flex>
          <Flex className="items-center justify-end gap-2">
            <Button colorScheme="dark" onClick={onClose} variant={"outline"}>
              Cancel
            </Button>
            <Button colorScheme="customRed" onClick={handleDelete}>
              Continue Editing
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
