import { Box } from "@chakra-ui/react";
import React, { useEffect } from "react";

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@/design/components/modal";
import { useAppDispatch, useAppSelector } from "@/reduxHooks.ts";
import { closeModal } from "@/slices/modal-slice.ts";

import { workflowRunningStatus } from "../../redux";
import { NODE_STATUS } from "../../utils";

import ConfirmTerminateModalBody from "./confirm-terminate-body";

const ConfirmTerminateModal: React.FC = () => {
  const wfRunStatus = useAppSelector(workflowRunningStatus);

  const dispatch = useAppDispatch();
  const onClose = () => {
    dispatch(closeModal());
  };

  useEffect(() => {
    if (wfRunStatus !== NODE_STATUS.RUNNING) {
      onClose();
    }
  }, [wfRunStatus]);

  return (
    <Modal isOpen={true} isCentered onClose={onClose}>
      <ModalOverlay />
      <ModalContent className="!w-[80vw]">
        <ModalHeader className="flex gap-3 items-start justify-between border-b !ml-0 !pb-3 !pl-5">
          <Box className="w-fit text-lg text-gray-800 font-medium break-all">
            Terminate Workflow Run?
          </Box>
          <ModalCloseButton className="!relative !top-0 !right-0" />
        </ModalHeader>
        <ModalBody className="!px-4 py-3">
          <ConfirmTerminateModalBody onCancel={onClose} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmTerminateModal;
