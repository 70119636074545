import { Input } from "@chakra-ui/react";
import clsx from "clsx";
import { ChangeEvent, useContext, useEffect, useMemo, useState } from "react";

import {
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
  FormHelperText,
  FormHelperTextIcon,
  FormLabel,
} from "@/design/components/form";
import { ViewTransformationParameter } from "@/features/data-transformation/types";
import { CHILD_PARAMETER } from "@/features/data-transformation/utils/constants.ts";

import { StepFormContext } from "../step-form-context.ts";

interface TextFormProps {
  param: Partial<ViewTransformationParameter>;
  errors?: string;
  hideLabel?: boolean;
  onChange?: (value: string) => void;
  id?: string;
  hideErrorIcon?: boolean;
  parentShortName?: string;
  placeholder?: string;
}

export const TextForm = ({
  param,
  errors,
  onChange,
  hideLabel,
  id,
  parentShortName,
  hideErrorIcon,
  placeholder = "No value",
}: TextFormProps) => {
  const { editEnabled, register } = useContext(StepFormContext)!;

  const defaultValue = param.value ?? param.defaultValue ?? "";
  const [formValue, setFormValue] = useState<string | number>(defaultValue);

  useEffect(() => {
    setFormValue(defaultValue);
  }, [param]);

  const controlKey = id
    ? `${CHILD_PARAMETER}%%%${parentShortName}%%%${param.shortName}%%%${id}`
    : param.shortName;

  const isEnabled = param.isModifiable && editEnabled;

  // Hack to allow custom onchagne handler
  const fieldRegister = register(controlKey);
  const origOnChange = fieldRegister.onChange;
  fieldRegister.onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const res = origOnChange(e);
    const value = e.target.value;
    if (onChange) {
      onChange(value);
    }
    setFormValue(value);
    return res;
  };

  const type = useMemo(() => {
    if (param.identifier == "INTEGER") {
      return "number";
    }
    return "text";
  }, [param]);

  return (
    <FormControl
      key={controlKey}
      isRequired={param.isMandatory}
      isInvalid={!!errors}
    >
      {!hideLabel && (
        <FormLabel className="!text-sm !mb-1.5 font-medium leading-none">
          {param.name}
        </FormLabel>
      )}
      <Input
        className="disabled:bg-gray-300 disabled:text-gray-900 !p-2 !text-[13px] !leading-[16px]"
        data-shortname={controlKey}
        id={controlKey}
        isDisabled={!isEnabled}
        placeholder={placeholder}
        size="sm"
        type={type}
        value={formValue}
        {...fieldRegister}
      />
      {!errors && param?.hintText && (
        <FormHelperText className="pl-3 text-[13px]">
          <FormHelperTextIcon className="!self-start" />
          {param?.hintText}
        </FormHelperText>
      )}
      <FormErrorMessage
        className={clsx("pl-1 text-[13px]", !hideErrorIcon && "pl-3")}
      >
        {!hideErrorIcon && <FormErrorIcon className="!self-start !mt-0.5" />}

        {errors}
      </FormErrorMessage>
    </FormControl>
  );
};
