import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ActorDeleteModal } from "@/features/data-manager/modals/actor-delete";
import { AddNewDatasetModal } from "@/features/data-manager/modals/add-dataset";
import CancelExportModal from "@/features/data-manager/modals/cancel-export";
import CancelUploadModal from "@/features/data-manager/modals/cancel-upload";
import { DatasetDetailModal } from "@/features/data-manager/modals/dataset-details/dataset-detail";
import DeleteIoRecordConfirmationModal from "@/features/data-manager/modals/delete-confirmation";
import DiscardDatasetModal from "@/features/data-manager/modals/discard-dataset";
import { DownloadDatasetModal } from "@/features/data-manager/modals/download-dataset";
import { EditDatasetDetail } from "@/features/data-manager/modals/edit-dataset.tsx/edit-dataset-detail";
import { ExportConnectorsModal } from "@/features/data-manager/modals/export-connectors/export-connectors";
import { ExportDataset } from "@/features/data-manager/modals/export-dataset";
import ExportErrorModal from "@/features/data-manager/modals/export-error";
import IoRecordError from "@/features/data-manager/modals/iorecord-errors";
import { SelectSheetModal } from "@/features/data-manager/modals/select-sheet";
import UsageConfirmationModal from "@/features/data-manager/modals/usage-confirmation";
import {
  DeleteStepConfirmation,
  ErrorModal,
  FatalErrorModal,
  SaveStep,
  ShowFullValueModal,
  TerminateExecutionConfirmation,
} from "@/features/data-transformation/modals";
import { CloseEdaDiscardChanges } from "@/features/data-transformation/modals/close-eda.tsx";
import { ExecutionRequired } from "@/features/data-transformation/modals/execution-required";
import { ExploreDataModal } from "@/features/data-transformation/modals/explore-data";
import LLMErrorsModal from "@/features/llm-prompt/modals/llm-errors";
import { AddAdminModal } from "@/features/org-settings/modals/add-admin/add-admin";
import AdminWarningModal from "@/features/org-settings/modals/admin-warning";
import { InviteMembersModal } from "@/features/org-settings/modals/invite-members/invite-members.tsx";
import RemoveConfirmation from "@/features/org-settings/modals/remove-confirmation";
import RemoveOrgUserConfirmation from "@/features/org-settings/modals/remove-org-user";
import DeleteAccountModal from "@/features/user-manager/modals/delete-account";
import DeleteOTPModal from "@/features/user-manager/modals/delete-otp";
import { InviteMemberManageAccess } from "@/features/user-manager/modals/invite-users";
import LeaveOrgModal from "@/features/user-manager/modals/leave-org";
import ManageAccessModal from "@/features/user-manager/modals/manage-access";
import RemoveUserAccess from "@/features/user-manager/modals/remove-access-confirmation";
import ConfirmTerminateModal from "@/features/workflow-studio/modals/confirm-terminate";
import EditFlowDetailsModal from "@/features/workflow-studio/modals/edit-flow-details";
import FlowDetailModal from "@/features/workflow-studio/modals/flow-detail-preview";
import FlowPreviewModal from "@/features/workflow-studio/modals/flow-preview";
import MarkAsOutputModal from "@/features/workflow-studio/modals/mark-as-output";
import RenameNodeModal from "@/features/workflow-studio/modals/rename-node";
import WorkflowErrorsModal from "@/features/workflow-studio/modals/run-workflow";
import { TimedOutModal } from "@/features/workflow-studio/modals/timeout-modals/timed-out";
import { TimeoutWarningModal } from "@/features/workflow-studio/modals/timeout-modals/timeout-warning";
import UploadToFlowStoreModal from "@/features/workflow-studio/modals/upload-flow";
import ConfirmationModal from "@/features/ws-manager/modals/confirmation-modal.tsx";
import CreateAnalysisModal from "@/features/ws-manager/modals/create-analysis";
import CreateProjectModal from "@/features/ws-manager/modals/create-project";
import WorkspaceCreationModal from "@/features/ws-manager/modals/create-workspace";
import { InfoModal } from "@/features/ws-manager/modals/info-modal.tsx";
import { ProfileSettings } from "@/features/ws-manager/modals/profile";

export const enum ModalTypes {
  CONFIRMATION = "Confirmation-Modal",
  WORKSPACE_CREATION = "Workspace-Creation-Modal",
  PROJECT_CREATION = "Project-Creation-Modal",
  ANALYSIS_CREATION = "Analysis-Creation-Modal",
  INFO = "Info-Modal",
  ADD_NEW_DATASET = "Add-New-Dataset-Modal",
  DELETE_IO_RECORD = "Delete-Io-Record-Modal",
  IORECORD_ERRORS = "Io-Record-Errors-Modal",

  // Workflow Modals
  WORKFLOW_ERRORS = "Workflow-Errors-Modal",
  PROFILE = "Profile-Modal",
  EDIT_FLOW_DETAILS = "Edit-Flow-Details-Modal",
  UPLOAD_FLOW = "Upload-Flow-Modal",
  MARK_AS_OUTPUT = "Mark-As-Output-Modal",
  FLOW_PREVIEW = "Flow-Preview-Modal",
  FLOW_DETAILS = "Flow-Details-Modal",
  RENAME_NODE = "Rename-Node-Modal",
  CONFIRM_TERMINATE = "Confirm-Terminate-Modal",
  // usermanager modals
  MANAGE_ACCESS = "Manage-Access-Modal",
  INVITE_USERS = "Invite-Users-Modal",
  ADD_ADMIN = "Add-Admin-Modal",
  REMOVE_ADMIN = "Remove-Admin-Modal",
  REMOVE_ORG_USER = "Remove-Org-User-Modal",
  WARNING_ADMIN = "Warning-Admin-Modal",
  REMOVE_USER_ACCESS = "remove-user-access",
  INVITE_MEMBER_MANAGE_ACCESS = "Invite-Member-Manage-Access-Modal",
  LEAVE_ORG = "Leave-Org-Modal",
  DELETE_ACCOUNT = "Delete-Account-Modal",
  DELETE_OTP = "Delete-Otp-Modal",

  //EDA modals
  EXPLORE_DATASET = "Explore-Dataset-Modal",
  TERMINATE_EXECUTION = "Terminate-Execution-Modal",
  CLOSE_EDA = "Close-EDA-Modal",
  FATAL_ERROR = "Fatal-Error-Modal",

  //LLM Modals
  LLM_ERRORS = "LLM-Errors-Modal",
  FULL_DATA = "Full-Data-Modal",
  SAVE_STEPS = "Save-Steps-Modal",
  DELETE_STEP = "Delete-Step-Modal",
  ERROR_STEPS_WARNING = "Error-Steps-Warning-Modal",
  EXECUTION_REQUIRED = "Execution-Required-Modal",

  //DATA Manager
  EXPORT_DATASET = "Export-Dataset-Modal",
  CANCEL_UPLOAD = "Cancel-Upload-Modal",
  SELECT_SHEET = "Select-Sheet-Modal",
  DOWNLOAD_DATA = "Download-Data-Modal",
  DATASET_NAME = "Dataset-Name-Modal",
  DISCARD_DATASET = "Discard-Dataset-Modal",
  USAGE_CONFIRMATION = "Usage-Confirmation-Modal",
  EDIT_DATASET_DETAILS = "Edit-Dataset-Details-Modal",
  EXPORT_CONNECTORS = "Export-Connectors-Modal",
  EXPORT_ERROR = "Export-Error-Modal",
  CANCEL_EXPORT = "Cancel-Export-Modal",
  DELETE_ACTOR = "Delete-Actor-Modal",

  // Timeout Modals
  TIMEOUT_WARNING = "Timeout-Warning-Modal",
  TIMED_OUT = "Timed-Out-Modal",
}

export const MODAL_COMPONENTS = {
  [ModalTypes.CONFIRMATION]: ConfirmationModal,
  [ModalTypes.WORKSPACE_CREATION]: WorkspaceCreationModal,
  [ModalTypes.PROJECT_CREATION]: CreateProjectModal,
  [ModalTypes.ANALYSIS_CREATION]: CreateAnalysisModal,
  [ModalTypes.INFO]: InfoModal,
  [ModalTypes.PROFILE]: ProfileSettings,
  [ModalTypes.ADD_NEW_DATASET]: AddNewDatasetModal,
  [ModalTypes.DELETE_IO_RECORD]: DeleteIoRecordConfirmationModal,
  [ModalTypes.IORECORD_ERRORS]: IoRecordError,

  // Workflow Modals
  [ModalTypes.WORKFLOW_ERRORS]: WorkflowErrorsModal,
  [ModalTypes.EDIT_FLOW_DETAILS]: EditFlowDetailsModal,
  [ModalTypes.UPLOAD_FLOW]: UploadToFlowStoreModal,
  [ModalTypes.MARK_AS_OUTPUT]: MarkAsOutputModal,
  [ModalTypes.FLOW_PREVIEW]: FlowPreviewModal,
  [ModalTypes.FLOW_DETAILS]: FlowDetailModal,
  [ModalTypes.RENAME_NODE]: RenameNodeModal,
  [ModalTypes.CONFIRM_TERMINATE]: ConfirmTerminateModal,

  //EDA modals
  [ModalTypes.EXPLORE_DATASET]: ExploreDataModal,
  [ModalTypes.FULL_DATA]: ShowFullValueModal,
  [ModalTypes.SAVE_STEPS]: SaveStep,
  [ModalTypes.DELETE_STEP]: DeleteStepConfirmation,
  [ModalTypes.ERROR_STEPS_WARNING]: ErrorModal,
  [ModalTypes.EXECUTION_REQUIRED]: ExecutionRequired,
  [ModalTypes.TERMINATE_EXECUTION]: TerminateExecutionConfirmation,
  [ModalTypes.CLOSE_EDA]: CloseEdaDiscardChanges,
  [ModalTypes.FATAL_ERROR]: FatalErrorModal,

  // usermanager modals
  [ModalTypes.MANAGE_ACCESS]: ManageAccessModal,
  [ModalTypes.INVITE_USERS]: InviteMembersModal,
  [ModalTypes.ADD_ADMIN]: AddAdminModal,
  [ModalTypes.REMOVE_ADMIN]: RemoveConfirmation,
  [ModalTypes.REMOVE_ORG_USER]: RemoveOrgUserConfirmation,
  [ModalTypes.WARNING_ADMIN]: AdminWarningModal,
  [ModalTypes.REMOVE_USER_ACCESS]: RemoveUserAccess,
  [ModalTypes.INVITE_MEMBER_MANAGE_ACCESS]: InviteMemberManageAccess,
  [ModalTypes.LEAVE_ORG]: LeaveOrgModal,
  [ModalTypes.DELETE_ACCOUNT]: DeleteAccountModal,
  [ModalTypes.DELETE_OTP]: DeleteOTPModal,

  // LLM Modals
  [ModalTypes.LLM_ERRORS]: LLMErrorsModal,

  //DATA Manager
  [ModalTypes.EXPORT_DATASET]: ExportDataset,
  [ModalTypes.CANCEL_UPLOAD]: CancelUploadModal,
  [ModalTypes.SELECT_SHEET]: SelectSheetModal,
  [ModalTypes.DOWNLOAD_DATA]: DownloadDatasetModal,
  [ModalTypes.DATASET_NAME]: DatasetDetailModal,
  [ModalTypes.DISCARD_DATASET]: DiscardDatasetModal,
  [ModalTypes.USAGE_CONFIRMATION]: UsageConfirmationModal,
  [ModalTypes.EDIT_DATASET_DETAILS]: EditDatasetDetail,
  [ModalTypes.EXPORT_CONNECTORS]: ExportConnectorsModal,
  [ModalTypes.EXPORT_ERROR]: ExportErrorModal,
  [ModalTypes.CANCEL_EXPORT]: CancelExportModal,
  [ModalTypes.DELETE_ACTOR]: ActorDeleteModal,

  // Timeout Modals
  [ModalTypes.TIMEOUT_WARNING]: TimeoutWarningModal,
  [ModalTypes.TIMED_OUT]: TimedOutModal,
};

type ModalState = {
  modalType: ModalTypes | null;
  modalProps: ModalProps;
};

type ModalProps = {
  [key: string]: any;
};

const initialState: ModalState = { modalType: null, modalProps: {} };
const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<ModalState>) => {
      window.history.replaceState({ modalOpened: true }, "");
      state.modalType = action.payload.modalType;
      state.modalProps = action.payload.modalProps;
    },
    closeModal: (state) => {
      window.history.replaceState({ modalOpened: false }, "");
      state.modalType = null;
      state.modalProps = {};
    },
    updateProps: (state, action: PayloadAction<ModalProps>) => {
      state.modalProps = action.payload.modalProps;
    },
  },
});

export const { openModal, closeModal, updateProps } = modalSlice.actions;
export default modalSlice.reducer;
