import { Flex, FlexProps, Image } from "@chakra-ui/react";
import clsx from "clsx";
import { useCallback } from "react";
import { Node, getRectOfNodes, useReactFlow } from "reactflow";
import { v4 as uuidv4 } from "uuid";

import { previewNode, setPreviewNode } from "@/features/workflow-studio";
import { triggerAutoSave } from "@/features/workflow-studio/redux";
import { NodeType } from "@/features/workflow-studio/types";
import {
  NODE_STATUS,
  categoryIcons,
} from "@/features/workflow-studio/utils/constants";
import { useAppDispatch, useAppSelector } from "@/reduxHooks.ts";

const HORIZONTAL_SPACING = 20; // Minimum horizontal space between nodes
const INITIAL_POSITION = { x: 100, y: 100 };

export const BaseNode = ({
  node,
  className = "",
  draggable = true,
  index,
  ...props
}: {
  className?: string;
  node: NodeType;
  index: number;
} & FlexProps) => {
  const dispatch = useAppDispatch();
  const currentNode = useAppSelector(previewNode);
  const { project, getNodes, setNodes } = useReactFlow();

  const findAvailablePosition = useCallback((existingNodes: Node[]) => {
    if (existingNodes.length === 0) return INITIAL_POSITION;

    const rect = getRectOfNodes(existingNodes);
    return {
      x: rect.x + rect.width + HORIZONTAL_SPACING,
      y: rect.y + rect.height / 2,
    };
  }, []);

  const showNodePreview = (activeNode: NodeType | null) => {
    dispatch(setPreviewNode(activeNode));
  };

  const onDragStart = (
    event: React.DragEvent,
    nodeVersionId: string,
    nodeType: string,
    nodeGroup: string
  ) => {
    event.dataTransfer.setData("application/nodeIdx", nodeVersionId);
    event.dataTransfer.setData("application/nodeType", nodeType);
    event.dataTransfer.setData("application/nodeGroup", nodeGroup);
    event.dataTransfer.setData("application/nodeIndex", index.toString());
    event.dataTransfer.effectAllowed = "move";
  };

  const handleClick = useCallback(() => {
    const existingNodes = getNodes();
    const position = findAvailablePosition(existingNodes);

    const newNode = {
      id: uuidv4(),
      type: "custom-node",
      position,
      data: {
        ...node,
        status: NODE_STATUS.DEFAULT,
        name: node.displayName,
      },
    };

    setNodes((nds) => nds.concat(newNode));
    setTimeout(() => {
      dispatch(triggerAutoSave());
    }, 300);
  }, [node, getNodes, setNodes, dispatch, findAvailablePosition]);

  return (
    <Flex
      className={clsx(
        `h-full w-full cursor-grab gap-3 p-3 bg-white rounded hover:bg-orange-50 hover:border-gray-600`,
        draggable && "border !h-[100px] !w-[160px] !cursor-pointer !bg-gray-50",
        draggable && currentNode?.displayName == node.displayName
          ? "bg-orange-50 border-gray-600"
          : " border-gray-400",
        className
      )}
      direction="column"
      draggable={draggable}
      onClick={handleClick}
      onDragStart={(event: React.DragEvent) =>
        draggable &&
        onDragStart(event, node.nodeVersionId, node.nodeType, node.nodeCategory)
      }
      onMouseEnter={() => showNodePreview(node)}
      title={node.displayName}
      {...props}
    >
      <Image
        className="rounded"
        boxSize="2rem"
        p={1.5}
        bg={categoryIcons[node.nodeCategory].theme}
        alt="Fluffybuns the destroyer"
        draggable={false}
        src={
          node.imageUrl ??
          "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ae/Globe_icon-white.svg/2048px-Globe_icon-white.svg.png"
        }
      />
      <span className="text-xs capitalize line-clamp-2">{node.displayName}</span>
    </Flex>
  );
};
