import { Flex, Spinner, useDisclosure } from "@chakra-ui/react";
import { captureException } from "@sentry/react";
import clsx from "clsx";
import { AnimatePresence, motion, Reorder } from "framer-motion";
import Fuse from "fuse.js";
import { isEmpty } from "lodash";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  compiledTransformationSteps,
  currentlyAddedStep,
  EdaMetaDataContext,
  FETCH_TYPE,
  TransformationStep,
  resetFetchTrigger,
  selectedStep,
  setCompiledTransformationSteps,
  setCurrentlyAddedStep,
  setRequestId,
  setSelectedStep,
  statusRequestId,
  tableLoading,
  triggerFetch,
  triggerStepsFetchEvent,
  useLazyAllStepsQuery,
  useEditStepMutation,
  useGetRequestStatusQuery,
  triggerOpenStepPanel,
  setOpenStepPanel,
  viewFilters,
  sorting,
  seperateFilters,
} from "@/features/data-transformation";
import { useReadOnlyMode } from "@/features/data-transformation/hooks";
import { useOverflowDetection } from "@/features/data-transformation/hooks/useOverflowDetection.ts";
import { usePrevious } from "@/hooks/usePrevious.ts";
import { ModalTypes, openModal } from "@/slices/modal-slice.ts";
import { POLLING_STATUS } from "@/utils/enums.ts";

import { StepForm } from "../step-form";

import { StepsPanelHeader } from "./setps-panel-header.tsx";
import { AddNewStep, Step } from "./step";
import { StepsPanelSearch } from "./steps-panel-search.tsx";

const fuseOptions = {
  keys: ["name", "shortName"],
};

const POLLING_INTERVAL = 500;

export const StepsPanel = () => {
  const dispatch = useDispatch();

  const sort = useSelector(sorting);
  const viewOnlyFilters = useSelector(viewFilters);

  const reorderedStep = useRef<TransformationStep | null>(null);
  const previousValues = useRef<TransformationStep[] | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const reorder = (step: TransformationStep) => {
    reorderedStep.current = step;
  };

  const containerRef = useRef(null);
  const listRef = useRef(null);

  const metaData = useContext(EdaMetaDataContext);

  const isDataLoading = useSelector(tableLoading);
  const triggerFetchSteps = useSelector(triggerStepsFetchEvent);
  const openPanel = useSelector(triggerOpenStepPanel);

  const requestId = useSelector(statusRequestId);
  const currentlySelectedStep = useSelector(selectedStep);
  const addedStep = useSelector(currentlyAddedStep);

  const stepsList = useSelector(compiledTransformationSteps);

  const [filteredSteps, setFilteredSteps] = useState<
    TransformationStep[] | null
  >(null);

  const fuse = new Fuse(stepsList, fuseOptions);

  const [editStepApi] = useEditStepMutation();
  const { data: runStatusData } = useGetRequestStatusQuery(
    {
      analysisId: metaData.analysisId!,
      edaId: metaData.edaId!,
      requestId: requestId!,
    },
    {
      pollingInterval: POLLING_INTERVAL,
      skip: requestId == null,
    }
  );

  const isButtonFixed = useOverflowDetection({
    containerRef,
    contentRef: listRef,
    buttonHeight: 40, // Approximate height of AddNewStep button
    dependencies: [stepsList, filteredSteps],
  });

  const [fetchSavedSteps] = useLazyAllStepsQuery();

  useEffect(() => {
    if (triggerFetchSteps && metaData.edaId) {
      fetchSavedStepsApi();
      dispatch(resetFetchTrigger(FETCH_TYPE.STEPS));
    }
  }, [triggerFetchSteps, metaData.edaId]);

  useEffect(() => {
    const taskStatus = runStatusData?.response.data?.taskStatus;
    if (taskStatus === POLLING_STATUS.COMPLETED) {
      dispatch(setRequestId(null));
      dispatch(triggerFetch(FETCH_TYPE.TABLE));
    }

    if (taskStatus === POLLING_STATUS.FAILED) {
      dispatch(setRequestId(undefined));

      captureException(runStatusData);
      dispatch(
        openModal({ modalProps: {}, modalType: ModalTypes.FATAL_ERROR })
      );
    }
  }, [runStatusData]);

  const { isOpen, onToggle, onOpen } = useDisclosure({ defaultIsOpen: true });

  useEffect(() => {
    if (openPanel) {
      onOpen();
      dispatch(setOpenStepPanel(false));
    }
  }, [openPanel]);

  const fetchSavedStepsApi = async () => {
    try {
      setIsLoading(true);
      const _data = await fetchSavedSteps({
        analysisId: metaData.analysisId!,
        edaId: metaData.edaId!,
      }).unwrap();

      previousValues.current = _data.response.data?.transformationSteps ?? [];
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const openStep = useMemo(
    () => addedStep || currentlySelectedStep,
    [addedStep, currentlySelectedStep]
  );

  useEffect(() => {
    setFilteredSteps(null);
  }, [openStep]);

  const previousAddedStep = usePrevious(addedStep);

  const togglePanel = () => {
    onToggle();
    if (isOpen) {
      dispatch(setSelectedStep(null));
      dispatch(setCurrentlyAddedStep(null));
    } else {
      dispatch(setSelectedStep(null));
      dispatch(setCurrentlyAddedStep(previousAddedStep ?? null));
    }
  };

  const onReorder = (values: TransformationStep[]) => {
    previousValues.current = stepsList;
    dispatch(setCompiledTransformationSteps(values));
  };

  const onReorderEnd = async () => {
    try {
      await updateStepCall(stepsList);
    } catch (e) {
      console.error(e);
    }
  };

  const updateStepCall = async (newList: TransformationStep[]) => {
    const stepToUpdate = reorderedStep.current;
    const newIndexOfStep = newList.findIndex(
      (_value) =>
        _value.transformationStepId === stepToUpdate?.transformationStepId
    );

    const _data = {
      ...stepToUpdate,
      analysisId: metaData.analysisId!,
      edaId: metaData.edaId!,
      stepOrder: newIndexOfStep + 1,
      viewFilters: seperateFilters(viewOnlyFilters),
      sort,
    };
    const res = await editStepApi(_data).unwrap();
    previousValues.current = res.response.data?.steps ?? [];
    const reqId = res.response.data?.requestId ?? null;

    dispatch(setRequestId(reqId));
  };

  const onSearch = (search: string) => {
    if (isEmpty(search)) {
      setFilteredSteps(null);
      return;
    }

    const searchResults = fuse.search(search).map((res) => res.item);
    setFilteredSteps(searchResults);
  };

  const { hasWriteAccess } = useReadOnlyMode();

  const canAddStep = hasWriteAccess && !isDataLoading;
  const canReorder =
    filteredSteps == null && !isDataLoading && hasWriteAccess && isOpen;

  return (
    <Flex
      className="relative flex-col h-full max-h-max max-w-[17.5rem] bg-gray-50 border-r border-r-gray-300"
      as={motion.div}
      animate={{
        width: isOpen ? "calc(17.5rem + 1px)" : "calc(3.75rem + 1px)",
      }}
    >
      <StepsPanelHeader isOpen={isOpen} onToggle={togglePanel} />
      {openStep && (
        <StepForm
          step={addedStep ?? currentlySelectedStep}
          isEdit={addedStep == null}
        />
      )}
      {!openStep && (
        <>
          <StepsPanelSearch isOpen={isOpen} onSearch={onSearch} />
          {isLoading && (
            <Flex className="w-full h-full items-center justify-center">
              <Spinner />
            </Flex>
          )}
          <AnimatePresence>
            {!isLoading && (
              <Flex
                className={clsx(
                  "flex-col w-full h-full p-2 max-h-full overflow-auto",
                  isOpen ? "px-3" : "px-2",
                  isButtonFixed && "pb-12"
                )}
                ref={containerRef}
              >
                <Reorder.Group
                  values={filteredSteps ?? stepsList}
                  onReorder={onReorder}
                  axis="y"
                  ref={listRef}
                >
                  {(filteredSteps ?? stepsList).map((step, index) => (
                    <Step
                      step={step}
                      reorder={reorder}
                      updateStepCallback={onReorderEnd}
                      totalSteps={stepsList.length}
                      index={index}
                      isOpen={isOpen}
                      key={step?.transformationStepId}
                      canReorder={canReorder}
                    />
                  ))}
                </Reorder.Group>
                {!isButtonFixed && canAddStep && <AddNewStep isOpen={isOpen} />}
              </Flex>
            )}
          </AnimatePresence>
          {isButtonFixed && canAddStep && (
            <Flex className="absolute bottom-0 shadow-[0_-5px_34px_-5px_rgba(0,0,0,0.06)] left-0 right-0 bg-gray-50 py-2 px-3 justify-start items-center">
              <AddNewStep isOpen={isOpen} isFixed={true} />
            </Flex>
          )}
        </>
      )}
    </Flex>
  );
};
