import {
  combineFilters,
  dataTransformationAPI,
  DataTransformationEndpoints,
  AnalysisEdaMetaData,
  EdaSaveExecuteRequest,
  GetAllTransformationStepRequest,
  SaveExecuteResponse,
  StepForm,
  TransformationStepRequest,
  TransformationStepResponse,
  TableDataQuery
} from "@/features/data-transformation";
import { ApiResponse } from "@/types";
import { keysToSnake } from "@/utils/snakeCaseConverter.ts";

export const stepsApi = dataTransformationAPI.injectEndpoints({
  endpoints: (build) => ({
    addSteps: build.mutation<
      ApiResponse<TransformationStepResponse>,
      Partial<TransformationStepRequest> & AnalysisEdaMetaData & Partial<TableDataQuery>
    >({
      query: ({ analysisId, edaId, viewFilters, sort, ...props }) => {
        
        return {
          url: DataTransformationEndpoints.addNewStep(analysisId, edaId),
          method: "POST",
          data: {
            ...keysToSnake(props),
            start_index: 0,
            end_index: 100,
            view_filters: keysToSnake(viewFilters),
            sort: keysToSnake(sort),
          },
        };
      },
      transformResponse: (
        response: ApiResponse<TransformationStepResponse>
      ) => {
        const updatedResponse = {
          ...response,
          response: {
            ...response.response,
            data: {
              ...response.response.data,
              steps: response.response.data?.steps.map((step) => {
                return {
                  ...step,
                  config: {
                    filters: combineFilters(step.config?.filters ?? []),
                  },
                };
              }),
            },
          },
        } as ApiResponse<TransformationStepResponse>;
        return updatedResponse;
      },
    }),
    editStep: build.mutation<
      ApiResponse<TransformationStepResponse>,
      Partial<StepForm> & AnalysisEdaMetaData & Partial<TableDataQuery>
    >({
      query: ({ analysisId, edaId, viewFilters, sort, ...props }) => {
        
        return {
          url: DataTransformationEndpoints.editStep(
            analysisId,
            edaId,
            props.transformationStepId!
          ),
          method: "PUT",
          data: {
            ...keysToSnake(props),
            start_index: 0,
            end_index: 100,
            view_filters: keysToSnake(viewFilters),
            sort: keysToSnake(sort),
          },
        };
      },
      transformResponse: (
        response: ApiResponse<TransformationStepResponse>
      ) => {
        const updatedResponse = {
          ...response,
          response: {
            ...response.response,
            data: {
              ...response.response.data,
              steps: response.response.data?.steps.map((step) => {
                return {
                  ...step,
                  config: {
                    filters: combineFilters(step.config?.filters ?? []),
                  },
                };
              }),
            },
          },
        } as ApiResponse<TransformationStepResponse>;
        return updatedResponse;
      },
    }),
    allSteps: build.query<
      ApiResponse<GetAllTransformationStepRequest>,
      AnalysisEdaMetaData
    >({
      query: ({ analysisId, edaId }) => ({
        url: DataTransformationEndpoints.getSteps(analysisId, edaId),
        method: "GET",
      }),
      transformResponse: (
        response: ApiResponse<GetAllTransformationStepRequest>
      ) => {
        const updatedResponse = {
          ...response,
          response: {
            ...response.response,
            data: {
              ...response.response.data,
              transformationSteps:
                response.response.data?.transformationSteps.map((step) => {
                  return {
                    ...step,
                    config: {
                      filters: combineFilters(step.config?.filters ?? []),
                    },
                  };
                }),
            },
          },
        } as ApiResponse<GetAllTransformationStepRequest>;
        return updatedResponse;
      },
    }),
    saveEDASteps: build.mutation<
      ApiResponse<SaveExecuteResponse>,
      AnalysisEdaMetaData & EdaSaveExecuteRequest
    >({
      query: ({ analysisId, edaId, ...props }) => ({
        url: DataTransformationEndpoints.saveSteps(analysisId, edaId),
        method: "POST",
        data: {
          ...keysToSnake(props),
        },
      }),
    }),
  }),
});

export const {
  useAddStepsMutation,
  useEditStepMutation,
  useLazyAllStepsQuery,
  useAllStepsQuery,
  useSaveEDAStepsMutation,
} = stepsApi;
