import cloneDeep from "lodash/cloneDeep";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  COLUMN_SHORTNAME,
  COLUMN_TYPE_MAP,
  compiledTransformationSteps,
  EdaMetaDataContext,
  TableDataColumns,
  NEW_POSITION_SHORTNAME,
  parametersFromStep,
  REFERENCE_COLUMN_SHORTNAME,
  REORDER_COLUMN_SHORTNAME,
  setColumns,
  setRequestId,
  setSelectedColumn,
  seperateFilters,
  sorting,
  viewFilters,
} from "@/features/data-transformation";
import {
  useAddStepsMutation,
  useEditStepMutation,
  useGetTransformationsQuery,
} from "@/features/data-transformation/api";

export const useMoveColumn = (columnData: TableDataColumns[]) => {
  const dispatch = useDispatch();
  const stepsList = useSelector(compiledTransformationSteps);
  const sort = useSelector(sorting);
  const viewOnlyFilters = useSelector(viewFilters);

  const { data: res } = useGetTransformationsQuery({});
  const data = res?.response.data ?? {
    availableTransformation: [],
    parameters: {},
    transformations: [],
  };

  const allParameters = data?.parameters || {};

  const metaData = useContext(EdaMetaDataContext);
  const [addStepApi] = useAddStepsMutation();
  const [editStepApi] = useEditStepMutation();

  function moveArrayItem<T>(
    array: T[],
    fromIndex: number,
    toIndex: number
  ): T[] {
    if (fromIndex === toIndex) return array;

    const newArray = [...array];
    const [movedItem] = newArray.splice(fromIndex, 1);
    newArray.splice(toIndex, 0, movedItem);

    return newArray;
  }

  const moveColumn = async (
    movedColumn: string,
    movedIndex: number,
    dropIndex: number,
    finalIndex: number
  ) => {
    const { referenceColumn, position } = getReferenceColumnAndPosition(
      movedIndex,
      dropIndex
    );
    const _step = data.transformations.find(
      (step) => step.shortName === REORDER_COLUMN_SHORTNAME
    )!;
    const stepParameters = parametersFromStep(_step, allParameters, {});
    const updatedParameters = stepParameters.map((_param) => {
      switch (_param.shortName) {
        case COLUMN_SHORTNAME:
          return { ..._param, value: movedColumn, parameterId: _param.id };
        case NEW_POSITION_SHORTNAME:
          return {
            ..._param,
            value: position,
            parameterId: _param.id,
          };
        case REFERENCE_COLUMN_SHORTNAME:
          return {
            ..._param,
            value: referenceColumn.name,
            parameterId: _param.id,
          };
        default:
          return _param;
      }
    });

    const updatedColumnData = moveArrayItem(
      cloneDeep(columnData),
      movedIndex,
      finalIndex
    );
    const columnObj = {
      column: columnData[movedIndex],
      index: finalIndex.toString(),
      columnType: COLUMN_TYPE_MAP[columnData[movedIndex].dataType],
    };
    dispatch(setSelectedColumn(columnObj));
    dispatch(setColumns(updatedColumnData));

    // Check if the last step is an active REORDER_COLUMN step for the same column
    const lastStep = stepsList[stepsList.length - 1];
    const isLastStepForSameColumn =
      lastStep &&
      lastStep.activeStatus &&
      lastStep.shortName === REORDER_COLUMN_SHORTNAME &&
      lastStep.parameters.find((param) => param.shortName === COLUMN_SHORTNAME)
        ?.value === movedColumn;

    try {
      if (isLastStepForSameColumn) {
        // Edit the last step
        const result = await editStepApi({
          ...lastStep,
          parameters: updatedParameters,
          config: { filters: [] },
          analysisId: metaData.analysisId!,
          edaId: metaData.edaId!,
          viewFilters: seperateFilters(viewOnlyFilters),
          sort,
        }).unwrap();
        dispatch(setRequestId(result.response.data?.requestId ?? null));
      } else {
        // Add new step
        const newStep = {
          ..._step,
          parameters: updatedParameters,
          config: { filters: [] },
          stepOrder: stepsList.length + 1,
        };
        const result = await addStepApi({
          steps: [newStep],
          analysisId: metaData.analysisId!,
          edaId: metaData.edaId!,
          viewFilters: seperateFilters(viewOnlyFilters),
          sort,
        }).unwrap();
        dispatch(setRequestId(result.response.data?.requestId ?? null));
      }
    } catch (e) {
      console.error("Error moving column:", e);
    }
  };

  function getReferenceColumnAndPosition(
    movedIndex: number,
    dropIndex: number
  ) {
    let referenceColumn, position;

    if (dropIndex > movedIndex) {
      referenceColumn = columnData[dropIndex - 1];
      position = "After reference column";
    } else {
      referenceColumn = columnData[dropIndex];
      position = "Before reference column";
    }

    // Handle the case where we're dropping at the end of the array
    if (dropIndex >= columnData.length) {
      referenceColumn = columnData[columnData.length - 1];
      position = "After reference column";
    }

    return { referenceColumn, position };
  }

  return { moveColumn };
};
