import { useContext } from "react";
import { useParams } from "react-router-dom";
import { Node } from "reactflow";

import { useAppDispatch } from "@/reduxHooks";

import {
  useLazyGetWorkflowQuery,
  useSaveWorkflowMutation,
} from "../api/workflow-api";
import { ReactFlowInstanceContext } from "../components/flow-editor";
import {
  setReferenceRunId,
  setWorkflowRunId,
  setWorkflowRunStatus,
  setWorkflowVersionTagId,
} from "../redux/workflow-slice";
import { NodeParameter, NodeType } from "../types";
import { WorkflowNodeSchema, WorkflowSchema } from "../types/workflow-types";
import { ConvertFlowtoWorkflowPayload, updateNodeParameters } from "../utils";
import { NODE_STATUS } from "../utils/constants";


export const useWorkflowActions = () => {
  const context = useContext(ReactFlowInstanceContext);
  const dispatch = useAppDispatch();
  const params = useParams();
  const [getWorkflowDetails] = useLazyGetWorkflowQuery();
  const [saveWorkflow] = useSaveWorkflowMutation();

  const setNodesFn = context?.setNodesFn;
  const nodes = context?.nodes || [];
  const editorInstance = context?.editorInstance;

  const applyNewParameters = (node: Node, newNodeParams: any[] = []) => {
    const nodeData = node.data as NodeType;

    // Handle case where parameters might be undefined
    if (!nodeData?.parameters) return [];
    return nodeData.parameters.map((param) => {
      const matchedParam = newNodeParams.find(
        (np) => np.parameterId === param.parameterId
      );
      return {
        ...param,
        value: matchedParam ? matchedParam.value : undefined,
      };
    });
  };

  const updateParamsForSingleNode = (
    nodeId: string,
    newParameters: NodeParameter[]
  ): Promise<void> => {
    return new Promise((resolve) => {
      if (!setNodesFn || !nodes) {
        resolve();
        return;
      }

      const nodeToBeUpdated = nodes.find((n) => n.id === nodeId);
      if (nodeToBeUpdated) {
        setNodesFn((nds) => {
          const updatedNodes = nds.map((node) => {
            if (node.id === nodeId) {
              return {
                ...node,
                data: {
                  ...node.data,
                  parameters: applyNewParameters(node, newParameters),
                },
              };
            }
            return node;
          });
          resolve();
          return updatedNodes;
        });
      } else {
        resolve();
      }
    });
  };

  const updateNodes = (nds: Node[], workflowNodes: WorkflowNodeSchema[]) => {
    return nds.map((node) => {
      const newNode = workflowNodes.find(
        (n: WorkflowNodeSchema) => n.workflowNodeId === node.data.workflowNodeId
      );
      if (newNode) {
        return {
          ...node,
          data: {
            ...node.data,
            workflowNodeId: newNode.workflowNodeId,
            displayName: newNode.displayName ?? node.data.name,
            parameters: updateNodeParameters(node, newNode.nodeParameters),
            nodeStatus: newNode.nodeStatus,
            isOutput: newNode.isOutput,
            outputName: newNode.outputName,
            outputState: newNode.outputState,
            isOutputDataAvailable: newNode.isOutputDataAvailable,
          } as NodeType,
        };
      }
      return node;
    });
  };

  const fetchAndUpdateWorkflow = async () => {
    try {
      const response = await getWorkflowDetails({
        workflowId: params.editorId!,
      }).unwrap();

      const wf = response.response.data?.workflows[0] as WorkflowSchema;
      const workflowNodes = wf.workflowNodes;

      if (!wf || !workflowNodes) {
        throw new Error("No workflow data found");
      }

      // Only update nodes if setNodesFn is provided
      if (
        setNodesFn &&
        workflowNodes.length > 0 &&
        wf.workflowStatus !== NODE_STATUS.RUNNING
      ) {
        setNodesFn((nds) => updateNodes(nds, workflowNodes));
      }

      // Update workflow status and run ID in store
      dispatch(setWorkflowRunStatus(wf.workflowStatus));
      dispatch(setWorkflowRunId(wf.workflowRunId as string));
      dispatch(setWorkflowVersionTagId(wf.workflowVersionTagId));
      dispatch(setReferenceRunId(wf.referenceRunId as string));
      
      return {
        workflow: wf,
        workflowNodes,
      };
    } catch (error) {
      console.error("Failed to fetch workflow details:", error);
      throw error;
    }
  };

  const fetchAndUpdateWFNodesOnly = async () => {
    try {
      const response = await getWorkflowDetails({
        workflowId: params.editorId!,
      }).unwrap();

      const wf = response.response.data?.workflows[0] as WorkflowSchema;
      const workflowNodes = wf.workflowNodes;

      if (!wf || !workflowNodes || !setNodesFn) return;

      if (
        wf.workflowStatus === NODE_STATUS.RUNNING ||
        workflowNodes.length <= 0
      )
        return;

      setNodesFn((nds) => updateNodes(nds, workflowNodes));

      return {
        workflow: wf,
        workflowNodes,
      };
    } catch (error) {
      console.error("Failed to update nodes:", error);
      throw error;
    }
  };

  const saveWFandUpdateNodes = async () => {
    if (!setNodesFn || !editorInstance) {
      throw new Error("ReactFlow instance not available");
    }

    const response = await saveWorkflow({
      analysisId: params.analysisId!,
      workflowId: params.editorId!,
      workflow: ConvertFlowtoWorkflowPayload(editorInstance.toObject()),
    }).unwrap();

    const wf = response.response.data?.workflows[0] as WorkflowSchema;
    const workflowNodes = wf.workflowNodes;

    if (!wf || !workflowNodes) {
      throw new Error("No workflow data found");
    }

    setNodesFn((nds) => updateNodes(nds, workflowNodes));
  };

  const getWFNodeStatus = (nodeId: string | undefined | null) => {
    if (!nodeId) return null;
    const node = context?.nodes.find((n) => n.id === nodeId);
    if (!node) return null;
    return {
      status: node?.data?.nodeStatus,
      isLocked: node?.data?.isLocked,
    };
  };

  return {
    fetchAndUpdateWorkflow,
    fetchAndUpdateWFNodesOnly,
    updateNodes,
    updateParamsForSingleNode,
    saveWFandUpdateNodes,
  };
};
