import { NodeInputSchemaResponse } from "@/features/workflow-studio/types";
import { ApiResponse } from "@/types";
import { getMSBaseUrl } from "@/utils/baseUrls";
import { MICRO_SERVICES } from "@/utils/enums";
import { keysToSnake } from "@/utils/snakeCaseConverter";

import {
  AIPlaygroundResponse,
  CreateAiPlaygroundPayload,
  ServiceProviderResponse,
  RunResultsResponse,
  VersionHistoryResponse,
  RunTestReponse,
  RevertToVersionResponse,
  SourceSchemaDetailsResponse,
  NodeColumnChoicesResponse,
} from "../types";

import { llmAPI } from "./api";
import { AIPlaygroundEndpoints } from "./api-endpoints";
import {
  updateAIPlaygroundInStore,
  updateRunDetailsInStore,
} from "./llm-api-utils";

const enum ApiActions {
  SAVE = "save",
  SAVE_AND_EXECUTE = "save_and_execute",
  RUN_TEST = "run_test",
}

const wfBaseUrl = getMSBaseUrl(MICRO_SERVICES.WORKFLOW_MANAGER);

export const aiPlaygroundAPI = llmAPI.injectEndpoints({
  endpoints: (build) => ({
    getAIPlaygroundBySource: build.query<
      ApiResponse<AIPlaygroundResponse>,
      { sourceType: string; sourceId: string }
    >({
      query: ({ sourceType, sourceId }) => {
        return {
          method: "GET",
          url: AIPlaygroundEndpoints.getAIPlaygroundBySource,
          params: {
            source_type: sourceType,
            source_id: sourceId,
          },
        };
      },
      onQueryStarted: async (body, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        updateAIPlaygroundInStore(data, dispatch);
      },
    }),
    createAiPlayground: build.mutation<
      ApiResponse<AIPlaygroundResponse>,
      CreateAiPlaygroundPayload
    >({
      query: (body) => {
        return {
          method: "POST",
          url: AIPlaygroundEndpoints.getAIPlaygroundBySource,
          params: {
            source_type: "workflow_node",
            source_id: body.sourceData.workflowNodeId,
          },
          body: keysToSnake(body),
        };
      },
      onQueryStarted: async (body, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        updateAIPlaygroundInStore(data, dispatch);
      },
    }),
    updatePlayground: build.mutation<
      ApiResponse<AIPlaygroundResponse>,
      CreateAiPlaygroundPayload
    >({
      query: (body) => {
        return {
          method: "PUT",
          url: AIPlaygroundEndpoints.getAIPlaygroundBySource,
          body: keysToSnake(body),
          params: {
            source_type: "workflow_node",
            source_id: body.sourceData.workflowNodeId,
          },
        };
      },
      onQueryStarted: async (body, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        updateAIPlaygroundInStore(data, dispatch);
      },
    }),
    getLLMProviders: build.query<ApiResponse<ServiceProviderResponse>, void>({
      query: () => {
        return {
          method: "GET",
          url: AIPlaygroundEndpoints.getServiceProviders,
        };
      },
    }),
    getVersionHistory: build.query<
      ApiResponse<VersionHistoryResponse>,
      { playgroundId: string; textGenId: string }
    >({
      query: ({ playgroundId, textGenId }) => {
        return {
          method: "GET",
          url: AIPlaygroundEndpoints.getVersionHistory(playgroundId, textGenId),
          params: {
            exclude_latest_version: true,
          },
        };
      },
    }),

    getRunDetails: build.query<
      ApiResponse<RunResultsResponse>,
      { textGenId: string; runId: string; isActiveVersionResult: boolean }
    >({
      query: ({ textGenId, runId }) => {
        return {
          method: "GET",
          url: AIPlaygroundEndpoints.getRunDetails(textGenId, runId),
          camelCase: false,
        };
      },
      onQueryStarted: async (body, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        updateRunDetailsInStore(
          data,
          {
            runId: body.runId,
            textGenId: body.textGenId,
            isActiveVersionResult: body.isActiveVersionResult,
          },
          dispatch
        );
      },
    }),

    getTestResults: build.query<
      ApiResponse<RunResultsResponse>,
      { runId: string; playgroundId: string }
    >({
      query: ({ runId, playgroundId }) => {
        return {
          method: "GET",
          url: AIPlaygroundEndpoints.getTestResults(runId, playgroundId),
        };
      },
    }),
    runTest: build.mutation<
      ApiResponse<RunTestReponse>,
      { textGenId: string; playgroundId: string; body: any }
    >({
      query: ({ textGenId, playgroundId, body }) => {
        return {
          method: "POST",
          body: keysToSnake(body),
          url: AIPlaygroundEndpoints.savePlayground(playgroundId, textGenId),
          params: {
            action: ApiActions.RUN_TEST,
          },
        };
      },
    }),
    saveVersion: build.mutation<
      ApiResponse<RunTestReponse>,
      { textGenId: string; playgroundId: string; body: any }
    >({
      query: ({ textGenId, playgroundId, body }) => {
        return {
          method: "POST",
          body: keysToSnake(body),
          url: AIPlaygroundEndpoints.savePlayground(playgroundId, textGenId),
          params: {
            action: ApiActions.SAVE,
          },
        };
      },
    }),
    saveAndExecute: build.mutation<
      ApiResponse<RunTestReponse>,
      { textGenId: string; playgroundId: string; body: any }
    >({
      query: ({ textGenId, playgroundId, body }) => {
        return {
          method: "POST",
          body: keysToSnake(body),
          url: AIPlaygroundEndpoints.savePlayground(playgroundId, textGenId),
          params: {
            action: ApiActions.SAVE_AND_EXECUTE,
          },
        };
      },
    }),
    revertToVersion: build.mutation<
      ApiResponse<RevertToVersionResponse>,
      { textGenId: string; playgroundId: string; versionId: string }
    >({
      query: ({ textGenId, playgroundId, versionId }) => {
        return {
          method: "POST",
          body: {
            version_id: versionId,
          },
          url: AIPlaygroundEndpoints.revertToVersion(playgroundId, textGenId),
        };
      },
    }),
    getUniqueNodeInputSchema: build.mutation<
      ApiResponse<NodeColumnChoicesResponse>,
      {
        workflowNodeId: string;
        workflowVersionTagId: string;
        analysisId: string;
        workflowId: string;
      }
    >({
      query: ({
        workflowNodeId,
        workflowVersionTagId,
        analysisId,
        workflowId,
      }) => {
        return {
          url: wfBaseUrl + AIPlaygroundEndpoints.getNodeInputSchema(
            analysisId,
            workflowId,
            workflowNodeId,
            workflowVersionTagId
          ),
          ignoreBaseUrl: true,
          method: "GET",
        };
      },
      transformResponse: (response: NodeInputSchemaResponse) => {
        const schemas = response?.data?.results?.schema || []
        const uniqueColumns = new Set<string>()

        if (schemas.length === 0) {
          return {
            ...response,
            response: {
              data: {
                columnChoices: []
              }
            }
          }
        }

        // Collect all unique columns from all schemas
        schemas.forEach(schema => {
          schema.columns.forEach(column => {
            uniqueColumns.add(column.value)
          })
        })
        
        return {
          ...response,
          response: {
            data: {
              columnChoices: Array.from(uniqueColumns)
            }
          }
        }
      },
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetAIPlaygroundBySourceQuery,
  useLazyGetAIPlaygroundBySourceQuery,
  useGetLLMProvidersQuery,
  useLazyGetLLMProvidersQuery,
  useGetTestResultsQuery,
  useLazyGetTestResultsQuery,
  useGetVersionHistoryQuery,
  useLazyGetVersionHistoryQuery,
  useCreateAiPlaygroundMutation,
  useLazyGetRunDetailsQuery,
  useGetRunDetailsQuery,
  useRunTestMutation,
  useRevertToVersionMutation,
  useSaveVersionMutation,
  useSaveAndExecuteMutation,
  useUpdatePlaygroundMutation,
  useGetUniqueNodeInputSchemaMutation,
} = aiPlaygroundAPI;
