const BASE_URL = `/api/v1.0` as const
const ANALYSES_PATH = `${BASE_URL}/analyses` as const

/**
 * Dashboard API endpoint configurations
 * @namespace DASHBOARD_API
 */
export const DASHBOARD_API = {
  /** Base URL for all dashboard endpoints */
  BASE_URL,

  /** Get all dashboards for an analysis */
  GET_DASHBOARDS: (analysisId: string): string =>
    `${ANALYSES_PATH}/${analysisId}/dashboards/`,

  /** Get embed token for a specific dashboard */
  GET_EMBED_TOKEN: (analysisId: string, dashboardId: string): string =>
    `${ANALYSES_PATH}/${analysisId}/dashboards/${dashboardId}/embed_token/`,

  /** Upload a new dashboard */
  UPLOAD_DASHBOARD: (analysisId: string): string =>
    `${ANALYSES_PATH}/${analysisId}/dashboards/`,

  /** Update an existing dashboard */
  UPDATE_DASHBOARD: (analysisId: string, dashboardId: string): string =>
    `${ANALYSES_PATH}/${analysisId}/dashboards/${dashboardId}/`
} as const