import { useMemo } from "react";

import { Button } from "@/design/components/button";
import { ConfigurePanelFooter } from "@/design/components/configure-panel";
import { useAppDispatch, useAppSelector } from "@/reduxHooks";

import { getEditingAllowed, hidePanel } from "../../redux";
import { WORKFLOW_PANELS } from "../../utils";

import { useConfigPanel } from "./config-panel-context";
import { getValidatedParams } from "./config-utils";

export const NodeConfigPanelFooter = () => {
  const dispatch = useAppDispatch();
  const isWFEditAllowed = useAppSelector(getEditingAllowed);
  const {
    parameters,
    autoSave,
    setParameters,
    updateNodeParams,
    currentNodeId,
    currentNodeData,
    loadingConfig,
  } = useConfigPanel();

  const hideFooter = useMemo(() => {
    return !isWFEditAllowed || currentNodeData?.name.includes("DTS") || loadingConfig;
  }, [currentNodeData, loadingConfig, isWFEditAllowed]);

  const onSave = () => {
    if (!currentNodeId) return;
    const { hasErrors, updatedParamList } = getValidatedParams(parameters);
    setParameters(updatedParamList);
    if (hasErrors) return;
    updateNodeParams(currentNodeId, updatedParamList);
    dispatch(hidePanel(WORKFLOW_PANELS.NodeConfigurationPanel));
    autoSave();
  };

  if (hideFooter) return null;

  return (
    <ConfigurePanelFooter display="flex" gap="5">
      <Button onClick={onSave} variant="outline" colorScheme="dark">
        Save
      </Button>
      {/* <Button
        rightIcon={<MdArrowRight size={24} />}
        variant="solid"
        colorScheme="dark"
      >
        Save & Run
      </Button> */}
    </ConfigurePanelFooter>
  );
};
