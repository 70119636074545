import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { createStandaloneToast } from "@chakra-ui/react";
import * as React from "react";
import { useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";
import { useDispatch } from "react-redux";
import { RouterProvider } from "react-router-dom";

import { AxiosMsalAuthProvider } from "@/auth/axios-auth-provider.tsx";
import { setAccount } from "@/slices/auth-slice.ts";

import ContextMenuManager from "./components/context-menu/context-menu-wrapper";
import { InitLoader } from "./components/loaders/InitLoader";
import { ToastType, useShowToast } from "./components/toast";
import { setIsWfUserOffline } from "./features/workflow-studio";
import AppRouter from "./routes";
import { PostAuthRouter } from "./routes/login-routes";
import { isOrgSelectionPending } from "./utils/baseUrls";

const { ToastContainer } = createStandaloneToast();

const App = () => {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const dispatch = useDispatch();
  const isAuthenticated = useIsAuthenticated();
  const toast = useShowToast(undefined, undefined, true);
  const router = isOrgSelectionPending() ? PostAuthRouter : AppRouter;

  useEffect(() => {
    if (activeAccount) {
      dispatch(setAccount(activeAccount));
    }
  }, [activeAccount, dispatch]);

  useEffect(() => {
    const handleOffline = () => {
      dispatch(setIsWfUserOffline(true));
      if (!toast.isActive("offline-toast")) {
        toast({
          id: "offline-toast",
          title: "Connectivity Lost",
          description:
            "Check your internet connection and try refreshing this page.",
          status: ToastType.Error,
          duration: null,
          isClosable: true,
        });
      }
    };

    const handleOnline = () => {
      dispatch(setIsWfUserOffline(false));
      if (toast.isActive("offline-toast")) {
        toast.close("offline-toast");
      }
    };

    window.addEventListener("offline", handleOffline);
    window.addEventListener("online", handleOnline);

    return () => {
      window.removeEventListener("offline", handleOffline);
      window.removeEventListener("online", handleOnline);
    };
  }, [toast]);

  // TODO: Need to handle blank route
  return (
    <React.Suspense fallback={<InitLoader />}>
      <HelmetProvider>
        {isAuthenticated ? (
          <AxiosMsalAuthProvider>
            <RouterProvider router={router} fallbackElement={<InitLoader />} />
          </AxiosMsalAuthProvider>
        ) : (
          <></>
        )}
      </HelmetProvider>
      <ContextMenuManager />

      <ToastContainer />
    </React.Suspense>
  );
};

export default App;
