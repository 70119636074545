import { Button, Flex } from "@chakra-ui/react";
import { clsx } from "clsx";
import { isEmpty } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  MdsChevronRightRound,
  MdsCloseRound,
} from "react-icons-with-materialsymbols/mds";
import { useDispatch, useSelector } from "react-redux";
import { useThrottledCallback } from "use-debounce";

import { Icon } from "@/design/components/icon";
import {
  setCurrentOpenFilter,
  setIsFilterMenuOpen,
  setViewFilters,
  viewFilters,
  tempFilters,
  isFilterMenuOpen,
  currentOpenFilter,
  ViewFiltersRequest,
  setTempFilter,
} from "@/features/data-transformation";
import { useSize } from "@/hooks/useSize.ts";

import { FilterContext } from "../filter-dropdown";

import { FilterItem } from "./filter-item.tsx";
import { FilterRowDropdown } from "./filter-row-dropdown.tsx";

import "./filter-row.module.css";

export const FilterRow = () => {
  const dispatch = useDispatch();
  const filters = useSelector(viewFilters);
  const temporaryFilters = useSelector(tempFilters);
  const isFilterOpen = useSelector(isFilterMenuOpen);
  const currentFilter = useSelector(currentOpenFilter);

  const filterList = useMemo(() => {
    if (!temporaryFilters) return filters;
    return [...filters, temporaryFilters];
  }, [filters, temporaryFilters]);

  const [showAll, setShowAll] = useState(false);
  const [itemsToShow, setItemsToShow] = useState(filterList);
  const [totalHiddenItem, setTotalHiddenItem] = useState(0);
  const [hiddenRefs, setHiddenRefs] = useState<HTMLDivElement[]>([]);

  const itemsRef = useRef<HTMLDivElement[]>([]);
  const parentRef = useRef<HTMLDivElement>(null);
  const size = useSize<HTMLDivElement>(parentRef);

  useEffect(() => {
    setItemsToShow(filterList);
    checkOverflow();
  }, [filterList]);

  useEffect(() => {
    overflowHandler();
  }, [size]);

  useEffect(() => {
    const showFilter = isFilterOpen && currentFilter;
    const hasHiddenItems = totalHiddenItem > 0;
    if (hasHiddenItems && showFilter) {
      setShowAll(true);
      checkOverflow();
    }
  }, [currentFilter, isFilterOpen]);

  const checkOverflow = () => {
    if (showAll) {
      onShowAll();
      return;
    }

    const maxWidth = size?.width || 0;
    const hiddenItemsRefs: HTMLDivElement[] = [];
    let totalWidth = 0;

    itemsRef.current.forEach((itemRef: HTMLDivElement, index) => {
      if (!itemRef) return;

      const isLast = index === itemsRef.current.length - 1;
      const showAllWidth = isLast && totalHiddenItem > 0 ? 90 : 0;
      const lastItemWidth = isLast && totalHiddenItem == 1 ? -90 : 0;

      const itemWidth = itemRef?.offsetWidth || 0;
      const potentialTotalWidth = totalWidth + itemWidth + 6;

      const shouldHide = potentialTotalWidth > maxWidth - 128 - showAllWidth;
      const shouldHideIfSingle =
        potentialTotalWidth > maxWidth - 128 - lastItemWidth - showAllWidth;

      const check =
        totalHiddenItem == 1 && isLast ? shouldHideIfSingle : shouldHide;

      if (check) {
        itemRef.style.opacity = String(0);
        hiddenItemsRefs.push(itemRef);
      } else {
        itemRef.style.opacity = String(1);
        totalWidth += itemRef?.offsetWidth + 6;
      }
    });
    setHiddenRefs(hiddenItemsRefs);
    setTotalHiddenItem(hiddenItemsRefs.length);
  };

  const overflowHandler = useThrottledCallback(checkOverflow, 100);
  const clearAllFilters = () => {
    dispatch(setViewFilters([]));
    dispatch(setTempFilter(null));
    dispatch(setIsFilterMenuOpen(false));
    dispatch(setCurrentOpenFilter(null));
  };

  const changeFilterOpenState = (value: boolean) => {
    dispatch(setIsFilterMenuOpen(value));
  };

  const changeCurrentOpenFilter = (value: string | null) => {
    dispatch(setCurrentOpenFilter(value));
  };

  const changeTempFilter = (value: ViewFiltersRequest | null) => {
    dispatch(setTempFilter(value));
  };

  const updateSavedFilters = (updatedFilters: ViewFiltersRequest[]) => {
    dispatch(setViewFilters(updatedFilters));
  };

  const onShowAll = () => {
    hiddenRefs.forEach((ref) => (ref.style.opacity = "1"));
  };

  const displayHideShowButton = !isEmpty(filterList) && totalHiddenItem > 0;
  return (
    <Flex
      className={clsx(
        "items-center justify-between py-2.5 pl-3.5 pr-1 ",
        showAll && "items-stretch"
      )}
    >
      <Flex className="flex-1 items-center truncate">
        <Flex
          className={clsx(
            "gap-1.5 items-center w-full ",
            showAll ? "flex-wrap" : "truncate"
          )}
          ref={parentRef}
        >
          {filters.length > 0 && (
            <Flex className="pr-2 border-r border-r-gray-300 items-center mr-1.5">
              <Button
                sx={{
                  ".chakra-button__icon": { marginInlineEnd: "2px !important" },
                }}
                colorScheme="secondary"
                leftIcon={<MdsCloseRound />}
                onClick={clearAllFilters}
                size="xs"
                variant="outline"
              >
                Clear all Filters
              </Button>
            </Flex>
          )}
          {itemsToShow.map((item, index) => (
            <FilterContext.Provider
              value={{
                filter: item,
                appliedFilters: filters,
                isFilterOpen: isFilterOpen,
                currentFilter: currentFilter,
                placement: "bottom",
                changeFilterOpenState,
                changeCurrentOpenFilter,
                changeTempFilter,
                updateSavedFilters,
              }}
              key={index}
            >
              <Flex
                key={index}
                ref={(el) => (itemsRef.current[index] = el as HTMLDivElement)}
              >
                <FilterItem key={index} />
              </Flex>
            </FilterContext.Provider>
          ))}
        </Flex>
      </Flex>
      <Flex className="relative items-center h-fit">
        {displayHideShowButton && (
          <Button
            className="mr-3"
            sx={{
              ".chakra-button__icon": {
                marginInlineStart: "2px !important",
                transform: showAll ? "rotate(-90deg)" : "rotate(90deg)",
              },
            }}
            colorScheme="secondary"
            onClick={() => {
              setShowAll(!showAll);
              if (!showAll) onShowAll();
            }}
            rightIcon={<Icon as={MdsChevronRightRound} size="xs" />}
            size="xs"
            variant="ghost"
          >
            {showAll ? <>Hide filters</> : <>Show all ({totalHiddenItem})</>}
          </Button>
        )}

        <FilterRowDropdown />
      </Flex>
    </Flex>
  );
};
