import { Button, Box, Flex } from "@chakra-ui/react";

export const GenieErrorComponent = () => {
  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <Flex className="w-full h-screen flex-col items-center justify-center">
      <Box className="p-3 font-medium text-lg text-center whitespace-pre-wrap">
        There was an error loading the Genie module. Please try refreshing the
        page.
      </Box>
      <Button colorScheme="red" onClick={handleRefresh} variant="outline">
        Refresh
      </Button>
    </Flex>
  );
};
