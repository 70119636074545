import { Box, Button, Flex, Text, useToast } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import {
  MdsErrorRound,
  MdsGppBadOutlined,
} from "react-icons-with-materialsymbols/mds";
import { useNavigate, useParams } from "react-router-dom";

import { InitLoader } from "@/components/loaders/InitLoader";
import { useLazyGetSingleAnalysisQuery } from "@/features/ws-manager/api";
import usePermissions from "@/hooks/usePermissions";
import { CATEGORY, PERMISSIONS } from "@/utils/enums";

import { WorkFlowLayout } from "./workflow-layout";

const PermissionDeniedFallback = () => {
  const navigate = useNavigate();

  return (
    <Flex className="w-screen h-screen flex-col items-center justify-center gap-6 bg-gray-50">
      <Flex className="flex-col gap-4 min-w-[30vw] items-center border border-red-500 rounded-lg p-4 bg-red-50">
        <Flex className="items-center gap-2 text-red-600">
          <MdsGppBadOutlined size={25} className="stroke-[22]" />
          <Box className="text-red-600 font-medium text-xl">
            Unauthorized Access
          </Box>
        </Flex>
        <Text className="text-gray-800">
          You do not have sufficient permissions to view this page
        </Text>
        <Button
          color="red.600"
          onClick={() => navigate("/home")}
          variant={"link"}
        >
          Back to Home
        </Button>
      </Flex>
    </Flex>
  );
};

const ErrorFallback = () => {
  const navigate = useNavigate();

  return (
    <Flex className="w-screen h-screen flex-col items-center justify-center gap-6 bg-gray-50">
      <Flex className="flex-col gap-4 min-w-[30vw] items-center border border-red-500 rounded-lg p-4 bg-red-50">
        <Flex className="items-center gap-2 text-red-600">
          <MdsErrorRound size={22} className="stroke-[22]" />
          <Box className="text-red-600 font-semibold text-xl">
            Something went wrong
          </Box>
        </Flex>
        <Text className="text-gray-700 ">
          Either you do not have access to this analysis or there is an issue
          with the analysis.
        </Text>
        <Button
          color="red.600"
          onClick={() => navigate("/home")}
          variant={"link"}
        >
          Back to Home
        </Button>
      </Flex>
    </Flex>
  );
};

export const PermissionCheckLayout = () => {
  const { analysisId } = useParams<{ analysisId: string }>();
  const [fetchAnalysis, { data, isLoading, isError, error }] =
    useLazyGetSingleAnalysisQuery();
  const { checkPermission, hasFetchedPermission } = usePermissions();
  const toast = useToast();
  const [hasCheckedPermission, setHasCheckedPermission] = useState(false);
  const [hasPermission, setHasPermission] = useState(false);
  const hasAttemptedFetch = useRef(false);
  const previousAnalysisId = useRef<string | undefined>(undefined);

  // Reset fetch attempt if analysis ID changes
  useEffect(() => {
    if (analysisId !== previousAnalysisId.current) {
      hasAttemptedFetch.current = false;
      setHasCheckedPermission(false);
      setHasPermission(false);
      previousAnalysisId.current = analysisId;
    }
  }, [analysisId]);

  // Fetch analysis data
  useEffect(() => {
    if (analysisId && !hasAttemptedFetch.current) {
      hasAttemptedFetch.current = true;

      fetchAnalysis({ id: analysisId }).catch((fetchError) => {
        console.error("Error fetching analysis:", fetchError);
        toast({
          title: "Error loading analysis",
          description: "Could not load analysis details",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
    }
  }, [analysisId, fetchAnalysis, toast]);

  // Check permissions when data is available
  useEffect(() => {
    if (hasFetchedPermission && analysisId) {
      const hasReadPermission = checkPermission({
        id: analysisId,
        requiredPermission: PERMISSIONS.READ,
        type: CATEGORY.Analysis,
      });

      setHasPermission(hasReadPermission);
      setHasCheckedPermission(true);
    }
  }, [hasFetchedPermission, analysisId, checkPermission]);

  // Handle error or no data returned
  if (isError || error) {
    return <ErrorFallback />;
  }

  // Show loader only if we're still loading
  if (isLoading || !hasCheckedPermission) {
    return <InitLoader />;
  }

  // Check permission after data is loaded
  if (!hasPermission) {
    return <PermissionDeniedFallback />;
  }

  return <WorkFlowLayout />;
};
