import { Box, Flex, Button } from "@chakra-ui/react";
import { useMemo } from "react";
import {
  MdsCleaningServicesRound,
  MdsHistoryRound,
} from "react-icons-with-materialsymbols/mds";

import { getEditingAllowed } from "@/features/workflow-studio/redux";
import { useAppDispatch, useAppSelector } from "@/reduxHooks";

import {
  setShowHistory,
  showPromptHistory,
  clearCurrentRunResultRows,
  selectResultRowCount,
  getPreviewVersion,
  getAllRunResults,
  isLLMEditable,
} from "../../redux";

import LLMResultsDataTable from "./results-datatable";
import ResultsRowCount from "./row-count";
import RunTestButton from "./run-test";

const LLMTestResult = () => {
  const dispatch = useAppDispatch();
  const isHistoryOpen = useAppSelector(showPromptHistory);
  const isLLMEditDisabled = useAppSelector(isLLMEditable);
  const resultRowCount = useAppSelector(selectResultRowCount);
  const previewVersion = useAppSelector(getPreviewVersion);
  const allRunResults = useAppSelector(getAllRunResults);
  const isWFEditAllowed = useAppSelector(getEditingAllowed);

  const isDisabled = !isWFEditAllowed && !isLLMEditDisabled;

  const openHistoryPanel = () => {
    isHistoryOpen
      ? dispatch(setShowHistory(false))
      : dispatch(setShowHistory(true));
  };

  const clearResultData = () => {
    dispatch(clearCurrentRunResultRows());
  };

  const rowCount: number = useMemo(() => {
    if (isHistoryOpen) {
      const previewResults = allRunResults[previewVersion?.runs?.runId || ""];
      if (!previewResults) return resultRowCount || 0;
      return previewResults.result?.length || 0;
    }
    return resultRowCount ?? 0;
  }, [isHistoryOpen, previewVersion, allRunResults, resultRowCount]);

  return (
    <Flex className="flex-col w-full overflow-hidden">
      <Flex className="p-3" align={"center"} justify={"space-between"} gap={3}>
        <Flex className="gap-2 items-center">
          <Box className="font-semibold uppercase tracking-tight">
            Test Results
          </Box>
          <Box>({rowCount} Rows)</Box>
          <ResultsRowCount />
        </Flex>
        {!isHistoryOpen && (
          <Flex className="gap-1" align={"center"}>
            <Button
              colorScheme="dark"
              isDisabled={isLLMEditDisabled}
              onClick={openHistoryPanel}
              rightIcon={<MdsHistoryRound size={16} className="stroke-[22]" />}
              size={"sm"}
              variant={"ghost"}
            >
              History
            </Button>
            <Button
              colorScheme="dark"
              isDisabled={isLLMEditDisabled}
              onClick={clearResultData}
              rightIcon={<MdsCleaningServicesRound className="stroke-[22]" />}
              size={"sm"}
              variant={"ghost"}
            >
              Clear
            </Button>
            <RunTestButton />
          </Flex>
        )}
      </Flex>
      <LLMResultsDataTable />
    </Flex>
  );
};

export default LLMTestResult;
