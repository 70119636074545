import { Node } from "reactflow";

export const createCopiedNodeName = (originalNode: Node, allNodes: Node[]) => {
  const copiedNodes = allNodes.filter(
    (n) =>
      n.data?.outputName?.startsWith(`Copy of ${originalNode.data?.outputName}`)
  );

  let originalNodeName = `Copy of ${originalNode.data.outputName}`;
  const originalCopies = copiedNodes.filter(
    (n) => n.data.outputName === originalNodeName
  );

  if (originalCopies.length > 0) {
    originalNodeName += ` (${originalCopies.length})`;
  }

  let finalNodeName = originalNodeName;

  const existingCopies = copiedNodes.filter((n) =>
    n.data.outputName.startsWith(originalNodeName)
  );

  if (existingCopies.length > 0) {
    finalNodeName += ` (${existingCopies.length})`;
  }

  return finalNodeName;
};

export function getDistinctAnalyticsNodes(flowNodes: Node[]) {
  return (
    Array.from(
      new Set(
        flowNodes
          .filter(
            (node) =>
              node.type === "custom-node" &&
              node.data?.nodeCategory === "ANALYTICS"
          )
          .map((node) => node.data?.name)
          .filter(Boolean)
      )
    ) || []
  );
}
