import { getMSBaseUrl } from "@/utils/baseUrls.ts";
import { DATASET_FORMAT, MICRO_SERVICES } from "@/utils/enums.ts";
import { CacheKey, cacheUtil } from "@/utils/localstorage";
import { keysToSnake } from "@/utils/snakeCaseConverter";

export function triggerDownloadFromBlob(blob: Blob, filename: string) {
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.target = "_blank";
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  // cleanup
  window.URL.revokeObjectURL(url);
  document.body.removeChild(link);
}

export const handleDataSetDownload = async ({
  instance,
  analysisId,
  ioRecordId,
  datasetName,
  datasetId,
  outputFormat = DATASET_FORMAT.CSV,
  toast,
}: {
  instance: any;
  datasetId: string;
  analysisId: string;
  datasetName: string;
  ioRecordId: string;
  outputFormat: DATASET_FORMAT;
  toast: any;
}) => {
  try {
    const tenantToken = cacheUtil.getUnParsed(CacheKey.TenantId) ?? {};
    const userId = cacheUtil.getUnParsed(CacheKey.UserId) ?? {};

    window.addEventListener("beforeunload", (event) => {
      event.preventDefault();
      return "";
    });
    // using fetch here, since response returns a blob and axios parses the blob to a string
    // which breaks the encoding causing the file to be corrupted

    const response = await fetch(
      `${getMSBaseUrl(MICRO_SERVICES.PDS)}/api/v1.0/download-dataset/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${instance.getActiveAccount()?.idToken}`,
          "X-TENANT-ID": tenantToken,
          "X-USER-ID": userId,
        },
        body: JSON.stringify(
          keysToSnake({
            analysisId: analysisId,
            ioRecordId: ioRecordId,
            outputFormat: outputFormat,
          })
        ),
      }
    );

    const blob = await response.blob();
    triggerDownloadFromBlob(blob, datasetName + ".zip");
  } catch (error) {
    toast({
      id: "error-downloading-" + datasetId,
      title: "Download failed",
      description: datasetName + " failed to download",
      status: "error",
      actionText: "Retry",
      action: () => {
        toast.close("error-downloading-" + datasetId);
        handleDataSetDownload({
          instance,
          analysisId,
          ioRecordId,
          datasetName,
          datasetId,
          outputFormat,
          toast,
        }).catch((e) => console.log(e));
      },
    });
    console.error("Error:", error);
  } finally {
    if (toast.isActive("downloading-" + datasetId)) {
      toast.close("downloading-" + datasetId);
    }
    window.removeEventListener("beforeunload", (event) => {
      event.preventDefault();
    });
  }
};
