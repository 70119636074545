import {
  Flex,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Text,
} from "@chakra-ui/react";

import { TAXONOMY_MODES } from "@/features/workflow-studio/utils";

import { useConfigPanel } from "../../config-panel-context";

import { PARAMETER_NAMES, RUN_MODES } from "./theme-mapping";

const RunModeOptions = () => {
  const { setParameters, parameters, isEditingAllowed } = useConfigPanel();
  const runModeParam = parameters.find((p) => p.name === "Run Mode");

  const setRunMode = (value: string) => {
    setParameters(
      parameters.map((p) => {
        if (p.name === "Run Mode") return { ...p, value };

        if (
          value === RUN_MODES.UPLOAD_TAXONOMY &&
          p.name === PARAMETER_NAMES.RUN_MODE_CONFIG
        )
          return {
            ...p,
            value: JSON.stringify({
              theme_discovery_mode:
                TAXONOMY_MODES.RESTRICT_TO_EXISTING_TAXONOMY,
            }),
          };

        return p;
      })
    );
  };
  return (
    <FormControl isRequired>
      <FormLabel>Select mode for theme-mapping</FormLabel>
      <RadioGroup
        onChange={(e: string) => setRunMode(e)}
        value={runModeParam?.value}
      >
        <Flex direction="column" gap={3}>
          <label className="border cursor-pointer flex flex-col gap-2 py-3 px-3 border-gray-300 rounded-md">
            <Radio w="full" isDisabled={!isEditingAllowed} value="find_new_themes">
              <Text fontWeight="semibold">Find new themes</Text>
            </Radio>
            <Text color="gray.700" fontSize="sm">
              Analyse text data and find themes from scratch
            </Text>
          </label>

          <label className="border cursor-pointer flex flex-col gap-2 py-3 px-3 border-gray-300 rounded-md">
            <Radio w="full" isDisabled={!isEditingAllowed} value="upload_taxonomy">
              <Text fontWeight="semibold">Map to my preferred themes</Text>
            </Radio>
            <Text color="gray.700" fontSize="sm">
              Analyse text data and categorize on the basis of a predefined set
              of themes (taxonomy)
            </Text>
          </label>
        </Flex>
      </RadioGroup>
    </FormControl>
  );
};

export default RunModeOptions;
