import { EndpointBuilder } from "@reduxjs/toolkit/query";

import { DataManagerEndpoints } from "@/features/data-manager/api/api-endpoints.ts";
import { IStrapiTransformation } from "@/features/data-transformation";
import { ApiResponse, PaginationBody } from "@/types";
import { keysToSnake } from "@/utils/snakeCaseConverter.ts";

import { setConnectorIcon } from "../redux";
import {
  IDataSetApi,
  DatasetsList,
  OnGoingUploadsList,
  PreviewDataApiResponse,
  IDataSourcesResponse,
  IProcessIoRecordPayload,
  DatasetDetail,
  IDestinationActorsResponse,
  ICreateDestinationResponse,
  IGetSchemaComparisonResponse,
  IConfirmSchemaPayload,
  IConfirmSchemaResponse,
  IOngoingExportsResponse,
  IAddDownloadPayload,
  IGetDownloadsResponse,
  IAddDownloadResponse,
} from "../types";

import { dataManagerAPI } from "./api";

const isPaginationBody = (body: any): body is PaginationBody => {
  return typeof body === "object" && "next" in body;
};
export const dataQueryAPI = dataManagerAPI.injectEndpoints({
  endpoints: (build: EndpointBuilder<any, any, any>) => ({
    getOngoingUploads: build.query<
      ApiResponse<OnGoingUploadsList>,
      { analysisId: string }
    >({
      query: ({ analysisId }) => {
        return {
          url: DataManagerEndpoints.getOngoingUploads(analysisId),
        };
      },
      providesTags: ["IORecords"],
    }),
    getDataSets: build.query<
      ApiResponse<DatasetsList>,
      IDataSetApi | PaginationBody
    >({
      query: (body) => {
        let url;

        if (isPaginationBody(body)) {
          url = body.next;
        } else {
          url = DataManagerEndpoints.getDatSets(body.analysisId);
        }

        return {
          url: url,
        };
      },
      providesTags: ["Datasets"],
    }),
    getDataPreview: build.query<
      ApiResponse<PreviewDataApiResponse>,
      { analysisId: string; ioRecordId: string }
    >({
      query: ({ analysisId, ioRecordId }) => ({
        url: DataManagerEndpoints.getPreviewData(analysisId, ioRecordId),
        ignoreBaseUrl: true,
      }),
      extraOptions: {
        maxRetries: 2,
      },
      transformResponse: (res: ApiResponse<PreviewDataApiResponse>) => {
        const seralizedRawData = JSON.parse(
          res.response.data!.previewData.rowData as string
        );
        const newResponse = {
          ...res,
          response: {
            data: {
              ...res.response.data,
              previewData: {
                ...res.response.data!.previewData,
                rowData: seralizedRawData,
              },
            },
          },
        } as ApiResponse<PreviewDataApiResponse>;
        return newResponse;
      },
      providesTags: ["DataPreview"],
      keepUnusedDataFor: 0,
    }),
    acceptSchema: build.mutation<
      ApiResponse<any>,
      { deltaTablePath: string; analysisId: string; ioRecordId: string }
    >({
      query: ({ deltaTablePath, analysisId, ioRecordId }) => {
        return {
          url: DataManagerEndpoints.acceptSchema(analysisId, ioRecordId),
          method: "PUT",
          data: { delta_table_path: deltaTablePath },
          ignoreBaseUrl: true,
        };
      },
      onQueryStarted: (arg, { queryFulfilled }) => {
        queryFulfilled
          .then(() => {
            dataQueryAPI.endpoints.getDataSets.initiate({
              analysisId: arg.analysisId,
            });
          })
          .catch((e) => console.error(e));
      },
      invalidatesTags: ["IORecords"],
    }),
    getSingleDataset: build.query<
      ApiResponse<DatasetsList>,
      { analysisId: string; datasetId: string }
    >({
      query: ({ analysisId, datasetId }) => {
        return {
          url: DataManagerEndpoints.getSingleDataSet(analysisId, datasetId),
        };
      },
    }),
    getSingleIoRecord: build.query<
      ApiResponse<OnGoingUploadsList>,
      { analysisId: string; ioRecordId: string }
    >({
      query: ({ analysisId, ioRecordId }) => {
        return {
          url: DataManagerEndpoints.getSingleIoRecord(analysisId, ioRecordId),
        };
      },
    }),
    editDataset: build.mutation<
      ApiResponse<DatasetsList>,
      { analysisId: string; datasetId: string; body: DatasetDetail }
    >({
      query: ({ analysisId, datasetId, body }) => {
        return {
          url: DataManagerEndpoints.editDataset(analysisId, datasetId),
          method: "PUT",
          data: keysToSnake(body),
        };
      },
      invalidatesTags: ["Datasets"],
    }),
    deleteDataset: build.mutation<
      ApiResponse<DatasetsList>,
      { analysisId: string; datasetId: string }
    >({
      query: ({ analysisId, datasetId }) => {
        return {
          url: DataManagerEndpoints.deleteDataset(analysisId, datasetId),
          method: "DELETE",
        };
      },
      invalidatesTags: ["Datasets"],
    }),
    deleteIoRecord: build.mutation<
      ApiResponse<DatasetsList>,
      { analysisId: string; ioRecordId: string }
    >({
      query: ({ analysisId, ioRecordId }) => {
        return {
          url: DataManagerEndpoints.deleteIORecord(analysisId, ioRecordId),
          method: "DELETE",
        };
      },
      invalidatesTags: ["IORecords"],
    }),
    sourceFormRecord: build.mutation<any, { actorDefinitionId: string }>({
      query: ({ actorDefinitionId }) => {
        return {
          url: DataManagerEndpoints.sourceForm(actorDefinitionId),
          method: "GET",
          camelCase: false,
        };
      },
    }),
    sourceListRecord: build.mutation<ApiResponse<IDataSourcesResponse>, any>({
      query: () => {
        return {
          url: DataManagerEndpoints.listSources(),
          method: "GET",
        };
      },
    }),
    getDestinations: build.query<ApiResponse<IDataSourcesResponse>, void>({
      query: () => {
        return {
          url: DataManagerEndpoints.listDestinations(),
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          // Update connector icons for each destination
          data.response.data?.results?.forEach((destination) => {
            if (destination.iconUrl) {
              dispatch(
                setConnectorIcon({
                  connector: destination.id,
                  icon: destination.iconUrl ?? "",
                })
              );
            }
          });
        } catch (error) {
          console.error("Failed to process destination icons:", error);
        }
      },
    }),
    sourceFormvalidation: build.mutation<
      any,
      {
        actorDefinitionId: string;
        name: string;
        connection_configuration: any;
        analysisId: string;
      }
    >({
      query: ({
        actorDefinitionId,
        name,
        connection_configuration,
        analysisId,
      }) => {
        return {
          url: DataManagerEndpoints.submitConnectionForm(analysisId),
          method: "POST",
          body: {
            actor_definition_id: actorDefinitionId,
            name: name,
            connection_configuration: keysToSnake(connection_configuration),
          },
        };
      },
    }),
    filesList: build.mutation<any, { actorId: string; analysisId: string }>({
      query: ({ actorId, analysisId }) => {
        return {
          url: DataManagerEndpoints.getDatasetList(actorId, analysisId),
          method: "GET",
          camelCase: false,
        };
      },
    }),
    syncSourceFile: build.mutation<
      any,
      { ioId: string; analysisId: string; body: any }
    >({
      query: ({ ioId, analysisId, body }) => {
        return {
          url: DataManagerEndpoints.syncSource(analysisId, ioId),
          method: "POST",
          body: body,
        };
      },
    }),
    uploadAirbyteFiles: build.mutation<
      ApiResponse<any>,
      { analysisId: string; body: any }
    >({
      query: ({ analysisId, body }) => {
        return {
          url: DataManagerEndpoints.uploadAirbyteFiles(analysisId),
          method: "POST",
          body: body,
        };
      },
    }),
    getConnectorDocumentation: build.query<
      IStrapiTransformation,
      { connectorName: string }
    >({
      query: ({ connectorName }) => ({
        url: DataManagerEndpoints.getConnectorDocumentation,
        method: "GET",
        params: {
          "filters[connector_name][$eq]": connectorName,
        },
        headers: {
          Authorization: `Bearer ${import.meta.env.VITE_STRAPI_API_KEY}`,
        },
        ignoreBaseUrl: true,
        customAuth: true,
      }),
      // Permanent caching
      keepUnusedDataFor: Number.MAX_SAFE_INTEGER,
    }),
    getIoRecordMetaData: build.query<
      ApiResponse<OnGoingUploadsList>,
      { ioId: string; analysisId: string }
    >({
      query: ({ ioId, analysisId }) => {
        return {
          url: DataManagerEndpoints.getIoRecordMetaData(ioId, analysisId),
          method: "GET",
        };
      },
    }),
    processIoRecord: build.mutation<
      ApiResponse<OnGoingUploadsList>,
      { ioId: string; analysisId: string; body: IProcessIoRecordPayload }
    >({
      query: ({ ioId, analysisId, body }) => {
        return {
          url: DataManagerEndpoints.processIoRecord(ioId, analysisId),
          method: "POST",
          body: keysToSnake(body),
        };
      },
      invalidatesTags: ["IORecords"],
    }),
    getOngoingExports: build.query<
      ApiResponse<IOngoingExportsResponse>,
      { analysisId: string }
    >({
      query: ({ analysisId }) => ({
        url: DataManagerEndpoints.getOngoingExports(analysisId),
      }),
      providesTags: ["Exports"],
    }),
    getDestinationActors: build.query<
      ApiResponse<IDestinationActorsResponse>,
      { analysisId: string; definitionId: string }
    >({
      query: ({ analysisId, definitionId }) => ({
        url: DataManagerEndpoints.getDestinationActors(
          analysisId,
          definitionId
        ),
      }),
      providesTags: ["DestinationActors"],
    }),
    getDestinationFormDetails: build.query<
      ApiResponse<any>,
      { analysisId: string; actorId: string }
    >({
      query: ({ analysisId, actorId }) => ({
        url: DataManagerEndpoints.getDestinationFormDetails(
          analysisId,
          actorId
        ),
        camelCase: false,
      }),
    }),
    getDestinationForm: build.query<
      ApiResponse<any>,
      { actorDefinitionId: string }
    >({
      query: ({ actorDefinitionId }) => ({
        url: DataManagerEndpoints.getDestinationForm(actorDefinitionId),
        camelCase: false,
      }),
    }),
    createDestinationActor: build.mutation<
      ApiResponse<ICreateDestinationResponse>,
      { analysisId: string; body: any }
    >({
      query: ({ analysisId, body }) => ({
        url: DataManagerEndpoints.createDestinationActor(analysisId),
        method: "POST",
        body,
      }),
      invalidatesTags: ["DestinationActors"],
    }),
    getSchemaComparison: build.mutation<
      ApiResponse<IGetSchemaComparisonResponse>,
      { sourceDatasetId: string; analysisId: string; actorId: string }
    >({
      query: ({ sourceDatasetId, analysisId, actorId }) => ({
        url: DataManagerEndpoints.getSchemaComparison(analysisId, actorId),
        method: "POST",
        body: {
          source_dataset_id: sourceDatasetId,
        },
        camelCase: false,
      }),
    }),
    confirmSchema: build.mutation<
      ApiResponse<IConfirmSchemaResponse>,
      { analysisId: string; payload: IConfirmSchemaPayload }
    >({
      query: ({ analysisId, payload }) => ({
        url: DataManagerEndpoints.confirmSchema(analysisId),
        method: "POST",
        body: keysToSnake(payload),
      }),
      invalidatesTags: ["Exports"],
    }),
    cancelExport: build.mutation<
      ApiResponse<IConfirmSchemaResponse>,
      { analysisId: string; exportId: string }
    >({
      query: ({ analysisId, exportId }) => ({
        url: DataManagerEndpoints.cancelExport(analysisId, exportId),
        method: "DELETE",
      }),
      invalidatesTags: ["Exports"],
    }),
    addDownload: build.mutation<
      ApiResponse<IAddDownloadResponse>,
      { analysisId: string; body: IAddDownloadPayload }
    >({
      query: ({ analysisId, body }) => ({
        url: DataManagerEndpoints.addDownload(analysisId),
        method: "POST",
        body: {
          downloads_config: [keysToSnake(body)],
        },
      }),
    }),
    getDownloads: build.query<
      ApiResponse<IGetDownloadsResponse>,
      { analysisId: string }
    >({
      query: ({ analysisId }) => ({
        url: DataManagerEndpoints.getDownloads(analysisId),
        method: "GET",
      }),
    }),
    deleteDestinationActor: build.mutation<
      ApiResponse<any>,
      { analysisId: string; actorId: string }
    >({
      query: ({ analysisId, actorId }) => ({
        url: DataManagerEndpoints.deleteDestinationActor(analysisId, actorId),
        method: "DELETE",
        camelCase: false,
      }),
      invalidatesTags: ["DestinationActors"],
    }),
  }),
});

export const {
  useGetOngoingUploadsQuery,
  useLazyGetOngoingUploadsQuery,
  useGetDataSetsQuery,
  useLazyGetDataSetsQuery,
  useGetDataPreviewQuery,
  useAcceptSchemaMutation,
  useGetSingleDatasetQuery,
  useLazyGetSingleDatasetQuery,
  useLazyGetSingleIoRecordQuery,
  useDeleteIoRecordMutation,
  useDeleteDatasetMutation,
  useSourceFormRecordMutation,
  useSourceListRecordMutation,
  useSourceFormvalidationMutation,
  useFilesListMutation,
  useSyncSourceFileMutation,
  useLazyGetConnectorDocumentationQuery,
  useLazyGetIoRecordMetaDataQuery,
  useProcessIoRecordMutation,
  useUploadAirbyteFilesMutation,
  useEditDatasetMutation,
  useGetDestinationsQuery,
  useGetDestinationActorsQuery,
  useGetDestinationFormDetailsQuery,
  useGetDestinationFormQuery,
  useCreateDestinationActorMutation,
  useGetSchemaComparisonMutation,
  useLazyGetDestinationActorsQuery,
  useLazyGetDestinationFormDetailsQuery,
  useLazyGetDestinationFormQuery,
  useConfirmSchemaMutation,
  useGetOngoingExportsQuery,
  useLazyGetOngoingExportsQuery,
  useCancelExportMutation,
  useDeleteDestinationActorMutation,
  useAddDownloadMutation,
  useGetDownloadsQuery,
  useLazyGetDownloadsQuery,
} = dataQueryAPI;
