import type { EndpointBuilder } from "@reduxjs/toolkit/query"
import type { ApiResponse } from "@/types"
import { DASHBOARD_API } from "./api-endpoints"
import { dashboardAPI } from "./api"
import type { EmbedTokenResponse, DashboardData } from "../types"

/** Base parameters required for all dashboard endpoints */
interface DashboardQueryParams {
  analysisId: string
}

/** Parameters required for embed token related endpoints */
interface EmbedTokenQueryParams extends DashboardQueryParams {
  dashboardId: string
}

/** Parameters required for dashboard update operations */
interface UpdateDashboardParams extends EmbedTokenQueryParams {
  file: FormData
}

/** Parameters required for dashboard upload operations */
interface UploadDashboardParams extends DashboardQueryParams {
  file: FormData
}

/** Common headers for all dashboard endpoints */
const getCommonHeaders = (analysisId: string) => ({
  'X-ANALYSIS-ID': analysisId
})

/**
 * Dashboard API endpoints using RTK Query
 * Provides hooks for fetching and mutating dashboard data
 */
export const dashboardQueryAPI = dashboardAPI.injectEndpoints({
  endpoints: (build: EndpointBuilder<any, any, any>) => ({
    /** Fetch all dashboards for an analysis */
    getDashboards: build.query<ApiResponse<DashboardData[]>, DashboardQueryParams>({
      query: ({ analysisId }) => ({
        url: DASHBOARD_API.GET_DASHBOARDS(analysisId),
        method: "GET",
        headers: getCommonHeaders(analysisId)
      }),
      keepUnusedDataFor: 0,
      providesTags: ["Dashboards"]
    }),

    /** Get embed token for a specific dashboard */
    getEmbedToken: build.query<EmbedTokenResponse, EmbedTokenQueryParams>({
      query: ({ analysisId, dashboardId }) => ({
        url: DASHBOARD_API.GET_EMBED_TOKEN(analysisId, dashboardId),
        method: "GET",
        headers: getCommonHeaders(analysisId)
      }),
      keepUnusedDataFor: 0
    }),

    /** Upload a new dashboard */
    uploadDashboard: build.mutation<ApiResponse<DashboardData>, UploadDashboardParams>({
      query: ({ analysisId, file }) => ({
        url: DASHBOARD_API.UPLOAD_DASHBOARD(analysisId),
        method: "POST",
        body: file,
        headers: getCommonHeaders(analysisId)
      }),
      invalidatesTags: ["Dashboards"]
    }),

    /** Update an existing dashboard */
    updateDashboard: build.mutation<ApiResponse<DashboardData>, UpdateDashboardParams>({
      query: ({ analysisId, dashboardId, file }) => ({
        url: DASHBOARD_API.UPDATE_DASHBOARD(analysisId, dashboardId),
        method: "PUT",
        body: file,
        headers: getCommonHeaders(analysisId)
      }),
      invalidatesTags: ["Dashboards"]
    })
  }),
  overrideExisting: false
})

/** Generated hooks for dashboard operations */
export const {
  useGetDashboardsQuery,
  useLazyGetDashboardsQuery,
  useGetEmbedTokenQuery,
  useLazyGetEmbedTokenQuery,
  useUploadDashboardMutation,
  useUpdateDashboardMutation
} = dashboardQueryAPI 