import { Button, Flex, Text } from "@chakra-ui/react";

import { useShowToast } from "@/components/toast";
import { useAppDispatch, useAppSelector } from "@/reduxHooks";

import { useTerminateWorkflowMutation } from "../../api/workflow-api";
import { setEditingAllowed } from "../../redux/workflow-slice";
import { WFUserSchema, WorkflowStateSchema } from "../../types";

interface ConfirmTerminateModalBodyProps {
  onCancel: () => void;
}

const ConfirmTerminateModalBody = ({
  onCancel,
}: ConfirmTerminateModalBodyProps) => {
  const dispatch = useAppDispatch();
  const toast = useShowToast();
  const {
    workflowId,
    workflowRunId,
    currentUser,
    workflowState,
  }: {
    workflowId: string;
    workflowRunId: string;
    currentUser: WFUserSchema | null;
    workflowState: WorkflowStateSchema | null;
  } = useAppSelector((state) => state.rootReducer.modals.modalProps as any);

  const [terminateWFApi, { isLoading: isTerminating }] =
    useTerminateWorkflowMutation();

  const handleTerminate = async () => {
    try {
      await terminateWFApi({
        workflowId: workflowId,
        workflowRunId: workflowRunId,
      }).unwrap();

      toast({
        title: "Workflow terminated Successfully",
        status: "success",
      });

      dispatch(setEditingAllowed(true));
      onCancel();
    } catch (error) {
      console.error("Error terminating workflow:", error);
      toast({
        title: "Failed to terminate workflow",
        status: "error",
      });
    }
  };

  const triggeredByName =
    workflowState?.triggeredBy?.displayName ?? "Someone Else";

  return (
    <Flex className="flex-col gap-4">
      {currentUser?.isCurrentUser ? (
        <Text className="mb-4">
          Terminating this Run now will lead to losing progress. Are you sure
          you want to proceed?
        </Text>
      ) : (
        <Flex className="flex-col gap-2">
          <Text className="mb-4">
            This Run was triggered by&nbsp;
            <b>{triggeredByName}</b>.&nbsp;Terminating this Run now will lead to
            losing progress.
          </Text>
          <Text className="mb-4">
            Are you sure you want to terminate&nbsp;
            <b>{triggeredByName}'s</b> Run?
          </Text>
        </Flex>
      )}
      <Flex className="items-center justify-end gap-2">
        <Button
          colorScheme="dark"
          isDisabled={isTerminating}
          onClick={onCancel}
          variant={"outline"}
        >
          Cancel
        </Button>
        <Button
          colorScheme="red"
          isDisabled={isTerminating}
          isLoading={isTerminating}
          onClick={handleTerminate}
        >
          Terminate Run
        </Button>
      </Flex>
    </Flex>
  );
};

export default ConfirmTerminateModalBody;
