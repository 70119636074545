import {
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderMark,
  Box,
} from "@chakra-ui/react";

import {
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
  FormLabel,
} from "@/design/components/form";
import { useConfigPanel } from "@/features/workflow-studio/components/config-panel/config-panel-context";
import { NodeParameter } from "@/features/workflow-studio/types";

export const RangeConfig = ({
  param,
  updateParameter,
}: {
  param: NodeParameter;
  updateParameter: (paramId: string, value: any, error: any) => void;
}) => {
  const { isEditingAllowed } = useConfigPanel();
  const defaultValue =
    param.value ?? param.valuesList.flatMap((v) => Number(v));
  const range = param.valuesList.flatMap((v) => Number(v));
  const onChangeEnd = (values: number[]) => {
    updateParameter(param.parameterId, values, undefined);
  };

  return (
    <FormControl
      key={param.name}
      isRequired={param.isMandatory}
      isInvalid={param.errors && param.errors.length > 0}
    >
      <FormLabel>{param.name}</FormLabel>
      <Box px={3}>
        <RangeSlider
          className="!relative"
          colorScheme="orange"
          isDisabled={!param.isModifiable || !isEditingAllowed}
          max={range[1]}
          min={range[0]}
          onChange={onChangeEnd}
          value={defaultValue}
        >
          <RangeSliderMark mt="2" ml="-3" fontSize="xs" value={defaultValue[0]}>
            {defaultValue[0]}
          </RangeSliderMark>
          <RangeSliderMark mt="2" ml="-3" fontSize="xs" value={defaultValue[1]}>
            {defaultValue[1]}
          </RangeSliderMark>
          <RangeSliderTrack bg="red.50">
            <RangeSliderFilledTrack bg="tomato" />
          </RangeSliderTrack>
          <RangeSliderThumb
            border="1px solid"
            borderColor="orange.400"
            index={0}
          />
          <RangeSliderThumb
            border="1px solid"
            borderColor="orange.400"
            index={1}
          />
        </RangeSlider>
      </Box>
      <FormErrorMessage>
        <FormErrorIcon />
        {param.errors?.map((err) => <div key={err}>{err}</div>)}
      </FormErrorMessage>
    </FormControl>
  );
};
