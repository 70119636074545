import { Flex, Text } from "@chakra-ui/react";
import { clsx } from "clsx";
import { IconType } from "react-icons/lib";
import { MdsChevronRightRound } from "react-icons-with-materialsymbols/mds";

import { Icon } from "@/design/components/icon";
import { ViewFiltersRequest } from "@/features/data-transformation";

type CustomDropdownRenderProps = {
  title: string;
  icon: IconType;
  hasArrow?: boolean;
  className?: string;
  filters?: ViewFiltersRequest[];
  colName?: string;
};
export const CustomDropdownRender = ({
  title,
  icon,
  hasArrow = false,
  className,
  filters,
  colName,
}: CustomDropdownRenderProps) => {
  const hasFilters = filters?.find((data: any) => data.column == colName);
  const isFilter = !!filters;
  const filterTitle = hasFilters ? "Edit " : "Add ";
  const beforeTitle = isFilter ? filterTitle : "";

  return (
    <Flex
      className={clsx(
        "justify-between gap-x-1 cursor-pointer px-2 py-2.5 hover:bg-gray-50 text-gray-900 border-b border-b-gray-100",
        className
      )}
    >
      <Flex className="flex-row items-center gap-x-1 max-w-full">
        <Icon as={icon} size="sm" />
        <Text className="font-[13px] leading-[120%] tracking-[-0.26px] truncate max-w-full">
          {beforeTitle}
          {title}
        </Text>
      </Flex>
      {hasArrow && <Icon as={MdsChevronRightRound} size="sm" />}
    </Flex>
  );
};
