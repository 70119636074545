import { Box, ButtonProps, Flex } from "@chakra-ui/react";
import clsx from "clsx";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import {
  MdEdit,
  MdOutlineCopyAll,
  MdOutlineDelete,
  MdOutlineMessage,
  MdOutlineRemoveRedEye,
  MdOutlineReplay,
  MdOutlineSettings,
} from "react-icons/md";
import {
  MdsDatabaseRound,
  MdsEditRound,
} from "react-icons-with-materialsymbols/mds";
import { useParams } from "react-router-dom";
import { useReactFlow } from "reactflow";

import { ToastType, useShowToast } from "@/components/toast";
import { Button } from "@/design/components/button";
import { useAppDispatch, useAppSelector } from "@/reduxHooks.ts";
import { ModalTypes, openModal } from "@/slices/modal-slice";

import { useCreateCopyNode } from "../../hooks/useCreateNodeCopy";
import useManageOutput from "../../hooks/useManageOutput";
import {
  getEditingAllowed,
  hideAllPanels,
  hidePanel,
  selectPanel,
  showPanel,
  workflowRunningStatus,
} from "../../redux";
import { NodeParameter, NodeType } from "../../types";
import { WORKFLOW_PANELS } from "../../utils/constants";

export interface CTXMenuProps {
  id: string;
  top?: number;
  left?: number;
  right?: number;
  bottom?: number;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const ListItemButton: React.FC<ButtonProps & { isVisible?: boolean }> = ({
  isVisible = true,
  children,
  ...props
}) => {
  if (!isVisible) return null;
  return (
    <Button
      variant="ghost"
      justifyContent="flex-start"
      colorScheme="dark"
      sx={{
        ".chakra-button__icon *": { height: "20px", width: "20px" },
      }}
      {...props}
      className={`border-b !font-normal !text-sm ${props.className}`}
    >
      {children}
    </Button>
  );
};

const ContextMenu: React.FC<
  CTXMenuProps & {
    setMenu: (menu: CTXMenuProps | null) => void;
  }
> = ({ id, top, left, right, bottom, setMenu, ...props }) => {
  const { getNode, deleteElements, setNodes, toObject } = useReactFlow();
  const toast = useShowToast(undefined, undefined, true);
  const ctxNode = getNode(id);
  const params = useParams();
  const dispatch = useAppDispatch();
  const menuRef = useRef<HTMLDivElement>(null);
  const { createNodeCopy } = useCreateCopyNode();

  const isEditingAllowed = useAppSelector(getEditingAllowed);
  const { isUnMarking, removeOutput, openOutputModal } = useManageOutput();

  const nodeConfigPanel = useAppSelector(
    selectPanel(WORKFLOW_PANELS.NodeConfigurationPanel)
  );
  const dataPreviewPanel = useAppSelector(
    selectPanel(WORKFLOW_PANELS.DataPreviewPanel)
  );

  const hasParent = useMemo(() => getNode(id)?.parentNode, [getNode, id]);
  const isFlowNode = useMemo(
    () =>
      (ctxNode?.data as NodeType)?.nodeType === "CUSTOM_FLOW_NODE" ||
      ctxNode?.type === "group-node",
    [ctxNode]
  );
  const hasConfig = useMemo(() => {
    const node = getNode(id);
    if (node?.data.parameters) return false;
    return (
      (node?.data?.parameters as NodeParameter[])?.filter((f) => !f.isHidden)
        .length > 0
    );
  }, [getNode, id]);

  const isSourceNode = useMemo(
    () =>
      (ctxNode?.data as NodeType)?.nodeType
        .toUpperCase()
        .includes("SOURCE".toUpperCase()),
    [ctxNode]
  );

  const onClickHandler = (event: React.MouseEvent) => {
    event.preventDefault();
    // event.stopPropagation();
  };

  const showLogsPanel = (event: React.MouseEvent<HTMLButtonElement>) => {
    dispatch(showPanel({ panel: WORKFLOW_PANELS.LogsPanel }));
  };

  const onRenameNode = () => {
    dispatch(
      openModal({
        modalType: ModalTypes.RENAME_NODE,
        modalProps: {
          node: ctxNode,
          setNodes: setNodes,
          instance: toObject(),
          params: params,
        },
      })
    );
  };

  const showDataPreviewPanel = (event: React.MouseEvent<HTMLButtonElement>) => {
    dispatch(
      showPanel({ panel: WORKFLOW_PANELS.DataPreviewPanel, nodeId: id })
    );
  };

  const openConfig = useCallback(() => {
    if (!hasConfig) {
      dispatch(hidePanel(WORKFLOW_PANELS.NodeConfigurationPanel));
    } else {
      dispatch(
        showPanel({
          panel: WORKFLOW_PANELS.NodeConfigurationPanel,
          nodeId: id,
        })
      );
    }
  }, [dispatch, hasConfig, id]);

  const duplicateNode = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    try {
      if (ctxNode) {
        createNodeCopy(ctxNode);
      }
    } catch (e) {
      toast({
        description: "Failed to duplicate node",
        status: ToastType.Error,
      });
    }
  };

  const removeNode = () => {
    if (!ctxNode) return;
    if (nodeConfigPanel.nodeId === id || dataPreviewPanel.nodeId === id)
      dispatch(hideAllPanels());
    deleteElements({ nodes: [{ id: ctxNode.id }] });
  };

  const handleDelete = () => {
    dispatch(
      openModal({
        modalType: ModalTypes.DELETE_NODE,
        modalProps: {
          nodeData: ctxNode?.data as NodeType,
          onConfirmDelete: removeNode,
        },
      })
    );
  };

  // Modified useEffect to handle click outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
      const target =
        "touches" in event
          ? document.elementFromPoint(
              event.touches[0].clientX,
              event.touches[0].clientY
            )
          : (event.target as Node);

      if (!menuRef.current || !target) return;

      const isClickInsideMenu = menuRef.current.contains(target);
      const clickedNodeId = (target as Element)
        .closest(".react-flow__node")
        ?.getAttribute("data-id");
      const isClickOnOriginalNode = clickedNodeId === id;

      if (!isClickInsideMenu && !isClickOnOriginalNode) {
        setMenu(null);
      }
    };

    document.addEventListener(
      "mousedown",
      handleClickOutside as EventListener,
      true
    );
    document.addEventListener(
      "touchstart",
      handleClickOutside as EventListener,
      true
    );

    return () => {
      document.removeEventListener(
        "mousedown",
        handleClickOutside as EventListener,
        true
      );
      document.removeEventListener(
        "touchstart",
        handleClickOutside as EventListener,
        true
      );
    };
  }, [id, setMenu]);

  if (!ctxNode || isFlowNode || !ctxNode.data) return null;

  return (
    <Box
      className={clsx(
        "z-[10] absolute shadow-noblur !rounded-none bg-white overflow-hidden",
        "w-min border border-gray-300 max-w-[250px]",
        bottom && "-translate-y-1/2"
      )}
      ref={menuRef}
      style={{
        top,
        left,
        right,
        bottom,
      }}
      {...props}
    >
      <Flex direction="column">
        <Box className="px-3 py-2 overflow-hidden text-xs border-b bg-gray-50 whitespace-pre text-ellipsis">
          {ctxNode.data.displayName}
        </Box>
        <ListItemButton
          isVisible={isEditingAllowed}
          onClick={onRenameNode}
          leftIcon={<MdsEditRound strokeWidth={18} />}
        >
          Rename
        </ListItemButton>
        {!isSourceNode &&
          (ctxNode.data.isOutput ? (
            <ListItemButton
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={() => removeOutput({ id })}
              isVisible={isEditingAllowed}
              isLoading={isUnMarking}
              isDisabled={isUnMarking}
              leftIcon={<MdsDatabaseRound className="stroke-[20]" />}
            >
              Remove Output Dataset
            </ListItemButton>
          ) : (
            <ListItemButton
              onClick={() => openOutputModal({ id })}
              isLoading={isUnMarking}
              isDisabled={isUnMarking}
              isVisible={isEditingAllowed}
              leftIcon={<MdsDatabaseRound className="stroke-[20]" />}
            >
              Create Output Dataset
            </ListItemButton>
          ))}

        <ListItemButton
          isVisible={isEditingAllowed}
          isDisabled={true}
          onClick={onClickHandler}
          leftIcon={<MdOutlineReplay />}
        >
          Run Again
        </ListItemButton>
        {hasConfig && (
          <ListItemButton
            isVisible={isEditingAllowed}
            onClick={openConfig}
            leftIcon={<MdOutlineSettings />}
          >
            Edit Configuration
          </ListItemButton>
        )}
        <ListItemButton
          isVisible={true}
          onClick={showDataPreviewPanel}
          leftIcon={<MdOutlineRemoveRedEye />}
        >
          View Data
        </ListItemButton>
        {!hasParent && (
          <ListItemButton
            isVisible={isEditingAllowed}
            onClick={duplicateNode}
            leftIcon={<MdOutlineCopyAll />}
          >
            Duplicate Node
          </ListItemButton>
        )}
        <ListItemButton onClick={showLogsPanel} leftIcon={<MdOutlineMessage />}>
          View Run Logs
        </ListItemButton>
        {!hasParent && (
          <ListItemButton
            isVisible={isEditingAllowed}
            leftIcon={<MdOutlineDelete />}
            onClick={handleDelete}
            color="red.600"
            className="hover:!text-red-600"
          >
            Remove Node
          </ListItemButton>
        )}
      </Flex>
    </Box>
  );
};

export default ContextMenu;
