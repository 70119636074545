import { Button } from "@chakra-ui/react";
import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";
import { createBrowserRouter, Navigate, Outlet } from "react-router-dom";

import { SettingsLayout } from "@/components/layouts";
import { PermissionCheckLayout } from "@/components/layouts/workflow/permission-check-layout";
import ModalWrapper from "@/components/modal-wrapper";
import { AskClooTrackWrapper } from "@/routes/askclootrack.tsx";
import { AdminRouteLayout } from "@/routes/role-guard/AdminRoute.tsx";

import AppInitializer from "./AppInitializer";
import OauthCallback from "./role-guard/OauthCallback";

const ErrorFallback = () => {
  return (
    <div className="w-screen h-screen flex flex-col items-center justify-center gap-4">
      <h1>Uh oh!</h1>
      <p>Something went wrong!</p>
      <Button
        color="red.600"
        onClick={() => window.location.reload()}
        variant={"outline"}
      >
        Click here to reload the page
      </Button>
    </div>
  );
};

function errorHandler(error: any) {
  console.error("routing error:", error)
  if (error.message.includes("dynamically imported module")) {
    window.location.reload();
  }
}

const AppRouter = createBrowserRouter([
  {
    id: "root",
    path: "/",
    // wrapper component to wrap all the routes
    Component: () => {
      if (window.location.pathname === "/oauth2callback") {
        return <Outlet />;
      }
      return (
        <AppInitializer>
          <SentryErrorBoundary fallback={ErrorFallback}>
            <Outlet />
            <ModalWrapper />
          </SentryErrorBoundary>
        </AppInitializer>
      );
    },

    children: [
      {
        index: true,
        Component: () => <Navigate to="home" replace={true} />,
      },
      {
        path: "select-org",
        lazy: async () => {
          try {
            const { SelectOrg } = await import("@/routes/pre-auth/select-org");
            return { Component: SelectOrg };
          } catch (error) {
            errorHandler(error);
            return { Component: ErrorFallback };
          }
        },
      },
      {
        path: "oauth2callback",
        Component: () => <OauthCallback />,
      },
      {
        path: "/signUp",
        Component: () => <Navigate to="/home" replace={true} />,
      },
      {
        children: [
          {
            path: "home/*",
            lazy: async () => {
              try {
                const { WorkspaceManagement } = await import(
                  "@/features/ws-manager"
                );
                return { Component: WorkspaceManagement };
              } catch (error) {
                errorHandler(error);
                return { Component: ErrorFallback };
              }
            },
          },
        ],
      },
      {
        path: "analysis/:analysisId/*",
        Component: () => <PermissionCheckLayout />,
        children: [
          {
            path: "editor/:editorId/*",
            lazy: async () => {
              try {
                const { NodeEditor } = await import(
                  "@/features/workflow-studio"
                );
                return { Component: NodeEditor };
              } catch (error) {
                errorHandler(error);
                return { Component: ErrorFallback };
              }
            },
          },
          {
            path: "genie/*",
            element: <AskClooTrackWrapper />,
          },
          {
            path: "data-manager/*",
            lazy: async () => {
              try {
                const { DataManager } = await import("@/features/data-manager");
                return { Component: DataManager };
              } catch (error) {
                errorHandler(error);
                return { Component: ErrorFallback };
              }
            },
          },
          {
            path: "dashboard/*",
            lazy: async () => {
              try {
                const { DashboardContainer } = await import("@/features/dashboard/routes/DashboardContainer")
                return { Component: DashboardContainer }
              } catch (error) {
                errorHandler(error)
                return { Component: ErrorFallback }
              }
            },
          },
        ],
      },
      {
        path: "settings/*",
        Component: () => <SettingsLayout />,
        children: [
          {
            path: "user/*",
            lazy: async () => {
              try {
                const { UserSettingsRoutes } = await import(
                  "@/features/user-settings"
                );
                return { Component: UserSettingsRoutes };
              } catch (error) {
                errorHandler(error);
                return { Component: ErrorFallback };
              }
            },
          },
          {
            path: "org/:orgId/*",
            Component: () => <AdminRouteLayout />,
            children: [
              {
                path: "*",
                lazy: async () => {
                  try {
                    const { OrgSettingsRoutes } = await import(
                      "@/features/org-settings"
                    );
                    return { Component: OrgSettingsRoutes };
                  } catch (error) {
                    errorHandler(error);
                    return { Component: ErrorFallback };
                  }
                },
              },
            ],
          },
        ],
      },
    ],
  },
  // {
  //   path: "/logout",
  //   async action() {
  //     // We signout in a "resource route" that we can hit from a fetcher.Form
  //     await fakeAuthProvider.signout();
  //     return redirect("/");
  //   },
  // },
]);

export default AppRouter;
