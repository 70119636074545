import { Box, ListItem, Portal, UnorderedList } from "@chakra-ui/react";
import React, { useState, useEffect, KeyboardEvent, useRef } from "react";

interface ColumnDropdownProps {
  options: string[];
  coordinates: { x: number; y: number };
  onSelect: (option: string) => void;
  onBlur: () => void;
  dropdownRef: React.RefObject<HTMLDivElement>;
}

const ColumnDropdown: React.FC<ColumnDropdownProps> = ({
  options,
  coordinates,
  onSelect,
  onBlur,
  dropdownRef,
}) => {
  const [selectedOptionIndex, setSelectedOptionIndex] = useState<number>(0);
  const listRef = useRef<HTMLUListElement>(null);
  const itemRefs = useRef<(HTMLLIElement | null)[]>([]);

  // Ensure scroll into view when selection changes
  useEffect(() => {
    if (selectedOptionIndex >= 0 && itemRefs.current[selectedOptionIndex]) {
      itemRefs.current[selectedOptionIndex]?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [selectedOptionIndex]);

  useEffect(() => {
    // Reset refs array when options change
    itemRefs.current = itemRefs.current.slice(0, options.length);
  }, [options]);

  useEffect(() => {
    const handleKeyDown = (e: globalThis.KeyboardEvent) => {
      switch (e.key) {
        case "ArrowUp":
          e.preventDefault();
          setSelectedOptionIndex((prevIndex) =>
            prevIndex > 0 ? prevIndex - 1 : options.length - 1
          );
          break;
        case "ArrowDown":
          e.preventDefault();
          setSelectedOptionIndex((prevIndex) =>
            prevIndex < options.length - 1 ? prevIndex + 1 : 0
          );
          break;
        case "Enter":
          e.preventDefault();
          onSelect(options[selectedOptionIndex]);
          break;
        default:
          break;
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [dropdownRef, onSelect, options, selectedOptionIndex]);

  const handleClick = (e: React.MouseEvent<HTMLLIElement>, option: string) => {
    e.preventDefault();
    e.stopPropagation();
    onSelect(option);
  };

  const handleMouseEnter = (index: number) => {
    setSelectedOptionIndex(index);
  };

  const handleBlur = (e: React.FocusEvent) => {
    if (dropdownRef.current?.contains(e.target as Node)) return;
    onBlur();
  };

  if (!options.length) {
    return null;
  }

  return (
    <Portal>
      <Box
        className="fixed bg-white shadow-lg rounded overflow-hidden border mt-1 w-fit !z-[999]"
        ref={dropdownRef}
        onBlur={handleBlur}
        role="menu"
        style={{ top: coordinates.y, left: coordinates.x }}
        tabIndex={0}
      >
        <UnorderedList 
          className="list-none !m-0 p-0 max-h-[30vh] overflow-y-auto" 
          ref={listRef}
          styleType={"none"}
        >
          {options.map((option, index) => (
            <ListItem
              className={`p-2 hover:bg-gray-100 max-w-[400px] border-b cursor-pointer ${
                selectedOptionIndex === index ? "bg-gray-100" : ""
              }`}
              key={option}
              ref={(el) => (itemRefs.current[index] = el)}
              onClick={(e) => handleClick(e, option)}
              onMouseEnter={() => handleMouseEnter(index)}
              role="menuitem"
              tabIndex={-1}
            >
              {option}
            </ListItem>
          ))}
        </UnorderedList>
      </Box>
    </Portal>
  );
};

export default ColumnDropdown;
