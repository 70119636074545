import { createContext, useContext } from "react"

export interface SelectedColumn {
  column: string
  configId: string
}

interface MultiConfigContextType {
  selectedColumns: SelectedColumn[]
  currentConfigId: string
  registerSelectedColumn: (column: string, configId: string) => void
  unregisterSelectedColumn: (column: string, configId: string) => void
}

export const MultiConfigContext = createContext<MultiConfigContextType | null>(null)

export const useMultiConfig = () => {
  const context = useContext(MultiConfigContext)
  if (!context) {
    return null
  }
  return context
} 