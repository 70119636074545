import { Node } from "reactflow";

import { NodeType, WorkflowNodeSchema } from "@/features/workflow-studio/types";

export const updateParamsInWfNodes = async (
  workflowNodes: WorkflowNodeSchema[],
  setNodes: (
    payload: Node<any>[] | ((nodes: Node<any>[]) => Node<any>[])
  ) => void
) => {
  if (!workflowNodes || workflowNodes.length === 0) return Promise.resolve();

  const findMatchingNode = (nodeId: string) => {
    return workflowNodes.find((n: WorkflowNodeSchema) => n.uiNodeId === nodeId);
  };

  const updateNodeParameters = (node: Node<any>, newNodeParams: any[]) => {
    const nodeData = node.data as NodeType;
    return nodeData.parameters.map((param) => {
      const matchedParam = newNodeParams.find(
        (np) => np.id === param.parameterId
      );
      return {
        ...param,
        value: matchedParam ? matchedParam.value : undefined,
      };
    });
  };

  const updateNodeData = (node: Node<any>, newNode: WorkflowNodeSchema) => {
    const updatedNodeData = {
      ...node.data,
      workflowNodeId: newNode.workflowNodeId,
    };

    if (node.type === "group-node") {
      return { ...node, data: updatedNodeData };
    }
    return {
      ...node,
      data: {
        ...updatedNodeData,
        parameters: updateNodeParameters(node, newNode.nodeParameters),
      } as NodeType,
    };
  };

  const updateNodes = (nds: Node<any>[]) => {
    return nds.map((node) => {
      const newNode = findMatchingNode(node.id);
      return newNode ? updateNodeData(node, newNode) : node;
    });
  };

  return Promise.resolve(setNodes(updateNodes));
};
