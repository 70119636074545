import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { ToastMessages } from "@/constants/toast-constants.ts";
import {
  ACCESS_MODE,
  FETCH_TYPE,
  IActiveRequests,
  SamplingTechnique,
  Sort,
  TableDataColumns,
  TransformationStep,
  ViewFiltersRequest,
} from "@/features/data-transformation";
import {
  SamplingTechniquesResponse,
  SelectedColumn,
  IActiveUser,
} from "@/features/data-transformation/types";
import { EDA_STATUS } from "@/features/data-transformation/utils";

import { extraReducers } from "./extra-reducers";

// Define a type for the slice state
export interface DataTransformationState {
  transformationPanel: boolean;
  tableLoading: boolean;
  selectedStep: TransformationStep | null;
  selectedColumn: SelectedColumn | null;
  transformationSteps: TransformationStep[];
  tempTransformationSteps: TransformationStep[];
  compiledTransformationSteps: TransformationStep[];
  stepsInfo: Partial<TransformationStep>[];
  isFilterMenuOpen: boolean;
  isStepFormFilterMenuOpen: boolean;
  currentOpenFilter: string | null;
  viewFilters: ViewFiltersRequest[];
  tempFilter: ViewFiltersRequest | null;
  currentStepFilters: ViewFiltersRequest[];
  stepTempFilters: ViewFiltersRequest | null;
  fullData: boolean;
  edaStatus: EDA_STATUS;
  samplingTechniques: SamplingTechniquesResponse | null;
  currentSampling: SamplingTechnique | null;
  tableSizeMetaData: {
    column?: string;
    rows?: string;
  };
  activeUser: IActiveUser | null;
  statusRequestId: string | null | undefined;
  columns: TableDataColumns[];
  currentlyAddedStep: TransformationStep | null;
  sorting: Sort[];
  stepsFetched: boolean;
  saveEditCalls: number;
  title: string | null;
  edaAccessMode: ACCESS_MODE | null;
  samplingAndStepsHash: string | null;
  userHasWriteAccess: boolean;
  isExecuteInProgress: boolean;
  activeRequests: IActiveRequests[];
  triggerTableFetch: boolean;
  triggerStepsFetch: boolean;
  triggerSaveExecuteFetch: boolean;
  triggerSuggestionsFetch: boolean;
  triggerExploreFetch: boolean;
  triggerOpenStepPanel: boolean;
  statusMessage?: string;
  stepMemoryWarning: TransformationStep | null;
  toastMessage?: ToastMessages;
}

// Define the initial state using that type
const initialState: DataTransformationState = {
  transformationPanel: false,
  tableLoading: true,
  selectedStep: null,
  selectedColumn: null,
  transformationSteps: [],
  compiledTransformationSteps: [],
  stepsInfo: [],
  currentOpenFilter: null,
  currentStepFilters: [],
  sorting: [],
  stepTempFilters: null,
  isFilterMenuOpen: false,
  isStepFormFilterMenuOpen: false,
  viewFilters: [],
  tempFilter: null,
  fullData: false,
  edaStatus: EDA_STATUS.Loading,
  samplingTechniques: null,
  currentSampling: null,
  tableSizeMetaData: {},
  columns: [],
  tempTransformationSteps: [],
  currentlyAddedStep: null,
  statusRequestId: undefined,
  stepsFetched: false,
  saveEditCalls: 0,
  edaAccessMode: null,
  userHasWriteAccess: false,
  title: null,
  activeRequests: [],
  isExecuteInProgress: false,
  triggerTableFetch: false,
  samplingAndStepsHash: null,
  triggerStepsFetch: false,
  triggerExploreFetch: false,
  triggerOpenStepPanel: false,
  triggerSaveExecuteFetch: false,
  triggerSuggestionsFetch: false,
  statusMessage: undefined,
  stepMemoryWarning: null,
  activeUser: null,
  toastMessage: undefined,
};

const dataTransformationSlice = createSlice({
  name: "data-transformation",
  initialState,
  reducers: {
    setTransformationPanel: (state, action: PayloadAction<boolean>) => {
      state.transformationPanel = action.payload;
    },
    setSelectedStep: (
      state,
      action: PayloadAction<TransformationStep | null>
    ) => {
      state.selectedStep = action.payload;
      if (action.payload) {
        state.currentStepFilters = action.payload.config?.filters ?? [];
      } else {
        state.stepTempFilters = null;
      }
    },
    setSelectedStepTempFilter: (
      state,
      action: PayloadAction<ViewFiltersRequest | null>
    ) => {
      state.stepTempFilters = action.payload;
    },
    setSelectedColumn: (
      state,
      action: PayloadAction<SelectedColumn | null>
    ) => {
      state.selectedColumn = action.payload;
    },
    setTransformationSteps: (
      state,
      action: PayloadAction<TransformationStep[]>
    ) => {
      state.transformationSteps = action.payload;
      state.compiledTransformationSteps = [
        ...action.payload,
        ...state.tempTransformationSteps,
      ];
    },
    setIsFilterMenuOpen: (state, action: PayloadAction<boolean>) => {
      state.isFilterMenuOpen = action.payload;
      if (action.payload) {
        state.isStepFormFilterMenuOpen = false;
      }
    },
    setIsStepFormFilterMenuOpen: (state, action: PayloadAction<boolean>) => {
      state.isStepFormFilterMenuOpen = action.payload;
      if (action.payload) {
        state.isFilterMenuOpen = false;
      }
    },
    setTempFilter: (
      state,
      action: PayloadAction<ViewFiltersRequest | null>
    ) => {
      state.tempFilter = action.payload;
    },
    setViewFilters: (state, action: PayloadAction<ViewFiltersRequest[]>) => {
      state.viewFilters = action.payload;
    },
    setCurrentOpenFilter: (state, action: PayloadAction<string | null>) => {
      state.currentOpenFilter = action.payload;
    },
    setFullData: (state, action: PayloadAction<boolean>) => {
      state.fullData = action.payload;
    },
    setEdaStatus: (state, action: PayloadAction<EDA_STATUS>) => {
      state.edaStatus = action.payload;
    },
    setTableSizeMetaData: (
      state,
      action: PayloadAction<{ column?: string; rows?: string }>
    ) => {
      state.tableSizeMetaData = {
        ...state.tableSizeMetaData,
        ...action.payload,
      };
    },
    setCurrentSampling: (
      state,
      action: PayloadAction<SamplingTechnique | null>
    ) => {
      state.currentSampling = action.payload;
    },
    setColumns: (state, action: PayloadAction<TableDataColumns[]>) => {
      state.columns = action.payload;
    },
    setCurrentlyAddedStep: (
      state,
      action: PayloadAction<TransformationStep | null>
    ) => {
      if (action.payload) {
        state.currentStepFilters = action.payload.config?.filters ?? [];
      }

      state.currentlyAddedStep = action.payload;
    },
    setCurrentStepFilters: (
      state,
      action: PayloadAction<ViewFiltersRequest[]>
    ) => {
      state.currentStepFilters = action.payload;
    },
    setTempTransformationSteps: (
      state,
      action: PayloadAction<TransformationStep[]>
    ) => {
      state.tempTransformationSteps = action.payload;
      const compliedSteps = [...state.compiledTransformationSteps];
      const updateTemplValuesInCompliedSteps = compliedSteps.map((_filter) => {
        const step = action.payload.find(
          (_step) => _step.transformationStepId == _filter.transformationStepId
        )!;
        return step;
      });
      state.compiledTransformationSteps = updateTemplValuesInCompliedSteps;
    },
    setRequestId: (state, action: PayloadAction<string | undefined | null>) => {
      state.statusRequestId = action.payload;
    },
    setCompiledTransformationSteps: (
      state,
      action: PayloadAction<TransformationStep[]>
    ) => {
      state.compiledTransformationSteps = action.payload;
    },
    setSorting: (state, action: PayloadAction<Sort[]>) => {
      state.sorting = action.payload;
    },
    setTableLoading: (state, action: PayloadAction<boolean>) => {
      state.tableLoading = action.payload;
    },
    setEdaAccessMode: (state, action: PayloadAction<ACCESS_MODE | null>) => {
      state.edaAccessMode = action.payload;
    },
    setUserAccess: (state, action: PayloadAction<boolean>) => {
      state.userHasWriteAccess = action.payload;
    },
    setIsExecuteInProgress: (state, action: PayloadAction<boolean>) => {
      state.isExecuteInProgress = action.payload;
    },
    setEdaMetaData: (
      state,
      action: PayloadAction<{
        accessMode: ACCESS_MODE;
        userHasWriteAccess: boolean;
        title?: string;
        activeRequests?: IActiveRequests[];
        activeUser: IActiveUser | null;
      }>
    ) => {
      state.edaAccessMode = action.payload.accessMode;
      state.userHasWriteAccess = action.payload.userHasWriteAccess;
      state.activeUser = action.payload.activeUser;

      if (action.payload.title) state.title = action.payload.title;
      if (action.payload.activeRequests)
        state.activeRequests = action.payload.activeRequests;
    },
    triggerFetch: (state, action: PayloadAction<FETCH_TYPE | undefined>) => {
      state[`trigger${action.payload ?? FETCH_TYPE.TABLE}Fetch`] = true;
    },
    resetFetchTrigger: (
      state,
      action: PayloadAction<FETCH_TYPE | undefined>
    ) => {
      state[`trigger${action.payload ?? FETCH_TYPE.TABLE}Fetch`] = false;
    },
    setOpenStepPanel: (state, action: PayloadAction<boolean>) => {
      state.triggerOpenStepPanel = action.payload;
    },
    setToastMessage: (
      state,
      action: PayloadAction<undefined | ToastMessages>
    ) => {
      state.toastMessage = action.payload;
    },
    setStatusMessage: (state, action: PayloadAction<string | undefined>) => {
      state.statusMessage = action.payload;
    },
    resetState: () => initialState,
  },
  extraReducers: extraReducers,
});

export const {
  setTransformationPanel,
  setSelectedStep,
  setSelectedColumn,
  setTransformationSteps,
  setIsFilterMenuOpen,
  setIsStepFormFilterMenuOpen,
  setViewFilters,
  setTempFilter,
  setCurrentOpenFilter,
  setTableLoading,
  setToastMessage,
  setSorting,
  setCurrentStepFilters,
  setIsExecuteInProgress,
  setEdaMetaData,
  setEdaAccessMode,
  setTableSizeMetaData,
  setCurrentSampling,
  setColumns,
  resetState,
  setCurrentlyAddedStep,
  setTempTransformationSteps,
  setRequestId,
  setCompiledTransformationSteps,
  setSelectedStepTempFilter,
  triggerFetch,
  resetFetchTrigger,
  setStatusMessage,
  setOpenStepPanel,
} = dataTransformationSlice.actions;

export const dataTransformationReducer = dataTransformationSlice.reducer;
