import { Portal } from "@chakra-ui/portal";
import { Flex, IconButton, Text } from "@chakra-ui/react";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { isEmpty } from "lodash";
import React, { useContext, useMemo, useRef } from "react";
import { MdsChevronLeftRound } from "react-icons-with-materialsymbols/mds";
import { useDispatch, useSelector } from "react-redux";

import { MoreVert } from "@/components/icons/MoreVert.tsx";
import { ToastType, useShowToast } from "@/components/toast";
import { Menu, MenuButton, MenuItem, MenuList } from "@/design/components/menu";
import {
  compiledTransformationSteps,
  EdaMetaDataContext,
  executeInProgress,
  TransformationStep,
  setRequestId,
  setStatusMessage,
  setTableLoading,
  tableLoading,
  useAddStepsMutation,
  seperateFilters,
  sorting,
  viewFilters,
} from "@/features/data-transformation";
import { useReadOnlyMode } from "@/features/data-transformation/hooks";
import { exportToJson } from "@/utils/object-downloader.ts";

type StepsPanelHeaderProps = {
  isOpen: boolean;
  onToggle: () => void;
};

const MotionIconButton = motion(IconButton);
export const StepsPanelHeader = ({
  isOpen,
  onToggle,
}: StepsPanelHeaderProps) => {
  const dispatch = useDispatch();
  const toast = useShowToast();

  const sort = useSelector(sorting);
  const viewOnlyFilters = useSelector(viewFilters);

  const isExecuting = useSelector(executeInProgress);
  const { hasWriteAccess } = useReadOnlyMode();

  const isTableLoading = useSelector(tableLoading);
  const metaData = useContext(EdaMetaDataContext);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const stepsList = useSelector(compiledTransformationSteps);

  const [addStepApi] = useAddStepsMutation();

  const validSteps = useMemo(
    () => stepsList.filter((step) => step.activeStatus).length,
    [stepsList]
  );

  const exportSteps = () => {
    const listWithoutDeletedSteps = stepsList.filter(
      (_step) => _step.activeStatus
    );
    exportToJson(listWithoutDeletedSteps, "transformation-steps");
  };

  const onImport = () => {
    fileInputRef?.current?.click();
  };

  const onFileAdded = (event: React.SyntheticEvent<EventTarget>) => {
    const target = event.target as HTMLInputElement;
    const file = target.files?.[0];
    const fileName = file?.name;
    try {
      if (file) {
        const reader = new FileReader();
        reader.onload = onLoadJson;
        reader.readAsText(file);
      }
    } catch (e) {
      alert(`Unable to import steps from ${fileName ?? "file"}`);
    }
  };

  const handleEmptyJsonImport = () => {
    dispatch(setTableLoading(false));
    toast({
      title: "There are no steps to import present in the uploaded file",
      status: ToastType.Error,
      duration: 2000,
    });
  };

  const onLoadJson = async (e: ProgressEvent<FileReader>) => {
    try {
      dispatch(setStatusMessage("Loading dataset…"));
      dispatch(setTableLoading(true));
      const content = e.target?.result as string;
      let steps = JSON.parse(content) as Partial<TransformationStep>[];
      steps = steps.map((step, idx) => ({
        ...step,
        stepOrder: stepsList.length + idx + 1,
      }));

      if (isEmpty(steps)) {
        handleEmptyJsonImport();
        return;
      }

      await addSteps(steps);

      toast({
        title: `${steps.length} steps imported`,
        status: ToastType.Success,
        duration: 2000,
      });
    } catch (err) {
      dispatch(setTableLoading(false));
      toast({
        title:
          "Adding imported Steps failed, the format of imported file is wrong.",
        status: ToastType.Error,
        duration: 2000,
      });
    }
  };

  const addSteps = async (steps: Partial<TransformationStep>[]) => {
    try {
      const res = await addStepApi({
        steps: steps,
        analysisId: metaData.analysisId!,
        edaId: metaData.edaId!,
        viewFilters: seperateFilters(viewOnlyFilters),
        sort,
      }).unwrap();
      const reqId = res.response.data?.requestId ?? null;
      dispatch(setRequestId(reqId));
    } catch (e) {
      toast({
        title: "Adding imported Steps failed, missing values in imported JSON",
        status: ToastType.Error,
        duration: 2000,
      });
      dispatch(setTableLoading(false));
    }
  };

  const isExportDisabled = isTableLoading || validSteps == 0 || isExecuting;
  const isImportDisabled = !hasWriteAccess || isExecuting || isTableLoading;

  return (
    <AnimatePresence>
      <Flex
        className={clsx(
          "w-full h-min items-center flex-none overflow-hidden max-w-full bg-white p-0.5",
          isOpen ? "justify-between" : "justify-center"
        )}
      >
        {isOpen && (
          <Text
            className={clsx(
              "text-gray-800 font-medium text-[15px] tracking-[-0.3px] ml-4 max-w-full truncate"
            )}
            as={motion.span}
            animate={{
              opacity: 1,
            }}
            exit={{ opacity: 0 }}
            initial={{ opacity: 1 }}
          >
            Transformation Steps {validSteps > 0 && <>({validSteps})</>}
          </Text>
        )}
        <input
          type="file"
          ref={fileInputRef}
          accept=".json"
          style={{ display: "none" }}
          onInput={onFileAdded}
          key="input"
        />

        <Flex className="w-fit justify-center items-center overflow-hidden max-w-full">
          {isOpen && (
            <Menu isLazy>
              {() => {
                return (
                  <>
                    <MenuButton
                      as={MotionIconButton}
                      color="gray.500"
                      animate={{
                        opacity: 1,
                      }}
                      aria-label="copy"
                      colorScheme="secondary"
                      exit={{ opacity: 0 }}
                      icon={<MoreVert />}
                      initial={{ opacity: 1 }}
                      size="lgSm"
                      variant="ghost"
                    ></MenuButton>
                    <Portal>
                      <MenuList
                        className="-mt-1 w-10"
                        zIndex={999}
                        borderRadius="3px"
                        border="1px solid"
                        borderColor="gray.300"
                      >
                        <MenuItem
                          className="text-gray-900"
                          onClick={exportSteps}
                          isDisabled={isExportDisabled}
                        >
                          Export Steps
                        </MenuItem>
                        <MenuItem
                          className="text-gray-900"
                          onClick={onImport}
                          isDisabled={isImportDisabled}
                        >
                          Import Steps
                        </MenuItem>
                      </MenuList>
                    </Portal>
                  </>
                );
              }}
            </Menu>
          )}
          <IconButton
            as={motion.button}
            color="gray.500"
            animate={{
              rotate: !isOpen ? -180 : 0,
              transition: { duration: 0.2, type: "spring" },
            }}
            aria-label="collapse"
            colorScheme="secondary"
            icon={<MdsChevronLeftRound />}
            onClick={onToggle}
            size="lgSm"
            variant="ghost"
          />
        </Flex>
      </Flex>
    </AnimatePresence>
  );
};
