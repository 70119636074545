import { Box, Button } from "@chakra-ui/react";

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@/design/components/modal";
import { useAppDispatch } from "@/reduxHooks";
import { closeModal } from "@/slices/modal-slice";

import { setCurrentWfActiveUser, setEditingAllowed, setIsInactiveModalOpen } from "../../redux";

export const TimedOutModal: React.FC = () => {
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(closeModal());
    dispatch(setEditingAllowed(false));
    dispatch(setIsInactiveModalOpen(false));
    dispatch(
      setCurrentWfActiveUser({
        isCurrentUser: false,
        user: null,
        status: null,
        timeSinceLastActivity: null,
      })
    );
  };

  return (
    <Modal isOpen={true} isCentered size="md" onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader className="flex gap-3 items-start justify-between border-b !ml-0 !pb-3 !pl-5">
          <Box className="w-fit text-lg text-gray-800 font-medium">
            Edit session closed
          </Box>
          <ModalCloseButton className="!relative !top-0 !right-0" />
        </ModalHeader>
        <ModalBody className="!px-4 py-3">
          <div className="space-y-4">
            <p>
              You have been away for a while, so your editing session was
              closed.
            </p>
            <div className="flex justify-end space-x-2">
              <Button colorScheme="dark" onClick={handleClose}>
                Continue
              </Button>
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
