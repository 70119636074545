import { Button, Flex, Input } from "@chakra-ui/react";
import React from "react";
import { useForm } from "react-hook-form";
import { Node, ReactFlowJsonObject } from "reactflow";
import { ZodError, z } from "zod";

import { useShowToast } from "@/components/toast";
import {
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
  FormLabel,
} from "@/design/components/form";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@/design/components/modal";
import { useAppDispatch, useAppSelector } from "@/reduxHooks.ts";
import { closeModal } from "@/slices/modal-slice.ts";

import { useSaveWorkflowMutation } from "../../api";
import { NodeType } from "../../types";
import { ConvertFlowtoWorkflowPayload } from "../../utils/transform-response";

const renameNodeFormSchema = z.object({
  nodeDisplayName: z
    .string()
    .regex(/^[a-zA-Z0-9\s,!?.,()'"''""]*$/, {
      message: "Name must contain only alphanumeric characters",
    })
    .max(128, { message: "Name must be at most 128 characters long" })
    .refine((data) => data.trim() !== "", {
      message: "Name cannot be empty",
    }),
});

export interface RenameNodeSchema {
  nodeDisplayName: string;
}

const RenameNodeModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const toast = useShowToast(undefined, undefined, true);
  const { node, setNodes, instance, params } = useAppSelector(
    (state) => state.rootReducer.modals.modalProps
  );
  const [saveWorkflow, { isLoading }] = useSaveWorkflowMutation();

  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
  } = useForm<RenameNodeSchema>({
    defaultValues: {
      nodeDisplayName: node.data.displayName ?? node.data.name ?? "",
    },
  });

  const renameApi = async (data: RenameNodeSchema) => {
    const workflow = ConvertFlowtoWorkflowPayload(
      instance as ReactFlowJsonObject
    );
    const updatedWF = {
      ...workflow,
      nodes: workflow.nodes.map((n) => {
        if (node.id === n.uiNodeId) {
          return {
            ...n,
            displayName: data.nodeDisplayName,
          };
        }
        return n;
      }),
    };
    console.log(updatedWF);
    try {
      const res = await saveWorkflow({
        analysisId: params.analysisId!,
        workflowId: params.editorId!,
        workflow: updatedWF,
      }).unwrap();
      const updatedNode = res.response.data?.workflows[0].workflowNodes.find(
        (n) => n.uiNodeId === node.id
      );
      if (updatedNode) {
        setNodes((prev: Node[]) => {
          return prev.map((n) => {
            if (n.id === node.id) {
              return {
                ...n,
                data: {
                  ...n.data,
                  displayName: data.nodeDisplayName,
                } as NodeType,
              };
            }
            return n;
          });
        });
        toast({
          status: "success",
          title: "Node renamed successfully",
        });
      }
    } catch (error) {
      toast({
        status: "error",
        title: "Failed to rename node",
      });
    }
  };

  const onSubmit = async (data: RenameNodeSchema) => {
    try {
      const validatedData = await renameNodeFormSchema.parseAsync(data);
      await renameApi(validatedData);
      dispatch(closeModal());
    } catch (error) {
      if (error instanceof ZodError) {
        error.errors.forEach((err) => {
          setError(err.path[0] as keyof RenameNodeSchema, {
            message: err.message,
          });
        });
      }
    }
  };

  const onClose = () => {
    if (isLoading) return;
    dispatch(closeModal());
  };

  const disableForm = isLoading;

  return (
    <Modal isOpen={true} isCentered size="lg" onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Rename Node</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form
            /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
            onSubmit={handleSubmit(onSubmit)}
            className="!px-5 gap-10 flex flex-col"
          >
            <FormControl isInvalid={!!errors.nodeDisplayName} isRequired>
              <FormLabel fontSize="sm">Enter Node Name</FormLabel>
              <Input
                className="disabled:bg-gray-300 disabled:text-gray-900"
                isDisabled={disableForm}
                type="text"
                {...register("nodeDisplayName")}
              />
              <FormErrorMessage>
                <FormErrorIcon />
                {errors.nodeDisplayName?.message}
              </FormErrorMessage>
            </FormControl>
            <Flex justify="flex-end">
              <Button
                className="mr-2"
                colorScheme="dark"
                isDisabled={disableForm}
                onClick={onClose}
                size={"sm"}
                variant="outline"
              >
                Cancel
              </Button>
              <Button
                className="mr-2"
                colorScheme="dark"
                isDisabled={disableForm}
                isLoading={disableForm}
                size={"sm"}
                type="submit"
              >
                Save
              </Button>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default RenameNodeModal;
