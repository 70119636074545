import { createApi, retry } from "@reduxjs/toolkit/query/react"
import { baseQuery } from "@/utils/baseQuery"
import { getMSBaseUrl } from "@/utils/baseUrls"
import { MICRO_SERVICES } from "@/utils/enums"

const staggeredBaseQuery = retry(
  async (args, api, extraOptions) => {
    
    const baseQueryFn = baseQuery({
      baseUrl: getMSBaseUrl(MICRO_SERVICES.DASHBOARD),
      defaultHeaders: {
        "X-CLIENT-ID": import.meta.env.VITE_CLIENT_ID
      }
    })
    
    return baseQueryFn(args, api, extraOptions)
  },
  {
    maxRetries: 0
  }
)

export const dashboardAPI = createApi({
  reducerPath: "dashboardAPI",
  baseQuery: staggeredBaseQuery,
  tagTypes: ["Dashboards"],
  endpoints: () => ({})
}) 