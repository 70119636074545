import { Button, Flex, useDisclosure } from "@chakra-ui/react";
import { useContext, useEffect, useMemo, useState } from "react";
import { MdsSyncRound } from "react-icons-with-materialsymbols/mds";
import { useDispatch, useSelector } from "react-redux";

import { ProtectedComponent } from "@/components/protected-component/protected-component";
import { Icon } from "@/design/components/icon";
import {
  activeUser,
  EdaMetaDataContext,
  FETCH_TYPE,
  RefreshAPI,
  ORIGIN,
  samplingAndStepsHash,
  setEdaMetaData,
  triggerFetch,
} from "@/features/data-transformation";
import {
  useGetReadonlyModeStatusQuery,
  useLazyGetReadonlyModeStatusQuery,
} from "@/features/data-transformation/api";
import { useReadOnlyMode } from "@/features/data-transformation/hooks";
import { useIsBackground } from "@/features/data-transformation/hooks/useIsBackground.ts";
import {
  getEditingAllowed,
  getWorkflowAccessMode,
} from "@/features/workflow-studio/redux/workflow-slice.ts";
import { useAppSelector } from "@/reduxHooks.ts";
import { openModal, ModalTypes } from "@/slices/modal-slice";
import { CATEGORY, PERMISSIONS, WF_ACCESS_MODE } from "@/utils/enums";

import { EditingMessage } from "./editing-message.tsx";
import { PopoverMessageButton } from "./popover-message.tsx";

export const ReadOnly = ({ isTransforming, isExecuting }: { isTransforming: boolean, isExecuting: boolean }) => {
  const dispatch = useDispatch();
  const isWFEditAllowed = useAppSelector(getEditingAllowed);
  const wfAccessMode = useAppSelector(getWorkflowAccessMode);

  const { edaId, analysisId, edaFrom } = useContext(EdaMetaDataContext);
  const currentHash = useSelector(samplingAndStepsHash);
  const currentActiveUser = useSelector(activeUser);
  const { isReadOnlyEda: showReadonlyMessage } = useReadOnlyMode();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showRefresh, setShowRefresh] = useState(false);
  const isBackground = useIsBackground();
  const isTransformNode = edaFrom === ORIGIN.Workflow;

  const { data } = useGetReadonlyModeStatusQuery(
    { edaId: edaId!, analysisId: analysisId! },
    {
      pollingInterval: isTransformNode ? 10000 : 5000,
      skip: !edaId,
    }
  );

  const showTransformBtn = useMemo(() => {
    if (isTransformNode)
      return isWFEditAllowed || wfAccessMode === WF_ACCESS_MODE.LOCKOUT;
    return true;
  }, [isWFEditAllowed, wfAccessMode]);

  const [refreshAPI] = useLazyGetReadonlyModeStatusQuery();

  const updateStatus = (responseData: RefreshAPI) => {
    dispatch(
      setEdaMetaData({
        accessMode: responseData.edaAccessMode,
        userHasWriteAccess: responseData.userHasWriteAccess,
        activeUser: responseData.activeUser,
      })
    );
  };

  const fetchStatus = async () => {
    try {
      const res = await refreshAPI({
        edaId: edaId!,
        analysisId: analysisId!,
      }).unwrap();
      const responseData = res.response.data!;
      updateStatus(responseData);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    // Trigger for editing user
    const checkUserStatus = !isBackground && !showReadonlyMessage && edaId;

    if (checkUserStatus) {
      fetchStatus();
    }
  }, [isBackground]);

  useEffect(() => {
    if (!data) return;
    const responseData = data.response.data!;
    const newHash = responseData.stepAndSamplingHash;
    updateStatus(responseData);

    // Check for session timeout warning conditions
    const user = responseData?.activeUser;
    const timeSinceLastActivity = responseData.timeSinceLastActivity?.seconds;
    const timeout = responseData?.timeout;

    if (
      user?.isCurrentUser &&
      timeout &&
      timeSinceLastActivity &&
      timeout - timeSinceLastActivity <= 60 // 1 minute remaining
    ) {
      dispatch(
        openModal({
          modalType: ModalTypes.EDA_SESSION_TIMEOUT_WARNING,
          modalProps: {
            analysisId,
            edaId,
            initialSeconds: Math.round(timeSinceLastActivity),
            timeOut: timeout,
          },
        })
      );
    }

    if (newHash && currentHash) {
      const isDifferent = newHash !== currentHash;
      if (isDifferent && !showRefresh) {
        setShowRefresh(true);
      }
    }
  }, [data, currentHash, dispatch, analysisId, edaId]);

  const onRefresh = () => {
    dispatch(triggerFetch(FETCH_TYPE.STEPS));
    setShowRefresh(false);
  };

    return (
      <Flex className="items-center">
        {showReadonlyMessage && <EditingMessage user={currentActiveUser} />}
        {showRefresh && !isTransforming && !isExecuting && (
          <Button
            className="mr-2"
            bgColor="green.500"
            colorScheme="secondary"
            iconSpacing="2px"
            onClick={onRefresh}
            rightIcon={<Icon as={MdsSyncRound} size="sm" />}
            size="sm"
            variant="solid"
          >
            Sync Changes
          </Button>
        )}
        {showReadonlyMessage && showTransformBtn && (
          <ProtectedComponent
            requiredPermission={PERMISSIONS.WRITE}
            type={CATEGORY.Analysis}
            id={analysisId}
          >
            <PopoverMessageButton
              isOpen={isOpen}
              disabled={showRefresh}
              onClose={onClose}
              onOpen={onOpen}
            />
          </ProtectedComponent>
        )}
    </Flex>
  );
};
