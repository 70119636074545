import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";

import { AnalysesSchema } from "@/features/ws-manager/types";
import { RootState } from "@/store";
import { WF_ACCESS_MODE } from "@/utils/enums";

import {
  WFUserSchema,
  WorkflowNodeStatusSchema,
  WorkflowStateSchema,
  WorkflowStatusSchema,
} from "../types/workflow-types";
import { NODE_STATUS } from "../utils/constants";

// Define a type for the slice state
interface WorkflowState {
  workflowId: string | null;
  workflowRunId?: string | null;
  referenceRunId?: string;
  workflowStatus: WorkflowStatusSchema | null;
  workflowRunStatus?: NODE_STATUS | null;
  isEditingAllowed?: boolean;
  currentWfUser: WFUserSchema | null;
  workflowAccessMode: WF_ACCESS_MODE | null;
  workflowState: WorkflowStateSchema | null;
  isInactiveModalOpen: boolean;
  analysisId?: string;
  //  TODO :remove below state - used just for milestone
  addedAnalyses: AnalysesSchema[];
}

// Define the initial state using that type
const initialState: WorkflowState = {
  workflowId: null,
  analysisId: undefined,
  addedAnalyses: [],
  workflowRunStatus: null,
  workflowStatus: null,
  workflowRunId: null,
  isEditingAllowed: true,
  currentWfUser: null,
  workflowState: null,
  workflowAccessMode: WF_ACCESS_MODE.READ_ONLY,
  isInactiveModalOpen: false,
};

const workflowSlice = createSlice({
  name: "workflow",
  initialState,
  reducers: {
    setWorkFlowId: (state, action: PayloadAction<string | null>) => {
      state.workflowId = action.payload;
    },
    setWorkflowRunId: (state, action: PayloadAction<string>) => {
      state.workflowRunId = action.payload;
    },
    setAnalysisId: (state, action: PayloadAction<string>) => {
      state.analysisId = action.payload;
    },
    setWorkflowRunStatus: (state, action: PayloadAction<NODE_STATUS>) => {
      state.workflowRunStatus = action.payload;
    },
    setReferenceRunId: (state, action: PayloadAction<string>) => {
      state.referenceRunId = action.payload;
    },
    setworkflowStatus: (
      state,
      action: PayloadAction<WorkflowStatusSchema | null>
    ) => {
      state.workflowStatus = action.payload;
      state.workflowRunStatus = action.payload?.workflowRunStatus;
    },
    resetNodeStatuesinWorkflowRunStatus: (
      state,
      action: PayloadAction<WorkflowNodeStatusSchema[] | undefined>
    ) => {
      action.payload &&
        (state.workflowStatus = {
          ...state.workflowStatus!,
          workflowNodeStatus: action.payload,
        });
    },
    // TODO: remove below state - used just for milestone
    addAnalysis: (state, action: PayloadAction<AnalysesSchema>) => {
      state.addedAnalyses.push(action.payload);
    },
    addAnalysisList: (state, action: PayloadAction<AnalysesSchema[]>) => {
      state.addedAnalyses = action.payload;
    },
    setEditingAllowed: (state, action: PayloadAction<boolean>) => {
      if (action.payload && state.currentWfUser?.isCurrentUser) {
        state.isEditingAllowed = action.payload;
      } else {
        state.isEditingAllowed = false;
      }
    },
    setCurrentWfActiveUser: (
      state,
      action: PayloadAction<WFUserSchema | null>
    ) => {
      state.currentWfUser = action.payload;
    },
    setWorkflowState: (state, action: PayloadAction<WorkflowStateSchema>) => {
      state.workflowState = action.payload;
    },
    setWorkflowAccessMode: (state, action: PayloadAction<WF_ACCESS_MODE>) => {
      state.workflowAccessMode = action.payload;
    },
    setIsInactiveModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isInactiveModalOpen = action.payload;
    },
  },
});

export const {
  setWorkFlowId,
  setAnalysisId,
  addAnalysis,
  addAnalysisList,
  setWorkflowRunStatus,
  setworkflowStatus,
  setWorkflowRunId,
  setReferenceRunId,
  setEditingAllowed,
  resetNodeStatuesinWorkflowRunStatus,
  setCurrentWfActiveUser,
  setWorkflowState,
  setWorkflowAccessMode,
  setIsInactiveModalOpen,
} = workflowSlice.actions;

export const currentWorkflowRunId = (state: RootState) =>
  state.workflowReducer.workflowRunId;

export const currentWorkflowId = (state: RootState) =>
  state.workflowReducer.workflowId;

export const workflowStatus = (state: RootState) =>
  state.workflowReducer.workflowStatus;

export const workflowRunningStatus = (state: RootState) =>
  state.workflowReducer.workflowRunStatus;

export const currentReferenceRunId = (state: RootState) =>
  state.workflowReducer.referenceRunId;

// use createSelector to get status of single node using node id from workflowRunStatus
export const getNodeStatus = (nodeId: string) =>
  createSelector(
    workflowStatus,
    (wf) =>
      wf?.workflowNodeStatus.find(
        (node: WorkflowNodeStatusSchema) => node.uiNodeId === nodeId
      )
  );

// TODO: remove below state - used just for milestone
export const addedAnalyses = (state: RootState) =>
  state.workflowReducer.addedAnalyses;

export const getEditingAllowed = (state: RootState) =>
  state.workflowReducer.isEditingAllowed;

export const currentWfActiveUser = (state: RootState) =>
  state.workflowReducer.currentWfUser;

export const getWorkflowState = (state: RootState) =>
  state.workflowReducer.workflowState;

export const isCurrentUserActivelyEditing = (state: RootState) =>
  state.workflowReducer.currentWfUser?.isCurrentUser;

export const getWorkflowAccessMode = (state: RootState) =>
  state.workflowReducer.workflowAccessMode;

export const getIsInactiveModalOpen = (state: RootState) =>
  state.workflowReducer.isInactiveModalOpen;

export const workflowReducer = workflowSlice.reducer;
