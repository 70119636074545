import { Flex, Text, Button } from "@chakra-ui/react";
import { useCallback } from "react";
import { MdsReplayRound } from "react-icons-with-materialsymbols/mds";

import { getEditingAllowed } from "@/features/workflow-studio/redux/workflow-slice";
import { useAppDispatch, useAppSelector } from "@/reduxHooks";

import {
  currentActiveVersion,
  isLLMEditable,
  setActiveVersion,
} from "../../redux";
import { AIModel, TextGenVersion } from "../../types";

const SettingsHeader = ({ allAIModels }: { allAIModels: AIModel[] }) => {
  const isLLMEditDisabled = useAppSelector(isLLMEditable);
  const isWFEditAllowed = useAppSelector(getEditingAllowed);
  const isLLMEditAllowed =  isWFEditAllowed && !isLLMEditDisabled;
  const activeVersion = useAppSelector(currentActiveVersion);

  const dispatch = useAppDispatch();

  const resetModel = useCallback(
    () =>
      dispatch(
        setActiveVersion({
          ...activeVersion,
          aiModel: allAIModels[0],
        } as TextGenVersion)
      ),
    [activeVersion, allAIModels, dispatch]
  );
  return (
    <Flex
      className="px-3 pb-2"
      align={"center"}
      justify={"space-between"}
      flex={0.1}
      shrink={0}
    >
      <Text className="font-semibold uppercase pl-3">LLM Settings</Text>
      <Button
        colorScheme="dark"
        isDisabled={!isLLMEditAllowed}
        onClick={resetModel}
        rightIcon={<MdsReplayRound className="stroke-[22]" />}
        variant={"ghost"}
      >
        Reset
      </Button>
    </Flex>
  );
};

export default SettingsHeader;
