import {
  Box,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CloseButton,
  Divider,
  Flex,
  Heading,
  Skeleton,
  Spinner,
  Tag,
  Text,
} from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import {
  MdOutlineArrowOutward,
  MdOutlineChevronRight,
  MdOutlineEdit,
  MdOutlineFolder,
  MdOutlineStore,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";

import { ProtectedComponent } from "@/components/protected-component/protected-component";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from "@/design/components/breadcrumbs";
import { Button } from "@/design/components/button";
import { Icon } from "@/design/components/icon";
import { useLazyGetworkflowsFromAnalysisQuery } from "@/features/workflow-studio/api/workflow-api";
import { useLazyGetDashboardsQuery } from "@/features/dashboard/api/dashboard-query";
import { setWorkFlowId } from "@/features/workflow-studio/redux/workflow-slice";
import { DetailsRow } from "@/features/ws-manager/layout/details-row.tsx";
import {
  previewAnalysis,
  setPreviewAnalysis,
} from "@/features/ws-manager/redux/analysis-slice.tsx";
import { AnalysesSchema, WorkspaceSchema, ProjectSchema } from "@/features/ws-manager/types";
import { useCustomQuery } from "@/hooks/useCustomQuery.ts";
import { useAppDispatch, useAppSelector } from "@/reduxHooks.ts";
import { ModalTypes, openModal } from "@/slices/modal-slice";
import {
  getDateFormat,
  getLocalDateTimeFormat,
} from "@/utils/date-convertor.ts";
import { CATEGORY, PERMISSIONS } from "@/utils/enums";
import { closestColor } from "@/utils/get-color.ts";

import { useGetWsListQuery, useLazyGetSingleAnalysisQuery } from "../../api";

const AnalysisDetails = (props: { onClose: () => void }) => {
  const analysis = useAppSelector(previewAnalysis) as Partial<AnalysesSchema>;
  const dispatch = useAppDispatch();
  const { removeParam } = useCustomQuery();
  const [fetchAnalysis, { data, isLoading }] = useLazyGetSingleAnalysisQuery();
  const { data: wsData } = useGetWsListQuery();

  const navigate = useNavigate();

  const analysisData = data?.response.data?.analyses?.[0];
  const item = analysis?.name ? analysis : analysisData;
  const onClose = () => {
    removeParam("analysis");
    dispatch(setPreviewAnalysis(null));
    props.onClose();
  };

  useEffect(() => {
    if (analysis && Object.keys(analysis).length < 2) {
      fetchAnalysis({ id: analysis.id! });
    }
  }, [analysis, fetchAnalysis]);

  const workspace = useMemo(
    () =>
      wsData?.response?.data?.workspaces.find(
        (ws: Partial<WorkspaceSchema>) => ws.id === (analysis ?? analysisData)?.workspaceId
      ) ?? null,
    [wsData, analysis, analysisData]
  );

  const project = useMemo(
    () =>
      workspace?.projects?.find((proj: Partial<ProjectSchema>) => proj.id === analysis?.projectId) ??
      null,
    [workspace, analysis?.projectId, analysisData?.projectId]
  );

  const [isOpeningStudio, setIsOpeningStudio] = useState(false);

  const [
    getWorkflowFromAnalysis,
    { isLoading: isWorkflowLoading, isFetching: isWorkflowFetching },
  ] = useLazyGetworkflowsFromAnalysisQuery();

  const [
    getDashboards,
    { isLoading: isDashboardLoading }
  ] = useLazyGetDashboardsQuery();

  const fetchWorkflow = async () => {
    const analysisId = analysis.id!;
    const res = await getWorkflowFromAnalysis({
      analysisId,
    }).unwrap();

    const workflowId = res.response.data?.workflows[0]?.workflowId;
    if (workflowId) {
      routeToFlowStudio(workflowId);
    }
  };

  const routeToFlowStudio = (workflowId: string) => {
    const analysisId = analysis.id!;

    dispatch(setWorkFlowId(workflowId));
    navigate(`/analysis/${analysisId}/data-manager`, {
      state: { workflowId: workflowId, analysis: item },
    });
    dispatch(setPreviewAnalysis(null));
  };

  const routeToStudio = async () => {
    const analysisId = analysis.id!;
    // Set our own loading state that we control directly
    setIsOpeningStudio(true);

    // Default to data-manager
    let url = `/analysis/${analysisId}/data-manager`;

    try {
      // Check if dashboards exist for this analysis
      const dashboardResult = await getDashboards({ analysisId }).unwrap();
      const dashboards = dashboardResult?.response?.data || [];

      // If dashboards exist, use dashboard URL instead
      if (dashboards.length > 0) {
        url = `/analysis/${analysisId}/dashboard`;
      }
    } catch (error) {
      console.error("Error checking for dashboards:", error);
    } finally {
      // Ensure we reset the loading state regardless of success or failure
      setIsOpeningStudio(false);
      // Open the URL in a new tab (happens regardless of try/catch outcome)
      window.open(url, "_blank");
    }
  };

  const openManageAccessModal = () => {
    dispatch(
      openModal({
        modalType: ModalTypes.MANAGE_ACCESS,
        modalProps: { object: item, type: CATEGORY.Analysis, navigate },
      })
    );
  };

  return (
    <Card h="100%" variant="primary">
      <CardHeader>
        <Flex className="flex-col justify-start w-full gap-2">
          <Flex className="w-full justify-between items-center">
            <Skeleton isLoaded={!!item?.name}>
              <Heading> {item?.name}</Heading>
            </Skeleton>
            <CloseButton onClick={onClose} />
          </Flex>
          {/*{analysis?.status === "published" && (*/}
          {/*  <Tag className="self-start" colorScheme="whatsapp">*/}
          {/*    {analysis?.status}*/}
          {/*  </Tag>*/}
          {/*)}*/}
        </Flex>
      </CardHeader>
      <Divider />
      {!item?.updatedOn && (
        <CardBody className="flex flex-col items-center justify-center" p={4}>
          <Spinner />
        </CardBody>
      )}
      {!isLoading && (
        <CardBody className="flex flex-col gap-5  !overflow-auto" p={4}>
          <Flex className="flex-col gap-4">
            {item?.tags && item.tags.length > 0 && (
              <Flex gap="3">
                <Box>Tags: </Box>
                <Flex className="gap-2 flex-wrap">
                  {item?.tags?.map((tag, idx) => (
                    <Tag
                      className="w-fit"
                      key={tag + idx}
                      colorScheme={closestColor(tag ?? "")}
                    >
                      {tag}
                    </Tag>
                  ))}
                </Flex>
              </Flex>
            )}
            {item?.description && (
              <Text className="text-sm2 text-gray-700">
                {item?.description}
              </Text>
            )}
            {workspace && project && (
              <BreadCrumbRow
                project={project.name!}
                workspace={workspace.name!}
              />
            )}
          </Flex>
          <ProtectedComponent
            requiredPermission={PERMISSIONS.WRITE}
            type={CATEGORY.Analysis}
            id={item?.id}
          >
            <Button
              colorScheme="secondary"
              w="fit-content"
              variant="outline"
              leftIcon={<Icon as={MdOutlineEdit} size="sm" />}
              size="sm"
              className=""
              onClick={openManageAccessModal}
            >
              Manage Access
            </Button>
          </ProtectedComponent>
          <Flex className="flex-col gap-6">
            {/* <DetailsRow header="Last Run">
              {getLocalDateTimeFormat(item?.updatedOn ?? "")}
            </DetailsRow> */}
            <DetailsRow header="Last Updated">
              {getLocalDateTimeFormat(item?.updatedOn ?? "")}
            </DetailsRow>
            <DetailsRow header="Created">
              {getDateFormat(item?.createdOn ?? "")}
            </DetailsRow>
            {/* <DetailsRow header="Dashboard Id">{analysis?.id}</DetailsRow> */}
          </Flex>
        </CardBody>
      )}
      <Divider />
      <CardFooter>
        <Button
          className="!w-full"
          colorScheme="secondary"
          size="lg"
          variant="solid"
          isLoading={isWorkflowLoading || isWorkflowFetching || isDashboardLoading || isOpeningStudio}
          //eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={routeToStudio}
          rightIcon={
            <Icon as={MdOutlineArrowOutward} size="sm" color="white" />
          }
        >
          Open in Studio
        </Button>
      </CardFooter>
    </Card>
  );
};

const BreadCrumbRow = ({
  project,
  workspace,
}: {
  project: string;
  workspace: string;
}) => {
  return (
    <Breadcrumb
      separator={<MdOutlineChevronRight />}
      spacing="0.125rem"
      color="gray.700"
    >
      <BreadcrumbItem icon={<Icon as={MdOutlineStore} size="sm" />}>
        <Text className="text-gray-700 font-medium text-xs">{workspace}</Text>
      </BreadcrumbItem>
      <BreadcrumbItem icon={<Icon as={MdOutlineFolder} size="sm" />}>
        <Text className="text-gray-700 font-medium text-xs">{project}</Text>
      </BreadcrumbItem>
    </Breadcrumb>
  );
};

export default AnalysisDetails;
