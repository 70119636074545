import { Slide } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { useReactFlow } from "reactflow";

import { ConfigurePanel } from "@/design/components/configure-panel";
import { useAppDispatch, useAppSelector } from "@/reduxHooks.ts";

import { hidePanel, selectPanel } from "../..";
import { NodeType } from "../../types";
import { WORKFLOW_PANELS } from "../../utils/constants";

import { NodeConfigPanelBody } from "./config-panel-body";
import { ConfigPanelProvider } from "./config-panel-context";
import { NodeConfigPanelFooter } from "./config-panel-footer";
import { NodeConfigPanelHeader } from "./config-panel-header";

export const NodeConfigPanel = () => {
  const nodeConfigPanel = useAppSelector(
    selectPanel(WORKFLOW_PANELS.NodeConfigurationPanel)
  ) as { nodeId: string | null; isVisible: boolean };

  const dispatch = useAppDispatch();
  useEffect(() => {
    return () => {
      dispatch(hidePanel(WORKFLOW_PANELS.NodeConfigurationPanel));
    };
  }, []);

  return (
    <Slide
      className="!absolute top-0 !right-0 h-full p-5 !w-fit"
      direction="right"
      in={nodeConfigPanel.isVisible}
      style={{ zIndex: 10 }}
      unmountOnExit={true}
    >
      <ConfigPanelProvider>
        <ConfigurePanel className="max-h-full relative">
          <NodeConfigPanelHeader />
          <NodeConfigPanelBody key={nodeConfigPanel.nodeId} />
          <NodeConfigPanelFooter />
        </ConfigurePanel>
      </ConfigPanelProvider>
    </Slide>
  );
};
