export const UserManagerEndpoints = {
  getUsers: () => `/api/v1.0/users/`,
  getUsersListForObject: (objectId: string) =>
    `/api/v1.0/authorisation/list-users-role-for-object/${objectId}`,
  createUserAccess: () => `/api/v1.0/authorisation/create-update-user-access/`,
  updateUserAccess: () => `/api/v1.0/authorisation/create-update-user-access/`,
  deleteUserAccess: (objectId: string) =>
    `/api/v1.0/authorisation/delete-user-access/${objectId}`,
  inviteUsers: `/api/v1.0/users/invite/`,
  getRoles: () => `/api/v1.0/authorisation/get-assignable-roles-on-object/`,
  getAccessList: (scope: string, permission: string) =>
    `/api/v1.0/authorisation/list-objects-by-access/?scope=${scope}&permission=${permission}`,
  getExportToken: `/api/v1.0/authorisation/get_dataset_export_token/`,
  getTenantId: `/api/v1.0/users/get_tenant/`,
  getUserId: `/api/v1.0/users/signin_signup_helper/`,
  createOrgUser: `/api/v1.0/users/auto-create-user/`,
  createOrgAdmin: `/api/v1.0/users/create_org_admin/`,
  removeOrgAdmin: `/api/v1.0/users/remove_org_admin/`,
  removeOrgUser: `/api/v1.0/users/remove_org_user/`,
  getOrganizationsForUser: (mail: string) =>
    `/api/v1.0/users/get_user_orgs/?email_id=${mail}`,
  verifyUserEmails: `/api/v1.0/users/verify-emails/`,
  removeUserFromPlatform: `/api/v1.0/users/remove_user_from_platform/`,
  syncObject: () => `/api/v1.0/authorisation/sync-object-access/`,
};
