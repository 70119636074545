import { useMsal } from "@azure/msal-react";
import React from "react";
import {
  MdsDeleteRound,
  MdsDownloadRound,
  MdsTransformRound,
  MdsUploadRound,
} from "react-icons-with-materialsymbols/mds";
import { useNavigate, useParams } from "react-router-dom";

import { TOAST_DURATION, ToastType, useShowToast } from "@/components/toast";
import { useDownloadDataMutation } from "@/features/workflow-studio";
import { handleDataSetDownload } from "@/features/workflow-studio/components/data-download/download-utils";
import { DATASET_STATES } from "@/features/workflow-studio/utils/constants";
import { useAppDispatch, useAppSelector } from "@/reduxHooks.ts";
import { closeModal, ModalTypes, openModal } from "@/slices/modal-slice";
import {
  CATEGORY,
  DATA_EXPORT_STATUS,
  DATASET_FORMAT,
  EDA_STATE,
  PERMISSIONS,
} from "@/utils/enums";

import { useAddDownloadMutation } from "../api";
import { addToOngoingDownloads, getOnGoingAnalysisDownloads } from "../redux";
import { DatasetsSchema } from "../types";

const useDatasetActions = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const toast = useShowToast(undefined, undefined, true);
  const { instance } = useMsal();
  const navigate = useNavigate();

  const [_, { isLoading }] = useDownloadDataMutation();
  const [addDownloadMutation, { isLoading: isDownloading }] =
    useAddDownloadMutation();
  const ongoingDownloads = useAppSelector((state) =>
    getOnGoingAnalysisDownloads(state, params.analysisId!)
  );

  const showEmptyDatasetToast = () => {
    toast({
      title:
        "This node has generated an empty output without any errors.\nCheck your configuration again.",
      status: ToastType.Warning,
    });
  };

  const showStaleToast = () => {
    toast({
      title: "Dataset is stale.",
      description:
        "Since workflow was re-run, the file will be prepared again.",
      status: ToastType.Info,
    });
  };

  const startDownload = async ({
    dataset,
    outputFormat,
  }: {
    dataset: DatasetsSchema;
    outputFormat: DATASET_FORMAT;
  }) => {
    try {
      setTimeout(() => {
        toast({
          status: ToastType.Info,
          id: "downloading-" + dataset.id,
          title: "Downloading ",
          description: dataset.displayName,
          duration: null,
        });
      }, 500);
      await handleDataSetDownload({
        analysisId: params.analysisId!,
        ioRecordId: dataset.ioRecord.id,
        datasetName: dataset.displayName,
        datasetId: dataset.id,
        instance,
        outputFormat: outputFormat,
        toast,
      });
    } catch (error) {
      toast({
        title: "Download failed",
        description: dataset.displayName + " failed to download",
        status: ToastType.Error,
        duration: 5000,
      });
    }
  };

  const downloadDataset = async ({
    dataset,
    format,
  }: {
    dataset: DatasetsSchema;
    format: DATASET_FORMAT;
  }) => {
    try {
      const response = await addDownloadMutation({
        analysisId: params.analysisId!,
        body: {
          datasetId: dataset.id,
          dataFormatStr: format,
        },
      }).unwrap();
      const newDownload = response?.response?.data?.datasetOperations[0];
      if (!newDownload) {
        throw new Error("Failed to add download");
      }
      toast({
        id: "prepare-download-" + dataset.id,
        title: "Preparing download...",
        description:
          newDownload?.statusMsg ??
          "This can take up to 10 mins. Do not close this tab.",
        status: ToastType.Info,
        duration: TOAST_DURATION.MEDIUM,
      });

      if (newDownload?.state === DATA_EXPORT_STATUS.COMPLETE) {
        dispatch(closeModal());
        await startDownload({
          dataset: dataset,
          outputFormat: format,
        });
        return;
      }

      // Add to active downloads in store
      dispatch(
        addToOngoingDownloads({
          analysisId: params.analysisId!,
          download: {
            dataset,
            datasetId: dataset.id,
            datasetOperationId: newDownload.id,
            dataFormatStr: format,
            state: newDownload.state,
          },
        })
      );

      dispatch(closeModal());
      return newDownload;
    } catch (error) {
      toast({
        title: "Failed to add download",
        description: "Please try again later",
        status: ToastType.Error,
      });
      throw error;
    }
  };

  const openDownloadDatasetModal = (
    e: React.MouseEvent,
    dataset: DatasetsSchema
  ) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(
      openModal({
        modalType: ModalTypes.DOWNLOAD_DATA,
        modalProps: {
          dataset,
          analysisId: params.analysisId!,
          downloadDataset,
        },
      })
    );
  };

  const downloadData = (
    e: React.MouseEvent<Element, MouseEvent>,
    dataset: DatasetsSchema
  ) => {
    e.stopPropagation();
    e.preventDefault();
    if (!dataset.rowCount || dataset.rowCount === 0) {
      showEmptyDatasetToast();
      return;
    }
    openDownloadDatasetModal(e, dataset);
  };

  const openDeleteModal = (e: React.MouseEvent, dataset: DatasetsSchema) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(
      openModal({
        modalType: ModalTypes.DELETE_IO_RECORD,
        modalProps: { dataset, type: "dataset" },
      })
    );
  };

  const routeToTransformation = (
    e: React.MouseEvent,
    dataset: DatasetsSchema
  ) => {
    e.stopPropagation();
    e.preventDefault();
    navigate(`${dataset.id}/transform`);
  };

  const openExportModal = (e: React.MouseEvent, dataset: DatasetsSchema) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(
      openModal({
        modalType: ModalTypes.EXPORT_DATASET,
        modalProps: { dataset, analysisId: params.analysisId! },
      })
    );
  };

  const openExportConnectorsModal = (
    e: React.MouseEvent,
    dataset: DatasetsSchema
  ) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(
      openModal({
        modalType: ModalTypes.EXPORT_CONNECTORS,
        modalProps: {
          dataset,
          analysisId: params.analysisId!,
          navigate,
        },
      })
    );
  };

  const openEditDatasetModal = (
    e: React.MouseEvent,
    dataset: DatasetsSchema
  ) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(
      openModal({
        modalType: ModalTypes.EDIT_DATASET_DETAILS,
        modalProps: { dataset, analysisId: params.analysisId! },
      })
    );
  };

  const disableDownload = (dataset: DatasetsSchema) =>
    ongoingDownloads?.some((ad) => ad.datasetId === dataset.id) ||
    isLoading ||
    dataset.state === DATASET_STATES.PENDING;

  const isInputDataset = (dataset: DatasetsSchema) =>
    dataset?.ioRecord?.ioType === "input";

  const datasetMenuItems = (
    e: React.MouseEvent<Element, MouseEvent>,
    dataset: DatasetsSchema
  ) => [
    {
      label: "Transform Data",
      action: () => routeToTransformation(e, dataset),
      icon: MdsTransformRound,
      isVisible: isInputDataset(dataset),
      isProtected: true,
      extraOptions: {
        permission: PERMISSIONS.WRITE,
        data: {
          id: dataset.analysisId,
          type: CATEGORY.Analysis,
        },
      },
    },
    // {
    //   label: "Edit Dataset",
    //   action: () => openEditDatasetModal(e, dataset),
    //   icon: MdsEditOutlined,
    //   isProtected: true,
    //   extraOptions: {
    //     permission: PERMISSIONS.WRITE,
    //     data: {
    //       id: dataset.analysisId,
    //       type: CATEGORY.Analysis,
    //     },
    //   },
    // },
    // {
    //   label: "Export Data Via API",
    //   action: () => openExportModal(e, dataset),
    //   icon: MdsArrowOutwardRound,
    // },
    {
      label: "Export Dataset",
      isVisible: isInputDataset(dataset),
      action: () => openExportConnectorsModal(e, dataset),
      icon: MdsUploadRound,
      isDisabled: dataset.edaState === EDA_STATE.IN_PROGRESS,
      toolTip: {
        label:
          dataset.edaState === EDA_STATE.IN_PROGRESS
            ? "Data is being processed"
            : undefined,
        hasArrow: dataset.edaState === EDA_STATE.IN_PROGRESS,
        placement: "left",
      },
    },
    {
      label: "Download Dataset",
      action: () => downloadData(e, dataset),
      icon: MdsDownloadRound,
      isDisabled: disableDownload(dataset),
      toolTip: {
        label:
          disableDownload(dataset) && dataset.ioRecord.ioType === "input"
            ? "Another download is in progress"
            : undefined,
        hasArrow: disableDownload(dataset),
        placement: "left",
      },
    },
    {
      label: "Delete Dataset",
      action: () => openDeleteModal(e, dataset),
      icon: MdsDeleteRound,
      isVisible: isInputDataset(dataset),
      color: "red.600",
      isProtected: true,
      extraOptions: {
        permission: PERMISSIONS.WRITE,
        data: {
          id: dataset.analysisId,
          type: CATEGORY.Analysis,
        },
      },
    },
  ];

  return {
    isInputDataset,
    isDownloading: isLoading,
    isAddingDownload: isDownloading,
    downloadDataset,
    disableDownload,
    onExport: openExportModal,
    onTransform: routeToTransformation,
    onDelete: openDeleteModal,
    onDownload: downloadData,
    datasetMenuItems,
    onEdit: openEditDatasetModal,
    onExportDataset: openExportConnectorsModal,
    startDownload,
  };
};

export default useDatasetActions;
