import { Flex, IconButton, Text, Tooltip } from "@chakra-ui/react";
import clsx from "clsx";
import { useContext } from "react";
import {
  MdsCloseRound,
  MdsTabCloseRound,
} from "react-icons-with-materialsymbols/mds";
import { useDispatch, useSelector } from "react-redux";

import { Icon } from "@/design/components/icon";
import {
  compiledTransformationSteps,
  TransformationStep,
  setCompiledTransformationSteps,
  setCurrentlyAddedStep,
  setCurrentOpenFilter,
  setIsStepFormFilterMenuOpen,
  setSelectedStep,
  setSelectedStepTempFilter,
  setToastMessage,
  tempTransformationSteps,
  transformationSteps,
} from "@/features/data-transformation";

import { StepFormContext } from "./step-form-context.ts";

export const FormHeader = () => {
  const dispatch = useDispatch();

  const { step } = useContext(StepFormContext)!;
  const addedSteps = useSelector(transformationSteps);
  const temporaryStep = useSelector(tempTransformationSteps);
  const stepsList = useSelector(compiledTransformationSteps);

  const closeForm = () => {
    dispatch(setToastMessage(undefined));
    dispatch(setCurrentlyAddedStep(null));
    dispatch(setSelectedStep(null));
    dispatch(setCurrentOpenFilter(null));
    dispatch(setSelectedStepTempFilter(null));
    dispatch(setIsStepFormFilterMenuOpen(false));

    if (step?.transformationStepId) {
      revertSelectedFilter();
    }
  };

  const revertSelectedFilter = () => {
    const matchingTempStep = temporaryStep.find(
      (addedStep) =>
        addedStep.transformationStepId === step?.transformationStepId
    );
    const matchingStep = addedSteps.find(
      (addedStep) =>
        addedStep.transformationStepId === step?.transformationStepId
    );

    const oldStep = matchingStep ?? matchingTempStep;

    const updatedStepList = stepsList.map((_step) =>
      _step.transformationStepId === step?.transformationStepId
        ? oldStep
        : _step
    ) as TransformationStep[];

    dispatch(setCompiledTransformationSteps(updatedStepList));
  };
  return (
    <Flex className="p-1 pl-3 bg-white border-y border-y-gray-300 gap-x-2 items-center justify-between">
      <Flex className={clsx("item-center max-w-full truncate justify-between")}>
        <Icon
          as={MdsTabCloseRound}
          color="gray.800"
          className="transition-all"
          size="sm"
        />
        <Flex
          className={clsx(
            "item-center text-xs leading-[14px] font-medium ml-1 max-w-full truncate break-words gap-x-1"
          )}
        >
          <Tooltip label={step?.name} openDelay={600}>
            <Text className="truncate break-words cursor-default">
              {step?.name}
            </Text>
          </Tooltip>
        </Flex>
      </Flex>
      <IconButton
        className="cursor-pointer transition-all"
        color="gray.500"
        aria-label="close"
        colorScheme="secondary"
        icon={<Icon as={MdsCloseRound} />}
        onClick={closeForm}
        size="sm"
        variant="ghost"
      />
    </Flex>
  );
};
