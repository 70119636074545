import {
  Button,
  Input,
  Center,
  Flex,
  FormErrorMessage,
  FormLabel,
  FormControl,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";

import { fetchRegionsForEmail } from "./fetchRegions";

const RegionSelection = () => {
  const [email, setEmail] = useState("");
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [availableRegions, setAvailableRegions] = useState<
    Record<string, string>
  >({});
  const [region, setRegion] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const emailFromQuery = urlParams.get("email");
    setEmail(emailFromQuery ?? "");
    if (emailFromQuery && validateEmail(emailFromQuery)) {
      setIsVerifying(true);
      fetchRegions(emailFromQuery);
    }
  }, []);

  const validateEmail = (emailId: string) => {
    if (!emailId) {
      setErrorMessage("Email is required");
      return false;
    }
    if (!/\S+@\S+\.\S+/.test(emailId)) {
      setErrorMessage("Email is invalid");
      return false;
    }
    return true;
  };

  const handleContinue = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateEmail(email)) return;
    setIsVerifying(true);
    fetchRegions();
  };

  const fetchRegions = (emailId?: string) => {
    const mail = emailId || email;
    fetchRegionsForEmail(mail)
      .then((data) => {
        if (data.ok) {
          const regions = (data.json?.regions as Record<string, string>) || {};
          setAvailableRegions(regions);
          setIsVerifying(false);
          if (Object.keys(regions).length >= 1) {
            setRegion(Object.keys(regions)[0]);
            handleLogin(Object.keys(regions)[0]);
            return;
          }
        } else {
          setRegion("cus");
          handleLogin("cus");
          return;
        }
      })
      .catch(() => {
        setErrorMessage("Something went wrong. Could not verify email");
        setIsVerifying(false);
      });
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setErrorMessage("");
  };

  const handleCountryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setRegion(e.target.value);
    setErrorMessage("");
  };

  const handleLogin = (selectedRegion?: string) => {
    window.location.href = `https://${selectedRegion ?? region}.${
      window.location.hostname
    }?login_hint=${email}`;
  };

  const handleInputDoubleClick = () => {
    setRegion("");
    setAvailableRegions({});
    setIsEmailVerified(false);
    setErrorMessage("");
  };

  return (
    <Center
      h="100vh"
      style={{
        backgroundImage:
          "url('https://gen2neopoc.blob.core.windows.net/user-mgmt-test/bg%20(1).png')",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Flex className="flex-col h-fit w-full sm:w-[35vw] min-w-[400px] max-w-[520px] p-[48px] text-center bg-white border border-gray-300 rounded shadow-lg">
        <img
          src="https://aadcdn.msftauthimages.net/dbd5a2dd-7-bfcjwtyhaftvanjcd1xsj-7hp1amlbafbqman4tja/logintenantbranding/0/bannerlogo?ts=638569665983860206"
          alt="Clootrack Logo"
          className="w-56 h-auto self-center"
        />
        <Flex className="justify-center mt-[24px]">
          <Text className="text-3xl font-medium">Log In</Text>
        </Flex>

        <form
          onSubmit={handleContinue}
          className="flex flex-col gap-8 grow mt-10"
        >
          <FormControl isInvalid={!!errorMessage}>
            <FormLabel className="pl-2 mb-1">Email</FormLabel>
            <Input
              className={`w-full border p-3 rounded-sm disabled:cursor-not-allowed disabled:text-gray-600 ${
                errorMessage ? "border-red-500 outline-none" : "border-gray-500"
              }`}
              isDisabled={isVerifying}
              isReadOnly={
                isVerifying || Object.keys(availableRegions).length > 0
              }
              name="email"
              onChange={handleEmailChange}
              onDoubleClick={handleInputDoubleClick}
              value={email}
            />
            <FormErrorMessage className="break-all text-sm text-red-500">
              {errorMessage}
            </FormErrorMessage>
          </FormControl>
          {isEmailVerified && (
            <FormControl>
              <FormLabel className="pl-2 mb-1">Select Region</FormLabel>
              <select
                onChange={handleCountryChange}
                value={region}
                className="w-full border p-3 rounded-sm border-gray-500"
              >
                {Object.keys(availableRegions).map((r) => (
                  <option key={r} value={r}>
                    {availableRegions[r]}
                  </option>
                ))}
              </select>
            </FormControl>
          )}
          {isEmailVerified ? (
            <Button
              className="!w-full !bg-black-900 py-3 px-4 rounded-sm text-white disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-gray-300"
              isDisabled={isVerifying}
              isLoading={isVerifying}
              onClick={() => handleLogin()}
            >
              Continue
            </Button>
          ) : (
            <Button
              className="w-full !bg-black-900 py-3 px-4 rounded-sm text-white disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-gray-300"
              isDisabled={isVerifying}
              isLoading={isVerifying}
              type="submit"
            >
              Continue
            </Button>
          )}
        </form>
      </Flex>
    </Center>
  );
};

export default RegionSelection;
