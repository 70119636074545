import { Flex, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import { MdClear } from "react-icons/md";

import { ConfigurePanelHeader } from "@/design/components/configure-panel";
import { IconButton } from "@/design/components/icon-button";
import { useAppDispatch } from "@/reduxHooks";

import { hidePanel } from "../../redux/workflow-studio-slice";
import { WORKFLOW_PANELS } from "../../utils";

import { useConfigPanel } from "./config-panel-context";

export const NodeConfigPanelHeader = () => {
  const { currentNodeData, canClosePanel,allowPanelClose } = useConfigPanel();

  const title = currentNodeData?.displayName;

  const dispatch = useAppDispatch();
  const onClose = () => {
    if (!canClosePanel) return;
    dispatch(
      hidePanel(
        WORKFLOW_PANELS.NodeConfigurationPanel as keyof typeof WORKFLOW_PANELS
      )
    );
  };

  useEffect(() => {
    return () => {
      allowPanelClose(true)
    }
  }, [])

  return (
    <ConfigurePanelHeader p={4}>
      <Flex align="center" justify="space-between" w="100%">
        <Text className="text-lg font-semibold">Configure {title}</Text>
        <IconButton
          variant="ghost"
          colorScheme="dark"
          isDisabled={!canClosePanel}
          aria-label="close panel"
          icon={<MdClear />}
          onClick={onClose}
        />
      </Flex>
    </ConfigurePanelHeader>
  );
};
