import { Input } from "@chakra-ui/input";
import {
  Divider,
  Text,
  Flex,
  PopoverArrow,
  PopoverContent,
  Button,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { isEmpty } from "lodash";
import { forwardRef, useContext, useEffect, useMemo, useState } from "react";
import { MdsChevronRightRound } from "react-icons-with-materialsymbols/mds";

import { DeleteFilled } from "@/components/icons/delete-filled.tsx";
import { Icon } from "@/design/components/icon";
import {
  EdaMetaDataContext,
  useGetRequestStatusQuery,
  useGetFilterStatsMutation,
  FilterContext,
  IFilterStatsTaskData,
  FilterCondition,
  CONDITIONS_MAP,
  MULTIPLE_INPUT,
  FILTER_TYPE,
  ViewFiltersRequest,
  getAllFilterOptions,
  isInvalidFilter,
  seperateFilters,
  COMMA_SEPARATOR,
  NO_CONDITION,
} from "@/features/data-transformation";
import { AllFiltersError } from "@/features/data-transformation/components/filter-dropdown/all-filters-error.tsx";
import { StepFormContext } from "@/features/data-transformation/components/step-form/step-form-context.ts";
import { normalizeValue } from "@/features/data-transformation/utils/helpers";
import { POLLING_STATUS } from "@/utils/enums.ts";

import { ColumnDropdown } from "./column-dropdown.tsx";
import { FilterByCondition } from "./filter-by-condition.tsx";
import { FilterByValueRow } from "./filter-by-value-row.tsx";
import { FilterDetailsContext } from "./filter-details-context.ts";

type FilterDropdownProps = {
  col?: string;
  removeFilter: () => void;
  closeFilter: () => void;
  setAllFilterOptions: (value: any) => void;
  isOpen: boolean;
};

const POLLING_INTERVAL = 500;
export const FilterDropdown = forwardRef<HTMLDivElement, FilterDropdownProps>(
  function (
    { col, setAllFilterOptions, removeFilter, closeFilter, isOpen },
    ref
  ) {
    const metaData = useContext(EdaMetaDataContext);

    const { step } = useContext(StepFormContext) ?? {};

    const {
      appliedFilters: filters,
      filter,
      editDisabled,
      placement,
      changeTempFilter,
      updateSavedFilters,
      changeCurrentOpenFilter,
    } = useContext(FilterContext)!;

    const [openByCondition, setOpenByCondition] = useState(false);
    const [openByValue, setOpenByValue] = useState(true);
    const [column, setColumn] = useState<string | null>(col ?? null);
    const [error, setError] = useState(false);

    const [input1, setInput1] = useState<string>("");
    const [input2, setInput2] = useState<string>("");

    const [selectedCondition, setSelectedCondition] =
      useState<FilterCondition | null>(null);

    const [selectAll, setSelectAll] = useState(true);
    const [requestId, setRequestId] = useState<string | null>(null);

    const [filterStatsData, setFilterStatsData] =
      useState<IFilterStatsTaskData | null>(null);
    const [filterItems, setFilterItems] = useState<string[] | null>(
      getAllFilterOptions(filterStatsData) ?? null
    );
    const [selectedValues, setSelectedValues] = useState<string[]>([]);

    const isSavedFilter = useMemo(() => {
      const isSaved = filters.find((_item) => _item.column === filter.column);

      return !!isSaved;
    }, [filter, filters]);

    const [getStats, { isLoading: isFetchingStats }] =
      useGetFilterStatsMutation();

    const { data: runStatusData, isFetching } = useGetRequestStatusQuery(
      {
        analysisId: metaData.analysisId!,
        edaId: metaData.edaId!,
        requestId: requestId!,
      },
      {
        pollingInterval: POLLING_INTERVAL,
        skip: requestId == null,
      }
    );

    const getFilterStats = async () => {
      try {
        const res = await getStats({
          edaId: metaData.edaId!,
          analysisId: metaData.analysisId!,
          column: column!,
          viewFilters: placement === "bottom" ? seperateFilters(filters) : [],
          transformationStepId: step?.transformationStepId,
        }).unwrap();
        const id = res.response.data?.requestId ?? null;
        setRequestId(id);
      } catch (e) {
        console.log(e);
      }
    };

    useEffect(() => {
      if (filter.filterType === FILTER_TYPE.CONDITION) {
        setOpenByCondition(true);
        setOpenByValue(false);
      } else {
        setOpenByCondition(false);
        setOpenByValue(true);
      }

      const _selectedCondition = CONDITIONS_MAP.find(
        (item) => item.value === filter.conditionType
      );

      const columnChange = filter.column !== column;

      if (columnChange) {
        setColumn(filter.column);
        setFilterStatsData(null);
      }

      if (filter.values) {
        setSelectedValues(filter.values);
      }

      if (!_selectedCondition) return;
      setInitialValuesForCondition(_selectedCondition);
    }, [filter]);

    useEffect(() => {
      const hasValues = !!metaData.edaId && !!metaData.analysisId && !!column;
      const shouldFetchFilterValues = hasValues && isOpen;

      if (shouldFetchFilterValues) {
        setFilterStatsData(null);
        getFilterStats();
      }
    }, [column, metaData, isOpen]);

    useEffect(() => {
      if (runStatusData?.response.data?.taskStatus === POLLING_STATUS.FAILED) {
        setRequestId(null);
        return;
      }
      if (
        runStatusData?.response.data?.taskStatus === POLLING_STATUS.COMPLETED
      ) {
        onFilterData();
      }
    }, [runStatusData]);

    const setInitialValuesForCondition = (
      _selectedCondition: FilterCondition | null
    ) => {
      setSelectedCondition(_selectedCondition);

      if (!_selectedCondition) {
        setInput1("");
        setInput2("");
        return;
      }

      const _isMultipleInput = MULTIPLE_INPUT.find(
        (item) => item.key === _selectedCondition.key
      );
      if (_isMultipleInput) {
        const [_input1, _input2]: string[] =
          filter.value.split(COMMA_SEPARATOR);
        setInput1(_input1);
        setInput2(_input2);
      } else {
        setInput1(filter?.value as string);
      }
    };

    const onFilterData = () => {
      const _data = runStatusData?.response.data
        ?.taskData as IFilterStatsTaskData;

      const allOptions = getAllFilterOptions(_data) ?? [];
      setRequestId(null);

      setFilterStatsData(_data);
      setAllFilterOptions(allOptions);

      if (isEmpty(selectedValues)) {
        setSelectedValues(allOptions);
      }
    };

    const onOpenByCondition = () => {
      setOpenByCondition(!openByCondition);
      if (!openByCondition) {
        setOpenByValue(false);
      }
    };

    const isMultipleInput = useMemo(() => {
      if (selectedCondition) {
        return MULTIPLE_INPUT.find(
          (item) => item.key === selectedCondition.key
        );
      }
      return false;
    }, [selectedCondition]);

    const onOpenByValue = () => {
      setOpenByValue(!openByValue);
      if (!openByValue) {
        setOpenByCondition(false);
      }
    };

    const changeColumn = (_column: string) => {
      setColumn(_column);
      setFilterStatsData(null);
      setSelectedValues([]);
      setSelectAll(true);

      if (isSavedFilter) {
        const oldFilter = filters.find((_filter) => col === _filter.column)!;
        const updatedAppliedFilters = getUpdatedFilters(oldFilter);
        updateSavedFilters(updatedAppliedFilters, true);
      }

      const newFilter = {
        filterType: filter.filterType,
        column: _column,
      };

      changeCurrentOpenFilter(_column);
      changeTempFilter(newFilter);
    };

    const isAllSelected = (values: string[]) => {
      return (
        values.length === (getAllFilterOptions(filterStatsData) ?? []).length
      );
    };

    const filterType = () => {
      const allSelected = isAllSelected(selectedValues);

      const hasFilterCondition = !!selectedCondition?.value;
      const isNone = selectedCondition?.value === NO_CONDITION;

      const isBoth = hasFilterCondition && !allSelected && !isNone;

      if (hasFilterCondition && allSelected) {
        return FILTER_TYPE.CONDITION;
      } else if (isBoth) {
        return FILTER_TYPE.BOTH;
      } else {
        return FILTER_TYPE.VALUE;
      }
    };

    const getUpdatedFilter = (): ViewFiltersRequest => {
      const _inputValue = isMultipleInput
        ? `${input1}${COMMA_SEPARATOR}${input2}`
        : input1;
      const commonValues = {
        ...filter,
        column: column!,
      };
      switch (filterType()) {
        case FILTER_TYPE.CONDITION:
          return {
            column: commonValues.column,
            id: commonValues?.id,
            conditionType: selectedCondition!.value ?? "",
            value: _inputValue,
            filterType: FILTER_TYPE.CONDITION,
          };
        case FILTER_TYPE.VALUE:
          return {
            column: commonValues.column,
            id: commonValues?.id,
            values: selectedValues,
            filterType: FILTER_TYPE.VALUE,
          };
        case FILTER_TYPE.BOTH:
          return {
            ...commonValues,
            conditionType: selectedCondition!.value ?? "",
            value: _inputValue,
            values: selectedValues,
            filterType: FILTER_TYPE.BOTH,
          };
      }
    };

    const getUpdatedFilters = (updatedFilter: ViewFiltersRequest) => {
      let updatedAppliedFilters = filters.filter(
        (_filter) => _filter.id !== updatedFilter.id
      );
      updatedAppliedFilters = updatedAppliedFilters.map((_filter, index) => {
        return { ..._filter, id: index.toString() };
      });

      return updatedAppliedFilters;
    };
    const removeInvalidFilter = (updatedFilter: ViewFiltersRequest) => {
      if (isSavedFilter) {
        const updatedAppliedFilters = getUpdatedFilters(updatedFilter);
        updateSavedFilters(updatedAppliedFilters);
      }
      changeTempFilter(null);
    };

    const isAllValuesSelected = (_filter: ViewFiltersRequest) => {
      if (_filter.filterType != FILTER_TYPE.VALUE) return false;

      return isAllSelected(selectedValues);
    };

    const showSelectAllError = () => {
      if (error) {
        setError(false);
        setTimeout(() => setError(true), 500);
        return;
      }

      setError(true);
    };

    const applyFilter = () => {
      const updatedFilter = getUpdatedFilter();

      if (isInvalidFilter(updatedFilter)) {
        removeInvalidFilter(updatedFilter);
        closeFilter();
        return;
      }

      if (isAllValuesSelected(updatedFilter)) {
        showSelectAllError();
        return;
      }

      setError(false);

      let updatedAppliedFilters = [];

      if (isSavedFilter) {
        updatedAppliedFilters = filters.map((_filter) => {
          if (_filter.column === updatedFilter.column) {
            return updatedFilter;
          }
          return _filter;
        });
      } else {
        updatedFilter.id = (filters.length + 1).toString();
        updatedAppliedFilters = [...filters, updatedFilter];
      }

      if (updatedFilter.filterType === FILTER_TYPE.CONDITION) {
        getFilterStats();
      }

      updateSavedFilters(updatedAppliedFilters);
      changeTempFilter(null);

      closeFilter();
    };

    const onSelectAll = () => {
      const _selectAll = !selectAll;

      if (_selectAll) {
        const itemsToSelect =
          filterItems ?? getAllFilterOptions(filterStatsData) ?? [];
        setSelectedValues(itemsToSelect);
      } else {
        setSelectedValues([]);
      }
      setSelectAll(_selectAll);
    };

    const onConditionChange = (_condition: FilterCondition) => {
      setSelectedCondition(_condition);
      setInput1("");
      setInput2("");
    };
    const showInput = useMemo(
      () => !(selectedCondition?.hasNoInput ?? false),
      [selectedCondition]
    );

    const allValuesNames = useMemo(
      () => getAllFilterOptions(filterStatsData) ?? null,
      [filterStatsData]
    );

    return (
      <PopoverContent
        className="!outline-0"
        sx={{
          ":focus-visible": {
            outline: "none",
          },
          "& .chakra-popover__popper": {
            zIndex: "99 !important",
          },
        }}
      >
        <PopoverArrow />
        <FilterDetailsContext.Provider
          value={{
            openByValue,
            onOpenByValue,
            selectedValues,
            allValues: allValuesNames,
            onSelectValue: (value) => {
              const updatedValue = normalizeValue(value);
              const normalizedSelectedValues = selectedValues.map((val) =>
                normalizeValue(val)
              );
              console.log(updatedValue, normalizedSelectedValues);

              if (normalizedSelectedValues.includes(updatedValue)) {
                setSelectedValues(
                  selectedValues.filter(
                    (i) => normalizeValue(i) !== updatedValue
                  )
                );
              } else {
                setSelectedValues([...selectedValues, value]);
              }
            },
            setSelectedValues,
            selectAll,
            onSelectAll,
            filterItems,
            setFilterItems,
            isLoading: isFetchingStats || isFetching,
          }}
        >
          <Flex className="p-4 flex-col gap-y-5" ref={ref}>
            <ColumnDropdown
              selectedColumn={column}
              setSelectedColumn={changeColumn}
              isSavedFilter={isSavedFilter}
            />
            {!column && (
              <Text className="text-[13px] leading-[120%] tracking-[-0.26px]">
                Select column to apply filters
              </Text>
            )}
            {column && (
              <>
                <Divider color="gray.200" />
                <Flex className="items-center gap-x-2 text-[13px] leading-none font-semibold uppercase">
                  <Flex
                    as={motion.div}
                    color="gray.500"
                    cursor="pointer"
                    animate={{
                      rotate: !openByCondition ? 0 : 90,
                      transition: { duration: 0.2, type: "spring" },
                    }}
                    aria-label="collapse"
                    onClick={onOpenByCondition}
                  >
                    <Icon as={MdsChevronRightRound} size="md" />
                  </Flex>
                  Filter By Condition
                </Flex>
                {openByCondition && (
                  <>
                    <FilterByCondition
                      col={col}
                      selectedCondition={selectedCondition}
                      onChange={onConditionChange}
                    />

                    {showInput && (
                      <Flex className="flex-col items-start text-[13px] gap-y-2">
                        <Input
                          placeholder="Value or Formula"
                          size="sm"
                          className="!rounded-[3px] !border-gray-500 placeholder-gray-500"
                          value={input1}
                          onChange={(e) => setInput1(e.target.value)}
                        />
                        {isMultipleInput && (
                          <>
                            <Text className="font-medium ml-2">and</Text>
                            <Input
                              placeholder="Value or Formula"
                              size="sm"
                              className="!rounded-[3px] !border-gray-500 placeholder-gray-500"
                              value={input2}
                              onChange={(e) => setInput2(e.target.value)}
                            />
                          </>
                        )}
                      </Flex>
                    )}
                  </>
                )}
                <FilterByValueRow />
                <AllFiltersError error={error} />
                {!editDisabled && (
                  <Flex className="justify-between items-center">
                    <Button
                      colorScheme="secondary"
                      leftIcon={<Icon as={DeleteFilled} size="sm" />}
                      onClick={removeFilter}
                      size="sm"
                      variant="ghost"
                    >
                      Remove Filter
                    </Button>
                    <Button onClick={applyFilter} size="sm">
                      Apply Filter
                    </Button>
                  </Flex>
                )}
              </>
            )}
          </Flex>
        </FilterDetailsContext.Provider>
      </PopoverContent>
    );
  }
);
