import { Flex } from "@chakra-ui/layout";
import { Input, Textarea, Box } from "@chakra-ui/react";
import React, { useState } from "react";
import { Node } from "reactflow";
import { ZodError, z } from "zod";

import { Button } from "@/design/components/button";
import {
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
  FormLabel,
} from "@/design/components/form";
import {
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Modal,
  ModalBody,
} from "@/design/components/modal";
import { triggerAutoSave } from "@/features/workflow-studio/redux";
import { useAppDispatch, useAppSelector } from "@/reduxHooks.ts";
import { closeModal } from "@/slices/modal-slice.ts";

// Define validation schema
const schema = z.object({
  flowName: z
    .string()
    .max(100, { message: "Name must be at most 100 characters long" })
    .refine((data) => data.trim() !== "", {
      message: "Name cannot be empty",
    }),

  flowDescription: z
    .string()
    .max(500, { message: "Description must be at most 500 characters long" })
    .refine((data) => data.trim() !== "", {
      message: "Description cannot be empty",
    }),
});

const EditFlowDetailsModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const { node, setNodes } = useAppSelector(
    (state) => state.rootReducer.modals.modalProps
  );
  const [formData, setFormData] = useState<{
    flowName: string;
    flowDescription: string;
  }>({
    flowName: node.data.displayName || "",
    flowDescription: node.data.description || "",
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const autoSave = () => {
    setTimeout(() => {
      dispatch(triggerAutoSave());
    }, 300);
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const validatedData = schema.parse(formData);
      setErrors({});
      setNodes((prevNodes: Node[]) => {
        const updatedNodes = prevNodes.map((n) => {
          if (n.id === node.id) {
            return {
              ...n,
              data: {
                ...n.data,
                name: n.data.name,
                displayName: validatedData.flowName,
                displayText: validatedData.flowName,
                description: validatedData.flowDescription,
              },
            };
          }
          return n;
        });
        return updatedNodes;
      });
      autoSave();
      dispatch(closeModal());
    } catch (error) {
      if (error instanceof ZodError) {
        const fieldErrors: { [key: string]: string } = {};
        error.errors.forEach((err) => {
          if (err.path.length > 0) {
            fieldErrors[err.path[0]] = err.message;
          }
        });
        setErrors(fieldErrors);
      }
    }
  };

  const onClose = () => {
    dispatch(closeModal());
  };

  return (
    <Modal isOpen={true} size="lg" isCentered onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Flow Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {!node ? (
            <Box>Invalid Flow</Box>
          ) : (
            <form onSubmit={handleSubmit} className="!px-5 gap-4 flex flex-col">
              <FormControl isRequired isInvalid={!!errors.flowName}>
                <FormLabel fontSize="sm">Flow Name</FormLabel>
                <Input
                  className="disabled:bg-gray-300 disabled:text-gray-900"
                  name="flowName"
                  onChange={handleInputChange}
                  size={"sm"}
                  type="text"
                  value={formData.flowName}
                />
                <FormErrorMessage>
                  <FormErrorIcon />
                  {errors.flowName}
                </FormErrorMessage>
              </FormControl>
              <FormControl isRequired isInvalid={!!errors.flowDescription}>
                <FormLabel fontSize="sm">Description</FormLabel>
                <Textarea
                  className="!text-sm"
                  name="flowDescription"
                  onChange={handleInputChange}
                  rows={5}
                  value={formData.flowDescription}
                />
                <FormErrorMessage>
                  <FormErrorIcon />
                  {errors.flowDescription}
                </FormErrorMessage>
              </FormControl>
              <Flex justifyContent={"flex-end"} gap={3}>
                <Button
                  onClick={onClose}
                  variant="outline"
                  size="sm"
                  colorScheme="dark"
                >
                  Cancel
                </Button>
                <Button type="submit" size="sm" colorScheme="dark">
                  Save
                </Button>
              </Flex>
            </form>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default EditFlowDetailsModal;
