import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

import { useAppDispatch, useAppSelector } from "@/reduxHooks";
import { closeModal } from "@/slices/modal-slice";

export const ConfirmNodeDelete = () => {
  const dispatch = useAppDispatch();
  const { nodeData, onConfirmDelete } = useAppSelector(
    (state) => state.rootReducer.modals
  ).modalProps;

  const hasOutputData = nodeData.isOutputDataAvailable;

  const onClose = () => {
    dispatch(closeModal());
  };

  const handleDelete = () => {
    onConfirmDelete();
    onClose();
  };

  return (
    <Modal isCentered isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent className="!w-[80vw]">
        <ModalHeader className="flex gap-3 items-start justify-between border-b !ml-0 !pb-3 !pl-5">
          <Box className="w-fit text-lg text-gray-800 font-medium break-all">
            Delete <strong>{nodeData.displayName}</strong>?{" "}
          </Box>
          <ModalCloseButton className="!relative !top-0 !right-0" />
        </ModalHeader>
        <ModalBody className="!px-4 py-3">
          <Flex className="flex-col gap-4">
            You will lose all {hasOutputData && "output data and "}node
            configuration. This could break your workflow and you might need to
            execute some nodes again.
          </Flex>
          <Flex className="items-center justify-end gap-2">
            <Button colorScheme="dark" onClick={onClose} variant={"outline"}>
              Cancel
            </Button>
            <Button colorScheme="customRed" onClick={handleDelete}>
              Delete
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
