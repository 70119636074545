import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { MdCheck } from "react-icons/md";
import {
  MdsDownloadRound,
  MdsErrorRound
} from "react-icons-with-materialsymbols/mds";

import { ControlledSelectNoForm } from "@/components/controlled-select";
import { useAppSelector } from "@/reduxHooks";
import { DATASET_FORMAT } from "@/utils/enums";

import { getDatasetIconColor } from "../../components/ongoing-uploads/get-icon";
import useDatasetActions from "../../hooks/useDatasetActions";
import { DatasetsSchema } from "../../types";

import { DownloadTitle } from "./download-title";

interface DownloadOption {
  label: string;
  value: DATASET_FORMAT;
}

export const DownloadDatasetBody = () => {
  const availableOptions: DownloadOption[] = [
    { label: "CSV  (comma-separated values)", value: DATASET_FORMAT.CSV },
    { label: "XLSX  (Microsoft Excel)", value: DATASET_FORMAT.XLSX },
  ];
  const { modalProps } = useAppSelector((s) => s.rootReducer.modals);
  const { downloadDataset, isAddingDownload } = useDatasetActions();

  const dataset = modalProps?.dataset as DatasetsSchema;
  const iconColor = getDatasetIconColor(dataset?.ioRecord?.ioType || "input");

  const [downloadFormat, setdownloadFormat] = useState<DownloadOption>(
    availableOptions[0]
  );

  const [showDataLimitWarning, setShowDataLimitWarning] = useState(false);

  const onDownload = async () => {
    try {
      await downloadDataset({
        dataset,
        format: downloadFormat.value,
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (
      downloadFormat.value === DATASET_FORMAT.XLSX &&
      dataset.rowCount > 400000
    ) {
      setShowDataLimitWarning(true);
    } else {
      setShowDataLimitWarning(false);
    }
  }, [downloadFormat, dataset.rowCount]);

  return (
    <Flex className="flex-col gap-3">
      <DownloadTitle iconColor={iconColor} title={dataset.displayName} />
      <FormControl>
        <FormLabel className="!text-sm">Select format</FormLabel>
        <ControlledSelectNoForm
          isSearchable={false}
          value={downloadFormat}
          selectedOptionStyle="check"
          components={{
            Option: ({ data, isSelected, ...props }: any) => {
              return (
                <Flex
                  {...props}
                  className={`px-2 !py-0 cursor-pointer items-center flex-row-reverse ${
                    isSelected ? "bg-gray-100" : ""
                  }`}
                  onClick={() => props.setValue(data)}
                >
                  {isSelected && (
                    <MdCheck className="w-fit shrink-0" />
                  )}
                  <Flex className="items-center gap-3 !grow p-2 overflow-hidden">
                    <Box className="text-sm  truncate">{data.label}</Box>
                  </Flex>
                </Flex>
              );
            },
          }}
          options={availableOptions}
          onChange={(option) => setdownloadFormat(option as DownloadOption)}
          className="[&_.chakra-menu__icon-wrapper]:!grow-0"
        />
      </FormControl>
      {showDataLimitWarning && (
        <Flex className="text-red-600 items-center text-sm gap-2">
          <Icon className="!text-red-600" as={MdsErrorRound} strokeWidth={22} />
          Only 400k records will be downloaded
        </Flex>
      )}
      <Button
        className="w-fit mt-8"
        alignSelf={"flex-end"}
        colorScheme="dark"
        isDisabled={isAddingDownload}
        isLoading={isAddingDownload}
        leftIcon={<Icon as={MdsDownloadRound} strokeWidth={22} />}
        onClick={onDownload}
      >
        Download
      </Button>
    </Flex>
  );
};
