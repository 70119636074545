import { Input } from "@chakra-ui/input";
import { isEmpty } from "lodash";
import { useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import {
  ControlledSelectNoForm,
  CustomOptions,
  MultiValue,
  MultiValueContainer,
  ValueContainer,
} from "@/components/controlled-select";
import {
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
  FormHelperText,
  FormHelperTextIcon,
  FormLabel,
} from "@/design/components/form";
import {
  arrayToString,
  COLUMN_IDENTIFIER,
  COMMA_SEPARATOR,
  convertArrayToLabelValue,
  ViewTransformationParameter,
  LabelValuePair,
  stringToArray,
  tableColumns,
} from "@/features/data-transformation";

import { StepFormContext } from "../step-form-context";

export const MultiSelectSelectForm = ({
  param,
  errors,
}: {
  param: Partial<ViewTransformationParameter>;
  errors?: string;
}) => {
  const columns = useSelector(tableColumns);
  const showColumns = param.identifier === COLUMN_IDENTIFIER;

  const { step, editEnabled, register, setValue } =
    useContext(StepFormContext)!;

  const oldColumns = step?.metadata?.onSample?.previousStepColumnInfo;
  const dropdownColumns = isEmpty(oldColumns)
    ? columns.map((item) => item.name)
    : oldColumns;

  const defaultValue = useMemo(() => {
    const value = (param.value ?? param.defaultValue ?? "") as string;
    const seperatorReplaced = value.replaceAll(COMMA_SEPARATOR, ",");
    return seperatorReplaced;
  }, [param]);

  const defaultValueArray = convertArrayToLabelValue(
    stringToArray(defaultValue, dropdownColumns ?? [])
  );

  useEffect(() => {
    setSelectedValue(defaultValueArray);
  }, [param]);

  const [selectedValue, setSelectedValue] =
    useState<LabelValuePair[]>(defaultValueArray);

  const list = showColumns ? dropdownColumns : param.valuesList;

  const isEnabled = param.isModifiable && editEnabled;

  const onChange = (newValue: any) => {
    const newVal = newValue as LabelValuePair[];
    if (!list || isEmpty(list)) {
      setSelectedValue(newVal);
      setValue(
        param.shortName,
        arrayToString(newVal.map((_item) => _item.value)),
        {
          shouldDirty: true,
        }
      );
      return;
    }

    const possibleValuesSet = new Set(list);
    const valueArr = newVal.map((item) => item.value);

    const filteredValues = valueArr.filter((value) =>
      possibleValuesSet.has(value)
    );

    const labelValue = convertArrayToLabelValue(filteredValues);
    setSelectedValue(labelValue);
    setValue(param.shortName, arrayToString(filteredValues), {
      shouldDirty: true,
    });
  };

  return (
    <FormControl
      key={param.shortName}
      isRequired={param.isMandatory}
      isInvalid={!!errors}
      isDisabled={!isEnabled}
    >
      <FormLabel className="!text-sm !mb-1.5 font-medium leading-none">
        {param.name}
      </FormLabel>
      <Input
        type="string"
        id={param.shortName}
        data-shortname={param.shortName}
        value={arrayToString(selectedValue.map((item) => item.value))}
        hidden={true}
        required={param.isMandatory}
        {...register(param.shortName)}
        readOnly
      />
      <ControlledSelectNoForm
        options={convertArrayToLabelValue(list ?? [])}
        data-shortname={param.shortName}
        id={param.shortName}
        closeMenuOnSelect={false}
        isClearable={false}
        value={selectedValue}
        onChange={onChange}
        size="sm"
        placeholder="select option"
        selectedOptionStyle="check"
        components={{
          Option: CustomOptions,
          ValueContainer,
          MultiValueContainer,
          MultiValue,
        }}
        hideSelectedOptions={false}
        isRequired={param.isMandatory}
        isMulti
        useBasicStyles
      />
      {!errors && param?.hintText && (
        <FormHelperText className="pl-3 text-[13px]">
          <FormHelperTextIcon className="!self-start" />
          {param?.hintText}
        </FormHelperText>
      )}
      <FormErrorMessage className="pl-3 text-[13px]">
        <FormErrorIcon />
        {errors && errors}
      </FormErrorMessage>
    </FormControl>
  );
};
