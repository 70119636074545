import { EndpointBuilder } from "@reduxjs/toolkit/query";

import {
  AnalysesCreateSchema,
  AnalysesListSchema,
  PartialAnalysesListSchema,
  TagsList,
} from "@/features/ws-manager/types";
import { ApiResponse } from "@/types";
import { ACTION_TYPE } from "@/utils/enums.ts";

import { wsManagerApi } from "./api";
import { WsManagerEndpoints } from "./api-endpoints.ts";
import { removeAnalysis } from "./ws-api-utils.ts";

const mergeCacheKeys = (currentCache: any, newItems: any, { arg }: any) => {
  if (!arg || !arg.next) return newItems;
  const pagination = newItems.response.pagination;
  const newData = {
    analyses: [
      ...(currentCache.response.data?.analyses ?? []),
      ...(newItems.response.data?.analyses ?? []),
    ],
  };

  return {
    ...newItems,
    response: {
      pagination: pagination,
      data: newData,
    },
  };
};

const serializeCacheKeys = ({ queryArgs }: any) => {
  if (!queryArgs || queryArgs.query === "") return {};

  const newQueryArgs = { ...queryArgs };

  if (newQueryArgs?.next) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const params = new URLSearchParams(newQueryArgs.next);
    const queryValue = params.get("query");
    if (queryValue) {
      Object.assign(newQueryArgs, { ...newQueryArgs, query: queryValue });
    }

    delete newQueryArgs.next;
  }

  return newQueryArgs;
};
export const analysisAPI = wsManagerApi.injectEndpoints({
  endpoints: (build: EndpointBuilder<any, any, any>) => ({
    createAnalyses: build.mutation<
      ApiResponse<AnalysesListSchema>,
      AnalysesCreateSchema & { projectId: string }
    >({
      query: (body) => {
        return {
          url: WsManagerEndpoints.createAnalyses(body.projectId),
          method: "POST",
          data: body,
        };
      },
      invalidatesTags: ["Analysis"],
    }),
    updateAnalyses: build.mutation<
      ApiResponse<AnalysesListSchema>,
      Partial<
        AnalysesCreateSchema & {
          projectId: string;
          action: ACTION_TYPE;
        }
      > & { id: string }
    >({
      query: (body) => {
        const userId = import.meta.env.VITE_USER_ID;
        const queryObj = {
          url: WsManagerEndpoints.analyses(body.id),
          method: "PUT",
          data: { ...body, client_id: userId },
        };
        if (body.action) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          queryObj["params"] = { action: body.action };
        }
        return queryObj;
      },
      invalidatesTags: (_, _error, body) => [
        { type: "Analysis", id: body.id },
        { type: "Analysis", id: "LIST" },
        { type: "Analysis" },
        { type: "Analysis", id: "FAVOURITES" },
        { type: "SearchInWs", id: body.id },
        { type: "SearchInWs", id: "LIST" },
      ],
    }),
    deleteAnalysis: build.mutation<
      ApiResponse<AnalysesListSchema>,
      { id: string; parent: string | undefined; parentId: string | undefined }
    >({
      query({ id }) {
        return {
          url: WsManagerEndpoints.analyses(id),
          method: "DELETE",
        };
      },
      onQueryStarted: async (body, { dispatch, queryFulfilled }) => {
        const { data: res } = await queryFulfilled;
        removeAnalysis(dispatch, body, res);
      },
      invalidatesTags: (_, _error, id) => [
        { type: "Analysis", id: id },
        { type: "Analysis", id: "LIST" },
        { type: "Analysis" },
        { type: "Analysis", id: "FAVOURITES" },
        { type: "SearchInWs", id: id },
        { type: "SearchInWs", id: "LIST" },
      ],
    }),
    getAnalysisListForProject: build.query<
      ApiResponse<AnalysesListSchema>,
      { projectId?: string; wsId?: string; query?: string; next?: string }
    >({
      query: ({ projectId = "", wsId = "", ...body }) => {
        let url = WsManagerEndpoints.getAnalysisListForProject(
          projectId,
          wsId
        );

        if (body.query) {
          url = url + `?filter=analysis&query=${body.query}`;
        } else {
          url = url + `/analyses/`;
        }
        return {
          url: body?.next ?? url,
          // ignoreBaseUrl: body?.next,
        };
      },
      serializeQueryArgs: ({ queryArgs }) =>
        "AnalysisListForProject" + queryArgs.projectId,
      merge: mergeCacheKeys,
      providesTags: (result = { response: { data: null } }) => [
        ...(result.response.data?.analyses ?? []).map(
          ({ id }) => ({ type: "Analysis", id }) as const
        ),
        { type: "Analysis", id: "LIST-PROJECT" },
        { type: "Analysis", id: "LIST" },
      ],
    }),
    getAnalysisListForWs: build.query<
      ApiResponse<AnalysesListSchema>,
      { wsId?: string; query?: string; next?: string }
    >({
      query: ({ wsId = "", ...body }) => {
        let url = WsManagerEndpoints.getAnalysisListForWs(wsId);

        if (body.query) {
          url = url + `&query=${body.query}`;
        }
        return {
          url: body?.next ?? url,
          // ignoreBaseUrl: body?.next ?? false,
        };
      },
      serializeQueryArgs: ({ queryArgs }) => {
        return "AnalysisListForWs" + queryArgs.wsId;
      },
      merge: mergeCacheKeys,
      providesTags: ["Analysis"],
    }),
    getAnalysisListForOrg: build.query<
      ApiResponse<AnalysesListSchema>,
      void | { query?: string; next?: string }
    >({
      query: (body) => {
        const clientId = import.meta.env.VITE_CLIENT_ID;
        let url = WsManagerEndpoints.getAnalysisListForOrg(clientId);
        if (body && body.query) {
          url = url + `&query=${body.query}`;
        }
        return {
          url: body?.next ?? url,
          // ignoreBaseUrl: body?.next,
        };
      },
      serializeQueryArgs: () => "getAnalysisListForOrg",
      merge: mergeCacheKeys,

      providesTags: (result = { response: { data: null } }) => [
        ...(result.response.data?.analyses ?? []).map(
          ({ id }) => ({ type: "Analysis", id }) as const
        ),
        { type: "Analysis", id: "LIST-ORG" },
        { type: "Analysis", id: "LIST" },
      ],
    }),
    getAnalysisFavourite: build.query<
      ApiResponse<{ favorites: PartialAnalysesListSchema }>,
      void
    >({
      query: () => {
        const cliendId = import.meta.env.VITE_CLIENT_ID;
        const url = WsManagerEndpoints.getAnalysisFavourite(cliendId);
        return {
          url: url,
        };
      },

      providesTags: [{ type: "Analysis", id: "FAVOURITES" }],
    }),
    getSingleAnalysis: build.query<
      ApiResponse<AnalysesListSchema>,
      { id: string }
    >({
      query: ({ id }) => {
        const url = WsManagerEndpoints.analyses(id);
        return {
          url: url,
        };
      },

      providesTags: (_, _r, { id }) => [{ type: "Analysis", id: id }],
    }),
    getTags: build.query<ApiResponse<TagsList>, { wsId: string }>({
      query: ({ wsId }) => {
        return {
          url: WsManagerEndpoints.getTags(wsId),
        };
      },
      providesTags: ["Tags"],
    }),
    //TODO: This is a test API for auth
    testApi: build.mutation<ApiResponse<any>, any>({
      query: () => {
        return {
          url: "https://backend.stage.neo.clootrack.com/resource-mgmt/api/v1.0/webhook/",
          method: "POST",
          data: {},
          // ignoreBaseUrl: true,
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateAnalysesMutation,
  useUpdateAnalysesMutation,
  useDeleteAnalysisMutation,
  useLazyGetAnalysisListForWsQuery,
  useLazyGetAnalysisListForProjectQuery,
  useLazyGetAnalysisListForOrgQuery,
  useLazyGetTagsQuery,
  useGetAnalysisFavouriteQuery,
  useGetSingleAnalysisQuery,
  useLazyGetSingleAnalysisQuery,
  useTestApiMutation,
} = analysisAPI;
