import { useMsal } from "@azure/msal-react";
import isEmpty from "lodash/isEmpty";
import { useState, useContext, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ToastType, useShowToast } from "@/components/toast";
import {
  EdaMetaDataContext,
  compiledTransformationSteps,
  useSaveEDAStepsMutation,
  saveEditCalls,
  useCloseEDAMutation,
  executeInProgress,
  STEP_STATUS,
  activeUser,
  ORIGIN,
} from "@/features/data-transformation";
import { useReadOnlyMode } from "@/features/data-transformation/hooks/useReadOnlyMode.ts";
import { getEditingAllowed } from "@/features/workflow-studio/redux";
import { ModalTypes, openModal } from "@/slices/modal-slice.ts";

export const useEdaActions = () => {
  const dispatch = useDispatch();

  const toast = useShowToast();
  const { analysisId, edaId, closeEda, saveEda, edaFrom } =
    useContext(EdaMetaDataContext);

  const { instance } = useMsal();
  const currentActiveUser = useSelector(activeUser);

  const compiledSteps = useSelector(compiledTransformationSteps);
  const isExecuting = useSelector(executeInProgress);
  const editCalls = useSelector(saveEditCalls);
  const isWFEditAllowed = useSelector(getEditingAllowed);

  const [closeAPI] = useCloseEDAMutation();

  const [saveSteps] = useSaveEDAStepsMutation();

  const [isSaving, setIsSaving] = useState(false);
  const [isInitializing, setIsInitializing] = useState(true);

  const { hasWriteAccess } = useReadOnlyMode();

  const closePanel = () => {
    if (edaId) {
      // Assuming closeAPI is defined somewhere
      closeAPI({
        analysisId: analysisId!,
        edaId: edaId,
      });
    }

    closeEda?.();
  };

  const closeEdaPage = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    closePanel();
  };

  const onSaveAction = async () => {
    if (!edaId) return;
    try {
      setIsSaving(true);
      await saveSteps({
        analysisId: analysisId!,
        edaId,
        execute: false,
        confirm: true,
      });
      setIsSaving(false);
      saveEda?.();
    } catch (e) {
      toast({
        title: "Unable to save steps please try again",
        status: ToastType.Error,
        duration: 3000,
      });
      console.error(e);
    }
  };

  const hasErrorSteps = () => {
    const erroredSteps = compiledSteps.filter(
      (step) => step.status == STEP_STATUS.Failed && step.activeStatus
    );
    if (!isEmpty(erroredSteps)) {
      dispatch(
        openModal({
          modalType: ModalTypes.ERROR_STEPS_WARNING,
          modalProps: {
            issues: erroredSteps,
          },
        })
      );

      return true;
    }

    return false;
  };

  const account = instance.getActiveAccount();
  const { idTokenClaims } = account!;

  const hasUser = useMemo(() => {
    if (!currentActiveUser || !idTokenClaims?.oid) return false;
    return idTokenClaims.oid === currentActiveUser?.userInfo?.azureOid;
  }, [currentActiveUser, idTokenClaims]);

  useEffect(() => {
    // Check if all required states are initialized
    if (
      isWFEditAllowed !== undefined &&
      hasWriteAccess !== undefined &&
      currentActiveUser !== undefined
    ) {
      setIsInitializing(false);
    }
  }, [isWFEditAllowed, hasWriteAccess, currentActiveUser]);

  const showSaveButton = useMemo(() => {
    // Don't show button while initializing to prevent flicker
    if (isInitializing) return false;
    const enableSaveButton = !isExecuting && hasWriteAccess && hasUser;
    if (edaFrom === ORIGIN.Workflow) {
      return isWFEditAllowed && enableSaveButton;
    }
    return enableSaveButton;
  }, [
    isInitializing,
    hasUser,
    isExecuting,
    hasWriteAccess,
    isWFEditAllowed,
    edaFrom,
  ]);

  return {
    closeEdaPage,
    closePanel,
    onSave: onSaveAction,
    isSaving,
    showSaveButton,
    compiledSteps,
    hasErrorSteps,
  };
};
