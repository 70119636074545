import { Button, Stack } from "@chakra-ui/react";
import clsx from "clsx";
import { useMemo } from "react";
import { MdsEditOffRound } from "react-icons-with-materialsymbols/mds";
import { useParams } from "react-router-dom";
import { Edge, Node, useReactFlow } from "reactflow";

import { ToastType, useShowToast } from "@/components/toast";
import usePermissions from "@/hooks/usePermissions";
import { useAppDispatch, useAppSelector } from "@/reduxHooks";
import { CATEGORY, PERMISSIONS } from "@/utils/enums";

import { useEndEditAccessMutation, useSaveWorkflowMutation } from "../../api";
import {
  currentWfActiveUser,
  getEditingAllowed,
  hideAllPanels,
  setCurrentWfActiveUser,
  setEditingAllowed,
  workflowRunningStatus,
} from "../../redux";
import { ConvertFlowtoWorkflowPayload, NODE_STATUS } from "../../utils";

import { ImportExportButtons } from "./import-export-buttons";
import { RunAndLogButtons } from "./run-log-buttons";
import ViewModeOptions from "./view-mode-options";

export const StatusBar = ({
  nodes,
  edges,
}: {
  nodes: Node[];
  edges: Edge[];
}) => {
  const params = useParams();
  const isEditingAllowed = useAppSelector(getEditingAllowed);
  const wfRunStatus = useAppSelector(workflowRunningStatus);
  const currentUser = useAppSelector(currentWfActiveUser);
  const { toObject } = useReactFlow();

  const { checkPermission } = usePermissions();
  const hasEditAccess = checkPermission({
    requiredPermission: PERMISSIONS.WRITE,
    type: CATEGORY.Analysis,
    id: params["analysisId"]!,
  });

  const dispatch = useAppDispatch();
  const toast = useShowToast(undefined, undefined, true);

  const [endEditAccess, { isLoading: isEnding }] = useEndEditAccessMutation();
  const [saveWorkflow, { isLoading: isSaving }] = useSaveWorkflowMutation();

  const isLoading = isSaving || isEnding;

  const isCurrentUserEditing = currentUser?.isCurrentUser;
  const isWorkflowRunning =
    wfRunStatus === NODE_STATUS.RUNNING ||
    wfRunStatus === NODE_STATUS.CANCELLING;

  const showLockedMessage = useMemo(() => {
    return !isWorkflowRunning && !isCurrentUserEditing && !isEditingAllowed;
  }, [isWorkflowRunning, isCurrentUserEditing, isEditingAllowed]);

  const saveWF = async () => {
    try {
      await saveWorkflow({
        analysisId: params.analysisId!,
        workflow: ConvertFlowtoWorkflowPayload(toObject()!),
        workflowId: params.editorId!,
      }).unwrap();
      return true;
    } catch (err) {
      return false;
    }
  };

  const onEndEditClick = async () => {
    try {
      const saved = await saveWF();
      if (!saved) throw new Error("Failed to save workflow");
      await endEditAccess({
        analysisId: params.analysisId!,
      }).unwrap();

      toast({
        title: "Session closed",
        status: ToastType.Success,
      });
      dispatch(hideAllPanels());
      dispatch(setEditingAllowed(false));
      dispatch(
        setCurrentWfActiveUser({
          user: null,
          status: "",
          isCurrentUser: false,
          timeSinceLastActivity: null,
        })
      );
    } catch (err) {
      toast({
        title: "Failed to end edit access",
        status: ToastType.Error,
      });
    }
  };

  return (
    <Stack
      className={clsx(
        "absolute top-5 left-5 border border-gray-400",
        "rounded-sm bg-white p-1 shadow-noblur",
        "flex gap-1"
      )}
      direction="row"
    >
      {showLockedMessage ? (
        <ViewModeOptions hasEditAccess={hasEditAccess} />
      ) : (
        <>
          <ImportExportButtons />
          <div className="w-0.5 bg-gray-200" />
          <RunAndLogButtons
            hasEditAccess={hasEditAccess}
            nodes={nodes}
            edges={edges}
          />
          {isCurrentUserEditing && isEditingAllowed && (
            <Button
              className="!bg-white !text-red-600"
              colorScheme="red"
              isDisabled={isLoading}
              isLoading={isLoading}
              leftIcon={
                <MdsEditOffRound className="stroke-[22]" fontSize={14} />
              }
              onClick={onEndEditClick}
              size="sm"
              variant={"outline"}
            >
              Stop Editing
            </Button>
          )}
        </>
      )}
    </Stack>
  );
};
