import isEqual from "lodash/isEqual";
import { useState, useCallback, useEffect, useContext, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";

import { SelectedColumn } from "@/features/data-transformation/components/step-form/form-types/multi-config-context";
import { ViewTransformationParameter } from "@/features/data-transformation/types";
import {
  COLUMN_IDENTIFIER,
  FORM_DATA_TYPE,
  CHILD_PARAMETER,
} from "@/features/data-transformation/utils";

import { StepFormContext } from "../components/step-form/step-form-context";

/**
 * Represents a row configuration with a unique ID and child parameters
 */
export interface ConfigRow {
  id: string;
  config: ViewTransformationParameter[];
}

/**
 * Return type for the useMultiConfigTransformation hook
 */
export interface UseMultiConfigTransformationReturn {
  configs: ConfigRow[];
  selectedColumns: SelectedColumn[];
  addNewConfig: () => void;
  removeConfig: (configId: string) => void;
  updateConfigValue: (
    configId: string,
    parameter: ViewTransformationParameter,
    value: string
  ) => void;
  registerSelectedColumn: (column: string, configId: string) => void;
  unregisterSelectedColumn: (column: string, configId: string) => void;
  createNewConfig: (
    childParameters?: ViewTransformationParameter[]
  ) => ConfigRow;
  isDirty: boolean;
}

export const useMultiConfigTransformation = (
  param: Partial<ViewTransformationParameter>
): UseMultiConfigTransformationReturn => {
  const { getValues, unregister, setValue, reset } =
    useContext(StepFormContext)!;

  const createParameterWithIds = useCallback(
    (parameter: ViewTransformationParameter): ViewTransformationParameter => ({
      ...parameter,
      id: parameter.id || uuidv4(),
      parameterId: parameter.parameterId,
    }),
    []
  );

  const createNewConfig = useCallback(
    (
      childParameters?: ViewTransformationParameter[],
      renameColumn?: string
    ): ConfigRow => {
      const configId = uuidv4();
      return {
        id: configId,
        config:
          childParameters?.map((child) => ({
            ...createParameterWithIds(child),
            configId, // Set configId for all parameters
            value:
              child.identifier === COLUMN_IDENTIFIER && renameColumn
                ? renameColumn
                : "",
          })) ?? [],
      };
    },
    [createParameterWithIds]
  );

  const parseApiValue = useCallback(() => {
    // If the value is an array of arrays (API format)
    if (
      Array.isArray(param.value) &&
      param.value.length > 0 &&
      Array.isArray(param.value[0])
    ) {
      return param.value.map((configGroup) => {
        const configId = configGroup[0]?.configId ?? uuidv4();
        return {
          id: configId,
          config: configGroup.map(
            (childParam: ViewTransformationParameter) => ({
              ...childParam,
              id: childParam.id ?? uuidv4(),
              parameterId: childParam.parameterId,
              configId: childParam.configId || configId, // Ensure configId is set
            })
          ),
        };
      });
    }

    // If the value is already in component format or empty, return default
    return [createNewConfig(param.childParameters, param?.renameColumn)];
  }, [param.value, param.childParameters, createNewConfig]);

  /**
   * Extracts selected columns from the parameter value
   */
  const extractSelectedColumns = useCallback(() => {
    if (!Array.isArray(param.value) || param.value.length === 0) {
      return [];
    }

    // Handle nested array format from API
    if (Array.isArray(param.value[0])) {
      return param.value.flatMap((configGroup: any[]) => {
        const columnParam = configGroup.find(
          (p) =>
            p.dataType === FORM_DATA_TYPE.DROPDOWN &&
            p.identifier === COLUMN_IDENTIFIER
        );
        if (columnParam?.value && columnParam.configId) {
          return [
            {
              column: columnParam.value as string,
              configId: columnParam.configId,
            },
          ];
        }
        return [];
      });
    }

    return [];
  }, [param.value]);

  const [configs, setConfigs] = useState<ConfigRow[]>(() => parseApiValue());
  const [selectedColumns, setSelectedColumns] = useState<SelectedColumn[]>(() =>
    extractSelectedColumns()
  );
  const [originalConfigs, setOriginalConfigs] = useState<ConfigRow[]>(() =>
    parseApiValue()
  );

  // Function to normalize configs for comparison (removing IDs which might be different but don't affect actual data)
  const normalizeConfigsForComparison = useCallback(
    (configsToNormalize: ConfigRow[]) => {
      return configsToNormalize.map((config) => ({
        config: config.config.map((paramItem) => ({
          shortName: paramItem.shortName,
          value: paramItem.value,
          dataType: paramItem.dataType,
        })),
      }));
    },
    []
  );

  // Check if current configs are different from original
  const isDirty = useMemo(() => {
    const normalizedOriginal = normalizeConfigsForComparison(originalConfigs);
    const normalizedCurrent = normalizeConfigsForComparison(configs);

    return !isEqual(normalizedOriginal, normalizedCurrent);
  }, [configs, originalConfigs, normalizeConfigsForComparison]);

  const updateSelectedColumns = useCallback(
    (configId: string, value: string) => {
      setSelectedColumns((prev) => {
        const filtered = prev.filter((col) => col.configId !== configId);
        return value ? [...filtered, { column: value, configId }] : filtered;
      });
    },
    []
  );

  const updateConfigsWithNewValue = useCallback(
    (
      prevConfigs: ConfigRow[],
      configId: string,
      parameter: ViewTransformationParameter,
      value: string
    ): ConfigRow[] => {
      return prevConfigs.map((config) => {
        if (config.id !== configId) return config;

        const updatedParams = config.config.map((existingParam) =>
          existingParam.id === parameter.id
            ? { ...existingParam, value, configId }
            : { ...existingParam, configId: existingParam.configId || configId }
        );

        return { ...config, config: updatedParams };
      });
    },
    []
  );

  const addNewConfig = useCallback(() => {
    let newConfigs: ConfigRow[] = [];

    setConfigs((prevConfigs) => {
      // If we have existing configs loaded from API, use the first one as a template
      if (prevConfigs.length > 0) {
        const templateConfig = prevConfigs[0];
        // Create a new config based on the template but with empty values
        const newConfig: ConfigRow = {
          id: uuidv4(),
          config: templateConfig.config.map((_param) => ({
            ..._param,
            id: uuidv4(),
            value: "", // Reset the value
            configId: undefined, // Will be set during updateConfigValue
          })),
        };
        newConfigs = [...prevConfigs, newConfig];
        return newConfigs;
      }

      // If no existing configs, create a new one from param.childParameters
      newConfigs = [...prevConfigs, createNewConfig(param.childParameters)];
      return newConfigs;
    });

    // Force the form to be dirty when adding a new config
    // This is always true since adding a row always makes the form dirty
    const dummyFieldName = `${param.shortName}_dirty_state_tracker`;
    setValue(dummyFieldName, Date.now(), { shouldDirty: true });
  }, [param.childParameters, createNewConfig, param.shortName, setValue]);

  /**
   * Removes a config row by its ID and unregisters its form fields
   */
  const removeConfig = useCallback(
    (configId: string) => {
      // Find the config to be removed to get its parameters
      const configToRemove = configs.find((c) => c.id === configId);

      if (configToRemove && unregister) {
        // Unregister each parameter in the config
        configToRemove.config.forEach((parameter) => {
          const controlKey = `${CHILD_PARAMETER}%%%${param.shortName}%%%${parameter.shortName}%%%${configId}`;
          unregister(controlKey);
        });
      }

      // Remove the config from state
      const newConfigs = configs.filter((c) => c.id !== configId);
      setConfigs(newConfigs);

      // Remove any selected columns for this config
      setSelectedColumns((prev) =>
        prev.filter((col) => col.configId !== configId)
      );

      // Check if the new configs are the same as the original configs
      const normalizedOriginal = normalizeConfigsForComparison(originalConfigs);
      const normalizedNew = normalizeConfigsForComparison(newConfigs);
      const isBackToOriginal = isEqual(normalizedOriginal, normalizedNew);

      // Update form dirty state based on comparison with original configs
      if (isBackToOriginal) {
        // If we're back to the original state, reset the form's dirty state
        // for this specific field
        const dummyFieldName = `${param.shortName}_dirty_state_tracker`;
        unregister(dummyFieldName);
        setValue(dummyFieldName, undefined, { 
          shouldDirty: false,
          shouldTouch: false
        });
        
        // Force a complete form state reset while keeping values
        reset(getValues(), {
          keepValues: true,
          keepDirty: false,
          keepErrors: true,
          keepIsSubmitted: true,
          keepTouched: true,
          keepIsValid: true,
          keepSubmitCount: true
        });
      } else {
        // If configs are still different from original, ensure form is marked as dirty
        const dummyFieldName = `${param.shortName}_dirty_state_tracker`;
        setValue(dummyFieldName, Date.now(), { shouldDirty: true });
      }
    },
    [
      configs,
      param.shortName,
      unregister,
      setValue,
      originalConfigs,
      normalizeConfigsForComparison,
      reset,
    ]
  );

  /**
   * Updates a specific parameter value in a config row
   */
  const updateConfigValue = useCallback(
    (
      configId: string,
      parameter: ViewTransformationParameter,
      value: string
    ) => {
      // Handle column selection updates if this is a column dropdown
      const isColumnDropdown =
        parameter.dataType === FORM_DATA_TYPE.DROPDOWN &&
        parameter.identifier === COLUMN_IDENTIFIER;

      if (isColumnDropdown) {
        updateSelectedColumns(configId, value);
      }

      // Update the config with the new parameter value
      const newConfigs = updateConfigsWithNewValue(
        configs,
        configId,
        parameter,
        value
      );
      setConfigs(newConfigs);

      // Check if the new configs are the same as the original configs
      const normalizedOriginal = normalizeConfigsForComparison(originalConfigs);
      const normalizedNew = normalizeConfigsForComparison(newConfigs);
      const isBackToOriginal = isEqual(normalizedOriginal, normalizedNew);

      // Update form dirty state based on comparison with original configs
      const dummyFieldName = `${param.shortName}_dirty_state_tracker`;

      if (isBackToOriginal) {
        // If we're back to the original state, reset the form's dirty state
        unregister(dummyFieldName);
        setValue(dummyFieldName, undefined, { 
          shouldDirty: false,
          shouldTouch: false
        });
        
        // Force a complete form state reset while keeping values
        reset(undefined, {
          keepValues: true,
          keepDirty: false,
          keepErrors: true,
          keepIsSubmitted: true,
          keepTouched: true,
          keepIsValid: true,
          keepSubmitCount: true
        });
      } else {
        // If configs are still different from original, ensure form is marked as dirty
        setValue(dummyFieldName, Date.now(), { shouldDirty: true });
      }
    },
    [
      configs,
      param.shortName,
      setValue,
      reset,
      originalConfigs,
      normalizeConfigsForComparison,
      updateConfigsWithNewValue,
      updateSelectedColumns,
      unregister,
    ]
  );

  /**
   * Registers a selected column
   */
  const registerSelectedColumn = useCallback(
    (column: string, configId: string) => {
      setSelectedColumns((prev) => [...prev, { column, configId }]);
    },
    []
  );

  /**
   * Unregisters a selected column
   */
  const unregisterSelectedColumn = useCallback(
    (column: string, configId: string) => {
      setSelectedColumns((prev) =>
        prev.filter(
          (col) => !(col.column === column && col.configId === configId)
        )
      );
    },
    []
  );

  useEffect(() => {
    if (Array.isArray(param.value)) {
      const parsedConfigs = parseApiValue();
      setConfigs(parsedConfigs);
      setOriginalConfigs(parsedConfigs);
      setSelectedColumns(extractSelectedColumns());
    }
  }, [param.value, parseApiValue, extractSelectedColumns]);

  return {
    configs,
    selectedColumns,
    addNewConfig,
    removeConfig,
    updateConfigValue,
    registerSelectedColumn,
    unregisterSelectedColumn,
    createNewConfig,
    isDirty,
  };
};
