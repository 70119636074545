import { IntlProvider } from "react-intl";

import { ConnectorGrid } from "@/airbyte-component-docs/components/source/SelectConnector/ConnectorGrid";
import { AirbyteThemeProvider } from "@/airbyte-component-docs/hooks/theme/useAirbyteTheme";
import messages from "@/airbyte-component-docs/locales/en.json";

import { IDataSource } from "../../types";

interface SelectConnectorProps {
  connectorType: "source" | "destination";
  sourceListData: IDataSource[];
  onSelectConnectorDefinition: (definition: any, event?: any) => void;
}

export const SelectConnector: React.FC<SelectConnectorProps> = (props) => {
  return (
    <IntlProvider locale="en" messages={messages}>
      <AirbyteThemeProvider>
        <SelectConnectorSupportLevel {...props} />
      </AirbyteThemeProvider>
    </IntlProvider>
  );
};

const SelectConnectorSupportLevel: React.FC<SelectConnectorProps> = ({
  onSelectConnectorDefinition,
  sourceListData,
}) => {
  return (
    // add padding to the top and bottom of the grid
    <div style={{ overflowY: "auto", padding: "16px" }}>
      <ConnectorGrid
        connectorDefinitions={sourceListData as any}
        onConnectorButtonClick={(definition: any, event: any) =>
          onSelectConnectorDefinition(definition, event)
        }
        searchResultsHiddenByFilters={0}
      />
    </div>
  );
};
