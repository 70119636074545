import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
} from "@chakra-ui/react";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { MdCheck } from "react-icons/md";

import { ControlledSelectNoForm } from "@/components/controlled-select";
import { ExcelIcon } from "@/components/icons/excel-icon";
import { useAppDispatch, useAppSelector } from "@/reduxHooks";
import { closeModal } from "@/slices/modal-slice";
import { SOURCE_FILE_TYPE } from "@/utils/enums";

import {
  useLazyGetIoRecordMetaDataQuery,
  useLazyGetOngoingUploadsQuery,
} from "../../api";
import { useFileUpload } from "../../hooks/useUploadFile";
import { IDataSource, IFileExtraOptions } from "../../types";
import { getExcelSheetNames } from "../../utils/excel-helper";

interface SheetOption {
  label: string;
  value: string;
}

export const SelectSheetBody = ({
  processIoRecord,
  isProcessing,
  isSubmitting,
  setIsSubmitting,
}: {
  processIoRecord: any;
  isProcessing: boolean;
  isSubmitting: boolean;
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const dispatch = useAppDispatch();
  const { modalProps } = useAppSelector((s) => s.rootReducer.modals);
  const { handleFileUpload } = useFileUpload({});
  const [sheetNames, setsheetNames] = useState([] as SheetOption[]);
  const [selectedSheet, setSelectedSheet] = useState<SheetOption>();

  const [sheetSelectionError, setSheetSelectionError] = useState<string | null>(
    null
  );

  const [getMetadata, { isFetching: isFetchingSheets }] =
    useLazyGetIoRecordMetaDataQuery();
  const [refetchIoRecords] = useLazyGetOngoingUploadsQuery();

  const getUploadMetaData = async () => {
    try {
      const response = await getMetadata({
        ioId: modalProps.ioRecord.id as string,
        analysisId: modalProps.analysisId as string,
      }).unwrap();

      const excelSheetNames =
        response?.response?.data?.results[0]?.metadata?.sheetNames?.map(
          (name: string) => ({
            label: name,
            value: name,
          })
        ) ?? [];
      setsheetNames(excelSheetNames as SheetOption[]);
      setSelectedSheet(excelSheetNames[0] as SheetOption);
    } catch (error) {
      console.error(error);
      setSheetSelectionError("Error fetching sheet names");
    }
  };

  useEffect(() => {
    if (modalProps.uploadType === SOURCE_FILE_TYPE.AIRBYTE) {
      getUploadMetaData().catch((error) => {
        console.error(error);
      });
    } else {
      getExcelSheetNames(modalProps.selectedFile as File)
        .then((sheets) => {
          setsheetNames(sheets.map((name) => ({ label: name, value: name })));
          setSelectedSheet({ label: sheets[0], value: sheets[0] });
        })
        .catch((error) => {
          setSheetSelectionError("Error getting sheet names");
          console.error("Error getting sheet names", error);
        });
    }
  }, [modalProps.selectedFile]);

  const handleExcelUpload = () => {
    if (!selectedSheet) return;
    setIsSubmitting(true);
    try {
      if (modalProps.uploadType === SOURCE_FILE_TYPE.AIRBYTE) {
        processIoRecord({
          ioId: modalProps.ioRecord.id as string,
          analysisId: modalProps.analysisId as string,
          body: {
            readerOptions: {
              excelSheetName: selectedSheet?.value,
            },
          },
        })
          .unwrap()
          .then(async () => {
            await refetchIoRecords({
              analysisId: modalProps.analysisId,
            }).unwrap();
            setIsSubmitting(false);
            dispatch(closeModal());
          })
          .catch((e: any) => {
            console.error(e);
            setIsSubmitting(false);
          });
      } else {
        handleFileUpload({
          selectedFile: modalProps.selectedFile as File,
          fileName: modalProps.fileName as string,
          source: modalProps.source as IDataSource,
          options: { sheetName: selectedSheet?.value } as IFileExtraOptions,
        }).catch((error) => {
          console.error(error);
        });
      }
    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
    }
  };

  const disableProceed = !selectedSheet || isProcessing || isSubmitting;

  return (
    <Flex className="flex-col gap-3">
      <Flex className="items-center p-2 gap-3 border rounded">
        <Box className="bg-gray-50 p-2 rounded">
          <Icon
            className="!text-green-600 rounded"
            as={ExcelIcon}
            fontSize={22}
          />
        </Box>
        <Box className="font-medium">{modalProps.fileName}</Box>
      </Flex>
      <FormControl isInvalid={!isEmpty(sheetSelectionError)}>
        <FormLabel className="!text-sm !text-gray-700">
          Choose the sheet you want to import
        </FormLabel>
        <ControlledSelectNoForm
          selectedOptionStyle="check"
          isDisabled={sheetNames.length === 0 || isProcessing}
          isLoading={isFetchingSheets}
          onChange={(value) => setSelectedSheet(value as SheetOption)}
          options={sheetNames}
          value={selectedSheet}
          components={{
            Option: ({ data, isSelected, ...props }: any) => (
              <Flex
                {...props}
                className={`px-2 !py-0 cursor-pointer items-center flex-row-reverse ${
                  isSelected ? "bg-gray-100" : ""
                }`}
                onClick={() => props.setValue(data)}
              >
                {isSelected && <MdCheck className="w-fit shrink-0" />}
                <Flex className="items-center gap-3 !grow p-2 overflow-hidden">
                  <Box className="text-sm  truncate">{data.label}</Box>
                </Flex>
              </Flex>
            ),
          }}
          placeholder="Select Sheet"
          selectedOptionColorScheme="gray"
          maxMenuHeight={200}
        />
        <FormErrorMessage>{sheetSelectionError}</FormErrorMessage>
      </FormControl>
      <Button
        className="w-fit"
        alignSelf={"flex-end"}
        colorScheme="dark"
        isDisabled={disableProceed}
        isLoading={isSubmitting}
        onClick={handleExcelUpload}
      >
        Proceed
      </Button>
    </Flex>
  );
};
