/**
 * Filters an array of strings based on a search string.
 * 
 * @param availableValues - Array of strings to filter
 * @param searchString - Search string to filter by
 * @returns Filtered array of strings
 */
export const searchedValues = (
  availableValues: string[] | null,
  searchString: string
): string[] => {
  if (!availableValues) return [];

  // Trim the search string and convert to lowercase
  const trimmedSearchString = searchString.trim().toLowerCase();

  // If the search string is empty after trimming, return all values
  if (trimmedSearchString === "") return availableValues;

  return availableValues.filter((item) => {
    if (!item) return false;

    const lowerItem = item.toLowerCase();

    // Check if the item contains the entire search string
    if (lowerItem.includes(trimmedSearchString)) return true;

    // For multi-word searches, check if the words appear in sequence
    if (trimmedSearchString.includes(" ")) {
      const searchWords = trimmedSearchString.split(/\s+/);
      const itemWords = lowerItem.split(/\s+/);
      
      // Check for exact sequence match
      for (let i = 0; i <= itemWords.length - searchWords.length; i++) {
        let matchesSequence = true;
        
        for (let j = 0; j < searchWords.length; j++) {
          if (!itemWords[i + j].startsWith(searchWords[j])) {
            matchesSequence = false;
            break;
          }
        }
        
        if (matchesSequence) return true;
      }
    }

    return false;
  });
}; 