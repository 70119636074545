import { createContext } from "react";

import { ViewFiltersRequest } from "@/features/data-transformation";

export const FilterContext = createContext<{
  filter: ViewFiltersRequest;
  previousColumns?: string[] | undefined;
  isFilterOpen: boolean;
  editDisabled?: boolean;
  currentFilter: string | null;
  placement: string;
  appliedFilters: ViewFiltersRequest[];
  changeFilterOpenState: (arg0: boolean) => void;
  changeCurrentOpenFilter: (arg0: string | null) => void;
  changeTempFilter: (arg0: ViewFiltersRequest | null) => void;
  updateSavedFilters: (
    arg0: ViewFiltersRequest[],
    forStepForm?: boolean
  ) => void;
  originalFilters?: ViewFiltersRequest[];
} | null>(null);
