import {
  Input,
  Textarea,
  FormErrorMessage,
  Flex,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { chakraComponents } from "chakra-react-select";
import { isEmpty, PartialObject, pickBy } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { AiFillFolder } from "react-icons/ai";
import { MdStore } from "react-icons/md";
import { MdsInfoRound } from "react-icons-with-materialsymbols/mds";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import {
  ControlledSelect,
  CreateProjectButton,
  CreateWorkspaceButton,
  customIconComponent,
  LoadingMessage,
  NoOptions,
} from "@/components/controlled-select";
import { SuggestedTags, TagsInput } from "@/components/tag-input";
import { CustomToastProps, useShowToast } from "@/components/toast";
import { TOAST_MESSAGES } from "@/constants/toast-constants.ts";
import { Avatar } from "@/design/components/avatar";
import { Button } from "@/design/components/button";
import { FormControl, FormLabel } from "@/design/components/form";
import { Icon } from "@/design/components/icon";
import { useGetAccessListQuery } from "@/features/user-manager";
import { useCreateWorkflowMutation } from "@/features/workflow-studio";
import {
  AnalysesSchema,
  ProjectCreateSchema,
  ProjectSchema,
  WorkspaceSchema,
} from "@/features/ws-manager/types";
import usePermissions from "@/hooks/usePermissions";
import { useAppSelector } from "@/reduxHooks.ts";
import { currentUserMetadata } from "@/slices/auth-slice";
import { ModalTypes, closeModal, openModal } from "@/slices/modal-slice.ts";
import { Optional } from "@/types";
import { CATEGORY, USER_ROLES, PERMISSIONS } from "@/utils/enums";
import { cleanData } from "@/utils/object-utils.ts";
import { keysToSnake } from "@/utils/snakeCaseConverter";

import {
  useCreateAnalysesMutation,
  useLazyGetTagsQuery,
  useGetWsItemsByIdListMutation,
  useUpdateAnalysesMutation,
} from "../../api";
import { previewAnalysis, setPreviewAnalysis } from "../../redux";

type AnalysisFormProps = {
  data: Partial<AnalysesSchema> & {
    workspace?: string;
    project?: string;
  };
  parentWs: WorkspaceSchema;
  parentProject: ProjectSchema;
};

const wsOptionsSchema = z.object(
  {
    label: z.optional(z.string()),
    value: z.string({ required_error: "Workspace is required" }),
    icon: z.optional(z.any()),
  },
  {
    required_error: "Workspace is required",
    invalid_type_error: "Workspace is required",
  }
);

const projectOptionsSchema = z.object(
  {
    label: z.optional(z.string()),
    value: z.string(),
    icon: z.optional(z.any()),
  },
  {
    required_error: "Project is required",
    invalid_type_error: "Project is required",
  }
);

type ProjectOptions = z.infer<typeof projectOptionsSchema>;
type WsOptions = z.infer<typeof wsOptionsSchema>;

const AnalysisCreateSchema = z.object({
  workspaceId: wsOptionsSchema,
  project: projectOptionsSchema,
  name: z
    .string({ required_error: "Analysis name is required" })
    .min(1, { message: "Analysis name is required" })
    .regex(/^[a-zA-Z0-9 _ -]*$/, {
      message:
        "Name must only contain English letters, numbers, spaces, underscores, and hyphens",
    })
    .max(50, { message: "Name must be at most 50 characters long" })
    .refine((data) => data.trim() !== "", {
      message: "Analysis name cannot be empty",
    }),
  description: z
    .string()
    .regex(/^[a-zA-Z0-9 _ -]*$/, {
      message:
        "Description must only contain English letters, numbers, spaces, underscores, and hyphens",
    })
    .max(500, { message: "Description must be at most 500 characters long" })
    .optional(),
  tags: z
    .array(z.string())
    .max(5, { message: "You can only add up to 5 tags" }),
  discreet: z.boolean(),
});

type AnalysisFormSchema = z.infer<typeof AnalysisCreateSchema>;

// Move type definition outside component
type SelectionChange = {
  from: string | null
  to: string | null
  name?: string // to store the name of the selected item
}

const AnalysisForm = ({
  data: analysisData,
  parentProject,
  parentWs,
}: AnalysisFormProps) => {
  const [changes, setChanges] = useState<{
    workspace: SelectionChange | null
    project: SelectionChange | null
  }>({
    workspace: null,
    project: null
  });

  const ref = useRef(null);
  const navigate = useNavigate();
  const openAnalysis = useAppSelector(previewAnalysis);
  const toast = useShowToast(undefined, undefined, true);
  const dispatch = useDispatch();
  const { modalProps } = useAppSelector((state) => state.rootReducer.modals);
  const isEditing = analysisData?.id !== undefined;
  const {
    id,
    tags,
    name,
    description,
    workspaceId: workspace,
    projectId: project,
    discreet,
  } = analysisData ?? {};

  const [getWsListItems, { data: WsListItems, isLoading: isWsListLoading }] =
    useGetWsItemsByIdListMutation();

  const [getTags, { data: tagsData }] = useLazyGetTagsQuery();
  const { checkPermission } = usePermissions();

  const hasProjectCreatePermission = checkPermission({
    requiredPermission: PERMISSIONS.DELETE,
    type: CATEGORY.Analysis,
    id: id,
  });

  let permission = "create";
  if (isEditing)
    permission = "edit";
  if (hasProjectCreatePermission) 
    permission = "delete";


  const { data: ItemsWithAccess, isLoading: isAccessListLoading } =
    useGetAccessListQuery(
      {
        scope: "analysis",
        permission: permission,
      },
      { refetchOnMountOrArgChange: true }
    );

  const isLoadingWsList = isWsListLoading || isAccessListLoading;

  const workspaces = WsListItems?.response.data?.workspaces ?? [];
  const projectsWithAccess = ItemsWithAccess?.response.data?.project ?? [];
  const suggestedTags = tagsData?.response.data?.tags ?? [];

  const wsOptions = useMemo(() => {
    return workspaces.map((ws: Partial<WorkspaceSchema>) => {
      const icon = ws.icon ? (
        <Avatar size="xs" bgColor={ws.icon} name={ws.name} />
      ) : (
        <Avatar size="xs" bgColor={"brand.300"} name={ws.name} />
      );
      return {
        label: ws.name,
        value: ws.id!,
        icon: ws.emoji ?? icon,
      };
    }) as WsOptions[];
  }, [workspaces]);

  // Move initalSelectedWs after wsOptions is defined
  const initalSelectedWs = useMemo(() => {
    const wsId = workspace ?? parentWs?.id ?? null;
    if (!wsId) return null;
    
    const wsDetails = wsOptions.find(ws => ws.value === wsId);
    return wsDetails ? {
      label: wsDetails.label,
      value: wsDetails.value,
      icon: wsDetails.icon
    } : null;
  }, [workspace, parentWs, wsOptions]);

  const [selectedWs, setSelectedWs] = useState<WsOptions | null>(initalSelectedWs);

  const [createAnalysisApi, { isLoading }] = useCreateAnalysesMutation();
  const [updateAnalysisApi, { isLoading: isUpdating }] =
    useUpdateAnalysesMutation();
  const [createWorkflowApi] = useCreateWorkflowMutation();

  const objectToOptions = (obj: any) => {
    return {
      label: obj.label,
      value: obj.value,
    };
  };

  const projectOptions = useMemo(() => {
    if (!selectedWs || isEmpty(workspaces)) return [];

    let _ws = workspaces.find(
      (ws) => ws.id === (selectedWs as unknown as WsOptions).value
    ) as Partial<WorkspaceSchema> | null;

    if (!isEmpty(workspaces) && !_ws) {
      if (isEditing) {
        toast({
          title: "Cannot Edit",
          description: "Access was revoked or Workspace was deleted.",
          status: "error",
        });
        dispatch(closeModal());
        return [];
      } else {
        _ws = workspaces[0];
      }
    }

    return _ws?.projects
      ?.filter((p) => projectsWithAccess.includes(p.id!))
      .map((proj) => {
        const icon = <Icon as={AiFillFolder} size="md" color="gray.900" />;
        return {
          label: proj.name,
          value: proj.id,
          icon: icon,
        };
      }) as ProjectOptions[];
  }, [selectedWs, workspaces, wsOptions, projectsWithAccess, isEditing]);

  const [defaultValues, defaultProject, defaultWs]: [
    Optional<AnalysisFormSchema, "workspaceId" | "project">,
    ProjectOptions | undefined,
    WsOptions | undefined,
  ] = useMemo(() => {
    const values = {
      name: name ?? "",
      description: description ?? "",
      discreet: discreet ?? false,
      tags: tags?.map((tag) => tag) ?? [],
    };

    let searchWsId: string | undefined, searchProjId: string | undefined;
    if (isEditing) {
      searchWsId = workspace;
      searchProjId = project;
    } else {
      searchWsId = parentWs?.id;
      searchProjId = parentProject?.id;
    }
    const workspaceDetails = wsOptions.find((ws) => ws.value == searchWsId);
    const projectDetails = projectOptions.find(
      (proj) => proj.value == searchProjId
    );

    Object.assign(values, {
      workspaceId: workspaceDetails ? objectToOptions(workspaceDetails) : null,
      project: projectDetails ? objectToOptions(projectDetails) : null,
    });

    return [values, projectDetails, workspaceDetails];
  }, [analysisData, wsOptions, projectOptions]);

  // Add refs to store initial values
  const initialWorkspaceRef = useRef<string | null>(null);
  const initialProjectRef = useRef<string | null>(null);

  // Set initial values when component mounts or when defaults change
  useEffect(() => {
    initialWorkspaceRef.current = defaultWs?.value ?? null;
    initialProjectRef.current = defaultProject?.value ?? null;
  }, [defaultWs, defaultProject]);

  // Modify the effect for project options
  useEffect(() => {
    if (selectedWs && projectOptions.length > 0) {
      const firstAvailableProject = projectOptions[0];
      if (firstAvailableProject) {
        setValue("project", firstAvailableProject);
        
        // Only track project change if workspace has changed
        if (changes.workspace && initialProjectRef.current !== firstAvailableProject.value) {
          setChanges(prev => ({
            ...prev,
            project: {
              from: initialProjectRef.current,
              to: firstAvailableProject.value,
              name: firstAvailableProject.label
            }
          }));
        }
      }
    }
  }, [selectedWs, projectOptions]);

  // Update the effect to handle initial workspace
  useEffect(() => {
    if (initalSelectedWs && (!selectedWs || selectedWs.value !== initalSelectedWs.value)) {
      setSelectedWs(initalSelectedWs);
      setValue("workspaceId", initalSelectedWs, { shouldValidate: true });
    }
  }, [initalSelectedWs]);

  const handleWorkspaceChange = (
    newValue: { value: string; label?: string; icon?: any } | null
  ) => {
    if (!newValue) return;
    const currentWs = newValue as WsOptions;
    const currentWsValue = currentWs?.value;
    
    // Check if we're returning to the initial workspace
    const isReturningToInitial = initialWorkspaceRef.current === currentWsValue;
    
    // Always update both the form value and selected state
    setValue("workspaceId", currentWs, { shouldValidate: true });
    setSelectedWs(currentWs);
    
    if (isReturningToInitial) {
      // If returning to initial workspace, clear the workspace change
      setChanges(prev => ({
        ...prev,
        workspace: null,
        // Also clear project change if we're returning to initial workspace
        project: null
      }));
    } else if (initialWorkspaceRef.current !== currentWsValue) {
      // Only track as a change if different from initial
      setChanges(prev => ({
        ...prev,
        workspace: {
          from: initialWorkspaceRef.current,
          to: currentWsValue,
          name: currentWs.label
        }
      }));
      
      // Clear project selection - it will be updated by the effect
      setValue("project", null as any);
    }
  };

  const handleProjectChange = (
    newValue: { value: string; label?: string; icon?: any } | null
  ) => {
    if (!newValue) return;
    const currentProject = newValue as ProjectOptions;
    const currentProjectValue = currentProject?.value;
    
    // Check if we're returning to the initial project
    const isReturningToInitial = initialProjectRef.current === currentProjectValue;
    
    // Always update the form value
    setValue("project", currentProject);
    
    if (isReturningToInitial) {
      // If returning to initial project, clear the project change
      setChanges(prev => ({
        ...prev,
        project: null
      }));
    } else if (initialProjectRef.current !== currentProjectValue) {
      // Only track as a change if different from initial
      setChanges(prev => ({
        ...prev,
        project: {
          from: initialProjectRef.current,
          to: currentProjectValue,
          name: currentProject.label
        }
      }));
    }
  };

  // Add these variables to determine if fields are optional
  const optionalWsCheck = isEditing && !!workspace;
  const optionalProjectCheck = isEditing && !!project;

  const {
    control,
    handleSubmit,
    register,
    formState: { errors, isDirty, dirtyFields },
    getValues,
    watch,
    clearErrors,
    setError,
    setValue,
  } = useForm<AnalysisFormSchema>({
    resolver: (function () {
      const schema = AnalysisCreateSchema.extend({
        project: optionalProjectCheck
          ? projectOptionsSchema.optional()
          : projectOptionsSchema.required(),
        workspaceId: optionalWsCheck
          ? wsOptionsSchema.optional()
          : wsOptionsSchema.required(),
      });
      return zodResolver(schema);
    })(),
    defaultValues: defaultValues,
  });

  useEffect(() => {
    if (isAccessListLoading || !ItemsWithAccess) return;
    getWsListItems({
      entityType: "workspace",
      idList: ItemsWithAccess?.response.data?.workspace ?? [],
    }).catch((e) => console.error(e));
  }, [isAccessListLoading, ItemsWithAccess, getWsListItems]);

  useEffect(() => {
    if (!selectedWs) return;
    getTags({ wsId: selectedWs.value }).catch((e) => console.error(e));
  }, [selectedWs]);

  useEffect(() => {
    if (defaultWs) {
      setValue("workspaceId", defaultWs);
    }
    if (defaultProject) {
      setValue("project", defaultProject);
    }
  }, [defaultProject, defaultWs]);

  const handleFormSubmit: SubmitHandler<AnalysisFormSchema> = async (values) => {
    if (isEditing) {
      if (changes.workspace || changes.project) {
        const sanitizedValues: Partial<ProjectSchema> = Object.keys(
          dirtyFields
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
        ).reduce((o, key) => ({ ...o, [key]: getValues(key) }), {});
        
        // Add workspace and project IDs if they've changed
        if (changes.workspace) {
          sanitizedValues.workspaceId = changes.workspace?.to ?? undefined;
        }
        
        if (changes.project) {
          sanitizedValues.projectId = changes.project?.to ?? undefined;
        }
        
        // Open the move confirmation modal
        dispatch(
          openModal({
            modalType: ModalTypes.MOVE_ANALYSIS,
            modalProps: {
              title: "Move Analysis?",
              name: name,
              workspaceName:
                changes.workspace?.name || getValues("workspaceId").label,
              projectName: changes.project?.name || getValues("project").label,
              subDescription: "Are you sure you want to move this analysis?",
              primaryButtonText: "Move Analysis",
              primaryButtonColorScheme: "secondary",
              primaryButtonVariant: "solid",
              sourceObjectId: id,
              sourceObjectType: "analysis",
              targetObjectId: changes.project?.to,
              targetObjectType: "project",
              primaryAction: async () => {
                await handleEditFormSubmit(sanitizedValues);
              },
            },
          })
        );
      } else {
        await handleEditFormSubmit();
        dispatch(closeModal());
      }
    } else {
      handleCreateFormSubmit(values);
    }
  };

  const handleEditFormSubmit = async (overrideValues?: Partial<ProjectSchema>) => {
    if (isDirty || overrideValues) {
      try {
        const sanitizedValues: Partial<ProjectSchema> = overrideValues || Object.keys(
          dirtyFields
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
        ).reduce((o, key) => ({ ...o, [key]: getValues(key) }), {});
        
        const snakeCaseValues = keysToSnake(sanitizedValues);
        const res = await updateAnalysisApi({
          ...snakeCaseValues,
          id: id!,
        }).unwrap();

        const updatedAnalysis = res.response.data?.analyses?.[0];
        
        if (openAnalysis?.id === id) {
          dispatch(setPreviewAnalysis(updatedAnalysis || null));
        }
        toast(TOAST_MESSAGES.analysisDetailsUpdated as CustomToastProps);
        navigate(`/home/${updatedAnalysis?.workspaceId}/project/${updatedAnalysis?.projectId}`);
      } catch (e) {
        console.error("Error updating analysis:", e);
        return;
      }
    }
  };

  const handleCreateFormSubmit: SubmitHandler<AnalysisFormSchema> = async (
    _values: AnalysisFormSchema
  ) => {
    try {
      /// This is to remove Icon key since it contained HTML element causing issues
      const cleanValues = cleanData(_values, ["icon"]);
      const sanitizedValues: PartialObject<ProjectCreateSchema> = pickBy(
        cleanValues,
        (item) => {
          if (typeof item == "string") return item.length > 0;
          if (typeof item == "object") return !isEmpty(item);
          return true;
        }
      );

      await createAnalysis(sanitizedValues as AnalysisFormSchema);
    } catch (e) {
      console.error("Error creating analysis:", e);
    }
  };

  const createAnalysis = async (values: AnalysisFormSchema) => {
    const createData = {
      ...values,
      projectId: values.project.value,
      tags: values.tags?.map((tag) => ({ value: tag })),
    };
    try {
      await createAnalysisApi(createData)
        .unwrap()
        .then((res) => {
          const newAnalysis = res.response.data?.analyses?.[0];
          const analysisId = newAnalysis?.id;
          const projId = values.project.value;
          const wkId = values.workspaceId.value;
          
          // TODO : Uncomment this when workflow creation is enabled
          // createWorkflowApi({ analysisId: analysisId! }).catch((e) =>
          //   console.error("Error creating workflow:", e));
          
          toast(TOAST_MESSAGES.analysisCreated(values.name) as CustomToastProps);

          if (modalProps.navigate && wkId && projId) {
            dispatch(setPreviewAnalysis(newAnalysis!));
            modalProps.navigate(`/home/${wkId}/project/${projId}`);
          }

          dispatch(closeModal());
        });
    } catch (e) {
      console.error(e);
    }
  };

  const hasSelectedWs = !watch("workspaceId");

  const isSubmitDisabled =
    isLoading ||
    isUpdating ||
    isLoadingWsList ||
    !watch("project") ||
    !watch("workspaceId");

  const validateTags = (text: string) => {
    const englishRegex = /^[A-Za-z0-9_-\s]+$/;
    if (englishRegex.test(text)) {
      return true;
    } else {
      setError("tags", {
        message:
          "Only English letters, numbers, spaces, underscores, and hyphens",
      });
      return false;
    }
  };

  return (
    <>
      <form
        /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
        onSubmit={handleSubmit(handleFormSubmit)}
        className="flex items-center justify-between flex-col mt-4 mb-2 gap-4 w-full rounded px-6"
        noValidate={true}
        ref={ref}
      >
        <Flex className="items-start justify-between gap-x-2 w-full">
          <ControlledSelect<AnalysisFormSchema, WsOptions, false>
            name="workspaceId"
            control={control}
            label="Workspace"
            placeholder="Select Workspace"
            placeholderIcon={<Icon as={AiFillFolder} size="md" />}
            options={wsOptions}
            components={{
              ...customIconComponent,
              LoadingIndicator: (props) => (
                <chakraComponents.LoadingIndicator spinnerSize="xs" {...props} />
              ),
              MenuList: (props) => (
                <CreateWorkspaceButton {...props} parent={CATEGORY.Analysis} />
              ),
              NoOptionsMessage: NoOptions,
              LoadingMessage: LoadingMessage,
            }}
            isLoading={isLoadingWsList}
            selectedOptionStyle="check"
            isRequired={true}
            isDisabled={isLoadingWsList || !hasProjectCreatePermission}
            onChange={handleWorkspaceChange}
            value={selectedWs}
            useBasicStyles
            isMulti={false}
          />

          <ControlledSelect<AnalysisFormSchema, ProjectOptions, false>
            name="project"
            control={control}
            label="Project"
            placeholder="Select Project"
            options={projectOptions}
            isDisabled={isLoadingWsList || hasSelectedWs || !hasProjectCreatePermission}
            components={{
              ...customIconComponent,
              MenuList: (props) => (
                <CreateProjectButton
                  {...props}
                  parent={CATEGORY.Analysis}
                  ws={getValues("workspaceId")}
                />
              ),
              NoOptionsMessage: NoOptions,
              LoadingMessage: LoadingMessage,
              LoadingIndicator: (props) => (
                <chakraComponents.LoadingIndicator
                  spinnerSize="xs"
                  {...props}
                />
              ),
            }}
            placeholderIcon={<Icon as={MdStore} size="md" />}
            isLoading={isLoadingWsList}
            selectedOptionStyle="check"
            isRequired={true}
            useBasicStyles
            onChange={handleProjectChange}
            isMulti={false}
          />
        </Flex>

        <FormControl
          id="Project-title"
          isRequired={true}
          isInvalid={!!errors.name}
        >
          <FormLabel>Analysis Title</FormLabel>
          <Input {...register("name")} />
          <FormErrorMessage>
            {errors.name && errors.name.message}
          </FormErrorMessage>
        </FormControl>
        <Flex className="flex-col w-full gap-y-1.5">
          <TagsInput
            setError={setError}
            clearErrors={clearErrors}
            beforeAddValidate={validateTags}
            errorKey="tags"
            addTagOnBlur={true}
            name="tags"
            control={control}
            label="Add Tags"
            isRequired={false}
          />
          {suggestedTags.length > 0 && (
            <SuggestedTags
              tags={suggestedTags.map((tag) => tag.value) ?? []}
              onSelect={(tag) => {
                const newTags = [...new Set([...getValues("tags"), tag])];
                setValue("tags", newTags);
              }}
            />
          )}
          <Flex className="text-gray-700 items-center gap-1 text-sm">
            <MdsInfoRound strokeWidth={22} />
            You can use tags to organise your analyses based on similar categories
          </Flex>
        </Flex>

        <FormControl id="description" isInvalid={!!errors.description}>
          <FormLabel>Description</FormLabel>
          <Textarea {...register("description")} />
          <FormErrorMessage>
            {errors.description && errors.description.message}
          </FormErrorMessage>
        </FormControl>
        {/*
        <FormControl id="isDiscreet" isDisabled={true}>
          <Checkbox {...register("discreet")}>Mark as discreet</Checkbox>
        </FormControl>
        */}
        <Button
          variant="solid"
          colorScheme="secondary"
          type="submit"
          className="pb-0 mt-8 self-end"
          isDisabled={isSubmitDisabled}
          isLoading={isEditing ? isUpdating : isLoading}
        >
          {isEditing ? "Save Details" : "Create Analysis"}
        </Button>
      </form>
    </>
  );
};

export default AnalysisForm;
