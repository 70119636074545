import { Box, Link as ChakraLink, Flex } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { MdError, MdOutlineAccountTree, MdOutlineDns } from "react-icons/md";
import {
  Link as ReactRouterLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

import { AssistantIcon } from "@/components/icons/assistant-icon.tsx";
import { LogoWithText } from "@/components/icons/logo-text.tsx";
import { Logo } from "@/components/icons/logo.tsx";
import { Icon } from "@/design/components/icon";
import {
  NavigationPanel,
  NavigationPanelBody,
  NavigationPanelFooter,
  NavigationPanelHeader,
  NavigationPanelItem,
  NavigationPanelToggle,
} from "@/design/components/navigation-panel";
import useOngoingUploads from "@/features/data-manager/hooks/useOngoingUploads";
import {
  getOnGoingAnalysisDownloads,
  getOnGoingAnalysisUploads,
  getStudioAnalysis,
} from "@/features/data-manager/redux";
import { currentWorkflowId } from "@/features/workflow-studio/redux/workflow-slice";
import { useAppSelector } from "@/reduxHooks.ts";
import { DATA_UPLOAD_STATUS } from "@/utils/enums";
import { CacheKey, cacheUtil } from "@/utils/localstorage.ts";

const AskClootrackIcon = () => {
  return <AssistantIcon />;
};

const sheetSelectionInfo = {
  icon: <MdError className="text-yellow-500" fontSize={20} />,
  hasArrow: true,
  closeButton: true,
  popoverContent: (
    <Flex className="flex-col gap-2">
      <Flex className="font-semibold gap-2">
        <Box className="rounded-full p-0.5 border h-fit border-yellow-500">
          <MdError className="text-yellow-500" fontSize={15} />
        </Box>
        <Box>Sheet Selection Required!</Box>
      </Flex>
      <Box>
        We currently support only one sheet. Please choose the sheet you want to
        import.
      </Box>
    </Flex>
  ),
};

export const WorkflowSideNav = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { state } = useLocation();
  const params = useParams();

  const workflowId = useAppSelector(currentWorkflowId);
  const studioAnalysis = useAppSelector(getStudioAnalysis);
  const ongoingDownloads = useAppSelector((s) =>
    getOnGoingAnalysisDownloads(s, params.analysisId!)
  );

  const [showGenie, setShowGenie] = useState(false);
  const isDataManger = pathname.includes("data-manager");
  const navigateToWorkflow = (name: string) => {
    navigate(`${name}`, { state: state });
  };

  const uploads = useAppSelector((s) =>
    getOnGoingAnalysisUploads(s, params.analysisId!)
  );

  const { uploads: ioRecords } = useOngoingUploads({ showReview: false });

  const tenantToken = cacheUtil.getUnParsed(CacheKey.TenantId) ?? {};

  useEffect(() => {
    if (!studioAnalysis) {
      setShowGenie(false);
    } else if (import.meta.env.VITE_DISABLED_GENIE_TENANTS) {
      const disabledTenants = import.meta.env.VITE_DISABLED_GENIE_TENANTS.split(
        ","
      );
      if (disabledTenants.includes(tenantToken as string)) {
        setShowGenie(false);
      } else {
        setShowGenie(true);
      }
    } else {
      setShowGenie(true);
    }
  }, [tenantToken, studioAnalysis]);

  const navigateToAskclootrack = () => {
    if (!allowNavigate() || !studioAnalysis) return;
    console.log("Attempting to navigate using React Router");

    // Extract the analysis ID from the current URL
    const analysisId = pathname.split("/analysis/")[1]?.split("/")[0];

    if (!analysisId) {
      console.error("Unable to extract analysis ID from current URL");
    }

    // Construct the new path
    const newPath = `/analysis/${analysisId}/genie/home`;

    // Perform the navigation
    navigate(newPath);
  };

  const isActive = (name: string) => {
    return pathname.includes(name);
  };

  const hasSheetSelection = useMemo(() => {
    const hasPendingSelection = ioRecords?.some(
      (record) => record.state === DATA_UPLOAD_STATUS.SELECTION_PENDING
    );
    return hasPendingSelection;
  }, [ioRecords]);

  const allowNavigate = () => {
    if (uploads && uploads.length > 0) {
      const userInput = confirm(
        "Upload is in progress, Exiting this page would cancel the ongoing upload.\nAre you sure you want to proceed?"
      );
      if (!userInput) return false;
    } else if (ongoingDownloads && ongoingDownloads?.length > 0) {
      const userInput = confirm(
        "Alert! Exiting this page will cancel your download.\nAre you sure you want to proceed?"
      );
      if (!userInput) return false;
    }
    return true;
  };

  const LogoTextLink = () => {
    return (
      <ChakraLink
        as={ReactRouterLink}
        onClick={(e) => {
          if (!allowNavigate()) {
            e.preventDefault();
          }
        }}
        to="/home"
      >
        <LogoWithText />
      </ChakraLink>
    );
  };

  const LogoLink = () => {
    return (
      <ChakraLink
        as={ReactRouterLink}
        onClick={(e) => {
          if (!allowNavigate()) {
            e.preventDefault();
          }
        }}
        to="/home"
      >
        <Logo />
      </ChakraLink>
    );
  };

  const isWorkflowEnabled = () => {
    if (import.meta.env.VITE_DISABLE_WORKFLOW === "true") return false;

    const allowedTenants =
      import.meta.env.VITE_WORKFLOW_TENANTS?.split(",")
        .map((tenant) => tenant.trim())
        .filter(Boolean) || [];

    return (
      allowedTenants.length === 0 ||
      allowedTenants.includes(tenantToken as string)
    );
  };

  const showWorkflow = isWorkflowEnabled();

  const controlledNavigate = (path: string) => {
    if (pathname.includes(path)) {
      navigate(`${path}`, { state: state });
    } else if (allowNavigate()) {
      navigate(`${path}`, { state: state });
    }
  };

  return (
    <NavigationPanel
      type="studio"
      aria-expanded="false"
      defaultIndex={isDataManger ? 0 : 1}
    >
      <NavigationPanelHeader
        className="cursor-pointer"
        // onClick={navigateToHome}
        logo={<LogoLink />}
      >
        <LogoTextLink />
      </NavigationPanelHeader>
      <NavigationPanelBody>
        <NavigationPanelItem
          icon={<Icon as={MdOutlineDns} />}
          label={"Data Manager"}
          info={hasSheetSelection ? sheetSelectionInfo : undefined}
          selected={isActive("/data-manager")}
          onClick={controlledNavigate.bind(null, "data-manager")}
        />
        {showWorkflow && (
          <NavigationPanelItem
            icon={<Icon as={MdOutlineAccountTree} />}
            label={"Workflow"}
            isDisabled={!workflowId}
            selected={isActive("/editor")}
            onClick={controlledNavigate.bind(null, `editor/${workflowId}`)}
          />
        )}
        {showGenie && (
          <NavigationPanelItem
            icon={<Icon as={AskClootrackIcon} />}
            label="Genie"
            selected={isActive("/genie")}
            onClick={navigateToAskclootrack}
          />
        )}
      </NavigationPanelBody>
      <NavigationPanelFooter>
        <NavigationPanelToggle />
      </NavigationPanelFooter>
    </NavigationPanel>
  );
};
