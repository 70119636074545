import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Instance, Node } from "reactflow";
import { v4 as uuidv4 } from "uuid";

import { ToastType, useShowToast } from "@/components/toast";
import { useSaveWorkflowMutation } from "@/features/workflow-studio";
import { useFlowLocalSave } from "@/features/workflow-studio/hooks/useSaveToLocal.ts";
import {
  AUTO_SAVE_DEBOUNCE,
  updateNodesAfterSave,
} from "@/features/workflow-studio/utils";
import { ConvertFlowtoWorkflowPayload } from "@/features/workflow-studio/utils/transform-response.ts";
import { useSingletonDebounce } from "@/hooks/useSingletonDebounce.ts";
import { useAppSelector } from "@/reduxHooks.ts";

import {
  getEditingAllowed,
  isAutoSaving,
  resetTriggerAutoSave,
  setIsSaving,
  setLastSavedTime,
  setReferenceRunId,
  setWorkflowRunId,
  setWorkflowRunStatus,
  setWorkflowVersionTagId,
  shouldTriggerAutoSave,
  timerInterval,
} from "../redux";

interface SaveOperation {
  id: string;
  version: number;
  timestamp: number;
  flowState: any;
  retryCount: number;
}

interface UseFlowAutoSaveHookReturn {
  isSaving: boolean;
  cancelAutoSave: () => void;
}

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;
const DEBOUNCE_WINDOW = 300; // 300ms debounce for rapid changes

/**
 * Custom hook to auto-save the flow instance at a given interval
 * @param toObject - The Flow instance to save
 * @param setNodes - The interval (in seconds) at which to save the flow instance
 */
export const useFlowAutoSave = (
  toObject: Instance.ToObject,
  setNodes: (
    value: React.SetStateAction<Node<any, string | undefined>[]>
  ) => void
): UseFlowAutoSaveHookReturn => {
  const dispatch = useDispatch();
  const params = useParams();
  const instance = toObject();
  const interval = useAppSelector(timerInterval);
  const isSaving = useAppSelector(isAutoSaving);
  const triggerAutoSave = useAppSelector(shouldTriggerAutoSave);
  const isEditingAllowed = useAppSelector(getEditingAllowed);
  const toast = useShowToast();

  // Queue to store pending save operations
  const saveQueueRef = useRef<SaveOperation[]>([]);
  // Track the latest version number
  const currentVersionRef = useRef(0);
  // Track deleted nodes to prevent restoration
  const deletedNodesRef = useRef(new Set<string>());
  // Track if we're currently processing
  const isProcessingRef = useRef(false);
  // Debounce timer reference
  const debounceTimerRef = useRef<NodeJS.Timeout | null>(null);
  // Last save timestamp
  const lastSaveTimestampRef = useRef<number>(0);

  const [saveWorkflow] = useSaveWorkflowMutation();
  const intervalRef = useRef<number | null>(null);
  const [shouldRunInterval, setShouldRunInterval] = useState(true);

  const { editorId } = useParams();
  const { triggerLocalSave } = useFlowLocalSave(instance, editorId!);

  // Function to process a single save operation
  const processSaveOperation = useCallback(
    async (operation: SaveOperation) => {
      try {
        console.log(
          `🔄 Processing save operation ${operation.id} (version ${operation.version})`
        );

        const res = await saveWorkflow({
          analysisId: params.analysisId!,
          workflow: ConvertFlowtoWorkflowPayload(operation.flowState),
          workflowId: params.editorId!,
        }).unwrap();

        const currentWorkflow = res.response.data!.workflows[0];
        const newNodes = currentWorkflow.workflowNodes;

        dispatch(setWorkflowRunStatus(currentWorkflow.workflowStatus));
        dispatch(setWorkflowRunId(currentWorkflow.workflowRunId as string));
        dispatch(setWorkflowVersionTagId(currentWorkflow.workflowVersionTagId));
        dispatch(setReferenceRunId(currentWorkflow.referenceRunId as string));
        // Only update nodes if this was the most recent version
        if (operation.version === currentVersionRef.current) {
          setNodes((currentNodes) => {
            const updatedNodes = updateNodesAfterSave(newNodes, currentNodes);
            return updatedNodes.filter(
              (node) => !deletedNodesRef.current.has(node.id)
            );
          });
          dispatch(setLastSavedTime(currentWorkflow.lastSavedOn));
          lastSaveTimestampRef.current = Date.now();
          console.log(`✅ Save operation completed successfully`);
        }

        return true;
      } catch (error) {
        console.error(`❌ Error in save operation ${operation.id}:`, error);

        if (operation.retryCount < MAX_RETRIES) {
          operation.retryCount++;
          console.log(
            `🔄 Retrying operation (attempt ${operation.retryCount})`
          );
          await new Promise((resolve) =>
            setTimeout(resolve, RETRY_DELAY * operation.retryCount)
          );
          return processSaveOperation(operation);
        }

        return false;
      }
    },
    [
      params.analysisId,
      params.editorId,
      saveWorkflow,
      setNodes,
      dispatch,
      toast,
    ]
  );

  // Function to process the save queue
  const processSaveQueue = useCallback(async () => {
    if (isProcessingRef.current || saveQueueRef.current.length === 0) {
      if (saveQueueRef.current.length === 0) {
        dispatch(setIsSaving(false));
      }
      return;
    }

    isProcessingRef.current = true;
    dispatch(setIsSaving(true));

    try {
      // Get the latest operation, discard intermediate ones
      const latestOperation =
        saveQueueRef.current[saveQueueRef.current.length - 1];
      saveQueueRef.current = [];

      await processSaveOperation(latestOperation);
      deletedNodesRef.current.clear();
    } finally {
      isProcessingRef.current = false;
      dispatch(setIsSaving(false));

      // If new operations were added during processing, process them after a small delay
      if (saveQueueRef.current.length > 0) {
        setTimeout(processSaveQueue, 50);
      }
    }
  }, [dispatch, processSaveOperation]);

  const saveFlow = useCallback(async () => {
    if (!isEditingAllowed) return;

    currentVersionRef.current++;
    const newFlowId = uuidv4();

    const saveOperation: SaveOperation = {
      id: newFlowId,
      version: currentVersionRef.current,
      timestamp: Date.now(),
      flowState: instance,
      retryCount: 0,
    };

    console.log(
      `➕ Adding save operation ${saveOperation.id} to queue (version ${saveOperation.version})`
    );

    // Add save operation to queue
    saveQueueRef.current.push(saveOperation);

    // Trigger local save immediately
    triggerLocalSave();

    // Clear existing debounce timer
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
      debounceTimerRef.current = null;
    }

    // Start new debounce timer
    debounceTimerRef.current = setTimeout(() => {
      processSaveQueue();
    }, DEBOUNCE_WINDOW);
  }, [
    dispatch,
    triggerLocalSave,
    processSaveQueue,
    instance,
    isEditingAllowed,
  ]);

  const autoSave = useSingletonDebounce(
    saveFlow,
    AUTO_SAVE_DEBOUNCE,
    "AUTOSAVE_DEBOUNCE"
  );

  useEffect(() => {
    if (!editorId) return;
    if (!instance) return;

    if (triggerAutoSave) {
      saveFlow();
      dispatch(resetTriggerAutoSave());
    }
  }, [dispatch, editorId, saveFlow, triggerAutoSave, instance]);

  useEffect(() => {
    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
      if (debounceTimerRef.current) clearTimeout(debounceTimerRef.current);
      saveQueueRef.current = [];
      deletedNodesRef.current.clear();
      dispatch(setIsSaving(false));
    };
  }, [dispatch]);

  // Track node deletions
  useEffect(() => {
    const handleNodeDeletion = (nodeId: string) => {
      deletedNodesRef.current.add(nodeId);
    };

    return () => {
      // Cleanup subscription
    };
  }, []);

  const cancelAutoSave = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
      debounceTimerRef.current = null;
    }
    saveQueueRef.current = [];
    deletedNodesRef.current.clear();
    dispatch(setIsSaving(false));
  };

  return { isSaving, cancelAutoSave };
};
