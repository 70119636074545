import { CellCoords } from "handsontable";
import { createRoot } from "react-dom/client";
import {
  MdsAutorenewRound,
  MdsCalendarTodayRound,
  MdsContentCopyRound,
  MdsCounter1Round,
  MdsDataArrayRound,
  MdsDataObjectRound,
  MdsDeleteRound,
  MdsFilterAltOffRound,
  MdsFilterAltRound,
  MdsFlakyRound,
  MdsFontDownloadRound,
  MdsScatterPlotRound,
  MdsSortRound,
  MdsTextFieldsAltRound,
} from "react-icons-with-materialsymbols/mds";
import { useSelector } from "react-redux";

import {
  DATA_TYPE,
  DATE_TIMESTAMP_TYPE,
  Sort,
  TableDataColumns,
  SORT_ORDER,
  viewFilters,
} from "@/features/data-transformation";

import { CustomDropdownRender } from "./dropdown-render.tsx";
import { CustomSubDropdownRender } from "./dropdown-submenu-render.tsx";

export const useTableMenuRenderer = ({
  hotRef,
  columnData,
  addFilter,
  openFilter,
  sortData,
  sorting,
  duplicateColumn,
  renameColumn,
  removeColumn,
  changeDataType,
  clearFilter,
  isReadOnlyEda,
}: {
  hotRef: React.MutableRefObject<any>;
  columnData: TableDataColumns[];
  addFilter: (arg0: any) => void;
  openFilter: (arg0: any) => void;
  sortData: (column: any, type: SORT_ORDER) => void;
  sorting: Sort[];
  duplicateColumn: (column: string) => void;
  renameColumn: (column: string) => void;
  changeDataType: (column: string, type: DATA_TYPE) => void;
  removeColumn: (column: string) => void;
  clearFilter: (column: string) => void;
  isReadOnlyEda: boolean;
}) => {
  const filters = useSelector(viewFilters);

  return {
    items: {
      sort: {
        name: "Sort Data",
        callback: function () {
          alert("Sorting data");
        },
        renderer(
          _core: any,
          wrapper: HTMLElement,
          _: any,
          _row: any,
          _col: any,
          itemValue: string
        ) {
          createRoot(wrapper).render(
            <CustomDropdownRender
              title={itemValue}
              icon={MdsSortRound}
              hasArrow={true}
            />
          );
          return wrapper;
        },
        submenu: {
          items: [
            {
              key: "sort:az",
              name: "Sort A to Z",
              callback: function (
                _key: any,
                selection: { end: CellCoords; start: CellCoords }[]
              ) {
                const colIndex = selection[0].start.col;
                sortData(columnData[colIndex].name, SORT_ORDER.ASC);
              },
              renderer(
                _core: any,
                wrapper: HTMLElement,
                _colIndex: any,
                _row: any,
                _col: any,
                itemValue: string
              ) {
                const columnIndex =
                  hotRef.current.hotInstance.getSelectedRange()[0].to.col;
                const columnDetails = columnData[columnIndex];
                const isSelected = !!sorting.find(
                  (sortItem: any) =>
                    sortItem.column == columnDetails.name &&
                    sortItem.order == SORT_ORDER.ASC
                );

                const dataType = columnDetails.dataType;
                let val = itemValue;
                if (DATE_TIMESTAMP_TYPE.includes(dataType)) {
                  val = "Sort Oldest to Newest";
                }

                createRoot(wrapper).render(
                  <CustomSubDropdownRender
                    title={val}
                    icon={MdsSortRound}
                    isSelected={isSelected}
                  />
                );
                return wrapper;
              },
            },
            {
              key: "sort:za",
              name: "Sort Z to A",
              callback: function (
                _key: any,
                selection: { end: CellCoords; start: CellCoords }[]
              ) {
                const colIndex = selection[0].start.col;
                sortData(columnData[colIndex].name, SORT_ORDER.DESC);
              },
              renderer(
                _core: any,
                wrapper: HTMLElement,
                _colIndex: any,
                _row: any,
                _col: any,
                itemValue: string
              ) {
                const columnIndex =
                  hotRef.current.hotInstance.getSelectedRange()[0].to.col;
                const columnDetails = columnData[columnIndex];
                const isSelected = !!sorting.find(
                  (sortItem: any) =>
                    sortItem.column == columnDetails.name &&
                    sortItem.order == SORT_ORDER.DESC
                );

                const dataType = columnDetails.dataType;
                let val = itemValue;
                if (DATE_TIMESTAMP_TYPE.includes(dataType)) {
                  val = "Sort Newest to Oldest";
                }

                createRoot(wrapper).render(
                  <CustomSubDropdownRender
                    title={val}
                    icon={MdsSortRound}
                    isSelected={isSelected}
                    iconClassName="rotate-180"
                  />
                );
                return wrapper;
              },
            },
          ],
        },
      },
      edit: {
        name: "Filter",
        callback: function (
          _key: any,
          selection: { end: CellCoords; start: CellCoords }[]
        ) {
          const colIndex = selection[0].start.col;
          const colName = columnData[colIndex].name;
          const hasFilters = filters?.find(
            (data: any) => data.column == colName
          );

          if (hasFilters) {
            openFilter(colName);
          } else {
            addFilter(colName);
          }
        },
        renderer(
          _core: any,
          wrapper: HTMLElement,
          _row: any,
          _col: any,
          _prop: any,
          itemValue: string
        ) {
          const col = hotRef.current.hotInstance.getSelectedRange()[0].to.col;
          const colName = columnData[col].name;
          createRoot(wrapper).render(
            <CustomDropdownRender
              title={itemValue}
              icon={MdsFilterAltRound}
              filters={filters}
              colName={colName}
            />
          );
          return wrapper;
        },
      },
      clear: {
        name: "Clear Filter",
        callback: function (
          _key: any,
          selection: { end: CellCoords; start: CellCoords }[]
        ) {
          const colIndex = selection[0].start.col;
          clearFilter(columnData[colIndex].name);
        },
        renderer(
          _core: any,
          wrapper: HTMLElement,
          _: any,
          _row: any,
          _col: any,
          itemValue: string
        ) {
          createRoot(wrapper).render(
            <CustomDropdownRender
              title={itemValue}
              icon={MdsFilterAltOffRound}
            />
          );
          return wrapper;
        },
        hidden: function () {
          const col = hotRef.current.hotInstance.getSelectedRange()[0].to.col;
          const colName = columnData[col].name;
          const hasFilters = filters?.find(
            (filterItem: any) => filterItem.column == colName
          );
          return !hasFilters;
        },
      },
      sep1: { name: "---------" },
      rename: {
        name: "Rename Column",
        callback: function (
          _key: any,
          selection: { end: CellCoords; start: CellCoords }[]
        ) {
          const colIndex = selection[0].start.col;
          renameColumn(columnData[colIndex].name);
        },
        renderer(
          _core: any,
          wrapper: HTMLElement,
          _: any,
          _row: any,
          _col: any,
          itemValue: string
        ) {
          createRoot(wrapper).render(
            <CustomDropdownRender
              title={itemValue}
              icon={MdsTextFieldsAltRound}
            />
          );
          return wrapper;
        },
        hidden: function () {
          return isReadOnlyEda;
        },
      },
      duplicate: {
        name: "Duplicate Column",
        callback: function (
          _key: any,
          selection: { end: CellCoords; start: CellCoords }[]
        ) {
          const colIndex = selection[0].start.col;
          duplicateColumn(columnData[colIndex].name);
        },
        renderer(
          _core: any,
          wrapper: HTMLElement,
          _: any,
          _row: any,
          _col: any,
          itemValue: string
        ) {
          createRoot(wrapper).render(
            <CustomDropdownRender
              title={itemValue}
              icon={MdsContentCopyRound}
            />
          );
          return wrapper;
        },
        hidden: function () {
          return isReadOnlyEda;
        },
      },
      datatype: {
        name: "Change Data-type",
        callback: function () {
          alert("Editing filter");
        },
        renderer(
          _core: any,
          wrapper: HTMLElement,
          _: any,
          _row: any,
          _col: any,
          itemValue: string
        ) {
          createRoot(wrapper).render(
            <CustomDropdownRender
              title={itemValue}
              icon={MdsDataObjectRound}
              hasArrow={true}
            />
          );
          return wrapper;
        },
        hidden: function () {
          return isReadOnlyEda;
        },
        submenu: {
          items: [
            {
              key: "datatype:auto",
              name: "Auto-detect",
              callback: function (
                _key: any,
                selection: { end: CellCoords; start: CellCoords }[]
              ) {
                const colIndex = selection[0].start.col;
                changeDataType(
                  columnData[colIndex].name,
                  DATA_TYPE.AUTO_DETECT
                );
              },
              renderer(
                _core: any,
                wrapper: HTMLElement,
                _: any,
                _row: any,
                _col: any,
                itemValue: string
              ) {
                createRoot(wrapper).render(
                  <CustomSubDropdownRender
                    title={itemValue}
                    icon={MdsAutorenewRound}
                  />
                );
                return wrapper;
              },
            },
            {
              key: "datatype:string",
              name: "String",
              callback: function (
                _key: any,
                selection: { end: CellCoords; start: CellCoords }[]
              ) {
                const colIndex = selection[0].start.col;
                changeDataType(columnData[colIndex].name, DATA_TYPE.STRING);
              },
              renderer(
                _core: any,
                wrapper: HTMLElement,
                _: any,
                _row: any,
                _col: any,
                itemValue: string
              ) {
                createRoot(wrapper).render(
                  <CustomSubDropdownRender
                    title={itemValue}
                    icon={MdsFontDownloadRound}
                  />
                );
                return wrapper;
              },
            },
            {
              key: "datatype:date",
              name: "Date",
              callback: function (
                _key: any,
                selection: { end: CellCoords; start: CellCoords }[]
              ) {
                const colIndex = selection[0].start.col;
                changeDataType(columnData[colIndex].name, DATA_TYPE.DATE);
              },
              renderer(
                _core: any,
                wrapper: HTMLElement,
                _: any,
                _row: any,
                _col: any,
                itemValue: string
              ) {
                createRoot(wrapper).render(
                  <CustomSubDropdownRender
                    title={itemValue}
                    icon={MdsCalendarTodayRound}
                  />
                );
                return wrapper;
              },
            },
            {
              key: "datatype:int",
              name: "Integer",
              callback: function (
                _key: any,
                selection: { end: CellCoords; start: CellCoords }[]
              ) {
                const colIndex = selection[0].start.col;
                changeDataType(columnData[colIndex].name, DATA_TYPE.INT);
              },
              renderer(
                _core: any,
                wrapper: HTMLElement,
                _: any,
                _row: any,
                _col: any,
                itemValue: string
              ) {
                createRoot(wrapper).render(
                  <CustomSubDropdownRender
                    title={itemValue}
                    icon={MdsCounter1Round}
                  />
                );
                return wrapper;
              },
            },
            {
              key: "datatype:float",
              name: "Float",
              callback: function (
                _key: any,
                selection: { end: CellCoords; start: CellCoords }[]
              ) {
                const colIndex = selection[0].start.col;
                changeDataType(columnData[colIndex].name, DATA_TYPE.FLOAT);
              },
              renderer(
                _core: any,
                wrapper: HTMLElement,
                _: any,
                _row: any,
                _col: any,
                itemValue: string
              ) {
                createRoot(wrapper).render(
                  <CustomSubDropdownRender
                    title={itemValue}
                    icon={MdsScatterPlotRound}
                  />
                );
                return wrapper;
              },
            },
            {
              key: "datatype:bool",
              name: "Boolean",
              callback: function (
                _key: any,
                selection: { end: CellCoords; start: CellCoords }[]
              ) {
                const colIndex = selection[0].start.col;
                changeDataType(columnData[colIndex].name, DATA_TYPE.BOOLEAN);
              },
              renderer(
                _core: any,
                wrapper: HTMLElement,
                _: any,
                _row: any,
                _col: any,
                itemValue: string
              ) {
                createRoot(wrapper).render(
                  <CustomSubDropdownRender
                    title={itemValue}
                    icon={MdsFlakyRound}
                  />
                );
                return wrapper;
              },
            },
            {
              key: "datatype:array",
              name: "Array",
              callback: function (
                _key: any,
                selection: { end: CellCoords; start: CellCoords }[]
              ) {
                const colIndex = selection[0].start.col;
                changeDataType(columnData[colIndex].name, DATA_TYPE.ARRAY);
              },
              renderer(
                _core: any,
                wrapper: HTMLElement,
                _: any,
                _row: any,
                _col: any,
                itemValue: string
              ) {
                createRoot(wrapper).render(
                  <CustomSubDropdownRender
                    title={itemValue}
                    icon={MdsDataArrayRound}
                  />
                );
                return wrapper;
              },
            },
          ],
        },
      },
      sep2: { name: "---------" },
      delete: {
        name: "Delete Column",
        callback: function (
          _key: any,
          selection: { end: CellCoords; start: CellCoords }[]
        ) {
          const colIndex = selection[0].start.col;
          removeColumn(columnData[colIndex].name);
        },
        renderer(
          _core: any,
          wrapper: HTMLElement,
          _: any,
          _row: any,
          _col: any,
          itemValue: string
        ) {
          createRoot(wrapper).render(
            <CustomDropdownRender
              title={itemValue}
              icon={MdsDeleteRound}
              className="text-red-600"
            />
          );
          return wrapper;
        },
        hidden: function () {
          return isReadOnlyEda;
        },
      },
    },
  };
};
