import { Input } from "@chakra-ui/input";
import { Box, chakra, Flex, Spinner, Tag, Text } from "@chakra-ui/react";
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  SortingState,
  getSortedRowModel,
  createColumnHelper,
} from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";
import clsx from "clsx";
import * as React from "react";
import { useMemo } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { MdClear } from "react-icons/md";
import {
  MdsArrowDownwardAltRound,
  MdsArrowUpwardAltRound,
  MdsConversionPathRound,
  MdsSwapVertRound,
} from "react-icons-with-materialsymbols/mds";
import { useDebounce } from "use-debounce";

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@/design/components/data-table";
import { IconButton } from "@/design/components/icon-button";
import { useAppDispatch, useAppSelector } from "@/reduxHooks.ts";
import { getRelativeTime } from "@/utils/date-convertor.ts";
import { closestColor } from "@/utils/get-color.ts";

import { currentFlowItem, setCurrentFlowStoreItem } from "../..";
import { useLazyGetFlowsQuery } from "../../api";
import { FlowSchema } from "../../types";
import { FLOW_TYPES } from "../../utils/constants";
import { NoFlows } from "../bottom-bar/flows/flow-tabs";

import { FlowStoreTableDropdown } from "./flow-store-dropdown";
import { FlowStoreFavButton } from "./flow-store-dt-favbtn";

const columnHelper = createColumnHelper<FlowSchema>();

export function FlowStoreDatatable({
  onOpen,
  label,
  flowType,
}: {
  onOpen: () => void;
  label: string;
  flowType: FLOW_TYPES;
}) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  // const [data, setData] = React.useState<AnalysesSchema[]>([]);
  // const [pagination, setPagination] = React.useState<Pagination | null>();
  const [search, setSearch] = React.useState("");
  const [isSearching, setIsSearching] = React.useState(false);
  // const [isLoading, setIsLoading] = React.useState(true);
  const project = useAppSelector(currentFlowItem);

  const tableContainerRef = React.useRef<HTMLDivElement>(null);

  const [fetchFlows, { data: flowList, isLoading }] = useLazyGetFlowsQuery();

  const pagination = useMemo(
    () => flowList?.pagination ?? null,
    [flowList?.pagination]
  );

  const paginate = React.useCallback(async () => {
    await fetchFlows({
      flowType,
      url: pagination && pagination.next ? pagination.next : null,
    });
  }, [flowType, fetchFlows, pagination]);

  //called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
  const fetchMoreOnBottomReached = React.useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        //once the user has scrolled within 300px of the bottom of the table, fetch more data if there is any
        const totalData = flowList?.flows.length ?? 0;
        const reachedBottom = scrollHeight - scrollTop - clientHeight < 100;
        const canFetchMore =
          (!isLoading && flowList?.pagination?.next) || !flowList;
        // !isLoading && !isFetching && flowList?.pagination?.next !== null;

        const shouldFetchMore = reachedBottom && canFetchMore;
        //once the user has scrolled within 500px of the bottom of the table, fetch more data if we can
        if (shouldFetchMore) {
          paginate();
        }
      }
    },
    [flowList, paginate, isLoading]
  );

  //a check on mount and after a fetch to see if the table is already scrolled to the bottom and immediately needs to fetch more data
  React.useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [fetchMoreOnBottomReached]);

  const handleInputChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSearch(event.target.value);
  };

  const [debouncedValue] = useDebounce(search, 500);

  const data = useMemo(() => {
    const currentData: FlowSchema[] = flowList?.flows ?? [];
    if (!debouncedValue) return currentData ?? [];

    const filteredData = currentData.filter((flow) =>
      flow.displayName.toLowerCase().includes(debouncedValue.toLowerCase())
    );
    return filteredData;
  }, [flowList, debouncedValue]);

  const columns = useMemo(
    () => [
      columnHelper.accessor("displayName", {
        cell: ({ row: _row, getValue }) => (
          <Flex className="items-center gap-3">
            <MdsConversionPathRound size={18} className="shrink-0" />
            <Text className="break-all">{getValue<string>()}</Text>
          </Flex>
        ),
        header: () => <span className="select-none ">Name</span>,
        size: 60,
        enableSorting: !isSearching,
      }),
      columnHelper.accessor("tags", {
        cell: ({ row: _row, getValue }) => {
          const tags = getValue<string[]>();
          return (
            <Flex wrap={"wrap"} gap={2}>
              {tags.slice(0, 2).map((tag, idx) => (
                <Tag
                  className="break-all"
                  key={tag + idx}
                  colorScheme={closestColor(tag)}
                >
                  {tag}
                </Tag>
              ))}
              {tags.length > 2 && (
                <small className="text-gray-600 p-0.5">
                  +{tags.length - 2}
                </small>
              )}
            </Flex>
          );
        },
        enableSorting: false,
        header: () => <span className="select-none">Tags</span>,
        size: 15,
      }),
      columnHelper.accessor("uploadDate", {
        cell: (info) => (
          <span className="select-none">
            {getRelativeTime(info.getValue())}
          </span>
        ),
        header: () => <span className="select-none">Upload Date</span>,
        size: 15,
      }),
      /*
      columnHelper.accessor("lastUsedAt", {
        cell: (info) => (
          <span className="select-none">
            {getRelativeTime(info.getValue())}
          </span>
        ),
        header: () => <span className="select-none">Last used</span>,
        size: 20,
      }),
*/

      columnHelper.accessor("nodeId", {
        cell: (info) => {
          return (
            <Flex align="center">
              {flowType === FLOW_TYPES.FAVORITES && (
                <FlowStoreFavButton {...info.row.original} />
              )}
              <FlowStoreTableDropdown
                flowTab={flowType}
                {...info.row.original}
              />
            </Flex>
          );
        },
        header: () => <span className="select-none invisible">Action</span>,
        size: 10,
        enableSorting: false,
      }),
    ],
    [label, isSearching]
  );

  // TODO: decide how to handle loading
  /*const _columnsMemo = useMemo(
    () =>
      isLoading
        ? columns.map((column) => ({
            ...column,
            cell: () => (
              <div className="flex-1 animate-pulse">
                <div className="h-12 bg-gray-300"></div>
              </div>
            ),
          }))
        : columns,
    [isLoading, columns],
  );*/

  const table = useReactTable({
    columns: columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
  });

  const { rows } = table.getRowModel();

  const virtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 50, //estimate row height for accurate scrollbar dragging
    getScrollElement: () => tableContainerRef.current,
    //measure dynamic row height, except in firefox because it measures table border height incorrectly
    measureElement:
      typeof window !== "undefined" &&
      navigator.userAgent.indexOf("Firefox") === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 5,
  });

  const dispatch = useAppDispatch();

  const setPreview = (row: FlowSchema) => {
    dispatch(
      setCurrentFlowStoreItem({
        flowType: flowType,
        flow: row,
      })
    );
  };

  const onSearchClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setSearch("");
    setIsSearching((state) => !state);
  };

  if (isLoading) {
    return (
      <Flex className="justify-center flex h-full w-full bg-white items-center gap-3">
        <Spinner size="sm" />
        <span className="font-semibold">Fetching Flows</span>
      </Flex>
    );
  }

  return (
    <Box className="w-full bg-white h-full grow">
      {flowList?.flows.length === 0 ? (
        <NoFlows />
      ) : (
        <Box
          className="overflow-auto w-full relative h-full "
          ref={tableContainerRef}
          onScroll={(e) => fetchMoreOnBottomReached(e.target as HTMLDivElement)}
        >
          <Table
            width="100%"
            minW={"70%"}
            variant="simple"
            position={"relative"}
          >
            <Thead
              position="sticky"
              top={0}
              zIndex={1}
              h={"65px"}
              bg={"white"}
              className="border-b-2"
            >
              <Flex
                className={clsx(
                  "!absolute top-1/2 -translate-y-1/2 left-2 !z-[11]",
                  isSearching ? "w-[40%]" : "w-fit"
                )}
              >
                <IconButton
                  color="gray.900"
                  aria-label="search"
                  colorScheme="blackAlpha"
                  icon={
                    isSearching ? (
                      <MdClear aria-label="search" title="search" />
                    ) : (
                      <AiOutlineSearch
                        aria-label="search"
                        title="search"
                        size={20}
                      />
                    )
                  }
                  onClick={onSearchClick}
                  size="md"
                  variant={"ghost"}
                />
                {isSearching && (
                  <Input
                    className="text-gray-800 !bg-white w-[65%] font-normal mr-2"
                    placeholder="Search by name"
                    name="search"
                    value={search}
                    onChange={handleInputChange}
                    autoFocus={true}
                  />
                )}
              </Flex>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id} w={"100%"}>
                  {headerGroup.headers.map((header, idx) => {
                    // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                    const meta = header.column.columnDef.meta;
                    return (
                      <Th
                        overflow={"hidden"}
                        style={{
                          width: `${header.column.columnDef.size}%`,
                          maxWidth: `${header.column.columnDef.size}%`,
                          minWidth: `${header.column.columnDef.size}%`,
                        }}
                        textOverflow={"ellipsis"}
                        key={header.id}
                        isNumeric={meta?.isNumeric}
                        onClick={header.column.getToggleSortingHandler()}
                        role="button"
                      >
                        <Flex
                          className="!normal-case tracking-normal font-medium text-gray-700 text-base"
                          align="center"
                          pl={idx == 0 ? 8 : 0}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {header.column.getCanSort() && (
                            <chakra.span pl="4">
                              {header.column.getIsSorted() ? (
                                header.column.getIsSorted() === "desc" ? (
                                  <MdsArrowDownwardAltRound
                                    aria-label="sorted descending"
                                    title="sorted descending"
                                  />
                                ) : (
                                  <MdsArrowUpwardAltRound
                                    aria-label="sorted ascending"
                                    title="sorted ascending"
                                  />
                                )
                              ) : (
                                <MdsSwapVertRound
                                  aria-label="sort"
                                  title="sort"
                                />
                              )}
                            </chakra.span>
                          )}
                        </Flex>
                      </Th>
                    );
                  })}
                </Tr>
              ))}
            </Thead>
            {!isLoading && (
              <Tbody
                style={{
                  height: `${virtualizer.getTotalSize()}px`, //tells scrollbar how big the table is
                  position: "relative", //needed for absolute positioning of rows
                }}
                width="100%"
                bg={"white"}
              >
                {virtualizer.getVirtualItems().map((virtualRow, index) => {
                  const row = rows[virtualRow.index];
                  const isSelected =
                    project?.flow &&
                    project.flow.nodeUsageInstanceId ==
                      row.original.nodeUsageInstanceId;
                  return (
                    <Tr
                      width={"100%"}
                      onClick={() => {
                        setPreview(row.original);
                        onOpen();
                      }}
                      data-index={virtualRow.index} //needed for dynamic row height measurement
                      ref={(node) => virtualizer.measureElement(node)} //measure dynamic row height
                      key={row.id}
                      bg={isSelected ? "orange.50" : ""}
                      _hover={{
                        bg: !isSelected && "gray.50",
                        cursor: "pointer",
                      }}
                      style={{ transform: `translateY(${virtualRow.start}px)` }}
                      className={clsx(
                        "absolute flex",
                        isSelected && "border-b border-orange-200"
                      )}
                    >
                      {row.getVisibleCells().map((cell, cellIndex) => {
                        return (
                          <Td
                            className={clsx(
                              "font-medium",
                              cellIndex === row.getVisibleCells().length - 1 &&
                                "!px-0",
                              cellIndex === 0
                                ? "text-gray-800"
                                : "text-gray-700",
                              isSelected && "text-orange-800"
                            )}
                            key={cell.id}
                            style={{
                              width: `${cell.column.columnDef.size}%`,
                            }}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })}
              </Tbody>
            )}
          </Table>
        </Box>
      )}
    </Box>
  );
}
