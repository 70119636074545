import { UsersSchema } from "@/features/user-manager/types";
import { WF_ACCESS_MODE } from "@/utils/enums";

import { NODE_STATUS } from "../utils/constants";

export enum EventType {
  INFORMATIONAL = 'INFORMATIONAL',
  ACTIONABLE_WARNING = 'ACTIONABLE_WARNING',
  ACTIONABLE_BLOCKER = 'ACTIONABLE_BLOCKER'
}

export enum ResolutionStatus {
  UNRESOLVED = 'UNRESOLVED',
  RESOLVED = 'RESOLVED',
  PENDING = 'PENDING'
}

export interface WorkflowEvent {
  eventName: string;
  eventMessage: string;
  eventResolutionStatus: ResolutionStatus;
  eventType: EventType;
  createdOn: number;  // milliseconds timestamp
}

export interface WorkflowNodeRelationsSchema {
  sourceUiNodeId: string;
  targetUiNodeId: string;
  sourceNodeIoId: string;
  targetNodeIoId: string;
  relationType: string;
  sourceWorkflowNodeId?: string;
  targetWorkflowNodeId?: string;
}

export interface WorkflowNodeSchema {
  uiNodeId: string;
  description?: string;
  nodeUsageInstanceId: string | null;
  nodeVersionId?: string | null;
  name: string;
  nodeDisplayParams: string;
  nodeType: string;
  numInputs: number;
  numOutputs: number;
  nodeParameters: WorkflowNodeParameterSchema[];
  nodeColumnMappings: WorkflowNodeColumnMappingSchema[];
  nodeStatus: string;
  workflowNodeId?: string;
  parentNodeUiId?: string;
  isOutput?: boolean;
  outputName?: string;
  outputState?: NODE_STATUS;
  isOutputDataAvailable?: boolean;
  displayName?: string;
}

export interface WorkflowNodeParameterSchema {
  id: string;
  value: string;
}

export interface WorkflowNodeColumnMappingSchema {
  nodeColumnMappings: Array<Record<string, string>>;
}
export interface WorkflowSchema {
  name: string;
  description: string;
  workflowType: string;
  isClootrackWorkflow?: boolean;
  workflowId: string;
  workflowVersionId: string;
  workflowVersionNumber: number;
  workflowVersionTagId: string;
  workflowVersionTagNumber: number;
  workflowVersionTagState: string;
  workflowRunId?: string | null;
  workflowNodes: WorkflowNodeSchema[];
  referenceRunId?: string;
  userNodeRelations: WorkflowNodeRelationsSchema[];
  workflowStatus: NODE_STATUS;
  lastSavedOn?: string;
}

export interface WorkflowResponseSchema {
  workflows: WorkflowSchema[];
}

export interface NodeAdvancedProperties {
  nodeId: string;
  nodeType: string;
  startNodes: string[];
  endNodes: string[];
}

export interface SaveWorkflowSchema {
  workflowStatus: string;
  nodes: WorkflowNodeSchema[];
  relations: WorkflowNodeRelationsSchema[];
  nodeAdvancedProperties: NodeAdvancedProperties[];
  outputNodes?: Array<{
    uiNodeId: string;
    outputTitle: string;
    nodeIoDetailId: string;
  }>;
  unmarkedOutputNodes?: string[];
}

////// Workflow Status //////
export interface WorkflowStatusSchema {
  workflowRunId: string | null;
  workflowRunStatus: NODE_STATUS;
  startTime: string | null;
  endTime: string | null;
  message: string;
  terminateWorkflowEnabled: boolean;
  workflowNodeStatus: WorkflowNodeStatusSchema[];
}

export interface WorkflowNodeStatusSchema {
  workflowNodeId: string;
  uiNodeId: string;
  nodeVersionId: string;
  nodeUsageInstanceId: string;
  nodeStatus: NODE_STATUS | string | null;
  nodeType: string;
  message: string;
}

export interface WorkflowStatusResponseSchema {
  workflowAndNodeStatus: WorkflowStatusSchema;
}

export interface WorkflowStateSchema {
  stateHash: string | null;
  versionNumber: number;
  versionTagNumber: number;
  status: NODE_STATUS;
  triggeredBy: UsersSchema | null;
}

export interface WFUserSchema {
  user: UsersSchema | null;
  status: string | null;
  isCurrentUser: boolean;
  lastActive?: string | null;
  timeSinceLastActivity?: {
    seconds: number;
    formatted: string;
  } | null;
}
export interface WFActiveUserResponseSchema {
  data: {
    results: {
      workflowId: string;
      timeout: number;
      workflowAccessMode: WF_ACCESS_MODE;
      currentUser: {
        userId: string;
        hasWriteAccess: boolean;
      };
      canAccessWorkflow: boolean;
      workflowState: WorkflowStateSchema;
      activeUser: WFUserSchema;
      events: WorkflowEvent[];
    };
  };
}

export interface WFEditRequestResponse {
  data: {
    results: {
      message: string;
      workflowState: {
        stateHash: string;
        versionNumber: number;
        status: NODE_STATUS;
      };
    };
  };
}

export interface NodeColumnChoicesResponse {
  columnChoices: string[];
}

export interface ColumnInfo {
  value: string;
  type: string;
}

export interface TableSchema {
  tableName: string;
  columns: ColumnInfo[];
}

export interface NodeInputSchemaResponse {
  data: {
    results: {
      schema: TableSchema[];
    };
  };
}
