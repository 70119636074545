import { Box, Divider } from "@chakra-ui/layout";
import { CircularProgress, Flex, Input, Spinner } from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { MdsSearchRound } from "react-icons-with-materialsymbols/mds";
import { useSelector } from "react-redux";
import { useDebounce } from "use-debounce";

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@/design/components/modal";
import { ConnectorSources, IDataSource } from "@/features/data-manager/types";
import { RootState } from "@/store";
import { SOURCE_FILE_TYPE } from "@/utils/enums.ts";

import { useSourceListRecordMutation } from "../../api/data-query.ts";
import { useFileUpload } from "../../hooks/useUploadFile.ts";

import { AddDatasetNavigation } from "./navigation.tsx";
import { SelectConnector } from "./select-connector.tsx";

const progressComponent = (progress: number = 0) => {
  const isIndeterminate = progress === 0 || progress >= 100;
  return (
    <CircularProgress
      color="blue.600"
      isIndeterminate={isIndeterminate}
      size={5}
      thickness="12px"
      value={progress}
    />
  ) as React.ReactNode;
};

export const AddNewDatasetModal: React.FC = () => {
  const [selectedNavValue, setSelectedNavValue] = useState("All");
  const [search, setSearch] = useState("");
  const [connectorSources, setConnectorSources] = useState<ConnectorSources>(
    {}
  );
  const [fetchSources, { isLoading }] = useSourceListRecordMutation();

  const { modalProps } = useSelector(
    (s) => (s as RootState).rootReducer.modals
  );

  const [debouncedValue] = useDebounce(search, 500);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    const fetchSourcesData = async () => {
      const response = await fetchSources({}).unwrap();
      const sources = response?.response?.data?.results;
      if (!sources) return;

      const groupedByCategory = groupSourcesByCategory(sources);
      setConnectorSources(groupedByCategory);
    };

    void fetchSourcesData();
  }, [fetchSources]);

  const groupSourcesByCategory = (sources: IDataSource[]) => {
    const grouped: ConnectorSources = {
      All: [],
    };

    sources.forEach((source) => {
      source.dataCategory.forEach((category) => {
        if (!grouped[category]) {
          grouped[category] = [];
        }

        grouped[category].push(source);
        grouped.All.push(source);
      });
    });

    return grouped;
  };

  const { handleFileChange, onClose } = useFileUpload({
    progressComponent,
  });

  const currentSourceList = useMemo(() => {
    if (debouncedValue) {
      return connectorSources[selectedNavValue]?.filter((item) =>
        item.name.toLowerCase().includes(debouncedValue.toLowerCase())
      );
    }
    return connectorSources[selectedNavValue] || [];
  }, [connectorSources, selectedNavValue, debouncedValue]);

  const handleConnectorButtonClick = (definition: any, event?: any) => {
    onClose();
    if (definition.name === SOURCE_FILE_TYPE.LOCAL_FILE) {
      handleFileChange(
        event as React.SyntheticEvent<EventTarget>,
        definition as IDataSource
      );
    } else {
      const url = `/analysis/${modalProps.analysisId}/data-manager/new-source/${definition.id}`;
      modalProps.navigate(url, {
        state: { source: definition },
      });
    }
  };

  return (
    <Modal isOpen={true} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent className="!max-w-[796px] w-full !pb-0 !gap-0">
        <ModalHeader>Add New Dataset</ModalHeader>
        <ModalCloseButton className="!mr-0" />
        <Divider className="mt-4" />
        <ModalBody>
          {isLoading ? (
            <Flex className="items-center justify-center" minH={"40vh"}>
              <Spinner size="sm" />
              <Box className="ml-2" pt="2xl">
                Loading...
              </Box>
            </Flex>
          ) : (
            <Flex className="h-[calc(100vh/1.5)] w-full">
              <AddDatasetNavigation
                connectorSources={connectorSources}
                setSelectedNavValue={setSelectedNavValue}
              />
              <Flex className="flex-col h-full" style={{ width: "inherit" }}>
                <Flex className="items-center text-gray-600 pl-3">
                  <MdsSearchRound fontSize={24} />
                  <Input
                    className="w-full !border-none"
                    onChange={(e) => handleInputChange(e)}
                    placeholder="Search Sources"
                    type="search"
                  />
                </Flex>
                <Divider className="!my-0" />
                <SelectConnector
                  sourceListData={currentSourceList}
                  connectorType="source"
                  onSelectConnectorDefinition={handleConnectorButtonClick}
                />
              </Flex>
            </Flex>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
