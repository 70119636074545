import { Portal, Spinner } from "@chakra-ui/react";
import React from "react";
import { IoShareSocialOutline } from "react-icons/io5";
import { MdArrowOutward, MdMoreVert, MdOutlineEdit } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { TbHeartMinus, TbHeartPlus } from "react-icons/tb";
import { MdsEditRound } from "react-icons-with-materialsymbols/mds";
import { useNavigate, useParams } from "react-router-dom";

import { FolderOpen } from "@/components/icons/folder-open.tsx";
import { ProtectedComponent } from "@/components/protected-component/protected-component";
import { useShowToast } from "@/components/toast";
import { MODAL_MESSAGES } from "@/constants/alerts-messages.ts";
import { TOAST_MESSAGES } from "@/constants/toast-constants.ts";
import { Icon } from "@/design/components/icon";
import { IconButton } from "@/design/components/icon-button";
import { Menu, MenuButton, MenuItem, MenuList } from "@/design/components/menu";
import { useLazyGetworkflowsFromAnalysisQuery } from "@/features/workflow-studio";
import { setWorkFlowId } from "@/features/workflow-studio/redux";
import { setPreviewAnalysis } from "@/features/ws-manager";
import { AnalysesSchema } from "@/features/ws-manager/types";
import { useAppDispatch } from "@/reduxHooks.ts";
import { ModalTypes, openModal } from "@/slices/modal-slice.ts";
import { ACTION_TYPE, CATEGORY, PERMISSIONS } from "@/utils/enums.ts";

import {
  useDeleteAnalysisMutation,
  useUpdateAnalysesMutation,
} from "../../api";

export const AnalysisDropdown = (props: AnalysesSchema) => {
  const [deleteAnalysis] = useDeleteAnalysisMutation();
  const [updateAnalysis] = useUpdateAnalysesMutation();
  const dispatch = useAppDispatch();
  const toast = useShowToast();
  const navigate = useNavigate();
  const params = useParams();

  const [getWorkflowFromAnalysis, { isFetching: isWorkflowFetching }] =
    useLazyGetworkflowsFromAnalysisQuery();

  const fetchWorkflow = async (analysisId: string) => {
    const res = await getWorkflowFromAnalysis({
      analysisId,
    }).unwrap();

    const workflowId = res.response.data?.workflows[0]?.workflowId;
    return workflowId;
  };

  const routeToFlowStudio = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(setPreviewAnalysis(null));
    const analysisId = props.id;
    const url = `/analysis/${analysisId}/data-manager`;
    dispatch(setPreviewAnalysis(null));

    // Open the URL in a new tab
    window.open(url, "_blank");
  };

  const onDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();

    const { project, workspace } = params;
    let parent: string;
    if (project) {
      parent = "project";
    } else if (workspace) {
      parent = "workspace";
    }

    dispatch(
      openModal({
        modalType: ModalTypes.CONFIRMATION,
        modalProps: {
          ...MODAL_MESSAGES.deleteAnalysis,
          primaryAction: () => {
            void deleteAnalysis({
              id: props.id,
              parent: parent,
              parentId: project || workspace,
            }).then(() => {
              toast(TOAST_MESSAGES.analysisDeleted(props.name));
            });
          },
        },
      })
    );
  };
  const onEdit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    dispatch(
      openModal({
        modalType: ModalTypes.ANALYSIS_CREATION,
        modalProps: { analysis: props },
      })
    );
  };

  const openProject = () => {
    navigate(`/home/${props.workspaceId}/project/${props.projectId}`);
  };

  const openManageAccessModal = () => {
    dispatch(
      openModal({
        modalType: ModalTypes.MANAGE_ACCESS,
        modalProps: { object: props, type: CATEGORY.Analysis, navigate },
      })
    );
  };

  const onFavorite = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();

    const action = props.favorite
      ? ACTION_TYPE.UNMARK_FAVORITE
      : ACTION_TYPE.MARK_FAVORITE;
    await updateAnalysis({
      id: props.id,
      action: action,
    })
      .unwrap()
      .then(() => {
        const toastMessage = props.favorite
          ? TOAST_MESSAGES.removeFromFavorites
          : TOAST_MESSAGES.addToFavorites;
        toast({
          ...toastMessage,
        });
      });
  };

  return (
    <Menu placement="bottom-end" closeOnBlur>
      <MenuButton
        onContextMenu={(e) => e.stopPropagation()}
        as={IconButton}
        icon={<MdMoreVert />}
        variant="ghost"
        colorScheme="dark"
        onClick={(e) => {
          e.stopPropagation();
        }}
      />
      <Portal>
        <MenuList onContextMenu={(e) => e.stopPropagation()}>
          <MenuItem
            icon={
              isWorkflowFetching ? (
                <Spinner size="sm" />
              ) : (
                <Icon as={MdArrowOutward} />
              )
            }
            closeOnSelect={false}
            onClick={routeToFlowStudio}
          >
            Edit In Studio
          </MenuItem>
          {/* <MenuItem
            onClick={onFavorite}
            icon={<Icon as={props.favorite ? TbHeartMinus : TbHeartPlus} />}
          >
            {props.favorite ? "Remove from Favorites" : "Add to Favorites"}
          </MenuItem> */}
          <ProtectedComponent
            requiredPermission={PERMISSIONS.WRITE}
            id={props.id}
            type={CATEGORY.Analysis}
          >
            <MenuItem
              onClick={openManageAccessModal}
              icon={<Icon as={IoShareSocialOutline} />}
            >
              Manage Access
            </MenuItem>
          </ProtectedComponent>
          <ProtectedComponent
            requiredPermission={PERMISSIONS.WRITE}
            id={props.id}
            type={CATEGORY.Analysis}
          >
            <MenuItem onClick={onEdit} icon={<Icon as={MdsEditRound} />}>
              Edit Analysis Details
            </MenuItem>
          </ProtectedComponent>
          <MenuItem onClick={openProject} icon={<Icon as={FolderOpen} />}>
            Open Project
          </MenuItem>
          <ProtectedComponent
            requiredPermission={PERMISSIONS.DELETE}
            id={props.id}
            type={CATEGORY.Analysis}
          >
            <MenuItem
              color="red.600"
              onClick={onDelete}
              icon={<Icon as={RiDeleteBin6Line} />}
            >
              Delete Analysis
            </MenuItem>
          </ProtectedComponent>
        </MenuList>
      </Portal>
    </Menu>
  );
};
