import { isEmpty } from "lodash";
import React, { useState, useRef, useEffect, useCallback } from "react";

import { getEditingAllowed } from "@/features/workflow-studio/redux/workflow-slice";
import { useAppDispatch, useAppSelector } from "@/reduxHooks";

import {
  addColumnsStyling,
  escapeHtml,
  getCaretCoordinates,
  getCaretIndex,
} from "../components/llm-prompt-input/helpers";
import {
  getInputColumns,
  getInputPrompt,
  getPreviewVersion,
  isLLMEditable,
  promptErrorColumns,
  setInputPromptText,
  showPromptHistory,
} from "../redux";
import {
  adjustRange,
  createColumnTagSpan,
  getClipboardText,
  insertHtmlAtCaret,
  insertSpanAndAdjustSelection,
  removeChildColumn,
  removePrevColumn,
  shouldRemoveChildColumn,
  shouldRemovePrevColumn,
} from "../utils/editor-utils";

export const useCustomEditor = () => {
  const parentRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const searchString = useRef<string>("");

  const [showDropdown, setShowDropdown] = useState(false);
  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 });
  const [savedRange, setSavedRange] = useState<Range | null>(null);
  const [filteredOptions, setFilteredOptions] = useState<string[]>([]);

  const dispatch = useAppDispatch();
  const isHistoryOpen = useAppSelector(showPromptHistory);
  const previewVersion = useAppSelector(getPreviewVersion);
  const inputColumns = useAppSelector(getInputColumns);
  const inputPromptText = useAppSelector(getInputPrompt);
  const isPromptError = useAppSelector(promptErrorColumns);
  const showProptError = isPromptError && !isHistoryOpen;
  const isLLMEditDisabled = useAppSelector(isLLMEditable);
  const isWFEditAllowed = useAppSelector(getEditingAllowed);

  const isEditAllowed = isWFEditAllowed && !isLLMEditDisabled;

  const getSelection = () => {
    const selection = window.getSelection();
    if (!selection || selection.rangeCount === 0) return;
    return selection;
  };

  useEffect(() => {
    if (inputRef.current) {
      const promptText = isHistoryOpen
        ? previewVersion?.messages[0].content[0].text || ""
        : inputPromptText;

      inputRef.current.innerHTML = addColumnsStyling(promptText, inputColumns);
    }
  }, [isHistoryOpen, previewVersion]);

  const filterOptions = useCallback(
    (query: string) => {
      if (!query || query.length === 0) {
        setFilteredOptions(inputColumns);
      }
      const filtered = inputColumns.filter((option) =>
        option.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredOptions(filtered);
    },
    [inputColumns]
  );

  const saveRange = (): Promise<void> => {
    return new Promise((resolve) => {
      const selection = getSelection();
      if (selection && selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        setSavedRange(range);
      }
      resolve();
    });
  };

  const handleClosedBraces = async (text: string, caretIndex: number) => {
    await saveRange();
    const lastOpenBracePos = text.lastIndexOf("{{", caretIndex - 1);
    const areOpeningBracesPresent =
      lastOpenBracePos !== -1 && caretIndex > lastOpenBracePos + 2;

    if (areOpeningBracesPresent) {
      const searchQuery = text.slice(lastOpenBracePos + 2, caretIndex - 2);
      const hasClosingBraces = text
        .substring(lastOpenBracePos + 2, caretIndex)
        .endsWith("}}");

      const isManuallyTypedMention =
        hasClosingBraces && !isEmpty(searchString.current);

      if (isManuallyTypedMention) {
        insertColumnTag(searchQuery, inputColumns.includes(searchQuery), true);
      }
      setShowDropdown(false); // Close dropdown after inserting
      filterOptions(searchQuery);
    }
  };

  const handleTypingMention = (text: string, caretIndex: number) => {
    const selection = getSelection();
    const range = selection?.getRangeAt(0);
    if (selection && range) setSavedRange(range);

    const { x, y } = getCaretCoordinates();
    setCoordinates({ x: x, y: y + 20 });
    setShowDropdown(true);
    const openBraceIndex = text.lastIndexOf("{{", caretIndex - 2);
    const searchQuery = text.slice(openBraceIndex + 2, caretIndex);
    searchString.current = searchQuery;
    filterOptions(searchQuery); // Filter options based on the search query
  };

  const handleDropdownDisplay = (text: string, caretIndex: number) => {
    const lastOpenBracePos = text.lastIndexOf("{{", caretIndex - 1);
    const areOpeningBracesPresent =
      lastOpenBracePos !== -1 && caretIndex > lastOpenBracePos + 2;
    if (areOpeningBracesPresent) {
      const searchQuery = text.slice(lastOpenBracePos + 2, caretIndex);
      if (!searchQuery.includes("}}")) {
        searchString.current = searchQuery;
      }
      filterOptions(searchQuery);
      setShowDropdown(true);
    } else {
      searchString.current = "";
      setShowDropdown(false);
    }
  };

  const handleInputChange = useCallback(async () => {
    const text = inputRef.current?.textContent || "";
    const caretIndex = getCaretIndex(inputRef.current as HTMLElement);

    const hasTypedOpeningBraces =
      caretIndex !== -1 && text.slice(caretIndex - 2, caretIndex) === "{{";
    const hasTypedClosingBraces =
      caretIndex !== -1 && text.slice(caretIndex - 2, caretIndex) === "}}";

    if (hasTypedClosingBraces) {
      await handleClosedBraces(text, caretIndex);
    } else if (hasTypedOpeningBraces) {
      handleTypingMention(text, caretIndex);
    } else if (showDropdown) {
      handleDropdownDisplay(text, caretIndex);
    }

    dispatch(setInputPromptText(inputRef.current?.innerText ?? ""));
  }, [filterOptions, showDropdown]);

  const insertColumnTag = useCallback(
    (option: string, valid: boolean = true, isCustom = false) => {
      if (savedRange) {
        const span = createColumnTagSpan(option, valid);
        adjustRange(savedRange, searchString, isCustom);
        insertSpanAndAdjustSelection(span, savedRange);
        dispatch(setInputPromptText(inputRef.current?.innerText ?? ""));
        searchString.current = "";
        setShowDropdown(false); // Close dropdown after inserting
      }
    },
    [savedRange]
  );
  const onKeyDownHandle = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === "Backspace") {
        const selection = getSelection();
        if (!selection) return;

        const range = selection.getRangeAt(0);
        const startContainer = range.startContainer;

        if (shouldRemovePrevColumn(range, startContainer)) {
          e.preventDefault();
          removePrevColumn(startContainer);
        } else if (shouldRemoveChildColumn(range, startContainer)) {
          e.preventDefault();
          removeChildColumn(startContainer, range.startOffset);
        }
        dispatch(setInputPromptText(inputRef.current?.innerText ?? ""));
      }
    },
    []
  );

  const handleDropdownSelect = useCallback(
    (option: string) => insertColumnTag(option),
    [insertColumnTag]
  );

  const handleBlur = () => {
    const hasClickedOnInput = document.activeElement === inputRef.current;
    const hasClickedOnDropdown = parentRef.current?.contains(
      document.activeElement
    );

    const hideDropdown = !hasClickedOnDropdown || hasClickedOnInput;
    setTimeout(() => {
      if (hideDropdown) setShowDropdown(false);
    }, 0);
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
    e.preventDefault();
    const clipboardData = getClipboardText(e);
    
    // Only escape HTML characters that could lead to XSS, but preserve quotes
    const safeText = clipboardData.replace(/[&<>]/g, (match) => {
      const map: { [key: string]: string } = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
      }
      return map[match]
    })
    
    const modifiedData = addColumnsStyling(clipboardData, inputColumns)
    dispatch(setInputPromptText(safeText))

    const selection = getSelection()
    insertHtmlAtCaret(modifiedData, selection)
  };

  return {
    inputRef,
    parentRef,
    handleDropdownSelect,
    handleBlur,
    handlePaste,
    onKeyDownHandle,
    handleInputChange,
    showDropdown,
    setShowDropdown,
    inputColumns,
    searchString,
    coordinates,
    setCoordinates,
    savedRange,
    setSavedRange,
    filteredOptions,
    setFilteredOptions,
    showProptError,
    isDisabled: !isEditAllowed,
    dropdownRef,
  };
};
