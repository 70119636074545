import { Button, Flex } from "@chakra-ui/react";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { useContext, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { MdsDoneRound } from "react-icons-with-materialsymbols/mds";
import { useDispatch, useSelector } from "react-redux";

import { useShowToast } from "@/components/toast";
import { TOAST_MESSAGES } from "@/constants/toast-constants.ts";
import { Icon } from "@/design/components/icon";
import {
  columnFromParameter,
  compiledTransformationSteps,
  EdaMetaDataContext,
  ErrorParamProps,
  FORM_DATA_TYPE,
  TransformationStep,
  ViewTransformationParameter,
  parametersFromStep,
  queuedToast,
  selectedStepFilters,
  seperateFilters,
  setCurrentlyAddedStep,
  setRequestId,
  setSelectedStep,
  setTableLoading,
  setToastMessage,
  STEP_STATUS,
  tableColumns,
  tableLoading,
  useAddStepsMutation,
  useEditStepMutation,
  useGetTransformationsQuery,
  CHILD_PARAMETER,
  sorting,
  viewFilters,
} from "@/features/data-transformation";
import { useReadOnlyMode } from "@/features/data-transformation/hooks";
import { getInvalidValues } from "@/features/data-transformation/utils/step-form-validation-helper.ts";

import { FilterForm } from "./filter-form.tsx";
import { FormBody } from "./form-body.tsx";
import { FormHeader } from "./form-header.tsx";
import { StepFormContext } from "./step-form-context.ts";
import { StepMessage } from "./step-message.tsx";

type StepFormProps = {
  step: Partial<TransformationStep> | null;
  isEdit: boolean;
};

export const StepForm = ({ step, isEdit }: StepFormProps) => {
  const dispatch = useDispatch();

  const toast = useShowToast(undefined, undefined, true);
  const metaData = useContext(EdaMetaDataContext);
  const allSteps = useSelector(compiledTransformationSteps);
  const filters = useSelector(selectedStepFilters);
  const isDataLoading = useSelector(tableLoading);
  const tableCols = useSelector(tableColumns);
  const nextToast = useSelector(queuedToast);
  const sort = useSelector(sorting);
  const viewOnlyFilters = useSelector(viewFilters);

  const [errors, setErrors] = useState<ErrorParamProps[]>([]);
  const [isDirty, setIsDirty] = useState(false);

  const [addStepApi, { isLoading }] = useAddStepsMutation();
  const [editStepApi, { isLoading: isEditLoading }] = useEditStepMutation();
  const { data } = useGetTransformationsQuery({});

  const mappedTransformation = useMemo(() => {
    const transformations = data?.response.data?.transformations;
    const currentTransformation = transformations?.find(
      (_transformation) =>
        _transformation.transformationId === step?.transformationId
    );
    return currentTransformation;
  }, [step]);

  const isFilterAllowed =
    step?.isFilterAllowed ?? mappedTransformation?.isFilterAllowed;

  const parameters = useMemo(() => {
    const _parameters = parametersFromStep(
      mappedTransformation!,
      data!.response.data!.parameters,
      {
        mergeParameters: step?.parameters,
      }
    );
    return _parameters;
  }, [step]);

  const defaultValues: Record<string, any> = useMemo(() => {
    // Empty if the step is a new step being added
    if (!step?.transformationStepId) return {};

    const valueMap: Record<string, any> = {};
    parameters.map((param) => {
      const defaultVal = param.value ?? param.defaultValue;
      valueMap[param.shortName] = defaultVal;
    });

    return valueMap;
  }, [parameters]);

  const { register, watch, getValues, setValue, formState, reset, unregister } = useForm({
    defaultValues: defaultValues,
  });

  useEffect(() => {
    reset({ ...defaultValues });
  }, [step]);

  /**
   * Transforms parameters based on whether they have configId or not
   * If parameters have configId, they are grouped by their parent parameter
   * @param formValues - The raw form values
   * @returns Transformed parameters ready for API submission
   */
  const transformParameters = (formValues: ViewTransformationParameter[]): ViewTransformationParameter[] => {
    const hasConfigId = formValues.some(param => param.configId);
    
    if (!hasConfigId) {
      return formValues;
    }

    const parentShortName = formValues[0].parentShortName;
    
    const parentParam = parameters.find(param => param.shortName === parentShortName);
    
    if (!parentParam) {
      return formValues;
    }
    
    const groupedByConfigId: Record<string, ViewTransformationParameter[]> = {};
    
    formValues.forEach(param => {
      const configId = param.configId as string;
      if (!groupedByConfigId[configId]) {
        groupedByConfigId[configId] = [];
      }
      groupedByConfigId[configId].push(param);
    });
    
    const transformedParam: ViewTransformationParameter = {
      ...parentParam,
      parameterId: parentParam.id ?? parentParam.parameterId,
      value: Object.values(groupedByConfigId) as unknown as any
    };
    
    return [transformedParam];
  };

  /**
   * Creates a properly formatted toast props object from toast message
   * @param toastMessage - The raw toast message
   * @returns CustomToastProps compatible object
   */
  const formatToastProps = (toastMessage: any) => {
    return {
      title: toastMessage.title,
      description: toastMessage.description,
      status: toastMessage.status,
      duration: toastMessage.duration,
      // Convert string action to undefined to avoid type error
      action: typeof toastMessage.action === 'function' ? toastMessage.action : undefined
    };
  };

  const createUpdateApi = async (
    _parameters: Partial<ViewTransformationParameter>[]
  ) => {
    const baseData = {
      analysisId: metaData.analysisId!,
      edaId: metaData.edaId!,
      shortName: step?.shortName,
      name: step?.name,
      transformationId: step?.transformationId,
      parameters: _parameters,
      activeStatus: true,
      config: {
        filters: seperateFilters(filters),
      },
      viewFilters: seperateFilters(viewOnlyFilters),
      sort,
    };

    try {
      let reqId;

      if (isEdit) {
        const _data = {
          ...baseData,
          transformationStepId: step?.transformationStepId,
          stepOrder: step?.stepOrder,
        };
        const res = await editStepApi(_data).unwrap();
        reqId = res.response.data?.requestId ?? null;
      } else {
        const _data = {
          ...baseData,
          stepOrder: allSteps.length + 1,
        };
        const res = await addStepApi({
          steps: [_data],
          analysisId: baseData.analysisId,
          edaId: baseData.edaId,
          sort,
          viewFilters: seperateFilters(viewOnlyFilters),
        }).unwrap();
        reqId = res.response.data?.requestId ?? null;

        if (!nextToast) {
          const toastMsg = TOAST_MESSAGES.transformStepApplied(baseData.name ?? "");
          toast(formatToastProps(toastMsg));
        }
      }
      dispatch(setRequestId(reqId));
    } catch (e) {
      if (!isEdit) {
        const colName = columnFromParameter(baseData.parameters);
        alert(
          `Failed to apply ${baseData.name} ${
            colName ? `on ${colName}` : ""
          }. Error Code: 399`
        );
      }
      dispatch(setTableLoading(false));
    }
  };

  useEffect(() => {
    if (!step?.transformationStepId) {
      setIsDirty(true);
      return;
    }

    const originalFilter =
      allSteps.find((_step) => _step.shortName === step?.shortName)?.config
        ?.filters ?? [];
    const shouldDirty = !isEqual(originalFilter, filters);

    setIsDirty(shouldDirty);
  }, [filters]);

  useEffect(() => {
    const subscription = watch((_value, { name }) => {
      if (!name) return;

      validateForm(name);
    });
    return () => subscription.unsubscribe();
  }, [watch, errors, parameters]);

  const mapParameter = (el: any) => {
    const shortName = el?.dataset.shortname;
    
    // Ignore dummy fields used for tracking dirty state
    if (shortName?.endsWith('_dirty_state_tracker')) {
      return null;
    }
    
    // Check if this is a child parameter by looking for the CHILD_PARAMETER pattern
    if (shortName?.includes(CHILD_PARAMETER)) {
      const [, parentShortName, paramShortName, configId] = shortName.split('%%%');
      
      // Find the parent parameter first
      const parentParameter = parameters.find(
        (param) => param.shortName === parentShortName
      );
      
      if (!parentParameter?.childParameters) {
        return null;
      }
      
      // Find the child parameter in the parent's childParameters array
      const parameter = parentParameter.childParameters.find(
        (param) => param.shortName === paramShortName
      );
      
      if (!parameter) {
        return null;
      }

      const hasRegex = parameter.additionalInfo?.regex;
      const shouldTrim = parameter.dataType === FORM_DATA_TYPE.TEXT;
      const value = shouldTrim ? el?.value.trim() : el?.value;

      return {
        ...parameter,
        shortName: paramShortName,
        value: hasRegex ? el?.value : value,
        parameterId: parameter.id ?? parameter.parameterId,
        isMandatory: el?.required,
        configId: configId,
        parentShortName: parentShortName,
      };
    }

    // Handle non-child parameters
    const parameter = parameters.find(
      (param) => param.shortName === shortName
    );
    
    if (!parameter) {
      return null;
    }

    const hasRegex = parameter.additionalInfo?.regex;
    const shouldTrim = parameter.dataType === FORM_DATA_TYPE.TEXT;
    const value = shouldTrim ? el?.value.trim() : el?.value;

    return {
      ...parameter,
      shortName,
      value: hasRegex ? el?.value : value,
      parameterId: parameter.id ?? parameter.parameterId,
      isMandatory: el?.required,
    };
  };

  const validateForm = (shortName: string) => {    
    // Ignore dummy fields used for tracking dirty state
    if (shortName.endsWith('_dirty_state_tracker')) {
      return;
    }
    
    if (shortName.includes(CHILD_PARAMETER)) {
      const [, parentShortName, childShortName, configId] = shortName.split('%%%');
      
      // Find the parent parameter first
      const parentParameter = parameters.find(
        (param) => param.shortName === parentShortName
      );
      
      if (!parentParameter?.childParameters) {
        return;
      }
      
      // Find the child parameter in the parent's childParameters array
      const parameter = parentParameter.childParameters.find(
        (param) => param.shortName === childShortName
      );
      
      if (!parameter) {
        return;
      }
      
      // Use functional update pattern to ensure we're working with the latest state
      setErrors(prevErrors => prevErrors.filter(
        (error) => !(error.shortName === childShortName && error.configId === configId)
      ));
      return;
    }
    
    // Handle regular parameters
    const parameter = parameters.find(
      (param) => param.shortName === shortName
    );
    
    if (!parameter) {
      return;
    }
    
    // Use functional update pattern for regular parameters too
    setErrors(prevErrors => prevErrors.filter(
      (error) => error.shortName !== shortName
    ));
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = e.target as HTMLFormElement;

    const values = Array.from(form)
      .map(mapParameter)
      .filter((value) => value !== null)
      .filter((value) => value.shortName);

    const oldColumns = step?.metadata?.onSample?.previousStepColumnInfo;
    const hasColumns = oldColumns && !isEmpty(oldColumns);
    
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const invalidValues: ErrorParamProps[] | null = getInvalidValues(
      values,
      hasColumns ? oldColumns : tableCols.map((col) => col.name)
    );

    if (invalidValues) {
      setErrors(invalidValues);
      return;
    }

    // Transform parameters using the new function
    const transformedParams = transformParameters(values);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - The values are correctly typed at runtime
    await createUpdateApi(transformedParams);
    
    if (nextToast) {
      toast(formatToastProps(nextToast));
    }
    
    dispatch(setToastMessage(undefined));
    dispatch(setCurrentlyAddedStep(null));
    dispatch(setSelectedStep(null));
  };

  const activeStatus = step?.activeStatus ?? true;
  const stepStatus = activeStatus
    ? step?.status ?? STEP_STATUS.Active
    : STEP_STATUS.Removed;

  const { hasWriteAccess } = useReadOnlyMode();

  const canSubmit =
    hasWriteAccess && !isDataLoading && stepStatus !== STEP_STATUS.Removed;
  const isDoneEnabled = isDirty || formState.isDirty;

  return (
    <StepFormContext.Provider
      value={{ step, editEnabled: canSubmit, register, setValue, unregister, reset, getValues }}
    >
      <Flex className="flex-col w-full h-full bg-white overflow-auto">
        <FormHeader />
        {isFilterAllowed && <FilterForm />}
        <form
          className="flex flex-col w-full h-full overflow-auto py-4 gap-y-6 bg-white"
          onSubmit={onSubmit}
          noValidate={true}
        >
          <Flex className="flex-col w-full h-full overflow-auto gap-y-4 px-3">
            <StepMessage type={stepStatus} />
            <FormBody errors={errors} />
          </Flex>
          {canSubmit && (
            <Button
              className="w-fit !rounded-sm self-end mx-3"
              colorScheme="secondary"
              isDisabled={!isDoneEnabled}
              isLoading={isLoading || isEditLoading}
              rightIcon={<Icon as={MdsDoneRound} size="sm" />}
              size="sm"
              type="submit"
            >
              Done
            </Button>
          )}
        </form>
      </Flex>
    </StepFormContext.Provider>
  );
};
