import { Button, Flex } from "@chakra-ui/react";
import { useContext, useCallback } from "react";
import {
  MdsAddRound,
  MdsArrowForwardRound,
  MdsDeleteRound,
} from "react-icons-with-materialsymbols/mds";

import { FormControl } from "@/design/components/form";
import { Icon } from "@/design/components/icon";
import { useMultiConfigTransformation } from "@/features/data-transformation/hooks";
import {
  ErrorParamProps,
  ViewTransformationParameter,
} from "@/features/data-transformation/types";
import { FORM_DATA_TYPE } from "@/features/data-transformation/utils";

import { StepFormContext } from "../step-form-context.ts";

import { MultiConfigContext } from "./multi-config-context";
import { SelectForm } from "./select-form";
import { TextForm } from "./text-form";


interface MultiConfigFormProps {
  param: Partial<ViewTransformationParameter>;
  errors?: ErrorParamProps[];
}

export const MultiConfigForm = ({ param, errors }: MultiConfigFormProps) => {
  const { editEnabled, setValue } = useContext(StepFormContext)!;

  // Use the custom hook for handling multi-config transformations
  const {
    configs,
    selectedColumns,
    addNewConfig,
    removeConfig,
    updateConfigValue,
    registerSelectedColumn,
    unregisterSelectedColumn
  } = useMultiConfigTransformation(param);


  const renderParameter = useCallback(
    (parameter: ViewTransformationParameter, configId: string) => {
      const commonProps = {
        param: parameter,
        hideLabel: true,
        hideErrorIcon: true,
        id: configId,
        parentShortName: param.shortName,
        onChange: (value: string) =>
          updateConfigValue(configId, parameter, value),
      };

      const paramErrors = errors?.find(
        (error) =>
          error.configId === configId && error.shortName === parameter.shortName
      )?.error;

      switch (parameter.dataType) {
        case FORM_DATA_TYPE.DROPDOWN:
          return (
            <>
              <SelectForm
                key={`${configId}-${parameter.id}`}
                {...commonProps}
                hideHintText={true}
                errors={paramErrors}
                placeholder="Current"
              />
              <Icon as={MdsArrowForwardRound} size="sm" className="mt-1.5" />
            </>
          );
        case FORM_DATA_TYPE.TEXT:
          return (
            <TextForm
              key={`${configId}-${parameter.id}-text`}
              {...commonProps}
              errors={paramErrors}
              placeholder="New"
            />
          );
        default:
          return null;
      }
    },
    [param.shortName, errors, updateConfigValue]
  );

  const showDeleteButton = configs.length > 1 && editEnabled;

  return (
    <FormControl
      key={param.shortName}
      className="w-full"
      isDisabled={!editEnabled}
      isInvalid={!!errors}
      isRequired={param.isMandatory}
    >
      <Flex className="flex-col gap-4 items-center">
        {configs.map((config) => (
          <MultiConfigContext.Provider
            key={config.id}
            value={{
              selectedColumns,
              currentConfigId: config.id,
              registerSelectedColumn,
              unregisterSelectedColumn,
            }}
          >
            <Flex className="flex-row items-start gap-x-2 w-full" key={config.id}>
              <Flex className="flex-1 flex-row items-start gap-x-2">
                {config.config.map((parameter) =>
                  renderParameter(parameter, config.id)
                )}
              </Flex>
              {showDeleteButton ? (
                <Icon
                  className="!cursor-pointer mt-1.5"
                  aria-label="Remove"
                  color="red.500"
                  as={MdsDeleteRound}
                  size="sm"
                  onClick={() => removeConfig(config.id)}
                />
              ) : (
                <div className="w-0" />
              )}
            </Flex>
          </MultiConfigContext.Provider>
        ))}
      </Flex>
      {editEnabled && (
        <Button
          className="mt-4 w-full"
          iconSpacing={0.5}
          leftIcon={<Icon as={MdsAddRound} size="sm" />}
          onClick={addNewConfig}
          size="sm"
          variant="ghost"
        >
          Add More
        </Button>
      )}
    </FormControl>
  );
};
