import { Flex } from "@chakra-ui/react";
import clsx from "clsx";
import { isEmpty } from "lodash";
import { useContext } from "react";
import { MdsPivotTableChartRound } from "react-icons-with-materialsymbols/mds";
import { useDispatch, useSelector } from "react-redux";

import { Icon } from "@/design/components/icon";
import {
  TransformationStep,
  ViewTransformationParameter,
  parametersFromStep,
  setCurrentlyAddedStep,
  setOpenStepPanel,
  setTransformationPanel,
  viewFilters,
} from "@/features/data-transformation";

import { ActiveCategoryContext } from "./active-category-context.ts";

type OperationTileProps = {
  operation: TransformationStep;
  allParameters: Record<string, ViewTransformationParameter>;
};

export const OperationTile = ({
  operation,
  allParameters,
}: OperationTileProps) => {
  const dispatch = useDispatch();
  const { setShortName } = useContext(ActiveCategoryContext)!;
  const currentViewFilters = useSelector(viewFilters);

  const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    const step = {
      ...operation,
      parameters: parametersFromStep(operation, allParameters, {}),
    };
    const addViewFilter =
      operation.isFilterAllowed && !isEmpty(currentViewFilters);

    if (addViewFilter) {
      step.config = { filters: currentViewFilters };
    }
    dispatch(setOpenStepPanel(true));
    dispatch(setCurrentlyAddedStep(step));
    dispatch(setTransformationPanel(false));
  };

  const onHover = () => {
    setShortName(operation.shortName);
  };

  return (
    <Flex
      className={clsx(
        "h-fit w-full px-2 py-3 border border-gray-100 rounded hover:border-gray-400 cursor-pointer text-gray-800 text-sm group"
      )}
      key={operation.id}
      onClick={onClick}
      onMouseEnter={onHover}
    >
      <Icon
        as={MdsPivotTableChartRound}
        size="md"
        className="text-gray-800 mr-1"
      />
      {operation.name}
    </Flex>
  );
};
